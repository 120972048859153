import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
}

const RotateCircle = ({ size, color }: Props) => {
    const { theme } = useThemes()
    return (
        <svg
            width={size ?? '48'}
            height={size ?? '48'}
            viewBox="0 0 48 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="48" height="48" rx="4" fill={colours[theme].primary} />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23.5221 19.0222C20.7726 19.0222 18.5443 21.2505 18.5443 24C18.5443 25.5751 19.2747 26.9788 20.4181 27.892L21.0041 28.3601L20.068 29.5321L19.482 29.0641C17.9974 27.8783 17.0443 26.0503 17.0443 24C17.0443 20.4221 19.9442 17.5222 23.5221 17.5222H24.2721V19.0222H23.5221Z"
                fill={colours[theme].background_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M22.8883 15.8613L25.3632 18.3362L22.8883 20.8111L21.8277 19.7504L23.2419 18.3362L21.8277 16.922L22.8883 15.8613Z"
                fill={colours[theme].background_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M24.4753 28.9778C27.2249 28.9778 29.4531 26.7495 29.4531 24C29.4531 22.359 28.6603 20.9038 27.4335 19.9959L26.8307 19.5497L27.7231 18.344L28.3259 18.7902C29.9185 19.9689 30.9531 21.8636 30.9531 24C30.9531 27.5779 28.0533 30.4778 24.4753 30.4778H23.7253V28.9778H24.4753Z"
                fill={colours[theme].background_default}
            />
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M24.9363 32.1083L22.4614 29.6334L24.9363 27.1585L25.9969 28.2192L24.5827 29.6334L25.9969 31.0476L24.9363 32.1083Z"
                fill={colours[theme].background_default}
            />
            <rect
                x="24"
                y="22"
                width="3"
                height="3"
                rx="1"
                transform="rotate(45 24 22)"
                fill={colours[theme].background_default}
            />
        </svg>
    )
}

export default RotateCircle
