import React, { useEffect, useState } from 'react'
import { ITheme } from './colours'
import { useAppSelector } from '../../redux/store/hooks'

export type IThemesContext = {
    setTheme: (v: ITheme) => void
    theme: ITheme
}

export const ThemesContext = React.createContext<IThemesContext>({
    setTheme: (v: ITheme) => {},
    theme: 'light',
})

export const getPreferredTheme = (): 'dark' | 'light' => {
    if (
        window.matchMedia &&
        window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
        return 'dark'
    }
    return 'light'
}

const ThemesProvider = ({ children }: { children: React.ReactNode }) => {
    const [activeTheme, setActiveTheme] = useState<ITheme>(getPreferredTheme)

    const userPreferredTheme = useAppSelector(
        (state) => state.user.userLoggedIn?.ui_appearance_mode
    )

    const setTheme = (v: ITheme) => {
        setActiveTheme(v)
        if (v === 'dark') {
            document.documentElement.classList.add('dark')
        } else {
            document.documentElement.classList.remove('dark')
        }
    }

    useEffect(() => {
        let systemTheme = getPreferredTheme()

        if (userPreferredTheme) {
            if (userPreferredTheme === 'SYSTEM') {
                if (systemTheme === 'dark') {
                    setTheme('dark')
                } else {
                    setTheme('light')
                }
            } else if (userPreferredTheme === 'DARK') {
                setTheme('dark')
            } else {
                setTheme('light')
            }
        } else if (
            typeof window?.localStorage !== 'undefined' &&
            window?.localStorage !== null &&
            window?.localStorage?.getItem('ui_appearance_mode')
        ) {
            let savedTheme = window?.localStorage?.getItem('ui_appearance_mode')
            if (savedTheme === 'light' || savedTheme === 'dark') {
                setTheme(savedTheme)
            }
        } else {
            if (systemTheme === 'dark') {
                setTheme('dark')
            } else {
                setTheme('light')
            }
        }
    }, [userPreferredTheme])

    const theme: IThemesContext = {
        setTheme: setTheme,
        theme: activeTheme,
    }

    return (
        <ThemesContext.Provider value={theme}>
            {children}
        </ThemesContext.Provider>
    )
}

export default ThemesProvider
