import useThemes from '../../../../providers/theme/hooks'
import StandardCtaBtn from '../../../atoms/Button/standardCtaBtn'
import colours from '../../../../providers/theme/colours'
import image from '../../../../public/assets/images/journey/carProfile/welcome.png'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import Faded from '../../../templates/animated/faded'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import {
    JourneyWelcomeBtnText,
    JourneyWelcomeButtonsWrapper,
    JourneyWelcomeNewLineOnMobile,
    JourneyWelcomeText,
    JourneyWelcomeTextWrapper,
    JourneyWelcomeTitle,
    JourneyWelcomeWrapper,
    JourneyWelcomeSubWrapperV2,
    JourneyWelcomeSubWrapper,
} from '../../../templates/styledcomponents/journeyStyles'
import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import FadedSlower from '../../../templates/animated/FadedSlower'
import grey_placeholder from '../../../../public/assets/placeholder/grey_placeholder.svg'

const JourneyWelcomeImage = styled.img`
    display: flex;
    width: auto;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    /* flex-shrink: 0; */
    object-fit: contain;

    @media ${device.mobile} {
        width: 800px;
        min-height: 50dvh;
    }
    @media (max-height: 880px) {
        max-height: 50dvh;
    }

    @media (max-width: 480px) {
        min-height: auto;
        max-height: 45dvh;
    }
`

const JourneyWelcomeImageWrapper = styled.div`
    display: flex;
    justify-content: center;
    /* flex: 1; */

    @media ${device.beyond_ipad_mobile} {
        margin-top: 2dvh;
        margin-bottom: 2dvh;
        height: 50dvh;
    }
    @media ${device.mobile} {
        /* flex: 0; */
        width: 100dvw;
        max-height: 50dvh;
        /* margin-top: 2dvh;
        min-height: 50dvh; */
    }

    @media (max-width: 480px) {
        max-height: 43dvh;
        margin-top: 2dvh;
    }
`

type Props = {
    start: () => void
    cancel: (carid: string) => void
}

export default function JourneyCarProfileWelcome({ start, cancel }: Props) {
    const { theme } = useThemes()

    return (
        <>
            <DesktopDisplayOnly>
                <Faded>
                    <JourneyWelcomeWrapper>
                        <JourneyWelcomeSubWrapperV2>
                            <JourneyWelcomeTextWrapper>
                                <JourneyWelcomeTitle>
                                    Create a digital profile for your car
                                </JourneyWelcomeTitle>
                                <JourneyWelcomeText>
                                    Embark on a journey to personalize your
                                    Custodian experience! Let's breathe life
                                    into your profile together. Share a glimpse
                                    of your passion, your story, and your
                                    journey.
                                </JourneyWelcomeText>
                            </JourneyWelcomeTextWrapper>
                            <JourneyWelcomeImageWrapper>
                                <FadedSlower isFullWidth={true}>
                                    <JourneyWelcomeImage
                                        src={image}
                                        alt={'image'}
                                        placeholder={grey_placeholder}
                                    />
                                </FadedSlower>
                            </JourneyWelcomeImageWrapper>

                            <JourneyWelcomeButtonsWrapper>
                                <StandardCtaBtn onClick={start}>
                                    Let's get started
                                </StandardCtaBtn>
                                <StandardCtaBtn
                                    onClick={cancel}
                                    boder="transparent"
                                    bg="transparent"
                                    removeShadowOnHover
                                    customWidth="100%"
                                >
                                    <JourneyWelcomeBtnText
                                        style={{
                                            color: colours[theme].primary,
                                        }}
                                    >
                                        Cancel
                                    </JourneyWelcomeBtnText>
                                </StandardCtaBtn>
                            </JourneyWelcomeButtonsWrapper>
                        </JourneyWelcomeSubWrapperV2>
                    </JourneyWelcomeWrapper>
                </Faded>
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <JourneyWelcomeWrapper>
                    <JourneyWelcomeSubWrapper>
                        <JourneyWelcomeTitle
                            style={{
                                fontSize: '26px',
                                lineHeight: '40px',
                            }}
                        >
                            Create a{' '}
                            <span style={{ color: colours[theme].primary }}>
                                digital profile
                            </span>{' '}
                            <JourneyWelcomeNewLineOnMobile />
                            for your car
                        </JourneyWelcomeTitle>
                        <JourneyWelcomeImageWrapper>
                            <Faded width={'auto'}>
                                <JourneyWelcomeImage
                                    src={image}
                                    alt={'image'}
                                    placeholder={grey_placeholder}
                                />
                            </Faded>
                        </JourneyWelcomeImageWrapper>
                        <JourneyWelcomeText>
                            Embark on a journey to personalize your Custodian
                            experience! Let's breathe life into your profile
                            together. Share a glimpse of your passion, your
                            story, and your journey.
                        </JourneyWelcomeText>
                        <JourneyWelcomeButtonsWrapper>
                            <StandardCtaBtn onClick={start} customWidth="100%">
                                Let's get started
                            </StandardCtaBtn>
                        </JourneyWelcomeButtonsWrapper>
                    </JourneyWelcomeSubWrapper>
                </JourneyWelcomeWrapper>
            </IpadAndMobileDisplay>
        </>
    )
}
