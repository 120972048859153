import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { useSetInitialInsurance } from '../../../../../../providers/insurance/insuranceInitialProvider'
import useThemes from '../../../../../../providers/theme/hooks'
import {
    IInsuranceQuoteApplication,
    insuranceActions,
} from '../../../../../../redux/insuranceQuoteApplication/reducer'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../../redux/store/hooks'
import InfoBox from '../../../../../atoms/info/infoBox'
import YesNoRadioInput from '../../../../../molecules/editOrCreateModeSingleFields/yesOrNoRadioInput/yesOrNoRadioInput'
import { ITopWizardCheckActions } from '../../../../../pages/insurance_quote/wizard'
import InsuranceGoBackReusableBottomBar from '../../../../../templates/bars/insurance/bottomBars/insureanceGoBackReusableBottomBar'
import DesktopDisplayOnly from '../../../../../templates/displays/desktopDisplayOnly'
import { device } from '../../../../../templates/displays/devices'
import IpadAndMobileDisplay from '../../../../../templates/displays/ipadAndMobileDisplay'
import { InsuranceFormQuestion } from '../../../formGenerators/renderElementMobile'
import { IRootState } from '../../../../../../redux/store'
import colours, {
    insurance_colours,
} from '../../../../../../providers/theme/colours'
import CircleLoader from '../../../../../atoms/loader/circleLoader'
import FadedSlower from '../../../../../templates/animated/FadedSlower'

const Wrap = styled.div`
    width: 100%;
    padding-top: 8px;
    @media ${device.beyond_ipad_mobile} {
        padding-top: 40;
        width: 566px;
    }
`

type Props = {
    car_id: string
    setTopWizardCheckActions: (p: ITopWizardCheckActions | null) => any
}

const IsInsuredVehicleEverydayWizard = (props: Props) => {
    let { car_id } = props
    let application: IInsuranceQuoteApplication | undefined = useAppSelector(
        (state) => state.insuranceQuoteApplication?.draft
    )

    let isEveryday: boolean =
        application?.everyday_car_ids &&
        application?.everyday_car_ids?.indexOf(car_id) === -1
            ? false
            : true

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    const [isDisabled, setIsDisabled] = useState<boolean>(false)

    const dispatch = useAppDispatch()

    let onToggle = (v: boolean) => {
        dispatch(
            insuranceActions.submit_set_everyday_car_id_request({
                car_id: car_id,
                answer: v,
            })
        )
    }

    let {
        getInitialSectionWizardIds,
        getFirstIncompleteSubsection_Single_InsuredVehicle,
        setCrossFormData,
    } = useSetInitialInsurance()

    let textt =
        'Please note that we do not provide coverage for typical everyday cars. Our team will get back to you with more information regarding the eligibility of your vehicle.'

    const goNext = () => {
        let isSavedDisabled =
            application?.vehicles[`${car_id}`]?.is_everyday === undefined
                ? true
                : false

        if (isSavedDisabled) {
            setIsDisabled(true)
        }

        if (!isSavedDisabled) {
            let ids = getFirstIncompleteSubsection_Single_InsuredVehicle(
                car_id,
                'is_everyday'
            )

            if (ids) {
                let currURL = new URL(window.location.href)
                let params2 = new URLSearchParams(currURL.search)
                params2.delete('section_id')
                params2.delete('car_id')
                params2.delete('driver_id')
                params2.delete('subsection_id')
                params2.append('section_id', `${ids.sectionID}`)
                params2.append('subsection_id', `${ids.sub_sectionID}`)
                if (ids.car_id) {
                    params2.append('car_id', `${ids.car_id}`)
                }
                if (ids.driver_id) {
                    params2.append('driver_id', `${ids.driver_id}`)
                }
                return history.replace({
                    search: params2.toString(),
                })
            }

            let getToAny = () => {
                let ids2 = getInitialSectionWizardIds('vehicles_to_insure')

                if (ids2) {
                    let currURL = new URL(window.location.href)
                    let params2 = new URLSearchParams(currURL.search)
                    params2.delete('section_id')
                    params2.delete('car_id')
                    params2.delete('driver_id')
                    params2.delete('subsection_id')
                    params2.append('section_id', `${ids2.sectionID}`)
                    params2.append('subsection_id', `${ids2.sub_sectionID}`)
                    if (ids2.car_id) {
                        params2.append('car_id', `${ids2.car_id}`)
                    }
                    if (ids2.driver_id) {
                        params2.append('driver_id', `${ids2.driver_id}`)
                    }
                    history.replace({
                        search: params2.toString(),
                    })
                } else {
                    if (ids2 === null) {
                        history.push(
                            `/insurance/application/${application?.id}`
                        )
                    }
                }
            }

            return getToAny()
        }
    }

    const history = useHistory()

    const goPrevious = () => {
        history.push(
            `/insurance/application/${application?.id}/wizard?section_id=insurance_details`
        )
    }

    useEffect(() => {
        props.setTopWizardCheckActions(null)
        return () => {
            setCrossFormData(undefined)
        }
    }, [])

    const { theme } = useThemes()
    let dataLoading = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.isLoading
    )

    return userLoggedIn && application?.id ? (
        <div
            style={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                backgroundColor: colours[theme].background_neutral_subtle,
            }}
        >
            <Wrap>
                <InsuranceFormQuestion $theme={theme}>
                    Is this your everyday vehicle?
                </InsuranceFormQuestion>
                <div style={{ paddingTop: 16 }} />

                {dataLoading ? (
                    <FadedSlower>
                        <div
                            style={{
                                width: '100%',
                                height: 56,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <CircleLoader
                                size={24}
                                color={
                                    insurance_colours[theme].sections[
                                        'vehicles_to_insure'
                                    ].primary_100
                                }
                            />
                        </div>
                    </FadedSlower>
                ) : (
                    <YesNoRadioInput
                        value={isEveryday}
                        isYesActive={isEveryday}
                        isNoActive={!isEveryday}
                        isDisabled={
                            application?.status === 'DRAFT' ? false : true
                        }
                        onChange={(v: boolean) => {
                            if (application?.status === 'DRAFT') {
                                if (isDisabled) {
                                    setIsDisabled(false)
                                }
                                onToggle(v)
                            }
                        }}
                    />
                )}

                <div style={{ width: '100%', paddingTop: 24 }}>
                    <InfoBox text={textt} />
                </div>

                <DesktopDisplayOnly>
                    <InsuranceGoBackReusableBottomBar
                        saveBtnTxt="Continue"
                        isSaveDisabled={isDisabled}
                        onSave={goNext}
                        hasFormChanged={false}
                        goBack={() => goPrevious()}
                        sectionId="vehicles_to_insure"
                    />
                </DesktopDisplayOnly>
                <IpadAndMobileDisplay>
                    <InsuranceGoBackReusableBottomBar
                        saveBtnTxt="Continue"
                        isSaveDisabled={isDisabled}
                        onSave={goNext}
                        hasFormChanged={false}
                        goBack={() => goPrevious()}
                        sectionId="vehicles_to_insure"
                    />
                </IpadAndMobileDisplay>
            </Wrap>
        </div>
    ) : (
        <div />
    )
}

export default IsInsuredVehicleEverydayWizard
