import { IDropdownItem } from 'entityModels'
import { isDateValidMinMax } from '../../helpers/dates'

import {
    IAnyObject,
    IInsuranceQuoteApplication,
} from '../../insuranceQuoteApplication/reducer'

type IInsuranceApplication_fields_with_date_validations_data = {
    [key: string]: {
        minDateID?: string
        maxDateID?: string
        incompatible_with_name: string
        field_name: string
        validation_depends_on_field_id?: string
        expected_value_of_dependable_field_for_validation?:
            | boolean
            | IDropdownItem
    }
}

// below notes:
// residency_date ≥ dob => validation runs only if is_uk_resident_since_birth = false
// license_date ≥ dob
// motoring offence_date ≤ conviction_date
// motoring driving_ban_start_date start  ≥ offence_date  => validation runs only if has_resulted_in_driving_ban = { uid: 'Yes', name: 'Yes'}
// motoring driving_ban_end_date end ≥ driving_ban_start_date => validation runs only if has_resulted_in_driving_ban = { uid: 'Yes', name: 'Yes'}
// non motoring conviction sentence_start_date start ≥ conviction offence_date
// non motoring conviction sentence_end_date end ≥ non motoring conviction sentence_start_date
// early_sentence_release_date ≥ non motoring conviction_start_date
// Claim reported_date ≥ Claim incident_date
// Claim settled_date ≥ Claim reported_date => validation runs only if status = { uid: 'SETTLED', name: 'Settled'}

export const fields_with_date_validations_data: IInsuranceApplication_fields_with_date_validations_data =
    {
        // residency_date: {
        //     minDateID: 'dob',
        //     field_name: 'Residency date',
        //     incompatible_with_name: 'date of birth',
        //     validation_depends_on_field_id: 'is_uk_resident_since_birth',
        //     expected_value_of_dependable_field_for_validation: false,
        // },
        // license_date: {
        //     minDateID: 'dob',
        //     field_name: 'License date',
        //     incompatible_with_name: 'date of birth',
        // },
        offence_date: {
            maxDateID: 'conviction_date',
            field_name: 'Offence date',
            incompatible_with_name: 'date of conviction',
        },
        driving_ban_start_date: {
            minDateID: 'offence_date',
            field_name: 'Driving ban start date',
            incompatible_with_name: 'offence date',
            validation_depends_on_field_id: 'has_resulted_in_driving_ban',
            expected_value_of_dependable_field_for_validation: {
                uid: 'Yes',
                name: 'Yes',
            },
        },
        driving_ban_end_date: {
            minDateID: 'driving_ban_start_date',
            field_name: 'Driving ban end date',
            incompatible_with_name: 'driving ban start date',
            validation_depends_on_field_id: 'has_resulted_in_driving_ban',
            expected_value_of_dependable_field_for_validation: {
                uid: 'Yes',
                name: 'Yes',
            },
        },
        conviction_start_date: {
            minDateID: 'offence_date',
            incompatible_with_name: 'offence start date',
            field_name: 'Conviction start date',
        },
        sentence_start_date: {
            minDateID: 'offence_date',
            incompatible_with_name: 'offence start date',
            field_name: 'Sentence start date',
        },

        sentence_end_date: {
            field_name: 'Sentence end date',
            minDateID: 'sentence_start_date',
            incompatible_with_name: 'offence start date',
        },
        early_sentence_release_date: {
            minDateID: 'conviction_start_date',
            field_name: 'Early sentence release_date',
            incompatible_with_name: 'conviction start date',
        },
        reported_date: {
            minDateID: 'incident_date',
            field_name: 'Reported date',
            incompatible_with_name: 'date of incident',
        },
        settled_date: {
            minDateID: 'reported_date',
            incompatible_with_name: 'reported date',
            field_name: 'Settled date',
            validation_depends_on_field_id: 'status',
            expected_value_of_dependable_field_for_validation: {
                uid: 'SETTLED',
                name: 'Settled',
            },
        },
    }

export const fields_with_date_validations_ids: string[] = Object.keys(
    fields_with_date_validations_data
)

export type IInsuranceApplicationDatesValidationArg = {
    application_edited?: IInsuranceQuoteApplication
    section?: 'claim' | 'conviction' | 'license' | 'motoring_conviction'
    other_driver_id?: string
    entity?: IAnyObject
    fieldID: string
    valueToCheck: any
    currErrBoolean?: boolean
    value_of_dependable_field?: boolean
    expected_value_of_dependable_field_for_validation?: boolean
}

export type IsInsuranceApplicationDateValid_result = {
    isValid: boolean
    error_txt?: string
}
export const isInsuranceApplicationDateValid = (
    p: IInsuranceApplicationDatesValidationArg
): IsInsuranceApplicationDateValid_result => {
    let isValid = p.currErrBoolean ?? true
    let error_txt: string | undefined = undefined

    let minDate: any | undefined = undefined
    let maxDate: any | undefined = undefined

    let minDateFieldID = fields_with_date_validations_data[p.fieldID]?.minDateID
    let maxDateFieldID = fields_with_date_validations_data[p.fieldID]?.maxDateID

    let name = fields_with_date_validations_data[p.fieldID]?.field_name
    let incompatible_with_name =
        fields_with_date_validations_data[p.fieldID]?.incompatible_with_name

    // in cases where data needs to be from somewhere else

    if (p.section === 'license' && p.application_edited) {
        if (minDateFieldID === 'dob') {
            minDate = p.other_driver_id
                ? p.application_edited &&
                  p.application_edited.other_drivers[p.other_driver_id] &&
                  p.application_edited.other_drivers[p.other_driver_id] &&
                  p.application_edited.other_drivers[p.other_driver_id]
                      .basic_details &&
                  p.application_edited.other_drivers![p.other_driver_id]
                      .basic_details!['dob']
                : p.application_edited &&
                  p.application_edited.main_driver &&
                  p.application_edited.main_driver &&
                  p.application_edited.main_driver.basic_details &&
                  p.application_edited.main_driver!.basic_details!['dob']
        } else if (maxDateFieldID === 'dob') {
            minDate = p.other_driver_id
                ? p.application_edited &&
                  p.application_edited.other_drivers[p.other_driver_id] &&
                  p.application_edited.other_drivers[p.other_driver_id] &&
                  p.application_edited.other_drivers[p.other_driver_id]
                      .basic_details &&
                  p.application_edited.other_drivers![p.other_driver_id]
                      .basic_details!['dob']
                : p.application_edited &&
                  p.application_edited.main_driver &&
                  p.application_edited.main_driver &&
                  p.application_edited.main_driver.basic_details &&
                  p.application_edited.main_driver!.basic_details!['dob']
        } else {
            if (minDateFieldID) {
                minDate = p.other_driver_id
                    ? p.application_edited &&
                      p.application_edited.other_drivers[p.other_driver_id] &&
                      p.application_edited.other_drivers[p.other_driver_id] &&
                      p.application_edited.other_drivers[p.other_driver_id]
                          .licence_address &&
                      p.application_edited.other_drivers![p.other_driver_id]
                          .licence_address![minDateFieldID]
                    : p.application_edited &&
                      p.application_edited.main_driver &&
                      p.application_edited.main_driver &&
                      p.application_edited.main_driver.licence_address &&
                      p.application_edited.main_driver!.licence_address![
                          minDateFieldID
                      ]
            }

            if (maxDateFieldID) {
                maxDate = p.other_driver_id
                    ? p.application_edited &&
                      p.application_edited.other_drivers[p.other_driver_id] &&
                      p.application_edited.other_drivers[p.other_driver_id] &&
                      p.application_edited.other_drivers[p.other_driver_id]
                          .licence_address &&
                      p.application_edited.other_drivers![p.other_driver_id]
                          .licence_address![maxDateFieldID]
                    : p.application_edited &&
                      p.application_edited.main_driver &&
                      p.application_edited.main_driver &&
                      p.application_edited.main_driver.licence_address &&
                      p.application_edited.main_driver!.licence_address![
                          maxDateFieldID
                      ]
            }
        }
    } else if (p.entity) {
        if (minDateFieldID) {
            minDate = p.entity[minDateFieldID]
        }
        if (maxDateFieldID) {
            maxDate = p.entity[maxDateFieldID]
        }
    }

    if (
        p.value_of_dependable_field !== undefined &&
        p.expected_value_of_dependable_field_for_validation !== undefined
    ) {
        if (
            p.value_of_dependable_field ===
            p.expected_value_of_dependable_field_for_validation
        ) {
            if (minDate || maxDate) {
                isValid = isDateValidMinMax({
                    minDate: minDate,
                    maxDate: maxDate,
                    value: p.valueToCheck,
                })

                if (!isValid) {
                    error_txt = `${name} is incompatible with ${incompatible_with_name}.`
                }
            }
        }
    } else {
        if (minDate || maxDate) {
            isValid = isDateValidMinMax({
                minDate: minDate,
                maxDate: maxDate,
                value: p.valueToCheck,
            })

            if (!isValid) {
                error_txt = `${name} is incompatible with ${incompatible_with_name}.`
            }
        }
    }

    return { isValid, error_txt }
}
