import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

type Props = {
    size?: string
    color?: string
}

export default function DarkThemeIcon({ size, color }: Props) {
    const { theme } = useThemes()
    return (
        <svg
            width={size ? size : '24'}
            height={size ? size : '24'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.7993 2.25C7.41454 2.25 3.04932 6.61522 3.04932 12C3.04932 17.3848 7.41454 21.75 12.7993 21.75C14.4621 21.75 16.0275 21.334 17.3974 20.6C17.1793 20.6166 16.959 20.625 16.7368 20.625C11.9734 20.625 8.11182 16.7635 8.11182 12C8.11182 7.23654 11.9734 3.375 16.7368 3.375C16.959 3.375 17.1793 3.38342 17.3974 3.39995C16.0275 2.66596 14.4621 2.25 12.7993 2.25ZM1.54932 12C1.54932 5.7868 6.58611 0.75 12.7993 0.75C16.2885 0.75 19.4071 2.33933 21.4694 4.83075C21.7039 5.11401 21.6984 5.52547 21.4565 5.8024C21.2146 6.07933 20.8076 6.14004 20.4954 5.94577C19.4049 5.26713 18.1178 4.875 16.7368 4.875C12.8018 4.875 9.61182 8.06497 9.61182 12C9.61182 15.935 12.8018 19.125 16.7368 19.125C18.1178 19.125 19.4049 18.7329 20.4954 18.0542C20.8076 17.86 21.2146 17.9207 21.4565 18.1976C21.6984 18.4745 21.7039 18.886 21.4694 19.1692C19.4071 21.6607 16.2885 23.25 12.7993 23.25C6.58611 23.25 1.54932 18.2132 1.54932 12Z"
                fill={color ? color : colours[theme].text_default}
            />
        </svg>
    )
}
