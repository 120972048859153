import { IDropdownItem } from 'entityModels'
import { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useSetInitialInsurance } from '../../../../../../providers/insurance/insuranceInitialProvider'
import {
    IAnyObject,
    IAnyErrorString,
    insuranceActions,
    IInsuranceQuoteApplication,
} from '../../../../../../redux/insuranceQuoteApplication/reducer'
import { insurance_fields_other_driver_additional_details } from '../../../../../../redux/localdata/insuranceLocalData/fieldTypes'
import { IRootState } from '../../../../../../redux/store'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../../redux/store/hooks'
import InsuranceApplicationMobileSearch from '../../../../../pages/insurance_quote/flows/search/mobileSearch'
import { ITopWizardCheckActions } from '../../../../../pages/insurance_quote/wizard'

import InsuranceGoBackReusableBottomBar from '../../../../../templates/bars/insurance/bottomBars/insureanceGoBackReusableBottomBar'

import DesktopDisplayOnly from '../../../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../../../templates/displays/ipadAndMobileDisplay'
import PortalInvisibleWrapper from '../../../../../templates/displays/pageWrappers/portalInvisibleWrapper'
import InsuranceQuoteFormGenerator from '../../../formGenerators'
import { ISubfieldsValidationDraftCheck } from '../mainPolicyHolder/additionalDetails'
import RemoveMobileSearchAnchor from '../../../../../templates/insurance/removeMobileSearchAnchor'
import colours from '../../../../../../providers/theme/colours'
import useThemes from '../../../../../../providers/theme/hooks'
type Props = {
    driver_id: string
    setTopWizardCheckActions: (p: ITopWizardCheckActions | null) => any
}

const InsuranceOtherDriverAdditionalDetailsApplicationWizardForm = (
    props: Props
) => {
    let userid = `${props.driver_id}`

    const history = useHistory()
    const dispatch = useAppDispatch()

    const data_draft: IAnyObject = useAppSelector((state) => {
        return (
            state.insuranceQuoteApplication.draft.other_drivers[userid]
                ?.additional_details ?? {}
        )
    })

    const entitiesObjArr = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft.other_drivers[userid] &&
            state.insuranceQuoteApplication.draft.other_drivers[userid].entities
            ? state.insuranceQuoteApplication.draft.other_drivers[userid]
                  .entities
            : undefined
    })

    const data_submitted: IAnyObject = useAppSelector((state) => {
        return (
            state.insuranceQuoteApplication.submitted_data.other_drivers[userid]
                ?.additional_details ?? {}
        )
    })

    const errors: IAnyErrorString | undefined = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft.other_drivers[userid]
            ?.additional_details_errors
    })

    const [isSavedDisabled, setIsSavedDisabled] = useState(false)
    const [hasFormChanged, setHasFormChanged] = useState(false)

    let setError = (id: string, error: string | undefined) => {
        dispatch(
            insuranceActions.set_error_insurance_other_driver_additionalDetails(
                {
                    id: id,
                    error: error,
                    userid: userid,
                }
            )
        )
    }

    const subfield_to_check_bool_and_data_obj_check: ISubfieldsValidationDraftCheck =
        {
            has_occupation_secondary: {
                required_fields_if_true: [
                    'occupation_secondary',
                    'industry_secondary',
                ],
            },
            has_disabilities: {
                required_entity_if_true: {
                    name: 'disability',
                    uid: 'disabilities',
                },
            },
        }

    const validateEntities = (): boolean => {
        let hasErr = false
        let idss = Object.keys(subfield_to_check_bool_and_data_obj_check)

        for (let i = 0; i < idss.length; i++) {
            let key = idss[i]
            if (data_draft[key] === true || data_draft[key]?.uid === 'Yes') {
                if (
                    subfield_to_check_bool_and_data_obj_check[key] &&
                    subfield_to_check_bool_and_data_obj_check[key]
                        .required_fields_if_true
                ) {
                    for (
                        let a = 0;
                        a <
                        subfield_to_check_bool_and_data_obj_check[key]!
                            .required_fields_if_true!.length;
                        a++
                    ) {
                        let required_field_id =
                            subfield_to_check_bool_and_data_obj_check[key]!
                                .required_fields_if_true![a]

                        if (!data_draft[required_field_id]) {
                            hasErr = true
                            setError(required_field_id, 'Please specify.')
                        }
                    }
                }

                if (
                    subfield_to_check_bool_and_data_obj_check[key] &&
                    subfield_to_check_bool_and_data_obj_check[key]
                        .required_entity_if_true
                ) {
                    let entityUID =
                        subfield_to_check_bool_and_data_obj_check[key]!
                            .required_entity_if_true!.uid

                    let entityName =
                        subfield_to_check_bool_and_data_obj_check[key]!
                            .required_entity_if_true!.name

                    // @ts-ignore
                    if (entitiesObjArr[entityUID]?.length === 0) {
                        hasErr = true
                        setError(key, `Please add the ${entityName} below.`)
                    }

                    if (
                        // @ts-ignore
                        entitiesObjArr[entityUID] &&
                        // @ts-ignore
                        entitiesObjArr[entityUID][0]
                    ) {
                        // @ts-ignore
                        const obj = entitiesObjArr[entityUID][0]
                        let entityKeys = !obj ? [] : Object.keys(obj)

                        if (entityKeys.length === 0) {
                            hasErr = true
                            setError(
                                key,
                                `Please add your ${entityName} below.`
                            )
                        }
                        for (let x = 0; x < entityKeys.length; x++) {
                            if (
                                entityKeys[x] &&
                                obj[entityKeys[x]] === undefined
                            ) {
                                hasErr = true
                                setError(
                                    key,
                                    `Please fill in all the ${entityName} fields.`
                                )
                            }
                        }
                    }
                    if (
                        // @ts-ignore
                        entitiesObjArr[entityUID]
                    ) {
                        // @ts-ignore
                        let arrayOfObjToCheck = [...entitiesObjArr[entityUID]]

                        arrayOfObjToCheck.map((obj, i) => {
                            let objTocheck = { ...obj }
                            if (objTocheck[`sentence`] === undefined) {
                                delete objTocheck[`sentence`]
                            }
                            if (objTocheck[`driving_ban`] === undefined) {
                                delete objTocheck[`driving_ban`]
                            }

                            if (
                                Object.values(objTocheck).some(
                                    (v) => v === undefined
                                )
                            ) {
                                hasErr = true
                                setError(
                                    key,
                                    `Please fill in all the ${entityName} fields.`
                                )
                            }
                        })
                    }
                }
            }
        }

        return hasErr
    }

    const validateOnClick = (): boolean => {
        let hasErr2 = false
        for (
            let i = 0;
            i < insurance_fields_other_driver_additional_details.length;
            i++
        ) {
            if (
                insurance_fields_other_driver_additional_details[i]
                    .is_required === true
            ) {
                if (
                    data_draft[
                        insurance_fields_other_driver_additional_details[i].id
                    ] === undefined
                ) {
                    setError(
                        insurance_fields_other_driver_additional_details[i].id,
                        'Required field.'
                    )

                    hasErr2 = true
                }
            }
        }

        let hasEntityErr = validateEntities()

        if (hasEntityErr === true) {
            hasErr2 = true
        }

        return hasErr2
    }

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    let {
        setInitialInsurance_Drivers_AdditionalDetails_dropdowns,
        getFirstIncompleteSubsection_OtherDriver,
        getInitialSectionWizardIds,
        setCrossFormData,
    } = useSetInitialInsurance()

    useEffect(() => {
        setInitialInsurance_Drivers_AdditionalDetails_dropdowns()
    }, [userLoggedIn])

    const { search } = useLocation()
    let queryParams = new URLSearchParams(search)
    let field_id = queryParams.get('field_id')
    let entity = queryParams.get('entity')
    let entity_index = queryParams.get('entity_index')

    let applicationData: IInsuranceQuoteApplication = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.submitted_data
    )

    const goPrevious = () => {
        history.push(
            `/insurance/application/${applicationData?.id}/wizard?section_id=drivers&subsection_id=licence_address&driver_id=${userid}`
        )
    }

    const goNext = () => {
        let ids = getFirstIncompleteSubsection_OtherDriver(
            userid,
            'additional_details'
        )

        if (ids) {
            let currURL = new URL(window.location.href)
            let params2 = new URLSearchParams(currURL.search)
            params2.delete('section_id')
            params2.delete('car_id')
            params2.delete('driver_id')
            params2.delete('subsection_id')
            params2.append('section_id', `${ids.sectionID}`)
            params2.append('subsection_id', `${ids.sub_sectionID}`)
            if (ids.car_id) {
                params2.append('car_id', `${ids.car_id}`)
            }
            if (ids.driver_id) {
                params2.append('driver_id', `${ids.driver_id}`)
            }
            return history.replace({
                search: params2.toString(),
            })
        }

        let getToAny = () => {
            let ids2 = getInitialSectionWizardIds('drivers')

            if (ids2) {
                let currURL = new URL(window.location.href)
                let params2 = new URLSearchParams(currURL.search)
                params2.delete('section_id')
                params2.delete('car_id')
                params2.delete('driver_id')
                params2.delete('subsection_id')
                params2.append('section_id', `${ids2.sectionID}`)
                params2.append('subsection_id', `${ids2.sub_sectionID}`)
                if (ids2.car_id) {
                    params2.append('car_id', `${ids2.car_id}`)
                }
                if (ids2.driver_id) {
                    params2.append('driver_id', `${ids2.driver_id}`)
                }
                history.replace({
                    search: params2.toString(),
                })
            } else history.push(`/insurance/application/${applicationData?.id}`)
        }

        return getToAny()
        // next of the driver that is 100%, I should do the same for cars
    }
    const saveForm = () => {
        let hasErr2 = validateOnClick()
        if (!hasErr2) {
            dispatch(
                insuranceActions.submit_otherDriver_additionalDetails_request({
                    driver_id: userid,
                })
            )
            setTimeout(() => {
                goNext()
            }, 100)
        } else {
            setIsSavedDisabled(true)
        }
    }

    let currURL = `/insurance/application/${applicationData?.id}/wizard?section_id=drivers&subsection_id=additional_details&driver_id=${userid}`

    useEffect(() => {
        props.setTopWizardCheckActions({
            sectionID: 'drivers',
            onSave: () => saveForm(),
            has_changes: false,
        })
        return () => {
            setCrossFormData(undefined)
        }
    }, [])

    let onAnswerChange = (id: string, answer: any) => {
        dispatch(
            insuranceActions.set_answer_other_driver_additionalDetails({
                id: id,
                answer: answer,
                userid: userid,
            })
        )
        if (isSavedDisabled) {
            setIsSavedDisabled(false)
        }
        if (hasFormChanged === false) {
            setHasFormChanged(true)
            props.setTopWizardCheckActions({
                sectionID: 'drivers',
                onSave: () => saveForm(),
                has_changes: true,
            })
        }
    }

    const { theme } = useThemes()

    return userLoggedIn && applicationData?.id ? (
        <div
            style={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                backgroundColor: colours[theme].background_neutral_subtle,
            }}
        >
            <DesktopDisplayOnly>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '566px',
                        paddingBottom: 120,
                    }}
                >
                    <InsuranceQuoteFormGenerator
                        driverid={userid}
                        sectionID="drivers"
                        list={insurance_fields_other_driver_additional_details}
                        onAnswerChange={onAnswerChange}
                        data={data_draft}
                        errors={errors}
                        setError={setError}
                        entitiesObjArr={entitiesObjArr}
                        setEntityArr={(
                            arr: IAnyObject[],
                            fieldID: string,
                            has_error?: {
                                entityTypeID: string
                                entityUID: string
                            }
                        ) => {
                            if (fieldID === 'disabilities') {
                                setError('has_disabilities', undefined)
                            }

                            if (hasFormChanged === false) {
                                setHasFormChanged(true)
                            }

                            if (isSavedDisabled) {
                                setIsSavedDisabled(false)
                            }

                            dispatch(
                                insuranceActions.set_entity_arr_other_driver({
                                    arr: arr,
                                    fieldID: fieldID,
                                    userid: userid,
                                    has_error,
                                })
                            )
                        }}
                    />
                </div>
                <div style={{ paddingTop: '200px' }} />

                <InsuranceGoBackReusableBottomBar
                    saveBtnTxt="Save & Continue"
                    isSaveDisabled={isSavedDisabled ? true : false}
                    onSave={saveForm}
                    hasFormChanged={hasFormChanged}
                    goBack={() => goPrevious()}
                    sectionId="drivers"
                />
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <RemoveMobileSearchAnchor />
                <div style={{ width: '100vw' }}>
                    {field_id ? (
                        <PortalInvisibleWrapper>
                            <InsuranceApplicationMobileSearch
                                sectionId="drivers"
                                urlBack={currURL}
                                draft={data_draft}
                                submitted={data_submitted}
                                onChange={(value: IDropdownItem) => {
                                    if (hasFormChanged === false) {
                                        setHasFormChanged(true)
                                    }

                                    if (
                                        field_id &&
                                        errors &&
                                        errors[field_id]
                                    ) {
                                        setError(field_id, undefined)
                                    }

                                    if (
                                        field_id &&
                                        errors &&
                                        errors[field_id]
                                    ) {
                                        setError(field_id, undefined)
                                    }

                                    if (
                                        entity !== null &&
                                        entity_index !== null
                                    ) {
                                        let roota: IAnyObject[] =
                                            entitiesObjArr &&
                                            // @ts-ignore
                                            entitiesObjArr[`${entity}`]
                                                ? [
                                                      // @ts-ignore
                                                      ...entitiesObjArr[
                                                          `${entity}`
                                                      ],
                                                  ]
                                                : []

                                        roota[+entity_index] = {
                                            ...roota[+entity_index],
                                            [`${field_id}`]: value,
                                        }

                                        dispatch(
                                            insuranceActions.set_entity_arr_other_driver(
                                                {
                                                    arr: [...roota],
                                                    fieldID: entity,
                                                    userid: userid,
                                                }
                                            )
                                        )
                                    } else
                                        dispatch(
                                            insuranceActions.set_answer_other_driver_additionalDetails(
                                                {
                                                    id: `${field_id}`,
                                                    answer: value,
                                                    userid: userid,
                                                }
                                            )
                                        )
                                }}
                            />
                        </PortalInvisibleWrapper>
                    ) : (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                width: '100%',
                                paddingBottom: 120,
                                backgroundColor:
                                    colours[theme].background_neutral_subtle,
                            }}
                        >
                            <div
                                style={{
                                    width: '88vw',
                                }}
                            >
                                <InsuranceQuoteFormGenerator
                                    driverid={userid}
                                    sectionID="drivers"
                                    list={
                                        insurance_fields_other_driver_additional_details
                                    }
                                    onAnswerChange={onAnswerChange}
                                    data={data_draft}
                                    errors={errors}
                                    setError={setError}
                                    mobileSearchPickerSectionURL={currURL}
                                    entitiesObjArr={entitiesObjArr}
                                    setEntityArr={(
                                        arr: IAnyObject[],
                                        fieldID: string,
                                        has_error?: {
                                            entityTypeID: string
                                            entityUID: string
                                        }
                                    ) => {
                                        if (fieldID === 'disabilities') {
                                            setError(
                                                'has_disabilities',
                                                undefined
                                            )
                                        }

                                        if (isSavedDisabled) {
                                            setIsSavedDisabled(false)
                                        }

                                        if (hasFormChanged === false) {
                                            setHasFormChanged(true)
                                        }

                                        dispatch(
                                            insuranceActions.set_entity_arr_other_driver(
                                                {
                                                    arr: arr,
                                                    fieldID: fieldID,
                                                    userid: userid,
                                                    has_error,
                                                }
                                            )
                                        )
                                    }}
                                />

                                <div style={{ paddingTop: '200px' }} />
                            </div>
                            <InsuranceGoBackReusableBottomBar
                                isMobile
                                saveBtnTxt="Save & Continue"
                                isSaveDisabled={isSavedDisabled ? true : false}
                                onSave={saveForm}
                                hasFormChanged={hasFormChanged}
                                goBack={() => goPrevious()}
                                sectionId="drivers"
                            />
                        </div>
                    )}
                </div>
            </IpadAndMobileDisplay>
        </div>
    ) : (
        <div />
    )
}

export default InsuranceOtherDriverAdditionalDetailsApplicationWizardForm
