import { IOnboardingContentReducer } from 'myInterfaces'
import onboarding_last_step_icon_2 from '../../../public/assets/icons/onboarding_last_step_icon_2.svg'
import onboarding_last_step_icon_3 from '../../../public/assets/icons/onboarding_last_step_icon_3.svg'
import onboarding_last_step_icon_4 from '../../../public/assets/icons/onboarding_last_step_icon_4.svg'
import onboarding_last_step_icon_5 from '../../../public/assets/icons/onboarding_last_step_icon_5.svg'
import onboarding_last_step_icon_6 from '../../../public/assets/icons/onboarding_last_step_icon_6.svg'

// import app_screenshots_placeholder_6 from '../../../public/assets/placeholder/app_screenshots_placeholder_6.png'

export const onboarding_content: IOnboardingContentReducer = {
    list: [0, 1, 2, 3, 4, 5, 6],
    content: {
        0: {
            title: 'Nighttime or Daytime driving',
            text: 'Choose your preferred appearance for the Custodian platform.',
            desktopRightSideTitle: 'Welcome to Custodian!',
            desktopRightSideSubtitle:
                "Let's get your car in here and start your automotive journey.",
        },
        1: {
            title: 'Let’s get started!',
            text: 'Please enter your car registration information to pre-populate information about your vehicle in the platform.',
            desktopRightSideTitle: 'Welcome to Custodian!',
            desktopRightSideSubtitle:
                "Let's get your car in here and start your automotive journey.",
        },
        2: {
            title: "Let's confirm some details",
            text: "By sharing your car's information with us, you're taking the first step in optimising your automotive life.",
            desktopRightSideTitle: "Don't forget the details!",
            desktopRightSideSubtitle:
                'They make all the difference in your car management experience.',
            dvlaText:
                "We've already pulled the important details of your car from DVLA based on the registration number you provided. If everything looks good, you can simply move on to the next step. ",
            nonDvlaText:
                "If your car is registered outside the UK, you'll need to fill in its details manually. Don't worry, it's quick and easy - simply click on the relevant field and enter the information. Thanks for your understanding!",
            findByRegNoTitle: 'Find your car',
            findByRegNoText:
                "Please enter the country where your car is registered and its registration number. If it's a UK registered car, our system will pull the details for you automatically.",
        },
        3: {
            title: 'Now for some paint!',
            text: "Upload your car's profile photo and a short bio highlighting why its special to you.",
            mutedText:
                'png | jpg | jpeg | Max file size: iOS(5MB), Desktop (20MB)',
            carBioPlaceholder:
                'One of just 600 ever made, this E30 M3 featured a four-cylinder, 2.5 litre engine and produced 238bhp. Restored to original specification and condition.',
        },

        4: {
            title: 'Congratulations!',
            text1: 'You’ve successfully added your first car.',
            text2: 'Check out what else you can do.',
            data: [
                {
                    icon_src: onboarding_last_step_icon_2,
                    title: 'Complete your digital garage',
                    text: 'Finish setting up you profile, add car images, details and highlights.',
                    linkTo: '/garage?tutorial=true',
                },
                {
                    icon_src: onboarding_last_step_icon_3,
                    title: 'Digitise your history file',
                    text: `Protect your car’s provenance with a fast, searchable backup of your car’s history.`,
                    linkTo: '/history-files',
                },
                {
                    icon_src: onboarding_last_step_icon_6,
                    title: 'Browse the Showroom',
                    text: `Whether you’re looking for your next pride and joy, or just tempting yourself, there’s something for everyone in our enthusiast and collectible car marketplace.`,
                    linkTo: '/history-files',
                },
                // {
                //     icon_src: onboarding_last_step_icon_4,
                //     title: 'Stay organised',
                //     text: 'Keep on top of maintenance with our task management system.',
                //     linkTo: '/tasks?tutorial=true',
                // },
                {
                    icon_src: onboarding_last_step_icon_4,
                    title: 'Get Insured',
                    text: 'Handpicked policies built for your collection.',
                    linkTo: '/insurance',
                },
                {
                    icon_src: onboarding_last_step_icon_5,
                    title: 'Explore the Apex',
                    text: 'Weekly podcasts, stories and news to keep you up-to-date.',
                    linkTo: '/apex',
                },
            ],
        },
    },
}
