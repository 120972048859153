import { call, fork, put, select, take } from 'redux-saga/effects'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { ICustomErrorData, IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IAnyObject,
    IInsuranceQuoteApplication,
    insuranceActions,
} from './../../reducer'

import { gen_draft_differing_values_to_patch } from '../../../conversions/insuranceApplication/convertDraftToAPIPatchObject'
import { IRootState } from '../../../store'
import { api } from '../../../services'
import { ValidateAndAddStatsToApplication } from '../application/get'
import posthog from 'posthog-js'
import * as unitGenerator from '../../../../helpers/units/unitConversion'
import { IDeleteInsuranceApplication_req } from '../../../services/insuranceApplication/deleteEntity'
import { message } from 'antd'

export let insurance_application_draft_state_select = (state: IRootState) =>
    state.insuranceQuoteApplication.draft

export let insurance_application_existing_state_select = (
    state: IRootState
): IInsuranceQuoteApplication => state.insuranceQuoteApplication.submitted_data

export type ICarID = {
    carid: string
    isCrossForm?: boolean
    message?: string
}

// const fields_to_not_send_if_residential_true: string[] = [
//     'parking_place_same_day_night',
//     'night_park_address_id',
//     'day_park_address_id',
//     'eveningtime_location',
//     'eveningtime_parking_info',
//     'daytime_location',
//     'daytime_parking_info',
// ]

export function* Submit_car_mileageParking(p: ICarID): any {
    posthog.capture('Update insurance application car mileage parking')

    let all_existing: IInsuranceQuoteApplication = yield select(
        insurance_application_existing_state_select
    )

    let external_car_ids = all_existing.external_car_ids

    let car_is_external: boolean =
        external_car_ids && external_car_ids.indexOf(p.carid) !== -1
            ? true
            : false

    try {
        // global func to convert and check any data thats not the same ?
        // yield call(api. ENDPOINT)

        let all_draft: IInsuranceQuoteApplication = yield select(
            insurance_application_draft_state_select
        )

        let draft = { ...all_draft.vehicles[p.carid].mileage_parking }
        let existing = { ...all_existing.vehicles[p.carid].mileage_parking }

        let d1 = { ...all_draft.vehicles[p.carid].daytime_parking_info }

        let draft_car_parking_day = gen_draft_differing_values_to_patch({
            existing: {},
            draft: d1,
        })

        draft_car_parking_day = draft_car_parking_day ?? {}

        let d2 = { ...all_draft.vehicles[p.carid].eveningtime_parking_info }

        let draft_car_parking_evening = gen_draft_differing_values_to_patch({
            existing: {},
            draft: d2,
        })

        draft_car_parking_evening = draft_car_parking_evening ?? {}

        let existing_car_parking_day = {
            ...all_existing.vehicles[p.carid].daytime_parking_info,
        }
        let existing_car_parking_evening = {
            ...all_existing.vehicles[p.carid].eveningtime_parking_info,
        }

        // previously commented out

        if (d1 && d1.has_location_security === false) {
            draft_car_parking_day['location_security'] = ['NO_SECURITY']
        }

        if (d2 && d2.has_location_security === false) {
            draft_car_parking_evening['location_security'] = ['NO_SECURITY']
        }

        const carLevel = car_is_external
            ? `external_vehicles`
            : `custodian_cars`

        let is_kept_at_customer_residential_address =
            draft['is_kept_at_customer_residential_address'] === true
                ? true
                : false
        let parking_place_same_day_night =
            draft['parking_place_same_day_night'] === true ? true : false

        const day_park_address_id = draft['day_park_address_id']

        const night_park_address_id = draft['night_park_address_id']

        let existingDayParkingUID = existing_car_parking_day.uid

        let existingNightParkingUID = existing_car_parking_evening.uid

        const should_delete_current_day_parking =
            existingDayParkingUID !== undefined &&
            day_park_address_id !== existing['day_park_address_id']
                ? true
                : false

        const should_delete_current_night_parking =
            existingNightParkingUID !== undefined &&
            night_park_address_id !== existing['night_park_address_id']
                ? true
                : false

        let api_uid = all_existing.vehicles[p.carid]?.api_uid

        // make deletion here so thats done

        if (should_delete_current_day_parking === true) {
            // day deletion
            const existingDayParkingUID_copy = existingDayParkingUID
            existingDayParkingUID = null

            try {
                let payload: IDeleteInsuranceApplication_req = {
                    entity_id: existingDayParkingUID_copy,
                    entity_type:
                        car_is_external === true
                            ? 'external_car_parking'
                            : 'custodian_car_parking',
                    parent_vehicle_id: api_uid ?? p.carid,
                    appli_id: `${all_existing.id}`,
                }

                yield call(
                    api.insuranceApplication.deleteInsuranceApplicationEntity,
                    payload
                )
            } catch (error: any) {
                if (error.status === 401) {
                    let p: IUnauthHandlerPayload = {
                        functionToRepeat: Submit_car_mileageParking,
                    }
                    yield call(unauthHandlerSaga, p)
                } else {
                    // console.log('error in saga', error)
                    let all_existing: IInsuranceQuoteApplication = yield select(
                        insurance_application_existing_state_select
                    )
                    let customErrorData: ICustomErrorData = {
                        custom_message: `Something went wrong, please try again.`,
                        custom_user_action_text: 'OK',
                        custom_redirect_path: `/insurance/application/${`${
                            all_existing.id
                        }/${car_is_external ? 'everyday_car' : 'car'}/${
                            p.carid
                        }/mileage_parking`}`,
                    }
                    let customError: IReduxError = ConvertToReduxError(
                        error,
                        customErrorData,
                        'insurance'
                    )
                    yield put(
                        insuranceActions.submit_carMileageParking_error(
                            customError
                        )
                    )
                }
            }
        }

        if (should_delete_current_night_parking === true) {
            // night deletion
            let existingNightParkingUID_copy = existingNightParkingUID
            existingNightParkingUID = null
            try {
                let payload: IDeleteInsuranceApplication_req = {
                    entity_id: existingNightParkingUID_copy,
                    entity_type:
                        car_is_external === true
                            ? 'external_car_parking'
                            : 'custodian_car_parking',
                    parent_vehicle_id: api_uid ?? p.carid,
                    appli_id: `${all_existing.id}`,
                }

                yield call(
                    api.insuranceApplication.deleteInsuranceApplicationEntity,
                    payload
                )
            } catch (error: any) {
                if (error.status === 401) {
                    let p: IUnauthHandlerPayload = {
                        functionToRepeat: Submit_car_mileageParking,
                    }
                    yield call(unauthHandlerSaga, p)
                } else {
                    // console.log('error in saga', error)
                    let all_existing: IInsuranceQuoteApplication = yield select(
                        insurance_application_existing_state_select
                    )
                    let customErrorData: ICustomErrorData = {
                        custom_message: `Something went wrong, please try again.`,
                        custom_user_action_text: 'OK',
                        custom_redirect_path: `/insurance/application/${`${
                            all_existing.id
                        }/${car_is_external ? 'everyday_car' : 'car'}/${
                            p.carid
                        }/mileage_parking`}`,
                    }
                    let customError: IReduxError = ConvertToReduxError(
                        error,
                        customErrorData,
                        'insurance'
                    )
                    yield put(
                        insuranceActions.submit_carMileageParking_error(
                            customError
                        )
                    )
                }
            }
        }

        // for stuff like mileage
        let carGeneralDataToPatch: IAnyObject | undefined =
            gen_draft_differing_values_to_patch({
                existing: existing,
                draft: draft,
            })

        let residence_address_id =
            all_existing.main_driver &&
            all_existing.main_driver.licence_address &&
            all_existing.main_driver?.licence_address['residential_address']
                ? all_existing.main_driver?.licence_address[
                      'residential_address'
                  ]
                : undefined

        let dataToPatch: IAnyObject = {}

        if (carGeneralDataToPatch) {
            dataToPatch = {
                ...dataToPatch,
                ...carGeneralDataToPatch,
            }
        }

        if (
            dataToPatch &&
            (dataToPatch[`odometer_reading`] ||
                dataToPatch[`expected_annual_mileage`])
        ) {
            if (
                !draft[`odometer_reading-unit`] ||
                (draft[`odometer_reading-unit`] &&
                    draft[`odometer_reading-unit`].toLowerCase() === 'miles')
            ) {
                dataToPatch[`odometer_reading`] =
                    unitGenerator.generateBackEndValueDistanceUnit(
                        'miles',
                        dataToPatch[`odometer_reading`]
                    )
            }

            if (
                !draft[`expected_annual_mileage-unit`] ||
                (draft[`expected_annual_mileage-unit`] &&
                    draft[`expected_annual_mileage-unit`].toLowerCase() ===
                        'miles')
            ) {
                dataToPatch[`expected_annual_mileage`] =
                    unitGenerator.generateBackEndValueDistanceUnit(
                        'miles',
                        dataToPatch[`expected_annual_mileage`]
                    )
            }
        }

        // scenario 1: same as residence.
        // in which case, create / update day parking to the night parking

        if (is_kept_at_customer_residential_address) {
            let location_type = draft_car_parking_day.location_type

            let day_and_evening_parking_update_data: IAnyObject = {}

            if (location_type) {
                day_and_evening_parking_update_data = {
                    ...day_and_evening_parking_update_data,
                    location_type: location_type,
                }
            }
            if (residence_address_id) {
                day_and_evening_parking_update_data = {
                    ...day_and_evening_parking_update_data,
                    address: {
                        uid: residence_address_id,
                    },
                }
            }

            dataToPatch = {
                ...dataToPatch,
                daytime_parking_info: {
                    ...day_and_evening_parking_update_data,
                },
                eveningtime_parking_info: {
                    ...day_and_evening_parking_update_data,
                },
            }
        }

        // scenario 2 : not same as residence

        if (
            is_kept_at_customer_residential_address !== true &&
            draft_car_parking_day
        ) {
            // scenario 2A: the same night & say so create / update day parking to the night to be same as day
            if (parking_place_same_day_night === true) {
                let dayParkingToUpdate: IAnyObject = {
                    ...draft_car_parking_day,
                }

                if (day_park_address_id) {
                    dayParkingToUpdate = {
                        ...dayParkingToUpdate,
                        address: {
                            uid: day_park_address_id,
                        },
                    }
                }

                if (existingDayParkingUID) {
                    dayParkingToUpdate['uid'] = existingDayParkingUID
                }

                if (should_delete_current_day_parking === true) {
                    delete dayParkingToUpdate['uid']
                }

                let nightParkingToUpdate: IAnyObject = {
                    ...dayParkingToUpdate,
                }
                if (!existingNightParkingUID) {
                    delete nightParkingToUpdate['uid']
                }

                if (existingNightParkingUID) {
                    nightParkingToUpdate['uid'] = existingNightParkingUID
                }

                if (should_delete_current_night_parking) {
                    delete nightParkingToUpdate['uid']
                }

                dataToPatch = {
                    ...dataToPatch,
                    daytime_parking_info: { ...dayParkingToUpdate },
                    eveningtime_parking_info: {
                        ...nightParkingToUpdate,
                    },
                }
            }
            if (
                parking_place_same_day_night === false &&
                draft_car_parking_day
            ) {
                let dayParkingToUpdate: IAnyObject = {
                    ...draft_car_parking_day,
                }

                if (day_park_address_id) {
                    dayParkingToUpdate = {
                        ...dayParkingToUpdate,
                        address: {
                            uid: day_park_address_id,
                        },
                    }
                }

                if (existingDayParkingUID) {
                    dayParkingToUpdate['uid'] = existingDayParkingUID
                }

                if (should_delete_current_day_parking === true) {
                    delete dayParkingToUpdate['uid']
                }

                dataToPatch = {
                    ...dataToPatch,
                    daytime_parking_info: { ...dayParkingToUpdate },
                }

                // nicer antd error
                // if (!night_park_address_id) {
                //     message.error({
                //         content: `Evening address is missing`,
                //         duration: 2,
                //     })
                // }

                let nightParkingToUpdate: IAnyObject = {
                    ...draft_car_parking_evening,
                }

                if (night_park_address_id) {
                    nightParkingToUpdate = {
                        ...nightParkingToUpdate,
                        address: {
                            uid: night_park_address_id,
                        },
                    }
                }
                if (existingNightParkingUID) {
                    nightParkingToUpdate['uid'] = existingNightParkingUID
                }

                if (should_delete_current_night_parking === true) {
                    delete nightParkingToUpdate['uid']
                }

                dataToPatch = {
                    ...dataToPatch,
                    eveningtime_parking_info: {
                        ...nightParkingToUpdate,
                    },
                }
            }
            // scenario 2B: not the same night & say so create / update day parking to the night + day parking
        }

        if (dataToPatch[`residential_visit_location`] && residence_address_id) {
            let v = dataToPatch[`residential_visit_location`]
            dataToPatch = {
                ...dataToPatch,
                residential_visit_location: {
                    location_type: v,
                    address: {
                        uid: residence_address_id,
                    },
                },
            }
        }

        // CLEANUP

        delete dataToPatch['parking_place_same_day_night']
        delete dataToPatch['night_park_address_id']
        delete dataToPatch['day_park_address_id']
        delete dataToPatch['is_kept_at_customer_residential_address']

        if (
            typeof dataToPatch?.daytime_parking_info?.has_location_security ===
            'boolean'
        ) {
            delete dataToPatch.daytime_parking_info.has_location_security
        }

        if (
            typeof dataToPatch?.eveningtime_parking_info
                ?.has_location_security === 'boolean'
        ) {
            delete dataToPatch.eveningtime_parking_info.has_location_security
        }

        if (api_uid) {
            dataToPatch = {
                customer: {
                    [carLevel]: [
                        {
                            uid: api_uid,
                            ...dataToPatch,
                        },
                    ],
                },
            }
        } else if (!car_is_external) {
            dataToPatch = {
                customer: {
                    [carLevel]: [
                        {
                            external_id: p.carid,
                            ...dataToPatch,
                        },
                    ],
                },
            }
        }

        // console.log('dataToPatch', dataToPatch)

        // call api to patch as thats all the data merged we want to patch
        let res = yield call(
            api.insuranceApplication.patchInsuranceApplication,
            {
                id: `${all_existing.id}`,
                data: { ...dataToPatch },
            }
        )

        if (p.isCrossForm) {
            p.message && message.success(p.message)
        }

        let applicationReducerDataWithStats = yield call(
            ValidateAndAddStatsToApplication,
            `${all_existing.id}`,
            res
        )

        yield put(
            insuranceActions.submit_carMileageParking_success(
                applicationReducerDataWithStats
            )
        )

        // here, pass the results and thats it
        return
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: Submit_car_mileageParking,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            // console.log('error in saga', error)
            let all_existing: IInsuranceQuoteApplication = yield select(
                insurance_application_existing_state_select
            )
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
                custom_redirect_path: `/insurance/application/${`${
                    all_existing.id
                }/${car_is_external ? 'everyday_car' : 'car'}/${
                    p.carid
                }/mileage_parking`}`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'insurance'
            )
            yield put(
                insuranceActions.submit_carMileageParking_error(customError)
            )
        }
    }
}

function* Watcher_Submit_car_mileageParking() {
    while (true) {
        let { payload } = yield take(
            insuranceActions.submit_carMileageParking_request
        )
        yield call(Submit_car_mileageParking, payload)
    }
}

const car_mileageParking_submit: any[] = [
    fork(Watcher_Submit_car_mileageParking),
]

export default car_mileageParking_submit
