import React from 'react'
import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { useSetInitialInsurance } from '../../../providers/insurance/insuranceInitialProvider'
import { menuActions } from '../../../redux/menus/reducer'
import { useAppSelector, useAppDispatch } from '../../../redux/store/hooks'
import InsuranceApplicationCardDesktop from '../../molecules/cards/insurance/insuranceApplicationCardDesktop'
import InsuranceApplicationCardMobile from '../../molecules/cards/insurance/insuranceApplicationCardMobile'
import InsuranceTopBarWithProgressionRate from '../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRate'
import InsuranceTopBarWithProgressionRateMobile from '../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRateMobile'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import { IInsuranceApplicationSectionCard } from './dashboards/insuranceApplicationDashboard'
import { submission_agreement_cards } from '../../../redux/localdata/insuranceLocalData/submissionAgreement'
import styled from 'styled-components'
import { device } from '../../templates/displays/devices'
import InsuranceCustomBottomBarSubmission from '../../templates/bars/insurance/bottomBars/insuranceCustomBottomSubmission'
import { insuranceActions } from '../../../redux/insuranceQuoteApplication/reducer'
import useThemes from '../../../providers/theme/hooks'
import colours from '../../../providers/theme/colours'

const Title = styled.h3`
    padding: 0;
    margin: 0;
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-bold;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.001px;
    padding-bottom: 4px;
    @media ${device.mobile_and_ipad} {
        padding-bottom: 4px;
    }
`

const Text = styled.p`
    padding: 0;
    margin: 0;
    color: var(--text-strong, #666666);
    font-family: Lato;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.001px;
    padding-bottom: 40px;
    @media ${device.mobile_and_ipad} {
        padding-bottom: 24px;
    }
`

const InsuranceApplicationSubmissionAgreement = () => {
    const history = useHistory()

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)

    const dispatch = useAppDispatch()

    const { id } = useParams<{ id?: string }>()

    let { getUserAndApplicationData } = useSetInitialInsurance()

    useEffect(() => {
        id && getUserAndApplicationData(id)
    }, [userLoggedIn])

    useEffect(() => {
        dispatch(menuActions.manageTopSubmenu({ isRemoved: true }))

        return () => {
            dispatch(menuActions.manageTopSubmenu({ isRemoved: false }))
        }
    }, [])

    let application = useAppSelector(
        (state) => state.insuranceQuoteApplication.submitted_data
    )

    const { theme } = useThemes()

    return userLoggedIn && application?.id ? (
        <div
            style={{
                minHeight: '100vh',
                width: '100%',
                backgroundColor: colours[theme].background_neutral_subtle,
            }}
        >
            <DesktopDisplayOnly>
                <InsuranceTopBarWithProgressionRate
                    progressionRate={application.overall_completion}
                    breadCrumbs={[
                        {
                            pageName: 'Your application',
                        },
                    ]}
                />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        paddingTop: 120,
                        paddingBottom: 120,
                    }}
                >
                    {submission_agreement_cards.map(
                        (card: IInsuranceApplicationSectionCard, i) => {
                            return (
                                <React.Fragment key={`${card.id}_${i}_desktop`}>
                                    <InsuranceApplicationCardDesktop
                                        cardProps={card}
                                    >
                                        {card.text_items
                                            ? card.text_items.map((item, i) => {
                                                  return (
                                                      <div
                                                          key={`text_item_${i}_desktop`}
                                                      >
                                                          <Title>
                                                              {item.title}
                                                          </Title>
                                                          <Text
                                                              style={
                                                                  i === 5
                                                                      ? {
                                                                            paddingBottom: 0,
                                                                        }
                                                                      : {}
                                                              }
                                                          >
                                                              {item.text}
                                                          </Text>
                                                      </div>
                                                  )
                                              })
                                            : ''}

                                        <Text
                                            style={{
                                                paddingTop: '20px',
                                                paddingBottom: '20px',
                                                fontFamily: 'Lato-Bold',
                                                color: 'var(--text-strong, #1a1a1a)',
                                            }}
                                        >
                                            By submitting your application you
                                            confirm you have answered all
                                            questions honestly and to the best
                                            of your ability.
                                        </Text>
                                    </InsuranceApplicationCardDesktop>
                                </React.Fragment>
                            )
                        }
                    )}
                </div>

                <InsuranceCustomBottomBarSubmission
                    onClose={() => history.push('/garage')}
                    onSendApplication={() => {
                        dispatch(insuranceActions.submit_application_request())
                    }}
                    goBack={() => {
                        history.push(`/insurance/application/${id}`)
                    }}
                />
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <InsuranceTopBarWithProgressionRateMobile
                    progressionRate={application.overall_completion ?? 0}
                    breadCrumbs={[
                        {
                            pageName: 'Your application',
                        },
                    ]}
                />
                <div
                    style={{
                        width: '100%',
                        paddingTop: 80,
                        paddingBottom: 120,
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            paddingTop: 40,
                            paddingLeft: 16,
                            paddingRight: 16,
                            backgroundColor:
                                colours[theme].background_neutral_subtle,
                        }}
                    >
                        {submission_agreement_cards.map(
                            (card: IInsuranceApplicationSectionCard, i) => {
                                return (
                                    <div key={`${card.id}_${i}_mobile`}>
                                        <InsuranceApplicationCardMobile
                                            cardProps={card}
                                        >
                                            {card.text_items
                                                ? card.text_items.map(
                                                      (item, i) => {
                                                          return (
                                                              <div
                                                                  key={`text_item_${i}_desktop`}
                                                              >
                                                                  <Title>
                                                                      {
                                                                          item.title
                                                                      }
                                                                  </Title>
                                                                  <Text
                                                                      style={
                                                                          i ===
                                                                          5
                                                                              ? {
                                                                                    paddingBottom: 0,
                                                                                }
                                                                              : {}
                                                                      }
                                                                  >
                                                                      {
                                                                          item.text
                                                                      }
                                                                  </Text>
                                                              </div>
                                                          )
                                                      }
                                                  )
                                                : ''}

                                            <Text
                                                style={{
                                                    paddingTop: '20px',
                                                    paddingBottom: '20px',
                                                    fontFamily: 'Lato-Bold',
                                                    color: 'var(--text-strong, #1a1a1a)',
                                                }}
                                            >
                                                By submitting your application
                                                you confirm you have answered
                                                all questions honestly and to
                                                the best of your ability.
                                            </Text>
                                        </InsuranceApplicationCardMobile>
                                    </div>
                                )
                            }
                        )}
                    </div>
                </div>

                <InsuranceCustomBottomBarSubmission
                    isMobile
                    onClose={() => history.push('/garage')}
                    onSendApplication={() => {
                        dispatch(insuranceActions.submit_application_request())
                    }}
                    goBack={() => {
                        history.push(`/insurance/application/${id}`)
                    }}
                />
            </IpadAndMobileDisplay>
        </div>
    ) : (
        <div />
    )
}

export default InsuranceApplicationSubmissionAgreement
