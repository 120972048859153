import { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { useSetInitialInsurance } from '../../../../../../providers/insurance/insuranceInitialProvider'
import colours, {
    insurance_colours,
} from '../../../../../../providers/theme/colours'
import useThemes from '../../../../../../providers/theme/hooks'
import {
    IAnyObject,
    IAnyErrorString,
    insuranceActions,
    IInsuranceQuoteApplication,
} from '../../../../../../redux/insuranceQuoteApplication/reducer'
import {
    insurance_fields_car_mileage_parking_added_address_same_as_residential,
    insurance_fields_car_mileage_location_type_if_same_as_residential,
    insurance_fields_everydaycar_mileage_parking_same_place_day_and_night,
    insurance_fields_car_mileage_parking_day_place_nigth_same,
    insurance_fields_car_mileage_parking_day_place,
    insurance_fields_car_mileage_parking_night_place,
} from '../../../../../../redux/localdata/insuranceLocalData/fieldTypes'
import { IRootState } from '../../../../../../redux/store'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../../redux/store/hooks'
import ButtonAtom from '../../../../../atoms/Button/ButtonAtom'
import Expander from '../../../../../atoms/expander/expander'
import InsuranceAddressCardV2 from '../../../../../molecules/cards/insurance/addressCardDesktopV2'
import info_red from '../../../../../..//public/assets/icons/info_red.svg'
import InsuranceGoBackReusableBottomBar from '../../../../../templates/bars/insurance/bottomBars/insureanceGoBackReusableBottomBar'
import DesktopDisplayOnly from '../../../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../../../templates/displays/ipadAndMobileDisplay'
import { IAddressActiveModal } from '../../../addressesManager'
import { AddressWindow } from '../../../addressesManager/addressWindow'
import InsuranceQuoteFormGenerator from '../../../formGenerators'
import { InsuranceFormQuestion } from '../../../formGenerators/renderElementMobile'
import { ITopWizardCheckActions } from '../../../../../pages/insurance_quote/wizard'
import CircleLoader from '../../../../../atoms/loader/circleLoader'
import FadedSlower from '../../../../../templates/animated/FadedSlower'

export const Error = styled.div`
    color: red;
    font-style: italic;
    font-size: 11px;
`

type Props = {
    car_id: string
    setTopWizardCheckActions: (p: ITopWizardCheckActions | null) => any
}

const EverydayCarInsuranceMileageParkingWizardFlow = (props: Props) => {
    let carid = props.car_id
    const history = useHistory()
    const dispatch = useAppDispatch()

    const data_draft: IAnyObject = useAppSelector((state) => {
        return (
            state.insuranceQuoteApplication.draft.vehicles[carid]
                ?.mileage_parking ?? {}
        )
    })

    const data_day_parking_draft: IAnyObject = useAppSelector((state) => {
        return (
            state.insuranceQuoteApplication.draft.vehicles[carid] &&
            state.insuranceQuoteApplication.draft.vehicles[carid]
                .daytime_parking_info
        )
    })

    const data_night_parking_draft: IAnyObject = useAppSelector((state) => {
        return (
            state.insuranceQuoteApplication.draft.vehicles[carid] &&
            state.insuranceQuoteApplication.draft.vehicles[carid]
                .eveningtime_parking_info
        )
    })

    const errors: IAnyErrorString | undefined = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft.vehicles[carid]
            ?.mileage_parking_errors
    })

    const errors_day: IAnyErrorString | undefined = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft.vehicles[carid]
            ?.daytime_parking_info_errors
    })

    const errors_night: IAnyErrorString | undefined = useAppSelector(
        (state) => {
            return state.insuranceQuoteApplication.draft.vehicles[carid]
                ?.eveningtime_parking_info_errors
        }
    )

    const [isSavedDisabled, setIsSavedDisabled] = useState(false)
    const [hasFormChanged, setHasFormChanged] = useState(false)

    let onParkingInfoAnswerChange = (
        id: string,
        answer: any,
        type: 'evening' | 'day'
    ) => {
        if (errors && errors[id]) {
            setError(id, undefined)
        }

        if (isSavedDisabled) {
            setIsSavedDisabled(false)
        }
        if (hasFormChanged === false) {
            setHasFormChanged(true)
        }

        if (errors_day && errors_day[id]) {
            dispatch(
                insuranceActions.set_error_insurance_car_mileage_parking_day({
                    id: id,
                    error: undefined,
                    carid: carid,
                })
            )
        }

        if (errors_night && errors_night[id]) {
            dispatch(
                insuranceActions.set_error_insurance_car_mileage_parking_evening(
                    {
                        id: id,
                        error: undefined,
                        carid: carid,
                    }
                )
            )
        }

        let isBoth =
            data_draft['parking_place_same_day_night'] &&
            data_draft['parking_place_same_day_night'] === true
                ? true
                : false

        if (isBoth) {
            // if (id === 'has_location_security' && answer === true) {
            //     if (
            //         data_day_parking_draft['location_security'] &&
            //         data_day_parking_draft['location_security'].includes(
            //             'NO_SECURITY'
            //         )
            //     ) {
            //         dispatch(
            //             insuranceActions.set_answer_insurance_car_mileage_parking_info(
            //                 {
            //                     id: 'location_security',
            //                     answer: undefined,
            //                     carid: carid,
            //                     type: 'day',
            //                 }
            //             )
            //         )
            //     }
            // }

            dispatch(
                insuranceActions.set_answer_insurance_car_mileage_parking_info({
                    id: id,
                    answer: answer,
                    carid: carid,
                    type: 'day',
                })
            )
            dispatch(
                insuranceActions.set_answer_insurance_car_mileage_parking_info({
                    id: id,
                    answer: answer,
                    carid: carid,
                    type: 'evening',
                })
            )
        } else {
            // if (
            //     id === 'has_location_security' &&
            //     answer === true &&
            //     type === 'day'
            // ) {
            //     if (
            //         data_day_parking_draft['location_security'] &&
            //         data_day_parking_draft['location_security'].includes(
            //             'NO_SECURITY'
            //         )
            //     ) {
            //         dispatch(
            //             insuranceActions.set_answer_insurance_car_mileage_parking_info(
            //                 {
            //                     id: 'location_security',
            //                     answer: undefined,
            //                     carid: carid,
            //                     type: 'day',
            //                 }
            //             )
            //         )
            //     }
            // }

            // if (
            //     id === 'has_location_security' &&
            //     answer === true &&
            //     type === 'evening'
            // ) {
            //     if (
            //         data_night_parking_draft['location_security'] &&
            //         data_night_parking_draft['location_security'].includes(
            //             'NO_SECURITY'
            //         )
            //     ) {
            //         dispatch(
            //             insuranceActions.set_answer_insurance_car_mileage_parking_info(
            //                 {
            //                     id: 'location_security',
            //                     answer: undefined,
            //                     carid: carid,
            //                     type: 'evening',
            //                 }
            //             )
            //         )
            //     }
            // }

            dispatch(
                insuranceActions.set_answer_insurance_car_mileage_parking_info({
                    id: id,
                    answer: answer,
                    carid: carid,
                    type: type,
                })
            )
        }
    }

    let setError = (id: string, error: string | undefined) => {
        dispatch(
            insuranceActions.set_error_insurance_car_mileage_parking({
                id: id,
                error: error,
                carid: carid,
            })
        )
    }

    const addresses = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft.addresses
    })

    let applicationData: IInsuranceQuoteApplication = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.submitted_data
    )
    let user_residential_address_uid =
        applicationData &&
        applicationData.main_driver &&
        applicationData.main_driver.licence_address &&
        applicationData.main_driver.licence_address['residential_address']
            ? applicationData.main_driver.licence_address['residential_address']
            : undefined

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    let {
        setInitialCarMileageAndParkingDropdowns,
        getFirstIncompleteSubsection_Single_EverydayVehicle,
        getInitialSectionWizardIds,
        setCrossFormData,
    } = useSetInitialInsurance()

    useEffect(() => {
        setInitialCarMileageAndParkingDropdowns(carid)
    }, [userLoggedIn])

    const [activeModal, toggleActiveModal] =
        useState<IAddressActiveModal>(undefined)

    let day_park_address_id = data_draft['day_park_address_id'] ?? undefined
    let night_park_address_id = data_draft['night_park_address_id'] ?? undefined

    const hasDayParkingErr = () => {
        let hasErr = false

        // if (data_day_parking_draft['has_location_security']) {
        //     if (
        //         !data_day_parking_draft['location_security'] ||
        //         (data_day_parking_draft['location_security'] &&
        //             data_day_parking_draft['location_security'].length === 0)
        //     ) {
        //         hasErr = true
        //         dispatch(
        //             insuranceActions.set_error_insurance_car_mileage_parking_day(
        //                 {
        //                     id: 'location_security',
        //                     error: 'Missing',
        //                     carid: carid,
        //                 }
        //             )
        //         )
        //     }
        // }

        // if (data_night_parking_draft['has_location_security']) {
        //     if (
        //         !data_night_parking_draft['location_security'] ||
        //         (data_night_parking_draft['location_security'] &&
        //             data_night_parking_draft['location_security'].length === 0)
        //     ) {
        //         hasErr = true
        //         dispatch(
        //             insuranceActions.set_error_insurance_car_mileage_parking_evening(
        //                 {
        //                     id: 'location_security',
        //                     error: 'Missing',
        //                     carid: carid,
        //                 }
        //             )
        //         )
        //     }
        // }

        if (data_draft['is_kept_at_customer_residential_address'] === true) {
            if (data_day_parking_draft['location_type'] === undefined) {
                hasErr = true
                dispatch(
                    insuranceActions.set_error_insurance_car_mileage_parking_day(
                        {
                            id: 'location_type',
                            error: 'Missing',
                            carid: carid,
                        }
                    )
                )
            }
        }

        if (data_draft['is_kept_at_customer_residential_address'] !== true) {
            if (data_draft['parking_place_same_day_night'] === true) {
                if (data_day_parking_draft['location_type'] === undefined) {
                    hasErr = true
                    dispatch(
                        insuranceActions.set_error_insurance_car_mileage_parking_day(
                            {
                                id: 'location_type',
                                error: 'Missing',
                                carid: carid,
                            }
                        )
                    )
                }
            }

            if (data_draft['parking_place_same_day_night'] !== true) {
                if (data_day_parking_draft['location_type'] === undefined) {
                    hasErr = true
                    dispatch(
                        insuranceActions.set_error_insurance_car_mileage_parking_day(
                            {
                                id: 'location_type',
                                error: 'Missing',
                                carid: carid,
                            }
                        )
                    )
                }

                if (data_night_parking_draft['location_type'] === undefined) {
                    hasErr = true
                    dispatch(
                        insuranceActions.set_error_insurance_car_mileage_parking_evening(
                            {
                                id: 'location_type',
                                error: 'Missing',
                                carid: carid,
                            }
                        )
                    )
                }
            }
        }

        return hasErr
    }

    const validateOnClick = (): boolean => {
        let hasErr2 = false

        let hasErrHere = hasDayParkingErr()

        if (hasErrHere === true) {
            hasErr2 = true
        }

        if (
            data_draft['is_kept_at_customer_residential_address'] ===
                undefined ||
            data_draft['is_kept_at_customer_residential_address'] === null
        ) {
            // if user didn't answer if car is kept at residential address
            setError(
                'is_kept_at_customer_residential_address',
                'Required field.'
            )
            hasErr2 = true
        } else {
            if (
                data_draft['is_kept_at_customer_residential_address'] === false
            ) {
                if (
                    data_draft['parking_place_same_day_night'] === undefined ||
                    data_draft['parking_place_same_day_night'] === null
                ) {
                    // if user dind't answer if car is not kept at residential address
                    setError('parking_place_same_day_night', 'Required field.')
                    hasErr2 = true
                } else {
                    if (
                        data_draft['parking_place_same_day_night'] &&
                        data_draft['parking_place_same_day_night'] === true
                    ) {
                        // if car is kept day and night at same address
                        if (!day_park_address_id) {
                            setError('day_park_address_id', 'Required field.')
                            hasErr2 = true
                        }
                    } else {
                        if (!day_park_address_id) {
                            setError('day_park_address_id', 'Required field.')
                            hasErr2 = true
                        }
                        if (!night_park_address_id) {
                            setError('night_park_address_id', 'Required field.')
                            hasErr2 = true
                        }
                        // if night address is different
                    }
                }
            } else if (
                data_draft['is_kept_at_customer_residential_address'] === true
            ) {
                if (data_day_parking_draft['location_type'] === undefined) {
                    hasErr2 = true
                    dispatch(
                        insuranceActions.set_error_insurance_car_mileage_parking_day(
                            {
                                id: 'location_type',
                                error: 'Missing',
                                carid: carid,
                            }
                        )
                    )
                }
                if (user_residential_address_uid === undefined) {
                    hasErr2 = true
                    setError(
                        'is_kept_at_customer_residential_address',
                        'Please provide the information below.'
                    )
                }
            }
        }

        return hasErr2
    }

    const goPrevious = () => {
        history.push(
            `/insurance/application/${applicationData?.id}/wizard?section_id=everyday_vehicles&subsection_id=tech_info&car_id=${carid}`
        )
    }

    const goNext = () => {
        let ids = getFirstIncompleteSubsection_Single_EverydayVehicle(
            carid,
            'mileage_parking'
        )

        if (ids) {
            let currURL = new URL(window.location.href)
            let params2 = new URLSearchParams(currURL.search)
            params2.delete('section_id')
            params2.delete('car_id')
            params2.delete('driver_id')
            params2.delete('subsection_id')
            params2.append('section_id', `${ids.sectionID}`)
            params2.append('subsection_id', `${ids.sub_sectionID}`)
            if (ids.car_id) {
                params2.append('car_id', `${ids.car_id}`)
            }
            if (ids.driver_id) {
                params2.append('driver_id', `${ids.driver_id}`)
            }
            return history.replace({
                search: params2.toString(),
            })
        }

        let getToAny = () => {
            let ids2 = getInitialSectionWizardIds('everyday_vehicles')

            if (ids2) {
                let currURL = new URL(window.location.href)
                let params2 = new URLSearchParams(currURL.search)
                params2.delete('section_id')
                params2.delete('car_id')
                params2.delete('driver_id')
                params2.delete('subsection_id')
                params2.append('section_id', `${ids2.sectionID}`)
                params2.append('subsection_id', `${ids2.sub_sectionID}`)
                if (ids2.car_id) {
                    params2.append('car_id', `${ids2.car_id}`)
                }
                if (ids2.driver_id) {
                    params2.append('driver_id', `${ids2.driver_id}`)
                }
                history.replace({
                    search: params2.toString(),
                })
            } else
                history.push(
                    `/insurance/application/${applicationData?.id}/wizard?section_id=everyday_vehicles`
                )
        }

        return getToAny()
    }

    const saveForm = () => {
        let hasErr2 = validateOnClick()
        if (!hasErr2) {
            dispatch(
                insuranceActions.submit_carMileageParking_request({
                    carid: carid,
                })
            )
            setTimeout(() => {
                goNext()
            }, 100)
        } else {
            setIsSavedDisabled(true)
        }
    }

    const { theme } = useThemes()

    useEffect(() => {
        props.setTopWizardCheckActions({
            sectionID: 'everyday_vehicles',
            onSave: () => saveForm(),
            has_changes: false,
        })
        return () => {
            setCrossFormData(undefined)
        }
    }, [])

    useEffect(() => {
        if (
            user_residential_address_uid !== undefined &&
            errors &&
            errors['is_kept_at_customer_residential_address']
        ) {
            if (isSavedDisabled) {
                setIsSavedDisabled(false)
            }
            if (hasFormChanged === false) {
                setHasFormChanged(true)
            }

            setError('is_kept_at_customer_residential_address', undefined)
        }
    }, [user_residential_address_uid])

    let onAnswerChange = (id: string, answer: any) => {
        if (isSavedDisabled) {
            setIsSavedDisabled(false)
        }
        if (hasFormChanged === false) {
            setHasFormChanged(true)
            props.setTopWizardCheckActions({
                sectionID: 'everyday_vehicles',
                onSave: () => saveForm(),
                has_changes: true,
            })
        }

        if (errors && errors[id]) {
            setError(id, undefined)
        }

        if (errors_day && errors_day[id]) {
            dispatch(
                insuranceActions.set_error_insurance_car_mileage_parking_day({
                    id: id,
                    error: undefined,
                    carid: carid,
                })
            )
        }

        if (errors_night && errors_night[id]) {
            dispatch(
                insuranceActions.set_error_insurance_car_mileage_parking_evening(
                    {
                        id: id,
                        error: undefined,
                        carid: carid,
                    }
                )
            )
        }

        dispatch(
            insuranceActions.set_answer_insurance_car_mileage_parking({
                id: id,
                answer: answer,
                carid: carid,
            })
        )
    }

    let dataLoading = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.isLoading
    )

    return (
        <>
            {dataLoading ? (
                <FadedSlower>
                    <div
                        style={{
                            width: '100%',
                            height: 240,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <CircleLoader
                            size={24}
                            color={
                                insurance_colours[theme].sections[
                                    'everyday_vehicles'
                                ].primary_100
                            }
                        />
                    </div>
                </FadedSlower>
            ) : userLoggedIn && applicationData?.id ? (
                <div
                    style={{
                        minHeight: '100vh',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        width: '100%',
                        backgroundColor:
                            colours[theme].background_neutral_subtle,
                    }}
                >
                    <DesktopDisplayOnly>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                width: '566px',
                                paddingBottom: 120,
                            }}
                        >
                            <InsuranceQuoteFormGenerator
                                list={
                                    insurance_fields_car_mileage_parking_added_address_same_as_residential
                                }
                                onAnswerChange={onAnswerChange}
                                data={data_draft}
                                errors={errors}
                                setError={setError}
                                sectionID={'everyday_vehicles'}
                                carid={carid}
                            />

                            <div
                                style={{
                                    position: 'relative',
                                    zIndex: 101,
                                    width: '100%',
                                }}
                            >
                                <Expander
                                    height={
                                        data_draft[
                                            'is_kept_at_customer_residential_address'
                                        ] === true
                                            ? 'auto'
                                            : 0
                                    }
                                    width="100%"
                                >
                                    <div
                                        style={{
                                            width: '100%',
                                            paddingTop: 16,
                                            position: 'relative',
                                            zIndex: 101,
                                        }}
                                    >
                                        <InsuranceQuoteFormGenerator
                                            list={
                                                insurance_fields_car_mileage_location_type_if_same_as_residential
                                            }
                                            onAnswerChange={(
                                                id: string,
                                                answer: any
                                            ) => {
                                                onParkingInfoAnswerChange(
                                                    id,
                                                    answer,
                                                    'day'
                                                )
                                            }}
                                            data={data_day_parking_draft}
                                            errors={errors_day}
                                            setError={setError}
                                            sectionID={'everyday_vehicles'}
                                            carid={carid}
                                        />
                                    </div>
                                </Expander>
                            </div>
                            <div style={{ paddingTop: 16 }} />

                            <Expander
                                height={
                                    data_draft[
                                        'is_kept_at_customer_residential_address'
                                    ] === undefined ||
                                    data_draft[
                                        'is_kept_at_customer_residential_address'
                                    ] === true
                                        ? 0
                                        : 'auto'
                                }
                                width="100%"
                            >
                                <InsuranceQuoteFormGenerator
                                    list={
                                        insurance_fields_everydaycar_mileage_parking_same_place_day_and_night
                                    }
                                    onAnswerChange={(
                                        id: string,
                                        answer: any
                                    ) => {
                                        onAnswerChange(id, answer)
                                        // if (answer === true) {
                                        dispatch(
                                            insuranceActions.reset_insurance_car_mileage_parking_info(
                                                {
                                                    carid: carid,
                                                    type: 'evening',
                                                }
                                            )
                                        )

                                        dispatch(
                                            insuranceActions.set_answer_insurance_car_mileage_parking(
                                                {
                                                    id: 'night_park_address_id',
                                                    answer: null,
                                                    carid: carid,
                                                }
                                            )
                                        )
                                        // }
                                    }}
                                    data={data_draft}
                                    errors={errors}
                                    setError={setError}
                                    sectionID={'everyday_vehicles'}
                                    carid={carid}
                                />
                                <div
                                    style={{
                                        width: '100%',
                                        paddingTop: 16,
                                        position: 'relative',
                                        zIndex: 100,
                                    }}
                                >
                                    <InsuranceQuoteFormGenerator
                                        list={
                                            data_draft[
                                                'parking_place_same_day_night'
                                            ] !== false
                                                ? insurance_fields_car_mileage_parking_day_place_nigth_same
                                                : insurance_fields_car_mileage_parking_day_place
                                        }
                                        onAnswerChange={(
                                            id: string,
                                            answer: any
                                        ) => {
                                            onParkingInfoAnswerChange(
                                                id,
                                                answer,
                                                'day'
                                            )
                                        }}
                                        data={data_day_parking_draft}
                                        errors={errors_day}
                                        setError={setError}
                                        sectionID={'everyday_vehicles'}
                                        carid={carid}
                                    />
                                </div>
                                <div style={{ paddingTop: 16 }} />
                                {/* 
                                <InsuranceHeader
                                    style={{
                                        paddingBottom: '20px',
                                    }}
                                >
                                    {data_draft[
                                        'parking_place_same_day_night'
                                    ] === true
                                        ? 'Vehicle Location'
                                        : 'Day Location'}
                                </InsuranceHeader> */}

                                {day_park_address_id ? (
                                    <div style={{ width: '100%' }}>
                                        <InsuranceAddressCardV2
                                            address={
                                                addresses[day_park_address_id]
                                            }
                                            onChange={() => {
                                                toggleActiveModal({
                                                    active_modal: 'select',
                                                    field_id:
                                                        'day_park_address_id',
                                                    title: 'Select address',
                                                })
                                            }}
                                            sectionID="everyday_vehicles"
                                        />
                                    </div>
                                ) : (
                                    <>
                                        <InsuranceFormQuestion $theme={theme}>
                                            Please provide the location where
                                            the vehicle is usually kept during
                                            the day:
                                        </InsuranceFormQuestion>
                                        <div style={{ paddingTop: 16 }} />
                                        <>
                                            <ButtonAtom
                                                theme="tertiary"
                                                textTransform="none"
                                                width={'100%'}
                                                customBgColor="transparent"
                                                onClick={() => {
                                                    toggleActiveModal({
                                                        active_modal: 'select',
                                                        field_id:
                                                            'day_park_address_id',
                                                        title: 'Select address',
                                                    })
                                                }}
                                                color={
                                                    insurance_colours[theme]
                                                        .sections[
                                                        'everyday_vehicles'
                                                    ].primary_100
                                                }
                                            >
                                                Add address
                                            </ButtonAtom>
                                            <Expander
                                                height={
                                                    errors &&
                                                    errors[
                                                        'day_park_address_id'
                                                    ]
                                                        ? 'auto'
                                                        : 0
                                                }
                                            >
                                                <Error
                                                    style={{
                                                        paddingTop: '8px',
                                                    }}
                                                >
                                                    {`Parking during the day address is required`}
                                                </Error>
                                            </Expander>
                                        </>
                                    </>
                                )}
                                <div
                                    style={{
                                        paddingTop: 16,
                                    }}
                                />
                                {/* <InsuranceQuoteFormGenerator
                                    list={insurance_fields_parking_info}
                                    onAnswerChange={(
                                        id: string,
                                        answer: any
                                    ) => {
                                        onParkingInfoAnswerChange(
                                            id,
                                            answer,
                                            'day'
                                        )
                                    }}
                                    data={data_day_parking_draft}
                                    errors={errors}
                                    setError={setError}
                                /> */}

                                <Expander
                                    height={
                                        data_draft[
                                            'parking_place_same_day_night'
                                        ] === undefined ||
                                        data_draft[
                                            'parking_place_same_day_night'
                                        ] === true
                                            ? 0
                                            : 'auto'
                                    }
                                    width="100%"
                                >
                                    <div
                                        style={{
                                            width: '100%',
                                            position: 'relative',
                                            zIndex: 100,
                                        }}
                                    >
                                        <InsuranceQuoteFormGenerator
                                            list={
                                                insurance_fields_car_mileage_parking_night_place
                                            }
                                            onAnswerChange={(
                                                id: string,
                                                answer: any
                                            ) => {
                                                onParkingInfoAnswerChange(
                                                    id,
                                                    answer,
                                                    'evening'
                                                )
                                            }}
                                            data={data_night_parking_draft}
                                            errors={errors_night}
                                            setError={setError}
                                            sectionID={'everyday_vehicles'}
                                            carid={carid}
                                        />
                                    </div>
                                    <div style={{ paddingTop: 16 }} />

                                    {/* <InsuranceHeader
                                        style={{
                                            paddingBottom: '20px',
                                        }}
                                    >
                                        Night Location
                                    </InsuranceHeader> */}

                                    {night_park_address_id ? (
                                        <div style={{ width: '100%' }}>
                                            <InsuranceAddressCardV2
                                                address={
                                                    addresses[
                                                        night_park_address_id
                                                    ]
                                                }
                                                onChange={() => {
                                                    toggleActiveModal({
                                                        active_modal: 'select',
                                                        field_id:
                                                            'night_park_address_id',
                                                        title: 'Select address',
                                                    })
                                                }}
                                                sectionID="everyday_vehicles"
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <InsuranceFormQuestion
                                                $theme={theme}
                                            >
                                                Please provide the location
                                                where the vehicle is usually
                                                kept during the night:
                                            </InsuranceFormQuestion>
                                            <div style={{ paddingTop: 16 }} />
                                            <>
                                                <ButtonAtom
                                                    theme="tertiary"
                                                    textTransform="none"
                                                    width={'100%'}
                                                    customBgColor="transparent"
                                                    onClick={() => {
                                                        toggleActiveModal({
                                                            active_modal:
                                                                'select',
                                                            field_id:
                                                                'night_park_address_id',
                                                            title: 'Select address',
                                                        })
                                                    }}
                                                    color={
                                                        insurance_colours[theme]
                                                            .sections[
                                                            'everyday_vehicles'
                                                        ].primary_100
                                                    }
                                                >
                                                    Add address
                                                </ButtonAtom>
                                                <Expander
                                                    height={
                                                        errors &&
                                                        errors[
                                                            'night_park_address_id'
                                                        ]
                                                            ? 'auto'
                                                            : 0
                                                    }
                                                >
                                                    <Error
                                                        style={{
                                                            paddingTop: '8px',
                                                        }}
                                                    >
                                                        {`Parking during the night address is required`}
                                                    </Error>
                                                </Expander>
                                            </>
                                        </>
                                    )}
                                    {/* <div style={{ paddingTop: 16 }} />
                                    <InsuranceQuoteFormGenerator
                                        list={insurance_fields_parking_info}
                                        onAnswerChange={(
                                            id: string,
                                            answer: any
                                        ) => {
                                            onParkingInfoAnswerChange(
                                                id,
                                                answer,
                                                'evening'
                                            )
                                        }}
                                        data={data_night_parking_draft}
                                        errors={errors}
                                        setError={setError}
                                    /> */}
                                    <div style={{ paddingTop: 16 }} />
                                </Expander>
                            </Expander>

                            {!user_residential_address_uid &&
                                data_draft[
                                    'is_kept_at_customer_residential_address'
                                ] === true && (
                                    <>
                                        <div
                                            style={{
                                                backgroundColor:
                                                    colours[theme]
                                                        .error_underlay,
                                                width: '100%',
                                                padding: '20px',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: '10px',
                                                alignItems: 'flex-start',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <img
                                                src={info_red}
                                                alt="info-red"
                                                style={{ height: '20px' }}
                                            />

                                            <InsuranceFormQuestion
                                                $theme={theme}
                                                style={{
                                                    color: colours[theme].error,
                                                }}
                                            >
                                                Please provide your residential
                                                address as it hasn't been
                                                previously filled. You can make
                                                changes later from your
                                                addressbook.
                                            </InsuranceFormQuestion>
                                        </div>

                                        <div style={{ paddingTop: 16 }} />

                                        <ButtonAtom
                                            theme="tertiary"
                                            textTransform="none"
                                            width={'100%'}
                                            customBgColor="transparent"
                                            onClick={() => {
                                                toggleActiveModal({
                                                    active_modal: 'select',
                                                    field_id:
                                                        'residential_address',
                                                    title: 'Select & Save Residential address',
                                                })
                                            }}
                                            color={
                                                insurance_colours[theme]
                                                    .sections[
                                                    'everyday_vehicles'
                                                ].primary_100
                                            }
                                        >
                                            Add address
                                        </ButtonAtom>

                                        <div style={{ paddingTop: 16 }} />

                                        <Expander
                                            height={
                                                errors &&
                                                errors['day_park_address_id']
                                                    ? 'auto'
                                                    : 0
                                            }
                                        >
                                            <Error
                                                style={{
                                                    paddingTop: '8px',
                                                }}
                                            >
                                                {`Parking during the day address is required`}
                                            </Error>
                                        </Expander>
                                    </>
                                )}

                            <div style={{ paddingTop: '200px' }} />
                        </div>

                        <InsuranceGoBackReusableBottomBar
                            saveBtnTxt="Save & Continue"
                            isSaveDisabled={isSavedDisabled ? true : false}
                            onSave={saveForm}
                            hasFormChanged={hasFormChanged}
                            goBack={() => goPrevious()}
                            sectionId="everyday_vehicles"
                        />
                    </DesktopDisplayOnly>
                    <IpadAndMobileDisplay>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: '88vw',
                                paddingBottom: 120,
                                paddingLeft: 16,
                                paddingRight: 16,
                                backgroundColor:
                                    colours[theme].background_neutral_subtle,
                            }}
                        >
                            <InsuranceQuoteFormGenerator
                                list={
                                    insurance_fields_car_mileage_parking_added_address_same_as_residential
                                }
                                onAnswerChange={onAnswerChange}
                                data={data_draft}
                                errors={errors}
                                setError={setError}
                                sectionID={'everyday_vehicles'}
                                carid={carid}
                            />
                            <Expander
                                height={
                                    data_draft[
                                        'is_kept_at_customer_residential_address'
                                    ] === true
                                        ? 'auto'
                                        : 0
                                }
                                width="100%"
                            >
                                <>
                                    <InsuranceQuoteFormGenerator
                                        list={
                                            insurance_fields_car_mileage_location_type_if_same_as_residential
                                        }
                                        onAnswerChange={(
                                            id: string,
                                            answer: any
                                        ) => {
                                            onParkingInfoAnswerChange(
                                                id,
                                                answer,
                                                'day'
                                            )
                                        }}
                                        data={data_day_parking_draft}
                                        errors={errors_day}
                                        setError={setError}
                                        sectionID={'everyday_vehicles'}
                                        carid={carid}
                                    />
                                    <div style={{ paddingTop: '16px' }} />
                                </>
                            </Expander>

                            <Expander
                                height={
                                    data_draft[
                                        'is_kept_at_customer_residential_address'
                                    ] === undefined ||
                                    data_draft[
                                        'is_kept_at_customer_residential_address'
                                    ] === true
                                        ? 0
                                        : 'auto'
                                }
                                width="100%"
                            >
                                <InsuranceQuoteFormGenerator
                                    list={
                                        insurance_fields_everydaycar_mileage_parking_same_place_day_and_night
                                    }
                                    onAnswerChange={(
                                        id: string,
                                        answer: any
                                    ) => {
                                        onAnswerChange(id, answer)
                                        // if (answer === true) {
                                        dispatch(
                                            insuranceActions.reset_insurance_car_mileage_parking_info(
                                                {
                                                    carid: carid,
                                                    type: 'evening',
                                                }
                                            )
                                        )

                                        dispatch(
                                            insuranceActions.set_answer_insurance_car_mileage_parking(
                                                {
                                                    id: 'night_park_address_id',
                                                    answer: null,
                                                    carid: carid,
                                                }
                                            )
                                        )
                                        // }
                                    }}
                                    data={data_draft}
                                    errors={errors}
                                    setError={setError}
                                    sectionID={'everyday_vehicles'}
                                    carid={carid}
                                />
                                <div style={{ paddingTop: 16 }} />
                                <InsuranceQuoteFormGenerator
                                    list={
                                        data_draft[
                                            'parking_place_same_day_night'
                                        ] !== false
                                            ? insurance_fields_car_mileage_parking_day_place_nigth_same
                                            : insurance_fields_car_mileage_parking_day_place
                                    }
                                    onAnswerChange={(
                                        id: string,
                                        answer: any
                                    ) => {
                                        onParkingInfoAnswerChange(
                                            id,
                                            answer,
                                            'day'
                                        )
                                    }}
                                    data={data_day_parking_draft}
                                    errors={errors_day}
                                    setError={setError}
                                    sectionID={'everyday_vehicles'}
                                    carid={carid}
                                />
                                <div style={{ paddingTop: 16 }} />

                                {day_park_address_id ? (
                                    <div style={{ width: '100%' }}>
                                        <InsuranceAddressCardV2
                                            sectionID="everyday_vehicles"
                                            address={
                                                addresses[day_park_address_id]
                                            }
                                            onChange={() => {
                                                toggleActiveModal({
                                                    active_modal: 'select',
                                                    field_id:
                                                        'day_park_address_id',
                                                    title: 'Select address',
                                                })
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <>
                                        <InsuranceFormQuestion $theme={theme}>
                                            {data_draft[
                                                'parking_place_same_day_night'
                                            ] === true
                                                ? 'Please provide the location where the vehicle is usually kept:'
                                                : 'Please provide the location where the vehicle is usually kept during the day:'}
                                        </InsuranceFormQuestion>
                                        <div style={{ paddingTop: 16 }} />
                                        <>
                                            <ButtonAtom
                                                theme="tertiary"
                                                textTransform="none"
                                                width={'100%'}
                                                customBgColor="transparent"
                                                onClick={() => {
                                                    toggleActiveModal({
                                                        active_modal: 'select',
                                                        field_id:
                                                            'day_park_address_id',
                                                        title: 'Select address',
                                                    })
                                                }}
                                                color={
                                                    insurance_colours[theme]
                                                        .sections[
                                                        'everyday_vehicles'
                                                    ].primary_100
                                                }
                                            >
                                                Add address
                                            </ButtonAtom>
                                            <Expander
                                                height={
                                                    errors &&
                                                    errors[
                                                        'day_park_address_id'
                                                    ]
                                                        ? 'auto'
                                                        : 0
                                                }
                                            >
                                                <Error
                                                    style={{
                                                        paddingTop: '8px',
                                                    }}
                                                >
                                                    {`Parking during the day address is required`}
                                                </Error>
                                            </Expander>
                                        </>
                                    </>
                                )}
                                <div style={{ paddingTop: 16 }} />

                                <Expander
                                    height={
                                        data_draft[
                                            'parking_place_same_day_night'
                                        ] === undefined ||
                                        data_draft[
                                            'parking_place_same_day_night'
                                        ] === true
                                            ? 0
                                            : 'auto'
                                    }
                                    width="100%"
                                >
                                    <InsuranceQuoteFormGenerator
                                        list={
                                            insurance_fields_car_mileage_parking_night_place
                                        }
                                        onAnswerChange={(
                                            id: string,
                                            answer: any
                                        ) => {
                                            onParkingInfoAnswerChange(
                                                id,
                                                answer,
                                                'evening'
                                            )
                                        }}
                                        data={data_night_parking_draft}
                                        errors={errors_night}
                                        setError={setError}
                                        sectionID={'everyday_vehicles'}
                                        carid={carid}
                                    />
                                    <div style={{ paddingTop: 16 }} />

                                    <div style={{ paddingTop: 16 }} />
                                    {/* <InsuranceHeader
                                            style={{
                                                paddingBottom: '20px',
                                            }}
                                        >
                                            Night Location
                                        </InsuranceHeader> */}

                                    {night_park_address_id ? (
                                        <div style={{ width: '100%' }}>
                                            <InsuranceAddressCardV2
                                                address={
                                                    addresses[
                                                        night_park_address_id
                                                    ]
                                                }
                                                onChange={() => {
                                                    toggleActiveModal({
                                                        active_modal: 'select',
                                                        field_id:
                                                            'night_park_address_id',
                                                        title: 'Select address',
                                                    })
                                                }}
                                                sectionID="everyday_vehicles"
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <InsuranceFormQuestion
                                                $theme={theme}
                                            >
                                                Please provide the location
                                                where the vehicle is usually
                                                kept during the night:
                                            </InsuranceFormQuestion>
                                            <div style={{ paddingTop: 16 }} />
                                            <>
                                                <ButtonAtom
                                                    theme="tertiary"
                                                    textTransform="none"
                                                    width={'100%'}
                                                    customBgColor="transparent"
                                                    onClick={() => {
                                                        toggleActiveModal({
                                                            active_modal:
                                                                'select',
                                                            field_id:
                                                                'night_park_address_id',
                                                            title: 'Select address',
                                                        })
                                                    }}
                                                    color={
                                                        insurance_colours[theme]
                                                            .sections[
                                                            'everyday_vehicles'
                                                        ].primary_100
                                                    }
                                                >
                                                    Add address
                                                </ButtonAtom>
                                                <Expander
                                                    height={
                                                        errors &&
                                                        errors[
                                                            'night_park_address_id'
                                                        ]
                                                            ? 'auto'
                                                            : 0
                                                    }
                                                >
                                                    <Error
                                                        style={{
                                                            paddingTop: '8px',
                                                        }}
                                                    >
                                                        {`Parking during the night address is required`}
                                                    </Error>
                                                </Expander>
                                            </>
                                        </>
                                    )}
                                    {/* <div style={{ paddingTop: 16 }} />
                                        <InsuranceQuoteFormGenerator
                                            list={insurance_fields_parking_info}
                                            onAnswerChange={(
                                                id: string,
                                                answer: any
                                            ) => {
                                                onParkingInfoAnswerChange(
                                                    id,
                                                    answer,
                                                    'evening'
                                                )
                                            }}
                                            data={data_night_parking_draft}
                                            errors={errors}
                                            setError={setError}
                                        /> */}
                                    <div style={{ paddingTop: 16 }} />
                                </Expander>
                            </Expander>

                            {!user_residential_address_uid &&
                                data_draft[
                                    'is_kept_at_customer_residential_address'
                                ] === true && (
                                    <>
                                        <div
                                            style={{
                                                backgroundColor:
                                                    colours[theme]
                                                        .error_underlay,
                                                width: '100%',
                                                padding: '20px',
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: '10px',
                                                alignItems: 'flex-start',
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <img
                                                src={info_red}
                                                alt="info-red"
                                                style={{ height: '20px' }}
                                            />

                                            <InsuranceFormQuestion
                                                $theme={theme}
                                                style={{ color: '#DF6F6F' }}
                                            >
                                                Please provide your residential
                                                address as it hasn't been
                                                previously filled. You can make
                                                changes later from your
                                                addressbook.
                                            </InsuranceFormQuestion>
                                        </div>

                                        <div style={{ paddingTop: 16 }} />

                                        <ButtonAtom
                                            theme="tertiary"
                                            textTransform="none"
                                            width={'100%'}
                                            customBgColor="transparent"
                                            onClick={() => {
                                                toggleActiveModal({
                                                    active_modal: 'select',
                                                    field_id:
                                                        'residential_address',
                                                    title: 'Select & Save Residential address',
                                                })
                                            }}
                                            color={
                                                insurance_colours[theme]
                                                    .sections[
                                                    'everyday_vehicles'
                                                ].primary_100
                                            }
                                        >
                                            Add address
                                        </ButtonAtom>

                                        <div style={{ paddingTop: 16 }} />

                                        <Expander
                                            height={
                                                errors &&
                                                errors['day_park_address_id']
                                                    ? 'auto'
                                                    : 0
                                            }
                                        >
                                            <Error
                                                style={{
                                                    paddingTop: '8px',
                                                }}
                                            >
                                                {`Parking during the day address is required`}
                                            </Error>
                                        </Expander>
                                    </>
                                )}
                        </div>

                        <InsuranceGoBackReusableBottomBar
                            isMobile
                            saveBtnTxt="Save & Continue"
                            isSaveDisabled={isSavedDisabled ? true : false}
                            onSave={saveForm}
                            hasFormChanged={hasFormChanged}
                            goBack={() => goPrevious()}
                            sectionId="everyday_vehicles"
                        />
                    </IpadAndMobileDisplay>
                </div>
            ) : (
                <div />
            )}
            {userLoggedIn && (
                <AddressWindow
                    sectionID="everyday_vehicles"
                    removeSideEffectSubmit={true}
                    selectedAddress={
                        activeModal?.field_id === 'residential_address'
                            ? user_residential_address_uid
                            : activeModal?.field_id === 'day_park_address_id'
                            ? day_park_address_id
                            : activeModal?.field_id === 'night_park_address_id'
                            ? night_park_address_id
                            : undefined
                    }
                    selectAddress={(addressid: string) => {
                        if (activeModal?.field_id === 'residential_address') {
                            dispatch(
                                insuranceActions.set_answer_main_driver_licenseAddress(
                                    {
                                        id: 'residential_address',
                                        answer: addressid,
                                        userid: userLoggedIn.id,
                                    }
                                )
                            )
                        } else {
                            activeModal?.field_id &&
                                onAnswerChange(activeModal?.field_id, addressid)
                        }
                    }}
                    activeModal={activeModal}
                    toggleActiveModal={toggleActiveModal}
                    onSaveForm={() => {
                        if (activeModal?.field_id === 'residential_address') {
                            dispatch(
                                insuranceActions.submit_user_residential_address_request()
                            )
                        } else if (
                            activeModal?.active_modal === 'create' ||
                            activeModal?.active_modal === 'edit'
                        ) {
                            dispatch(
                                insuranceActions.submit_user_address_request()
                            )
                        }
                        // else {
                        //     dispatch(
                        //         insuranceActions.submit_carMileageParking_request(
                        //             {
                        //                 carid: carid,
                        //             }
                        //         )
                        //     )
                        // }
                    }}
                />
            )}
        </>
    )
}

export default EverydayCarInsuranceMileageParkingWizardFlow
