import { Radio } from 'antd'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import colours, { ITheme } from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'
import Expander from '../../atoms/expander/expander'

type Props = {
    value: string | undefined
    options: {
        uid: string
        name: string
        img?: string
        [key: string]: string | undefined
    }[]
    onChange: (payload: {
        uid: string
        name: string
        img?: string
        [key: string]: string | undefined
    }) => void
    isDisabled?: boolean
    customPrimaryColour?: string
    customPrimaryColour_04?: string
}

type IStyle = {
    $theme: ITheme
    $isActive?: boolean
    $customPrimaryColour?: string
    $customPrimaryColour_04?: string
}

const CustomRadioGroup = styled(Radio.Group)<IStyle>`
    .ant-radio-inner {
        background-color: transparent !important;
        border: ${(props) => `1px solid ${colours[props.$theme].text_default}`};
        height: 20px !important;
        width: 20px !important;
    }

    .ant-radio-inner::before {
        background-color: ${(props) =>
            colours[props.$theme].background_default} !important;
        height: 14px !important;
        width: 14px !important;
    }

    .ant-radio-inner::after {
        background-color: ${(props) =>
            colours[props.$theme].background_default} !important;
        transform: scale(0.7) !important;
    }

    .ant-radio-checked .ant-radio-inner {
        background-color: ${(props) =>
            `${
                props.$customPrimaryColour ?? colours[props.$theme].primary
            }`} !important;
        border: ${(props) =>
            `1px solid ${
                props.$customPrimaryColour ?? colours[props.$theme].primary
            }`} !important;
    }
    .ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
        border-color: ${(props) =>
            `${
                props.$customPrimaryColour ?? colours[props.$theme].primary
            }`} !important;
    }
`

const Option = styled.button`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border: none;
    cursor: pointer;
    margin-top: 40px;
    border-radius: 8px;
    background-color: transparent;
    transition: transform 200ms;

    :hover {
        transform: scale(1.02);
    }
`

const OptionText = styled.p`
    color: var(--text-strong, #121212);
    font-feature-settings: 'clig' off, 'liga' off;
    /* Desktop/Text/T300 Regular */
    font-family: Lato-semibold;
    font-size: 18px;
    line-height: 24px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`

export const YesNoBox = styled(motion.div)<IStyle>`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 18px 16px 16px;
    width: 100%;
    height: 48px;
    border-radius: 4px;
    transition: all 200ms;

    :hover {
        background-color: ${(props) =>
            `${
                props.$customPrimaryColour_04 ??
                colours[props.$theme].primary_04
            }`};
        border: ${(props) =>
            `1px solid ${
                props.$customPrimaryColour ?? colours[props.$theme].primary
            }`};
    }

    cursor: pointer;
`

function ImageRadioInput({
    options,
    value,
    onChange,
    isDisabled,
    customPrimaryColour,
    customPrimaryColour_04,
}: Props) {
    const { theme } = useThemes()
    return (
        <CustomRadioGroup
            $customPrimaryColour={customPrimaryColour}
            $customPrimaryColour_04={customPrimaryColour_04}
            $theme={theme}
            value={value}
            style={{ width: '100%' }}
            disabled={isDisabled}
        >
            {options.map((option, index) => {
                return (
                    <Expander
                        key={`${option.uid}-${index}`}
                        height={option ? 'auto' : 0}
                    >
                        <Option
                            key={`${option.uid}-${index}-child`}
                            onClick={() => {
                                onChange(option)
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 12,
                                }}
                            >
                                <Radio
                                    id={option.uid}
                                    type="radio"
                                    value={option.uid}
                                    name={option.name}
                                    style={{ margin: 0 }}
                                />
                                <OptionText>{option.name}</OptionText>
                            </div>
                            <div style={{ width: '100%' }}>
                                {option.img ? (
                                    <div
                                        style={{
                                            width: '100%',
                                            boxSizing: 'border-box',
                                            borderRadius: 24,
                                            padding: 8,
                                            background: '#fff',
                                            border:
                                                value && value === option.uid
                                                    ? `2px solid ${
                                                          customPrimaryColour ??
                                                          colours[theme].primary
                                                      }`
                                                    : `1px solid ${
                                                          customPrimaryColour ??
                                                          colours[theme]
                                                              .border_muted
                                                      }`,
                                        }}
                                    >
                                        <img
                                            src={option.img}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                                borderRadius: 16,
                                            }}
                                        />
                                    </div>
                                ) : null}
                            </div>
                        </Option>
                    </Expander>
                )
            })}
        </CustomRadioGroup>
    )
}

export default ImageRadioInput
