import { IDropdownItem, ICar } from 'entityModels'
import { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useSetInitialInsurance } from '../../../../../../providers/insurance/insuranceInitialProvider'
import {
    IAnyObject,
    IAnyErrorString,
    insuranceActions,
    IInsuranceQuoteApplication,
} from '../../../../../../redux/insuranceQuoteApplication/reducer'
import { dropDownActions } from '../../../../../../redux/localdata/dropdownData/reducer'
import { insurance_fields_everyday_car_ownership } from '../../../../../../redux/localdata/insuranceLocalData/fieldTypes'
import { IRootState } from '../../../../../../redux/store'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../../redux/store/hooks'
import InsuranceApplicationMobileSearch from '../../../../../pages/insurance_quote/flows/search/mobileSearch'
import { ITopWizardCheckActions } from '../../../../../pages/insurance_quote/wizard'
import InsuranceGoBackReusableBottomBar from '../../../../../templates/bars/insurance/bottomBars/insureanceGoBackReusableBottomBar'
import DesktopDisplayOnly from '../../../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../../../templates/displays/ipadAndMobileDisplay'
import PortalInvisibleWrapper from '../../../../../templates/displays/pageWrappers/portalInvisibleWrapper'
import InsuranceQuoteFormGenerator from '../../../formGenerators'
import RemoveMobileSearchAnchor from '../../../../../templates/insurance/removeMobileSearchAnchor'
import useThemes from '../../../../../../providers/theme/hooks'
import colours from '../../../../../../providers/theme/colours'

type Props = {
    car_id: string
    setTopWizardCheckActions: (p: ITopWizardCheckActions | null) => any
}

const EverydayVehicleOwnershipWizardForm = (props: Props) => {
    let carid = props.car_id
    const history = useHistory()
    const dispatch = useAppDispatch()

    const data_draft: IAnyObject = useAppSelector((state) => {
        return (
            state.insuranceQuoteApplication.draft.vehicles[carid]?.ownership ??
            {}
        )
    })

    const data_submitted: IAnyObject = useAppSelector((state) => {
        return (
            state.insuranceQuoteApplication.submitted_data.vehicles[carid]
                ?.ownership ?? {}
        )
    })

    const errors: IAnyErrorString | undefined = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft.vehicles[carid]
            ?.ownership_errors
    })

    let countriesList: IDropdownItem[] | null = useAppSelector((state) => {
        return state.localdata.dropdownData.countriesCode
    })

    const [isSavedDisabled, setIsSavedDisabled] = useState(false)
    const [hasFormChanged, setHasFormChanged] = useState(false)

    let setError = (id: string, error: string | undefined) => {
        dispatch(
            insuranceActions.set_error_insurance_car_ownership({
                id: id,
                error: error,
                carid: carid,
            })
        )
    }

    // const hasErrorFunc = () => {
    //     let hasErr: boolean = false

    //     if (errors) {
    //         for (const [_, value] of Object.entries(errors)) {
    //             if (value !== undefined) {
    //                 hasErr = true
    //             }
    //         }
    //     }

    //     return hasErr
    // }

    // let hasError = hasErrorFunc()

    const validateOnClick = (): boolean => {
        let hasErr2 = false
        for (
            let i = 0;
            i < insurance_fields_everyday_car_ownership.length;
            i++
        ) {
            if (
                insurance_fields_everyday_car_ownership[i].is_required === true
            ) {
                if (
                    data_draft[
                        insurance_fields_everyday_car_ownership[i].id
                    ] === undefined
                ) {
                    setError(
                        insurance_fields_everyday_car_ownership[i].id,
                        'Required field.'
                    )

                    hasErr2 = true
                }
            }

            if (
                insurance_fields_everyday_car_ownership[i].validation &&
                data_draft[insurance_fields_everyday_car_ownership[i].id] !==
                    undefined
            ) {
                let is_valid_func =
                    insurance_fields_everyday_car_ownership[i].validation
                let value =
                    data_draft[insurance_fields_everyday_car_ownership[i].id]
                let error_txt =
                    insurance_fields_everyday_car_ownership[i].error_txt

                if (is_valid_func && is_valid_func(value) !== true) {
                    setError(
                        insurance_fields_everyday_car_ownership[i].id,
                        error_txt ?? 'Invalid'
                    )
                    hasErr2 = true
                }
            }
        }

        return hasErr2
    }

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    let carsData = useAppSelector((state) => {
        return state.entities.carsData.cars
    })

    let car: ICar | undefined = carsData[carid]

    useEffect(() => {
        if (userLoggedIn && !countriesList) {
            dispatch(dropDownActions.setCountriesCodeDropdownDataRequest())
        }
    }, [car, userLoggedIn])

    let {
        setInitialCarOwnershipDropdowns,
        getFirstIncompleteSubsection_EverydayVehicle,
        getInitialSectionWizardIds,
        set_wizard_entity_id_to_skip,
        setCrossFormData,
    } = useSetInitialInsurance()

    useEffect(() => {
        set_wizard_entity_id_to_skip(carid)
        setInitialCarOwnershipDropdowns(carid)
    }, [userLoggedIn])

    const { search } = useLocation()
    let queryParams = new URLSearchParams(search)
    let field_id = queryParams.get('field_id')

    let applicationData: IInsuranceQuoteApplication = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.submitted_data
    )

    const goPrevious = () => {
        history.push(
            `/insurance/application/${applicationData?.id}/wizard?section_id=everyday_vehicles&subsection_id=mileage_parking&car_id=${carid}`
        )
    }

    const currURL = `/insurance/application/${applicationData?.id}/wizard?section_id=everyday_vehicles&subsection_id=ownership&car_id=${carid}`

    const goNext = () => {
        let ids = getFirstIncompleteSubsection_EverydayVehicle()

        if (ids) {
            let currURL = new URL(window.location.href)
            let params2 = new URLSearchParams(currURL.search)
            params2.delete('section_id')
            params2.delete('car_id')
            params2.delete('driver_id')
            params2.delete('subsection_id')
            params2.append('section_id', `${ids.sectionID}`)
            params2.append('subsection_id', `${ids.sub_sectionID}`)
            if (ids.car_id) {
                params2.append('car_id', `${ids.car_id}`)
            }
            if (ids.driver_id) {
                params2.append('driver_id', `${ids.driver_id}`)
            }
            return history.replace({
                search: params2.toString(),
            })
        }

        let getToAny = () => {
            let ids2 = getInitialSectionWizardIds('everyday_vehicles')

            if (ids2) {
                let currURL = new URL(window.location.href)
                let params2 = new URLSearchParams(currURL.search)
                params2.delete('section_id')
                params2.delete('car_id')
                params2.delete('driver_id')
                params2.delete('subsection_id')
                params2.append('section_id', `${ids2.sectionID}`)
                params2.append('subsection_id', `${ids2.sub_sectionID}`)
                if (ids2.car_id) {
                    params2.append('car_id', `${ids2.car_id}`)
                }
                if (ids2.driver_id) {
                    params2.append('driver_id', `${ids2.driver_id}`)
                }
                history.replace({
                    search: params2.toString(),
                })
            } else {
                history.push(`/insurance/application/${applicationData?.id}`)
            }
        }
        return getToAny()
    }

    const saveForm = () => {
        let hasErr2 = validateOnClick()
        if (!hasErr2) {
            dispatch(
                insuranceActions.submit_carOwnership_request({
                    carid: carid,
                })
            )

            setTimeout(() => {
                goNext()
            }, 100)
        } else {
            setIsSavedDisabled(true)
        }
    }

    let onAnswerChange = (id: string, answer: any) => {
        if (isSavedDisabled) {
            setIsSavedDisabled(false)
        }
        if (hasFormChanged === false) {
            props.setTopWizardCheckActions({
                sectionID: 'everyday_vehicles',
                onSave: () => saveForm(),
                has_changes: true,
            })
            setHasFormChanged(true)
        }
        dispatch(
            insuranceActions.set_answer_insurance_car_ownership({
                id: id,
                answer: answer,
                carid: carid,
            })
        )
    }

    useEffect(() => {
        props.setTopWizardCheckActions({
            sectionID: 'everyday_vehicles',
            onSave: () => saveForm(),
            has_changes: false,
        })
        return () => {
            setCrossFormData(undefined)
        }
    }, [])

    const { theme } = useThemes()

    return userLoggedIn && applicationData?.id ? (
        <div
            style={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                backgroundColor: colours[theme].background_neutral_subtle,
            }}
        >
            <DesktopDisplayOnly>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '566px',
                        paddingBottom: 120,
                    }}
                >
                    <InsuranceQuoteFormGenerator
                        sectionID="everyday_vehicles"
                        list={insurance_fields_everyday_car_ownership}
                        onAnswerChange={onAnswerChange}
                        data={data_draft}
                        errors={errors}
                        setError={setError}
                        carid={carid}
                    />
                </div>
                <div style={{ paddingTop: '200px' }} />

                <InsuranceGoBackReusableBottomBar
                    saveBtnTxt="Save & Continue"
                    isSaveDisabled={isSavedDisabled ? true : false}
                    onSave={saveForm}
                    hasFormChanged={hasFormChanged}
                    goBack={() => goPrevious()}
                    sectionId="everyday_vehicles"
                />
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <RemoveMobileSearchAnchor />
                {field_id ? (
                    <PortalInvisibleWrapper>
                        <InsuranceApplicationMobileSearch
                            sectionId="everyday_vehicles"
                            urlBack={currURL}
                            draft={data_draft}
                            submitted={data_submitted}
                            onChange={(value: IDropdownItem) => {
                                dispatch(
                                    insuranceActions.set_answer_insurance_car_ownership(
                                        {
                                            id: `${field_id}`,
                                            answer: value,
                                            carid: carid,
                                        }
                                    )
                                )
                            }}
                        />
                    </PortalInvisibleWrapper>
                ) : (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: '88vw',
                                paddingBottom: 120,
                                backgroundColor:
                                    colours[theme].background_neutral_subtle,
                            }}
                        >
                            <InsuranceQuoteFormGenerator
                                sectionID="everyday_vehicles"
                                list={insurance_fields_everyday_car_ownership}
                                onAnswerChange={onAnswerChange}
                                data={data_draft}
                                errors={errors}
                                setError={setError}
                                mobileSearchPickerSectionURL={currURL}
                                carid={carid}
                            />
                            <div style={{ paddingTop: '200px' }} />
                        </div>
                        <InsuranceGoBackReusableBottomBar
                            isMobile
                            saveBtnTxt="Save & Continue"
                            isSaveDisabled={isSavedDisabled ? true : false}
                            onSave={saveForm}
                            hasFormChanged={hasFormChanged}
                            goBack={() => goPrevious()}
                            sectionId="everyday_vehicles"
                        />
                    </>
                )}
            </IpadAndMobileDisplay>
        </div>
    ) : (
        <div />
    )
}

export default EverydayVehicleOwnershipWizardForm
