import useThemes from '../../../../providers/theme/hooks'
import DarkThemeIcon from './dark'
import LightThemeIcon from './light'
import SystemThemeIcon from './system'

export default function ActiveThemeIcon({
    color,
    size,
}: {
    color?: string
    size?: string
}) {
    const { theme } = useThemes()
    switch (theme) {
        case 'light':
            return <LightThemeIcon color={color} size={size ?? '16'} />
        case 'dark':
            return <DarkThemeIcon color={color} size={size ?? '16'} />
        default:
            return <SystemThemeIcon color={color} size={size ?? '16'} />
    }
}
