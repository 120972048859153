import { FunctionComponent, useState, useCallback, useEffect } from 'react'
import Cropper from 'react-easy-crop'
import getCroppedImg from './cropImage'
import SliderAllDevice from '../../atoms/slider/slider'
import ButtonAtom from '../../atoms/Button/ButtonAtom'
import './stylesDesktop.css'
import styled from 'styled-components'
import heic2any from 'heic2any'
import { message } from 'antd'
import Ellipsis from '../../atoms/loader/ellipsis'

type Props = {
    currentImagesUploaded?: any
    crop?: any
    setCrop?: any
    rotation?: any
    setRotation?: any
    zoom?: any
    setZoom?: any
    croppedAreaPixels?: any
    setCroppedAreaPixels?: any
    croppedImage?: any
    setcroppedImage?: any
    handleFile: any
    aspect?: number
}

type OtherProps = {
    close: any
}

const FormBtnsBottomRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
    min-width: 500px;
    padding-top: 16px;
    z-index: 100;
`

const LabelSliderRow = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    color: var(--text-strong, #1a1a1a);
    font-family: Lato;
    gap: 4px;
`

// FixedLabel
const FixedLabel = styled.div`
    width: 70px;
    color: var(--text-default, #666666);
    font-size: 16px;
`

const WrapperCropper = styled.div`
    position: absolute;
    top: 5vh;
    left: 50%;
    transform: translate(-50%, 0);
`

// const gallery_car_ex =
//   "https://img.huffingtonpost.com/asset/5ab4d4ac2000007d06eb2c56.jpeg?cache=sih0jwle4e&ops=1910_1000";

const ImageCropperDesktop: FunctionComponent<Props & OtherProps> = (
    props: any
) => {
    const [url, setUrl] = useState('')
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
    // const [croppedImage, setCroppedImage] = useState(null)

    const [hasSaveBeenClicked, setHasSaveBeenClicked] = useState(false)

    const onCropComplete = useCallback(
        (croppedArea: any, croppedAreaPixels: any) => {
            setCroppedAreaPixels(croppedAreaPixels)
        },
        []
    )

    const { currentImagesUploaded, handleFile } = props

    const [imgPreviewLoading, setImgPreviewLoading] = useState(false)

    // fixing infinite blob generation - putting image inside local state to avoinf re-render onChange
    useEffect(() => {
        const getCustomFormatImgUrl = async () => {
            try {
                // Convert HEIC/HEIF to JPEG/PNG
                const conversionResult = await heic2any({
                    blob: currentImagesUploaded,
                    toType: 'image/jpeg',
                })

                if (conversionResult instanceof Blob) {
                    // Create a new filename with the .jpg extension
                    const newFileName =
                        currentImagesUploaded.name.replace(/\.[^/.]+$/, '') +
                        '.jpg'
                    // Create a new File object
                    const newFile = new File([conversionResult], newFileName, {
                        type: 'image/jpeg',
                    })

                    // Create a URL for the converted image
                    const url = URL.createObjectURL(newFile)
                    setUrl(url)
                    setImgPreviewLoading(false)
                } else {
                    message.error(
                        'An error occured, please try uploading your image again.'
                    )
                }
            } catch (e) {
                message.error(
                    'An error occured, please try uploading your image again.'
                )
            }
        }

        if (currentImagesUploaded) {
            if (
                currentImagesUploaded.type === 'image/heic' ||
                currentImagesUploaded.type === 'image/heif'
            ) {
                setImgPreviewLoading(true)
                getCustomFormatImgUrl()
            } else {
                let url = URL.createObjectURL(currentImagesUploaded)

                url && setUrl(url)
            }
        }

        return () => {}
    }, [currentImagesUploaded])

    const generateCroppedImage = useCallback(async () => {
        let img_to_crop: string = url
        try {
            const croppedImage: any = await getCroppedImg(
                img_to_crop,
                croppedAreaPixels,
                rotation
            )
            // setCroppedImage(croppedImage)

            const newFileName =
                currentImagesUploaded.name.replace(/\.[^/.]+$/, '') + '.jpg'

            let finalFile = await convertToBlob(croppedImage, newFileName)

            handleFile(finalFile)

            props.close()
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels, rotation])

    type IBlobFetchRes = File | undefined

    const convertToBlob = async (
        url: string,
        fileTitle: string
    ): Promise<IBlobFetchRes> => {
        let res: IBlobFetchRes = await fetch(url)
            .then((res) => res.blob()) // Gets the response and returns it as a blob
            .then((blob) => {
                // Here's where you get access to the blob
                // And you can use it for whatever you want
                // Like calling ref().put(blob)

                let newfile: File = new File([blob], fileTitle, {
                    type: 'image/jpeg',
                })

                return newfile

                // Here, I use it to make an image appear on the page
            })
            .catch((e) => {
                return undefined
            })

        return res
    }

    return (
        <WrapperCropper className="inner-form-padding-desktop">
            <div>
                {imgPreviewLoading ? (
                    <div
                        style={{
                            width: '100%',
                            height: '50vh',
                            position: 'absolute',
                            zIndex: 1,
                            left: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Ellipsis colour="#fff" size={10} />
                    </div>
                ) : (
                    <Cropper
                        image={url}
                        crop={crop}
                        rotation={rotation}
                        zoom={zoom}
                        aspect={props.aspect ? props.aspect : 3.2}
                        onCropChange={(newCrop) => setCrop(newCrop)}
                        onRotationChange={setRotation}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                    />
                )}
            </div>

            <div className="sliders-container-desktop">
                <div
                    style={{
                        color: 'var(--text-strong, #1a1a1a)',
                        alignSelf: 'start',
                        paddingBottom: '8px',
                        fontSize: '18px',
                        fontFamily: 'Lato',
                    }}
                >
                    Please crop your photo to fit in the relevant aspect ratio
                </div>
                <LabelSliderRow>
                    <FixedLabel>Zoom</FixedLabel>
                    <SliderAllDevice
                        value={zoom}
                        min={1}
                        max={3}
                        step={0.01}
                        aria-labelledby="Zoom"
                        onChange={setZoom}
                    />
                </LabelSliderRow>
                <LabelSliderRow style={{ paddingTop: 8 }}>
                    <FixedLabel>Rotate</FixedLabel>

                    <SliderAllDevice
                        value={rotation}
                        min={0}
                        max={360}
                        step={0.1}
                        aria-labelledby="Rotation"
                        onChange={setRotation}
                    />
                </LabelSliderRow>

                <FormBtnsBottomRow>
                    <ButtonAtom
                        theme="uppercase-white-background"
                        onClick={(e: any) => {
                            props.close()
                            handleFile(undefined)
                        }}
                        // width="150px"
                        height="40px"
                    >
                        <div style={{ textTransform: 'none' }}>Go back</div>
                    </ButtonAtom>
                    <div style={{ paddingLeft: '16px' }} />

                    <ButtonAtom
                        theme="uppercase-blue-background"
                        onClick={(e: any) => {
                            setHasSaveBeenClicked(!hasSaveBeenClicked)
                            generateCroppedImage()
                        }}
                        disabled={hasSaveBeenClicked === true ? true : false}
                        // width="150px"
                        height="40px"
                    >
                        {hasSaveBeenClicked === true ? (
                            <div
                                style={{
                                    fontWeight: 800,
                                    textTransform: 'none',
                                    color: 'var(--text-muted, #b3b3b3)',
                                }}
                            >
                                Please Wait...
                            </div>
                        ) : (
                            <div
                                style={{
                                    textTransform: 'none',
                                    color: 'var(--bg-color, #fff)',
                                }}
                            >
                                Save image
                            </div>
                        )}
                    </ButtonAtom>
                </FormBtnsBottomRow>
            </div>
        </WrapperCropper>
    )
}

export default ImageCropperDesktop
