import { FormikProps, withFormik } from 'formik'
import styled from 'styled-components'

import { RouteComponentProps, withRouter } from 'react-router-dom'

import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'

import { IDropdownItem, IList } from 'entityModels'
import { IUserPreferencesDataLevel4Obj } from 'IUserPreferencesModels'
import { IUSerGeneralInfoUpdatePayload } from 'myModels'
import {
    ModalContentMobile,
    ModalInnerContentWrapperMobile,
    ModalTopBar,
} from '../../../molecules/technicalInformation/modal'
import ButtonsStandardBottomRowMobile from '../buttons/buttonsStandardBottomRowMobile'
import SelectSearchBarCarInfoMobile from '../technicalInformationEditForm/searchselect/mobile/selectSearchBarCarInfoMobile'
import useThemes from '../../../../providers/theme/hooks'
import ChevronLeft from '../../../atoms/icons/components/chevronLeft'

const PickerButton = styled.button`
    width: 100%;
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    border-radius: 4px;
    position: relative;

    ${(props: { noValue: boolean; disabled: boolean }) => `
        border: ${
            props.disabled
                ? 'none'
                : props.noValue
                ? '1px solid var(--primary, #5EC3CA)'
                : '1px solid var(--text-muted, #B3B3B3)'
        }
    `}
`

const PickerButtonContents = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 12px;
    color: var(--text-strong, #1a1a1a);

    ${(props: { disabled: boolean }) => `
        opacity: ${props.disabled ? 0.5 : 1}
    `}
`

const Padding24 = styled.div`
    padding-top: 24px;
`

const PickerLabel = styled.label`
    position: absolute;
    top: -16px;
    left: 8px;
    padding: 8px;
    background-color: var(--modal-side-sheet-pop-over-bg, #fff);
    color: var(--text-darker, #616161);
`

const PickerLink = ({ children, ...props }: any) => {
    return (
        <PickerButton {...props}>
            <PickerButtonContents disabled={props.disabled}>
                {children}
                <div style={{ transform: 'rotate(180deg)' }}>
                    <ChevronLeft />
                </div>
            </PickerButtonContents>
            <PickerLabel>{props.label}</PickerLabel>
        </PickerButton>
    )
}
// const ColumnAlignStart = styled.div`
//     display: flex;
//     flex-direction: column;
//     align-items: start;
//     justify-content: flex-start;
// `

const dispatchProps = {}

function mapStateToProps(state: RootState) {
    return {}
}

interface Values {
    unique_value_mobile: string
}

interface OtherProps extends RouteComponentProps<any> {
    title: any
    item: IList | null
    data?: IUserPreferencesDataLevel4Obj | null
    ids_list?: string[] | null
    toggle: any
    handleSubmit: any
    onCancel: () => void
    field_id: string | null
    userid: string
    onlyShowMatch?: boolean
    optionsList?: IDropdownItem[] | string[]
    isKeyValue?: boolean
    placeholder?: string
    helperText?: string
    standalonePicker?: boolean
    pickerPath?: string
    selectedValue?: string | null
}
const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        title,
        ids_list,
        data,
        setFieldValue,
        values,
        toggle,
        handleSubmit,
        item,
        onlyShowMatch,
        optionsList,
        isKeyValue,
        standalonePicker,
        pickerPath,
        history,
        selectedValue,
    } = props

    let convertedOptionsList: IDropdownItem[] = []

    if (data && ids_list) {
        convertedOptionsList = ids_list.map((id: string, index: any) => {
            return { uid: data[id].id, name: data[id].name }
        })
    }

    const handleCancel = () => {
        toggle()
        props.onCancel()
    }

    const { theme } = useThemes()

    return (
        <ModalContentMobile $theme={theme}>
            <ModalTopBar $theme={theme}>{title}</ModalTopBar>
            <ModalInnerContentWrapperMobile>
                {standalonePicker && pickerPath ? (
                    <PickerLink
                        label={title}
                        onClick={() => {
                            history.push(pickerPath)
                        }}
                    >
                        {selectedValue ?? item?.text ?? `Pick a ${title}`}
                    </PickerLink>
                ) : (
                    <SelectSearchBarCarInfoMobile
                        handleSelectChange={(
                            name: string,
                            other?: string | undefined
                        ) => {
                            setFieldValue('unique_value_mobile', name)
                        }}
                        item={item}
                        placeholder={title}
                        dropdownTargetId="unique_value_mobile"
                        items={optionsList ? optionsList : convertedOptionsList} //IDropdownItem[]
                        formCurrentValue={values.unique_value_mobile}
                        isDisabled={
                            !values.unique_value_mobile &&
                            (!item || item.answer === null)
                                ? true
                                : false
                        }
                        onlyShowMatch={onlyShowMatch}
                        maxWidth="200px"
                        helperText="Please type your region (ex: Europe) to search"
                        sendId
                        isKeyValue={isKeyValue}
                        backgroundColor="var(--modal-side-sheet-pop-over-bg, #fff)"
                    />
                )}
                <Padding24 />
                <ButtonsStandardBottomRowMobile
                    cancel={handleCancel}
                    submit={handleSubmit}
                    confirmationtext="save"
                />{' '}
            </ModalInnerContentWrapperMobile>
        </ModalContentMobile>
    )
}

const PreferencesSearchSelectFormMobile = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        unique_value_mobile: props.item?.id ? `${props.item.id}` : '',
    }),
    // validationSchema: {},

    enableReinitialize: true,

    handleSubmit: (values, { props, setSubmitting }) => {
        if (props.field_id) {
            if (props.standalonePicker) {
                let dataToSend: IUSerGeneralInfoUpdatePayload = {
                    id: props.userid,
                    data: {
                        user_preferences: {
                            [props.field_id]: props.selectedValue,
                        },
                    },
                }

                if (props.selectedValue !== props.item?.answer) {
                    props.handleSubmit(dataToSend)
                }
            } else if (values.unique_value_mobile !== props.item?.answer) {
                let dataToSend: IUSerGeneralInfoUpdatePayload = {
                    id: props.userid,
                    data: {
                        user_preferences: {
                            [props.field_id]: values.unique_value_mobile,
                        },
                    },
                }
                props.handleSubmit(dataToSend)
            }
        }
        props.toggle()

        setSubmitting(false)
    },
})(InnerForm)

export default withRouter(
    connect(mapStateToProps, dispatchProps)(PreferencesSearchSelectFormMobile)
)
