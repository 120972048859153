import styled from 'styled-components'
import { device } from '../../../templates/displays/devices'
import { IInsuranceAddress_API } from '../../../../redux/insuranceQuoteApplication/types'
import { useAppSelector } from '../../../../redux/store/hooks'
import Faded from '../../../templates/animated/faded'
import { Spin } from 'antd'
import { IInsuranceApplicationSectionId } from '../../../pages/insurance_quote/dashboards/insuranceApplicationDashboard'
import useThemes from '../../../../providers/theme/hooks'
import colours, { insurance_colours } from '../../../../providers/theme/colours'

type IStyledProps = {
    $isSelectable?: boolean
}
const Card = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid var(--border-muted, #e5e5e5);
    background-color: var(
        --base-overlays-neutral-alpha-100,
        rgba(0, 0, 0, 0.04)
    );

    min-width: 443px;
    max-width: 443px;

    @media (min-width: 910px) and (max-width: 1200px) {
        min-width: 400px;
        max-width: 400px;
    }

    @media (min-width: 800px) and (max-width: 910px) {
        min-width: 350px;
        max-width: 350px;
    }

    @media ${device.mobile_and_ipad} {
        width: 100%;
        min-width: unset;
        max-width: unset;
    }
`

const CardBody = styled.div<IStyledProps>`
    all: unset;
    height: 100%;
    cursor: ${(props) => (props.$isSelectable ? `pointer` : 'auto')};
`
const CardBodyText = styled.p`
    width: 100%;
    font-family: Lato;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--text-strong, #1a1a1a);
`

const CardFooter = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding-top: 16px;
`

const Btn = styled.button`
    all: unset;
    font-family: Lato-semibold;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: max-content;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: all 150ms;
`

type Props = {
    address: IInsuranceAddress_API
    onChange: () => void
    sectionID?: IInsuranceApplicationSectionId
}

const InsuranceAddressCardV2 = (props: Props) => {
    let { address, onChange, sectionID } = props

    let flattenedAddress_part_3 = `
          ${address && address.postcode ? `${address.postcode} ` : ''}`

    let application = useAppSelector(
        (state) => state.insuranceQuoteApplication.submitted_data
    )

    let isLoading = useAppSelector(
        (state) => state.insuranceQuoteApplication.isLoading
    )

    let isDraft = application?.status === 'DRAFT' ? true : false

    const { theme } = useThemes()

    return (
        <>
            {isLoading ? (
                <Faded>
                    <Card
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Spin
                            style={{
                                height: '20px',
                                width: '20px',
                            }}
                        />
                    </Card>
                </Faded>
            ) : (
                <Card>
                    <CardBody>
                        <CardBodyText>
                            {address && address.street_1
                                ? `${address.street_1} `
                                : ''}
                            {address && address.street_2
                                ? `${address.street_2} `
                                : ''}
                            {address && address.locality
                                ? `${address.locality} `
                                : ''}
                        </CardBodyText>

                        <CardBodyText>{flattenedAddress_part_3}</CardBodyText>
                    </CardBody>
                    {isDraft && (
                        <CardFooter>
                            <Btn
                                style={{
                                    color: sectionID
                                        ? insurance_colours[theme].sections[
                                              sectionID
                                          ].primary_100
                                        : colours[theme].primary,
                                }}
                                onClick={onChange}
                            >
                                Change address
                            </Btn>
                        </CardFooter>
                    )}
                </Card>
            )}
        </>
    )
}

export default InsuranceAddressCardV2
