import { IReduxError } from 'entityModels'
import styled from 'styled-components'
import { device } from '../displays/devices'
import { fromIsoStringToDDMMYYY_time } from '../../../redux/conversions/time'
import LeftAlignLogo from '../../atoms/icons/components/leftAlignLogo'

const ScreenWrapper = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    z-index: 100;
    left: 0;
    right: 0;
    background-color: var(--bg-color, #fff);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @media ${device.beyond_ipad_mobile} {
    }
`

const ContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    padding: 24px;
    max-width: 600px;

    @media ${device.beyond_ipad_mobile} {
        max-width: 700px;
        gap: 40px;
    }
`

const Title = styled.h1`
    margin: 0px;
    padding: 0px;
    color: var(--text-strong, #1a1a1a);
    font-family: Lato-semibold;
    font-size: 32px;
    line-height: normal;
    @media ${device.small_mobile} {
        font-size: 28px;
    }
    @media ${device.beyond_ipad_mobile} {
        font-size: 64px;
    }
    @media ${device.large_desktop} {
        font-size: 72px;
    }
`

const Details = styled.p`
    margin: 0px;
    padding: 0px;
    color: var(--text-default, #666);
    font-family: Lato;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.001px;
    @media ${device.beyond_ipad_mobile} {
        font-size: 18px;
        line-height: 28px;
    }
    @media ${device.large_desktop} {
        font-size: 20px;
        line-height: 32px;
    }
`

const DetailsLink = styled.a`
    all: unset;
    cursor: pointer;
    margin: 0px;
    padding: 0px;
    color: var(--primary, #5ec3ca);
    font-family: Lato;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.001px;
    @media ${device.beyond_ipad_mobile} {
        font-size: 20px;
        line-height: 32px;
    }
`

const FooterContent = styled.div`
    display: flex;
    gap: 16px;
    padding: 24px 0px;
    align-self: stretch;
    border-top: 1px solid var(--border-muted, #e5e5e5);
    @media ${device.small_mobile} {
        gap: 10px;
    }
`

const FooterColumn = styled.div`
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 8px;
`

const FooterText = styled.p`
    font-family: Lato;
    margin: 0px;
    padding: 0px;
    color: #b3b3b3;
    font-size: 14px;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.001px;
    @media ${device.small_mobile} {
        font-size: 12px;
    }
`

type Props = {
    error: IReduxError
}

export const MaintenanceScreen = (props: Props) => {
    let startDate = props.error?.default?.start_date
    let endDate = props.error?.default?.end_date
    let txt = props.error?.default?.message
    return (
        <ScreenWrapper>
            <ContentWrapper>
                <LeftAlignLogo size={32} />
                <Title>We’ll be back soon</Title>

                {txt && txt.length > 2 ? (
                    <Details>{txt}</Details>
                ) : (
                    <>
                        <Details>
                            Sorry for the inconvenience but we're undergoing
                            some maintenance at the moment. If you need to you
                            can{' '}
                            <DetailsLink href="mailto:hello@custodia.club?subject=Hello">
                                contact us
                            </DetailsLink>
                            , otherwise we'll be back shortly!
                        </Details>
                        <Details>Custodian Team</Details>
                    </>
                )}

                <FooterContent>
                    <FooterColumn>
                        <FooterText>Maintenance start date</FooterText>

                        <FooterText>Maintenance end date</FooterText>
                    </FooterColumn>
                    {startDate && (
                        <FooterColumn>
                            {/* to be replaced with props for actual date */}
                            <FooterText style={{ color: '#666' }}>
                                {fromIsoStringToDDMMYYY_time(startDate)}
                            </FooterText>
                            <FooterText style={{ color: '#666' }}>
                                {endDate &&
                                    fromIsoStringToDDMMYYY_time(endDate)}
                            </FooterText>
                        </FooterColumn>
                    )}
                </FooterContent>
            </ContentWrapper>
        </ScreenWrapper>
    )
}
