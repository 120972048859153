import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router'
import { menuActions } from '../../../../redux/menus/reducer'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'

import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import InsuranceApplicationCardDesktop from '../../../molecules/cards/insurance/insuranceApplicationCardDesktop'
import React from 'react'
import InsuranceTopBarWithProgressionRate from '../../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRate'
import InsuranceCustomBottomBar from '../../../templates/bars/insurance/bottomBars/insuranceCustomBottomBar'
import InsuranceApplicationCardMobile from '../../../molecules/cards/insurance/insuranceApplicationCardMobile'
import InsuranceTopBarWithProgressionRateMobile from '../../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRateMobile'
import InsuranceApplicationCardContentManager from '../../../organisms/InsuranceApplicationCardContentManager/InsuranceApplicationCardContentManager'
import FullWidthInfoBoxWithTitle from '../../../atoms/info/fullWidthinfoBoxWithTitle'
import Expander from '../../../atoms/expander/expander'
import {
    IInsuranceQuoteApplication_MainDriver,
    insuranceActions,
} from '../../../../redux/insuranceQuoteApplication/reducer'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import InsuranceValidationSheetManager from '../../../templates/bars/insurance/validation'
import useWindowSize, {
    WindowSize,
} from '../../../templates/displays/windowSizeHook'
import RightSideModalDisplay from '../../../templates/displays/pageWrappers/rightSideModalDisplay'
import EverydayCarSelection from '../../../organisms/insuranceQuote/modalManagers/everydayCarSelection'
import { useSetInitialInsurance } from '../../../../providers/insurance/insuranceInitialProvider'
import styled from 'styled-components'
import SquareIconBtn from '../../../atoms/Button/squareIconBtn'
import CustomTooltip from '../../../atoms/info/CustomTooltip'
import BottomSheetWarning from '../../../atoms/info/bottomSheetWarning'
import posthog from 'posthog-js'
import { device } from '../../../templates/displays/devices'
import { useCheckIfTopPage } from '../../../../helpers/hooks/checkScrollDirection'
import MissingInfoAnimatedSvg from '../../../atoms/animatedSvgs/missingInfo'
import ReferralCodeModalMobile from '../../../organisms/insuranceQuote/modalManagers/referralCodeModalMobile'
import colours from '../../../../providers/theme/colours'
import useThemes from '../../../../providers/theme/hooks'

export const FloatingBtnWrapper = styled.div`
    position: fixed;
    bottom: 16px;
    right: 2rem;
    z-index: 110;
    @media ${device.mobile_and_ipad} {
        bottom: 12px;
    }
`
export const FloatingBtnSubWrapper = styled.div`
    position: relative;
`

const AbsolutePositioning = styled.div`
    position: absolute;
    bottom: 100px;
    right: 40px;
    @media ${device.mobile_and_ipad} {
        display: none;
    }
`

export type IInsuranceApplicationSectionId =
    | 'insurance_details'
    | 'vehicles_to_insure'
    | 'everyday_vehicles'
    | 'main_policy_holder'
    | 'drivers'
    | 'addresses'
    | 'referral_code'

export type IInsuranceApplicationSectionCard = {
    id: string
    sectionID?: IInsuranceApplicationSectionId
    title?: string
    text?: string
    text_items?: { title?: string; text: string }[]
    onClick?: () => any
    onToggle?: (v: boolean) => any
    isToggled?: boolean
    hasToggle?: boolean
    isDisabled?: boolean
    btnTxt?: string
    btnIcon?: string
    numberOfProgressBars?: number
    arrayOfProgressBarsValues?: number[]
    hasTooltip?: boolean
    tooltipTxt?: string
    removeBtn?: boolean
    hideOnDesktop?: boolean
}

const InsuranceApplicationDashboard = () => {
    const history = useHistory()

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)
    const addresses = useAppSelector((state) => {
        return state.insuranceQuoteApplication.submitted_data.addresses
    })

    let addressesList = Object.keys(addresses).map((key: string) => {
        return addresses[key]
    })

    let numberOfAddresses = addressesList.length

    const dispatch = useAppDispatch()

    const { id } = useParams<{ id?: string }>()

    let { getUserAndApplicationData, setCrossFormData } =
        useSetInitialInsurance()

    useEffect(() => {
        id && getUserAndApplicationData(id)
    }, [userLoggedIn])

    useEffect(() => {
        setCrossFormData(undefined)
        dispatch(menuActions.manageTopSubmenu({ isRemoved: true }))

        return () => {
            dispatch(menuActions.manageTopSubmenu({ isRemoved: false }))
        }
    }, [])

    const main_driver: IInsuranceQuoteApplication_MainDriver = useAppSelector(
        (state) => state.insuranceQuoteApplication.submitted_data.main_driver
    )

    let everyday_car_ids = useAppSelector(
        (state) =>
            state.insuranceQuoteApplication.submitted_data.everyday_car_ids
    )

    let application = useAppSelector(
        (state) => state.insuranceQuoteApplication.submitted_data
    )

    let is_application_loading = useAppSelector(
        (state) => state.insuranceQuoteApplication.isLoading
    )

    let details_card_completion: number =
        application && application.insurance_details_stats
            ? application.insurance_details_stats
            : 0
    let main_holder_card_completion: number =
        application && application.main_driver_overall_stats
            ? application.main_driver_overall_stats
            : 0
    let veficles_overall_stats: number =
        application && application.vehicles_overall_stats
            ? application.vehicles_overall_stats
            : 0
    let everyday_car_completion: number =
        application && application.everyday_car_stats
            ? application.everyday_car_stats
            : 0

    let drivers_completion: number[] = application.selected_named_drivers
        ? application.selected_named_drivers.map(
              (key) => application?.other_drivers[key]?.completion ?? 0
          )
        : []

    let isDraft = application.status === 'DRAFT'

    const cards: IInsuranceApplicationSectionCard[] = [
        {
            id: 'insurance_details',
            sectionID: 'insurance_details',
            title: 'Insurance details',
            text: `Specify the start date and duration of the insurance policy. These details help us create a policy that aligns with your preferred coverage period and ensures seamless coverage when you need it.`,
            isDisabled: false,
            btnTxt: `Add details`,
            btnIcon: 'add',
            numberOfProgressBars: 1,
            arrayOfProgressBarsValues: [details_card_completion],
            onClick: () => {
                return history.push(
                    `/insurance/application/${id}/insurance_details`
                )
            },
        },
        {
            id: 'vehicles_to_insure',
            sectionID: 'vehicles_to_insure',
            title: `Vehicles to insure`,
            text: `Choose the cars you want to include in the insurance policy from your personal garage. We'll prefill most of the vehicle details you've already entered, saving you time and effort.`,
            isDisabled: false,
            btnTxt: `Select cars`,
            btnIcon: 'edit',
            numberOfProgressBars: 1,
            arrayOfProgressBarsValues: [veficles_overall_stats],
            onClick: () => {
                return history.push(`/insurance/application/${id}/vehicles`)
            },
        },
        {
            id: 'everyday_vehicles',
            sectionID: 'everyday_vehicles',
            title: `Everyday vehicles`,
            text: `Even if you don't plan to insure your everyday vehicle with Custodian, we still need to know what you drive regularly.  You can either add that information in this section, or mark one of the vehicles to insure above as your everyday.`,
            isDisabled: false,
            btnTxt: `Select cars`,
            btnIcon: 'edit',
            numberOfProgressBars: 1,
            arrayOfProgressBarsValues: [everyday_car_completion],
            onClick: () => {
                return toggleShowAddEverydayCar(true)
            },
            removeBtn: isDraft ? false : true,
        },
        {
            id: 'main_policy_holder',
            sectionID: 'main_policy_holder',
            title: `Policy holder`,
            text: `These details help us ensure accurate communication and assess the policyholder's risk profile, allowing us to tailor the coverage accordingly.`,
            isDisabled: false,
            btnTxt: `Add details`,
            btnIcon: 'edit',
            numberOfProgressBars: 1,
            arrayOfProgressBarsValues: [main_holder_card_completion],
            onClick: () => {
                return history.push(
                    `/insurance/application/${id}/main_driver/${main_driver?.id}`
                )
            },
        },
        {
            id: 'drivers',
            sectionID: 'drivers',
            title: `Drivers`,
            text: `Capture information about any additional drivers who will be covered by the policy. This helps us assess the overall risk and ensure appropriate coverage for all drivers involved.`,
            isDisabled: false,
            btnTxt: `Manage drivers`,
            btnIcon: 'add',
            numberOfProgressBars: application.selected_named_drivers
                ? application.selected_named_drivers?.length + 1
                : 1,
            arrayOfProgressBarsValues: [
                main_holder_card_completion,
                ...drivers_completion,
            ],
            onClick: () => {
                return history.push(`/insurance/application/${id}/drivers`)
            },
        },
        // {
        //     id: 'adds_on',
        //     title: `Add-ons`,
        //     text: `Capture information about any additional drivers who will be covered by the policy. This helps us assess the overall risk and ensure appropriate coverage for all drivers involved.`,
        //     isDisabled: false,
        //     btnTxt: `Add details`,
        //     btnIcon: 'add',
        //     numberOfProgressBars: 0,
        //     arrayOfProgressBarsValues: [],
        //     onClick: () => {
        //         return history.push(`/insurance/application/${id}/adds_on`)
        //     },
        // },
        {
            id: 'addresses',
            sectionID: 'addresses',
            title: `Addresses`,
            text: `Save multiple addresses that are frequently used, such as your home address, or addresses of other drivers. This allows for quick and convenient selection of addresses when filling in details for drivers or vehicles.`,
            isDisabled: false,
            btnTxt:
                numberOfAddresses > 0
                    ? numberOfAddresses > 2
                        ? `Manage addresses (${numberOfAddresses})`
                        : 'Manage addresses'
                    : `Add address`,
            btnIcon: 'add',
            numberOfProgressBars: 0,
            arrayOfProgressBarsValues: [],
            onClick: () => {
                return history.push(`/insurance/application/${id}/addresses`)
            },
        },
        {
            id: 'referral_code',
            sectionID: 'referral_code',
            title: `Got a Referral Code?`,
            text: `If a friend or fellow car enthusiast already has insurance with Custodian, they might have shared a referral code with you.`,
            isDisabled: false,
            btnIcon: 'add',
            numberOfProgressBars: 0,
            arrayOfProgressBarsValues: [],
            onClick: () => {
                toggleReferralCodeModal(true)
            },
            hideOnDesktop: true,
        },
    ]

    let hasValidationError = useAppSelector((state) => {
        return state.insuranceQuoteApplication.showValidationSheet
    })

    const windowWidth = useWindowSize().width

    const [showAddEverydayCar, toggleShowAddEverydayCar] = useState(false)
    const [updatedEverydayCars, setUpdatedEverydayCars] = useState<string[]>([])

    const [showValidationFromBtnPress, toggleValidationFromBtnPress] =
        useState(false)

    useEffect(() => {
        setUpdatedEverydayCars(everyday_car_ids ?? [])
    }, [everyday_car_ids])

    let isApplicationValidAndSubmitted =
        application.status === 'REQUESTED' ||
        application.status === 'QUOTING' ||
        application.status === 'BROKING' ||
        application.status === 'ENRICHMENT'
            ? true
            : false

    const [showHelpTooltip, toggleShowHelpTooltip] = useState(false)
    const size: WindowSize = useWindowSize()
    let isMobile = size && size.width && size.width < 880 ? true : false

    let showInfoBox = useCheckIfTopPage(100)

    const goToWizard = () => {
        return history.push(`/insurance/application/${id}/wizard`)
    }

    const [showReferralCodeModal, toggleReferralCodeModal] = useState(false)
    const [hasRCFormChanged, setHasRCFormChanged] = useState(false)

    let application_draft = useAppSelector(
        (state) => state.insuranceQuoteApplication.draft
    )

    let draft_referral_code =
        application_draft.insurance_details[`application_referral_code`]
    let saved_referral_code =
        application.insurance_details[`application_referral_code`]

    let onAnswerChange = (answer: string) => {
        if (
            answer &&
            answer.trim().length > 0 &&
            answer !== saved_referral_code
        ) {
            setHasRCFormChanged(true)
        } else {
            setHasRCFormChanged(false)
        }

        dispatch(
            insuranceActions.set_answer_insuranceDetails({
                id: `application_referral_code`,
                answer: answer,
            })
        )
    }

    const submitReferralCode = () => {
        dispatch(insuranceActions.submit_insuranceDetailsApplication_request())
        toggleReferralCodeModal(false)
    }

    const { theme } = useThemes()

    return userLoggedIn ? (
        <div
            style={{
                minHeight: '100vh',
                width: '100%',
                backgroundColor: colours[theme].background_neutral_subtle,
            }}
        >
            {isDraft && (
                <InsuranceValidationSheetManager
                    triggerShowAddEverydayCar={() =>
                        toggleShowAddEverydayCar(true)
                    }
                    showValidationFromBtnPress={showValidationFromBtnPress}
                    closeValidationFromBtnPress={() =>
                        toggleValidationFromBtnPress(false)
                    }
                />
            )}
            <DesktopDisplayOnly>
                <InsuranceTopBarWithProgressionRate
                    wizard_click_play={
                        isDraft &&
                        application?.overall_completion &&
                        application.overall_completion < 100
                            ? goToWizard
                            : undefined
                    }
                    progressionRate={application.overall_completion}
                    breadCrumbs={[
                        {
                            pageName: 'Your application',
                        },
                    ]}
                    goBack={() => history.push('/insurance/overview')}
                />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        paddingTop: 80,
                        paddingBottom: 120,
                        position: 'relative',
                    }}
                >
                    <div
                        style={{
                            position: 'fixed',
                            width: '100%',
                            zIndex: 10,
                            transform: showInfoBox
                                ? `translateY(0)`
                                : `translateY(-400px)`,
                            transition: 'all 200ms',
                        }}
                    >
                        <Expander
                            width="100%"
                            height={
                                (isApplicationValidAndSubmitted || !isDraft) &&
                                !is_application_loading &&
                                application?.id
                                    ? 'auto'
                                    : 0
                            }
                        >
                            <FullWidthInfoBoxWithTitle
                                title="Thank you for submitting your insurance application!"
                                text={
                                    isApplicationValidAndSubmitted
                                        ? `We're working diligently to review your details and provide you with a personalized offer. Sit tight while our team processes your application. In the meantime, feel free to review the information you've provided. Please note that editing is no longer available at this stage. We'll be in touch with our response soon.`
                                        : `You can view the data you've provided us with here.`
                                }
                                variant="info"
                            />
                        </Expander>
                    </div>

                    <div
                        style={{
                            paddingTop: !isDraft && application?.id ? 140 : 40,
                        }}
                    />

                    {cards.map((card: IInsuranceApplicationSectionCard) => {
                        return card.hideOnDesktop ? null : (
                            <React.Fragment key={card.id}>
                                <InsuranceApplicationCardDesktop
                                    cardProps={card}
                                    isApplicationSubmitted={
                                        isApplicationValidAndSubmitted ||
                                        !isDraft
                                    }
                                    outlinedBtns={true}
                                    hasIcon={true}
                                >
                                    <InsuranceApplicationCardContentManager
                                        card={card}
                                        addressesList={addressesList}
                                        applicationID={id}
                                    />
                                </InsuranceApplicationCardDesktop>
                            </React.Fragment>
                        )
                    })}
                    <AbsolutePositioning>
                        <MissingInfoAnimatedSvg />
                    </AbsolutePositioning>
                </div>
                {isDraft && showAddEverydayCar && (
                    <RightSideModalDisplay
                        isOpen={showAddEverydayCar ? true : false}
                        cancel={() => {
                            toggleShowAddEverydayCar(false)
                        }}
                        title={'Add everyday car'}
                        footerBtn={{
                            action: () => {
                                dispatch(
                                    insuranceActions.submit_everyday_car_ids_request(
                                        updatedEverydayCars
                                    )
                                )

                                toggleShowAddEverydayCar(false)
                            },
                            text: 'Save',
                        }}
                        sectionId="everyday_vehicles"
                    >
                        <EverydayCarSelection
                            selected={updatedEverydayCars}
                            updateSelected={(ids: string[]) =>
                                setUpdatedEverydayCars(ids)
                            }
                        />
                    </RightSideModalDisplay>
                )}

                <InsuranceCustomBottomBar
                    onClose={() => history.push('/insurance/overview')}
                    onSendApplication={() => {
                        !hasValidationError &&
                            application.id &&
                            dispatch(
                                insuranceActions.validate_insurance_application_request(
                                    {
                                        application: { ...application },
                                        activateValidationSheet: true,
                                    }
                                )
                            )
                        hasValidationError && toggleValidationFromBtnPress(true)

                        showReferralCodeModal && toggleReferralCodeModal(false)
                    }}
                    isSendDisabled={hasValidationError}
                    onDeleteApplication={() => {
                        application.id &&
                            dispatch(
                                insuranceActions.delete_insurance_application_request(
                                    {
                                        uid: application.id,
                                        redirectURL: '/insurance/overview',
                                    }
                                )
                            )
                    }}
                    showReferralCodeModal={showReferralCodeModal}
                    toggleReferralCodeModal={(v: boolean) => {
                        toggleReferralCodeModal(v)
                        if (showValidationFromBtnPress) {
                            toggleValidationFromBtnPress(false)
                        }
                    }}
                    draft_referral_code={draft_referral_code}
                    saved_referral_code={saved_referral_code}
                    submitReferralCode={submitReferralCode}
                    onReferralCodeChange={onAnswerChange}
                    isSaveRCDisabled={hasRCFormChanged ? false : true}
                />
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <InsuranceTopBarWithProgressionRateMobile
                    wizard_click_play={
                        isDraft &&
                        application?.overall_completion &&
                        application.overall_completion < 100
                            ? goToWizard
                            : undefined
                    }
                    goBack={() => history.push('/insurance/overview')}
                    progressionRate={application.overall_completion ?? 0}
                    breadCrumbs={[
                        {
                            pageName: 'Your application',
                        },
                    ]}
                />
                {!showAddEverydayCar && (
                    <div
                        style={{
                            width: '100%',
                            paddingTop: 80,
                            paddingBottom: 120,
                        }}
                    >
                        <div
                            style={{
                                position: 'fixed',
                                width: '100%',
                                zIndex: 10,
                                transform: showInfoBox
                                    ? `translateY(0)`
                                    : `translateY(-400px)`,
                                transition: 'all 200ms',
                            }}
                        >
                            <Expander
                                width="100%"
                                height={
                                    !isDraft &&
                                    !is_application_loading &&
                                    application?.id
                                        ? 'auto'
                                        : 0
                                }
                            >
                                <FullWidthInfoBoxWithTitle
                                    title="Thank you for submitting your insurance application!"
                                    text={
                                        isApplicationValidAndSubmitted
                                            ? `We're working diligently to review your details and provide you with a personalized offer. Sit tight while our team processes your application. In the meantime, feel free to review the information you've provided. Please note that editing is no longer available at this stage. We'll be in touch with our response soon.`
                                            : `You can view the data you've provided us with here.`
                                    }
                                    variant="info"
                                    isMobile
                                    noInfoIcon
                                />
                            </Expander>
                        </div>
                        <div
                            style={{
                                width: '100%',
                                paddingTop:
                                    application.id && !isDraft
                                        ? windowWidth && windowWidth < 450
                                            ? '250px'
                                            : '200px'
                                        : '40px',
                                paddingLeft: 16,
                                paddingRight: 16,
                                backgroundColor:
                                    colours[theme].background_neutral_subtle,
                            }}
                        >
                            {cards.map(
                                (card: IInsuranceApplicationSectionCard) => {
                                    return (
                                        <div key={card.id}>
                                            <InsuranceApplicationCardMobile
                                                cardProps={card}
                                                hasIcon={true}
                                                outlinedBtns={false}
                                            >
                                                <InsuranceApplicationCardContentManager
                                                    card={card}
                                                    addressesList={
                                                        addressesList
                                                    }
                                                    isMobile
                                                    applicationID={id}
                                                />
                                            </InsuranceApplicationCardMobile>
                                        </div>
                                    )
                                }
                            )}
                        </div>
                    </div>
                )}
                {showAddEverydayCar && isDraft && (
                    <RightSideModalDisplay
                        isOpen={showAddEverydayCar ? true : false}
                        cancel={() => {
                            toggleShowAddEverydayCar(false)
                        }}
                        title={'Add everyday car'}
                        footerBtn={{
                            action: () => {
                                if (isDraft) {
                                    dispatch(
                                        insuranceActions.submit_everyday_car_ids_request(
                                            updatedEverydayCars
                                        )
                                    )

                                    toggleShowAddEverydayCar(false)
                                }
                            },
                            text: 'Save',
                        }}
                        sectionId="everyday_vehicles"
                    >
                        <EverydayCarSelection
                            selected={updatedEverydayCars}
                            updateSelected={(ids: string[]) =>
                                setUpdatedEverydayCars(ids)
                            }
                            isMobile
                        />
                    </RightSideModalDisplay>
                )}

                <InsuranceCustomBottomBar
                    isMobile
                    onClose={() => history.push('/insurance/overview')}
                    onSendApplication={() => {
                        !hasValidationError &&
                            application.id &&
                            dispatch(
                                insuranceActions.validate_insurance_application_request(
                                    {
                                        application: { ...application },
                                        activateValidationSheet: true,
                                    }
                                )
                            )

                        hasValidationError && toggleValidationFromBtnPress(true)
                    }}
                    isSendDisabled={hasValidationError}
                    onDeleteApplication={() => {
                        application.id &&
                            dispatch(
                                insuranceActions.delete_insurance_application_request(
                                    {
                                        uid: application.id,
                                        redirectURL: '/insurance/overview',
                                    }
                                )
                            )
                    }}
                />
                <ReferralCodeModalMobile
                    isOpen={showReferralCodeModal}
                    toggleModal={() => toggleReferralCodeModal(false)}
                    draft_referral_code={draft_referral_code}
                    saved_referral_code={saved_referral_code}
                    onChange={onAnswerChange}
                    onSubmit={submitReferralCode}
                    isSaveDisabled={hasRCFormChanged ? false : true}
                />
            </IpadAndMobileDisplay>
            {application.status !== 'DRAFT' && application.id && (
                <FloatingBtnWrapper>
                    <FloatingBtnSubWrapper>
                        <CustomTooltip
                            isOpen={!isMobile && showHelpTooltip ? true : false}
                            toggle={() => toggleShowHelpTooltip(false)}
                            title="Need to make a change to your application?"
                            text={
                                <span>
                                    Please email us at{' '}
                                    <a
                                        href={
                                            'mailto:insurance@custodian.club?subject=Help'
                                        }
                                        style={{ color: '#5EC3CA' }}
                                    >
                                        insurance@custodian.club
                                    </a>{' '}
                                    and let us know what change you’d like to
                                    make.
                                </span>
                            }
                        />
                        <BottomSheetWarning
                            isOpen={isMobile && showHelpTooltip ? true : false}
                            toggle={() => toggleShowHelpTooltip(false)}
                            customHeight="250px"
                            title="Need to make a change to your application?"
                            text={
                                <span>
                                    Please email us at{' '}
                                    <a
                                        href={
                                            'mailto:insurance@custodian.club?subject=Help'
                                        }
                                        style={{ color: '#5EC3CA' }}
                                    >
                                        insurance@custodian.club
                                    </a>{' '}
                                    and let us know what change you’d like to
                                    make.
                                </span>
                            }
                        />
                        <SquareIconBtn
                            icon="pen"
                            padding="12px"
                            size="48px"
                            isActive={showHelpTooltip ? true : false}
                            onClick={() => {
                                posthog.capture(
                                    'Click on insurance application help from readonly view'
                                )
                                toggleShowHelpTooltip(true)
                            }}
                        />
                    </FloatingBtnSubWrapper>
                </FloatingBtnWrapper>
            )}
        </div>
    ) : (
        <div />
    )
}

export default InsuranceApplicationDashboard
