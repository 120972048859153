import { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { menuActions } from '../../../../redux/menus/reducer'
import { useAppDispatch, useAppSelector } from '../../../../redux/store/hooks'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'
import React from 'react'
import InsuranceApplicationCardDesktop from '../../../molecules/cards/insurance/insuranceApplicationCardDesktop'
import InsuranceTopBarWithProgressionRate from '../../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRate'
import InsuranceGoBackReusableBottomBar from '../../../templates/bars/insurance/bottomBars/insureanceGoBackReusableBottomBar'
import InsuranceApplicationCardMobile from '../../../molecules/cards/insurance/insuranceApplicationCardMobile'
import InsuranceTopBarWithProgressionRateMobile from '../../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRateMobile'
import DesktopDisplayOnly from '../../../templates/displays/desktopDisplayOnly'
import InsuranceApplicationCardContentManager from '../../../organisms/InsuranceApplicationCardContentManager/InsuranceApplicationCardContentManager'
import { useSetInitialInsurance } from '../../../../providers/insurance/insuranceInitialProvider'
import { IInsuranceQuoteApplication } from '../../../../redux/insuranceQuoteApplication/reducer'
import { IRootState } from '../../../../redux/store'
import { IInsuranceApplicationSectionCard } from './insuranceApplicationDashboard'
import useThemes from '../../../../providers/theme/hooks'
import colours from '../../../../providers/theme/colours'

type UserIDParams = {
    userid: string
}

const InsuranceApplicationMainDriverDashboard = () => {
    const history = useHistory()

    const { userid } = useParams<UserIDParams>()

    const mainDriver = useAppSelector(
        (state) => state.insuranceQuoteApplication.submitted_data.main_driver
    )

    const main_driver_cards: IInsuranceApplicationSectionCard[] = [
        {
            id: 'basic_details_main_policy_driver',
            sectionID: 'main_policy_holder',
            title: 'Basic details',
            text: `Provide essential information about yourself, including your name, date of birth, and contact details. These details help us identify and communicate with you effectively throughout the insurance process.`,
            isDisabled: false,
            btnTxt: `Fill in details`,
            numberOfProgressBars: 1,
            arrayOfProgressBarsValues: [mainDriver?.basic_details_stats ?? 0],
            onClick: () => {
                return history.push(
                    `/insurance/application/${id}/main_driver/${userid}/basic_details`
                )
            },
        },
        {
            id: 'driver_license_address',
            sectionID: 'main_policy_holder',
            title: `Driver licence & Address`,
            text: `Provide your driver's licence information and address.`,
            isDisabled: false,
            btnTxt: 'Fill in details',
            numberOfProgressBars: 1,
            arrayOfProgressBarsValues: [mainDriver?.licence_address_stats ?? 0],
            onClick: () => {
                return history.push(
                    `/insurance/application/${id}/main_driver/${userid}/licence_address`
                )
            },
        },
        {
            id: 'additional_details',
            sectionID: 'main_policy_holder',
            title: `Additional details`,
            text: `Share additional information that can help us customize your insurance coverage. This may include details about homeownership, occupation, or any relevant memberships you hold.`,
            isDisabled: false,
            btnTxt: `Fill in details`,
            numberOfProgressBars: 1,
            arrayOfProgressBarsValues: [
                mainDriver?.additional_details_stats ?? 0,
            ],
            onClick: () => {
                return history.push(
                    `/insurance/application/${id}/main_driver/${userid}/additional_details`
                )
            },
        },
        {
            id: 'track_record',
            sectionID: 'main_policy_holder',
            title: `Track record`,
            text: `Provide details about your driving history, including any previous claims, convictions, or driving penalties.`,
            isDisabled: false,
            btnTxt: `Fill in details`,
            numberOfProgressBars: 1,
            arrayOfProgressBarsValues: [mainDriver.track_record_stats ?? 0],
            onClick: () => {
                return history.push(
                    `/insurance/application/${id}/main_driver/${userid}/track_record`
                )
            },
        },
    ]

    const dispatch = useAppDispatch()

    const { id } = useParams<{ id?: string }>()

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })
    let {
        getUserAndApplicationData,
        getInitialSectionWizardIds,
        setCrossFormData,
    } = useSetInitialInsurance()

    useEffect(() => {
        id && getUserAndApplicationData(id)
    }, [userLoggedIn])

    useEffect(() => {
        setCrossFormData(undefined)
        dispatch(menuActions.manageTopSubmenu({ isRemoved: true }))

        return () => {
            dispatch(menuActions.manageTopSubmenu({ isRemoved: false }))
        }
    }, [])

    // let params = new URLSearchParams(location.search)
    // let anchor_params = params.get('anchor')

    let applicationData: IInsuranceQuoteApplication = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.submitted_data
    )

    let getToAny = () => {
        let ids2 = getInitialSectionWizardIds()

        if (ids2) {
            let url1 = `/insurance/application/${id}/wizard?section_id=${ids2.sectionID}`
            if (ids2.sub_sectionID) {
                url1 = url1 + `&subsection_id=${ids2.sub_sectionID}`
            }
            if (ids2.car_id) {
                url1 = url1 + `&car_id=${ids2.car_id}`
            }
            if (ids2.driver_id) {
                url1 = url1 + `&driver_id=${ids2.driver_id}`
            }
            history.push(url1)
        } else {
            if (ids2 === null) {
                return
                // history.push(
                //     `/insurance/application/${applicationData?.id}`
                // )
            }
        }
    }

    let goToWizard = () => {
        let ids = getInitialSectionWizardIds('main_policy_holder')

        if (ids) {
            let url1 = `/insurance/application/${id}/wizard?section_id=${ids.sectionID}`
            if (ids.sub_sectionID) {
                url1 = url1 + `&subsection_id=${ids.sub_sectionID}`
            }
            if (ids.car_id) {
                url1 = url1 + `&car_id=${ids.car_id}`
            }
            if (ids.driver_id) {
                url1 = url1 + `&driver_id=${ids.driver_id}`
            }
            return history.push(url1)
        } else {
        }
        return getToAny()
    }

    let isDraft = applicationData.status === 'DRAFT' ? true : false

    const { theme } = useThemes()

    return userLoggedIn ? (
        <div
            style={{
                minHeight: '100vh',
                width: '100%',
                backgroundColor: colours[theme].background_neutral_subtle,
            }}
        >
            <DesktopDisplayOnly>
                <InsuranceTopBarWithProgressionRate
                    wizard_click_play={isDraft ? goToWizard : undefined}
                    breadCrumbs={[
                        {
                            pageName: 'Your application',
                            pageUrl: () =>
                                history.push(`/insurance/application/${id}`),
                        },
                        { pageName: 'Policy holder' },
                    ]}
                    progressionRate={
                        applicationData.main_driver?.completion ?? 0
                    }
                    sectionId="main_policy_holder"
                />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        paddingTop: 120,
                        paddingBottom: 120,
                        backgroundColor:
                            colours[theme].background_neutral_subtle,
                    }}
                >
                    {main_driver_cards.map(
                        (card: IInsuranceApplicationSectionCard) => {
                            return (
                                <React.Fragment key={card.id}>
                                    <InsuranceApplicationCardDesktop
                                        cardProps={card}
                                    >
                                        <InsuranceApplicationCardContentManager
                                            card={card}
                                            mainDriver={mainDriver}
                                            applicationID={id}
                                        />
                                    </InsuranceApplicationCardDesktop>
                                </React.Fragment>
                            )
                        }
                    )}
                </div>
                <InsuranceGoBackReusableBottomBar
                    goBack={() => history.push(`/insurance/application/${id}`)}
                />
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <InsuranceTopBarWithProgressionRateMobile
                    wizard_click_play={isDraft ? goToWizard : undefined}
                    breadCrumbs={[
                        {
                            pageName: 'Your application',
                            pageUrl: () =>
                                history.push(`/insurance/application/${id}`),
                        },
                        { pageName: 'Policy holder' },
                    ]}
                    progressionRate={
                        applicationData.main_driver?.completion ?? 0
                    }
                    sectionId={'main_policy_holder'}
                />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        paddingTop: 120,
                        paddingBottom: 120,
                        paddingLeft: 24,
                        paddingRight: 24,
                        backgroundColor:
                            colours[theme].background_neutral_subtle,
                    }}
                >
                    {main_driver_cards.map(
                        (card: IInsuranceApplicationSectionCard) => {
                            return (
                                <React.Fragment key={card.id}>
                                    <InsuranceApplicationCardMobile
                                        cardProps={card}
                                    >
                                        <InsuranceApplicationCardContentManager
                                            card={card}
                                            mainDriver={mainDriver}
                                            isMobile
                                            applicationID={id}
                                        />
                                    </InsuranceApplicationCardMobile>
                                </React.Fragment>
                            )
                        }
                    )}
                </div>
                <InsuranceGoBackReusableBottomBar
                    isMobile
                    goBack={() => history.push(`/insurance/application/${id}`)}
                />
            </IpadAndMobileDisplay>
        </div>
    ) : (
        <div />
    )
}

export default InsuranceApplicationMainDriverDashboard
