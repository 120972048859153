import { ICar } from 'entityModels'
import React, { useState } from 'react'
import { IInsuranceApplicationSectionId } from '../../components/pages/insurance_quote/dashboards/insuranceApplicationDashboard'
import { getGarageByUserIdRequest } from '../../redux/entities/garages/actions/loadingActions'

import {
    IAnyObject,
    IInsuranceQuoteApplication,
    insuranceActions,
    InsuranceSectionsWithDropdownsList_IDS,
} from '../../redux/insuranceQuoteApplication/reducer'
import { dropDownActions } from '../../redux/localdata/dropdownData/reducer'
import { IRootState } from '../../redux/store'
import { useAppDispatch, useAppSelector } from '../../redux/store/hooks'
import { getCurrentUserDataRequest } from '../../redux/user/actions/loadingActions'
import { IInsuranceApplicationSubSectionId, IWizardSectionsID } from './data'
import { IInsuranceFieldType } from '../../redux/localdata/insuranceLocalData/fieldTypes'

type ContextType = {
    setInitialInsurance_CarTechInfo: (carid: string) => any

    // dropdowns
    setInitialCarOwnershipDropdowns: (carid: string) => any
    setInitialCarTechnicalDropdowns: (carid: string) => any
    setInitialCarMileageAndParkingDropdowns: (carid: string) => any
    setInitialInsurance_Drivers_AdditionalDetails_dropdowns: () => any
    setInitialInsurance_Drivers_TrackRecord_dropdowns: () => any
    setInitialInsurance_Drivers_License_dropdowns: () => any
    getUserAndApplicationData: (appliid: string) => any
    setInitialInsurance_Drivers_BasicDetails_dropdowns: () => any
    getInitialSectionWizardIds: (
        sectionID?: IInsuranceApplicationSectionId,
        subsectionID?: string
    ) => IWizardSectionsID | null
    getFirstIncompleteSubsection_InsuredVehicle: (
        from_subsection?: string
    ) => IWizardSectionsID | null
    getFirstIncompleteSubsection_EverydayVehicle: () => IWizardSectionsID | null
    getFirstIncompleteSubsection_OtherDrivers: () => IWizardSectionsID | null

    getFirstIncompleteSubsection_OtherDriver: (
        id: string,
        from_subsection?: string
    ) => IWizardSectionsID | null

    getFirstIncompleteSubsection_Single_EverydayVehicle: (
        id: string,
        from_subsection?: string
    ) => IWizardSectionsID | null

    getFirstIncompleteSubsection_Single_InsuredVehicle: (
        id: string,
        from_subsection?: string
    ) => IWizardSectionsID | null

    set_wizard_entity_id_to_skip: (id: string) => any
    unset_wizard_entity_id_to_skip: (id: string) => any
    // on exit etc
    reset_set_wizard_entity_id_to_skip: () => any

    isInsuranceWindowOpened: boolean
    setIsInsuranceWindowOpened: (p: boolean) => any

    crossFormData:
        | undefined
        | {
              [key: string]: {
                  affected_field_id: string
                  affected_field_error_cta: string
                  field_to_update_info: string
                  field_to_update: IInsuranceFieldType
                  field_to_update_section_id?:
                      | 'insurance_details'
                      | 'main_driver'
                      | 'vehicles'
                      | 'other_drivers'

                  field_to_update_vehicle_id?: string
                  field_to_update_other_driver_id?: string

                  field_to_update_sub_section_id?: IInsuranceApplicationSubSectionId
                  onAnswerChange: any
                  onSubmit: any
                  currentFormSnapshop: {
                      keepUnsavedDataFunction: (id: string, answer: any) => any
                      resetUnsavedDataErrorsFunction: (
                          id: string,
                          answer: any
                      ) => any
                      unsavedDataListOfFields: IInsuranceFieldType[]
                      unsavedData: IAnyObject
                  }
              }
          }

    setCrossFormData: (
        p:
            | undefined
            | {
                  [key: string]: {
                      affected_field_id: string
                      affected_field_error_cta: string
                      field_to_update_info: string
                      field_to_update: IInsuranceFieldType
                      field_to_update_section_id?:
                          | 'insurance_details'
                          | 'main_driver'
                          | 'vehicles'
                          | 'other_drivers'

                      field_to_update_vehicle_id?: string
                      field_to_update_other_driver_id?: string

                      field_to_update_sub_section_id?: IInsuranceApplicationSubSectionId
                      onAnswerChange: any
                      onSubmit: any
                      currentFormSnapshop: {
                          keepUnsavedDataFunction: (
                              id: string,
                              answer: any
                          ) => any
                          resetUnsavedDataErrorsFunction: (
                              id: string,
                              answer: any
                          ) => any
                          unsavedDataListOfFields: IInsuranceFieldType[]
                          unsavedData: IAnyObject
                      }
                  }
              }
    ) => any
    isCrossFormOpen: { id: string } | undefined
    setIsCrossFormOpen: (v: { id: string } | undefined) => any
    trackCrossFormSubmission: { id: string } | undefined
    setTrackCrossFormSubmission: (v: { id: string } | undefined) => any
}

// add dropdown_id inside of types fields
const general_driver_dropdown_ids = ['titles']
// const vehicle_dropdown_ids = ['security_device_type']

const main_driver_additional_drop_ids = [
    'occupations',
    'employment_types',
    'industries',
    'motoring_clubs',
    'disability_types',
    'titles',
]

const trackrecord_drop_ids = [
    'conviction_types',
    'conviction_sentence_types',
    'claim_types',
    'motoring_conviction_types',
    'motoring_conviction_codes',
]

// claim status is ENUM see docs

const car_mileage_and_parking_drop_ids = ['parking_place', 'location_security']

const InsuranceInitialCtxt = React.createContext<ContextType>({} as any)

export const useSetInitialInsurance = () => {
    const props = React.useContext(InsuranceInitialCtxt)

    if (!props) {
        throw new Error(
            `You must call useSetInitialInsurance() inside of a <InsuranceGETProvider />`
        )
    }

    return props
}

export const InsuranceInitialProvider = ({ children }: any) => {
    const dispatch = useAppDispatch()

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    const garagesData = useAppSelector((state) => {
        return state.entities.garagesData
    })

    let applicationData: IInsuranceQuoteApplication = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.submitted_data
    )

    let applicationDataDraft: IInsuranceQuoteApplication = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.draft
    )

    let carsData = useAppSelector((state) => {
        return state.entities.carsData.cars
    })

    let has_sections_dropdowns: InsuranceSectionsWithDropdownsList_IDS[] =
        useAppSelector((state) => {
            return state.insuranceQuoteApplication.has_sections_dropdowns
        })

    const [wizard_entities_to_skip, set_wizard_entities_to_skip] = useState<
        string[]
    >([])

    const [isCrossFormOpen, setIsCrossFormOpen] = useState<
        { id: string } | undefined
    >(undefined)

    const [crossFormData, setCrossFormData] = useState<
        | undefined
        | {
              [key: string]: {
                  affected_field_id: string
                  affected_field_error_cta: string
                  field_to_update_info: string
                  field_to_update: IInsuranceFieldType
                  field_to_update_section_id?:
                      | 'insurance_details'
                      | 'main_driver'
                      | 'vehicles'
                      | 'other_drivers'

                  field_to_update_vehicle_id?: string
                  field_to_update_other_driver_id?: string

                  field_to_update_sub_section_id?: IInsuranceApplicationSubSectionId
                  onAnswerChange: any
                  onSubmit: any
                  currentFormSnapshop: {
                      keepUnsavedDataFunction: (id: string, answer: any) => any
                      resetUnsavedDataErrorsFunction: (
                          id: string,
                          answer: any
                      ) => any
                      unsavedDataListOfFields: IInsuranceFieldType[]
                      unsavedData: IAnyObject
                  }
              }
          }
    >(undefined)

    const [trackCrossFormSubmission, setTrackCrossFormSubmission] = useState<
        { id: string } | undefined
    >(undefined)

    const [isInsuranceWindowOpened, setIsInsuranceWindowOpened] =
        useState<boolean>(false)
    const set_wizard_entity_id_to_skip = (id: string) => {
        set_wizard_entities_to_skip([...wizard_entities_to_skip, id])
        return
    }

    const reset_set_wizard_entity_id_to_skip = () => {
        set_wizard_entities_to_skip([])
        return
    }

    const unset_wizard_entity_id_to_skip = (id: string) => {
        let newIds = wizard_entities_to_skip.filter((item) => item !== id)
        set_wizard_entities_to_skip([...newIds])
        return
    }

    // let techInfoData = useAppSelector((state) => {
    //     return state.entities.technicalInformationData?.technical_information
    // })

    let makesDropdowndata = useAppSelector((state) => {
        return state.localdata.dropdownData.carMakes
    })

    let modelsDropdowndata = useAppSelector((state) => {
        return state.localdata.dropdownData.carModels
    })

    let transmissionsList = useAppSelector((state) => {
        return state.localdata.dropdownData.carTransmissionTypes
    })

    let body_types_list = useAppSelector((state) => {
        return state.localdata.dropdownData.carBodyTypes
    })

    let countriesList = useAppSelector((state) => {
        return state.localdata.dropdownData.countriesCode
    })

    const setInitialInsurance_Drivers_BasicDetails_dropdowns = () => {
        if (
            userLoggedIn &&
            !has_sections_dropdowns.includes('drivers_basicDetails')
        ) {
            dispatch(
                dropDownActions.setInsuranceFieldByIDsDropdownDataRequest(
                    general_driver_dropdown_ids
                )
            )

            dispatch(
                insuranceActions.set_has_sections_dropdowns_ids([
                    'drivers_basicDetails',
                ])
            )
        }
    }

    const setInitialInsurance_Drivers_AdditionalDetails_dropdowns = () => {
        if (
            userLoggedIn &&
            !has_sections_dropdowns.includes('drivers_additionalDetails')
        ) {
            dispatch(
                dropDownActions.setInsuranceFieldByIDsDropdownDataRequest(
                    main_driver_additional_drop_ids
                )
            )

            dispatch(
                insuranceActions.set_has_sections_dropdowns_ids([
                    'drivers_additionalDetails',
                ])
            )
        }
    }

    const setInitialInsurance_Drivers_TrackRecord_dropdowns = () => {
        if (
            userLoggedIn &&
            !has_sections_dropdowns.includes('drivers_trackRecord')
        ) {
            dispatch(
                dropDownActions.setInsuranceFieldByIDsDropdownDataRequest(
                    trackrecord_drop_ids
                )
            )

            dispatch(
                insuranceActions.set_has_sections_dropdowns_ids([
                    'drivers_trackRecord',
                ])
            )
        }
    }

    let drivers_license_drop_ids = ['licence_type', 'license_issue_locations']

    const getUserAndApplicationData = (appliid: string) => {
        // const { id } = useParams<{id?: string}>()

        // let refresh_token: string | null =
        //     typeof localStorage !== 'undefined'
        //         ? localStorage.getItem('refresh_token')
        //         : null

        if (!userLoggedIn) {
            dispatch(getCurrentUserDataRequest())
        } else {
            if (!applicationData?.id && appliid) {
                dispatch(
                    insuranceActions.get_insurance_application_request(appliid)
                )
            } else if (appliid && `${applicationData.id}` !== `${appliid}`) {
                dispatch(
                    insuranceActions.get_insurance_application_request(appliid)
                )
            }

            if (Object.keys(garagesData.garages).length === 0) {
                dispatch(getGarageByUserIdRequest())
            }
        }
    }

    const setInitialInsurance_Drivers_License_dropdowns = () => {
        if (userLoggedIn) {
            dispatch(
                dropDownActions.setInsuranceFieldByIDsDropdownDataRequest(
                    drivers_license_drop_ids
                )
            )
        }
    }

    let tech_info_drop_ids = ['vehicle_modification_types']

    const setInitialCarTechnicalDropdowns = (carid: string) => {
        if (userLoggedIn && !transmissionsList) {
            dispatch(dropDownActions.setTransmissionTypesDropdownDataRequest())
        }

        if (userLoggedIn && !makesDropdowndata) {
            dispatch(dropDownActions.setMakeDropdownDataRequest())
        }

        if (userLoggedIn && !body_types_list) {
            dispatch(dropDownActions.setBodyTypeDropdownDataRequest())
        }

        if (
            userLoggedIn &&
            !has_sections_dropdowns.includes('technical_information')
        ) {
            dispatch(
                dropDownActions.setInsuranceFieldByIDsDropdownDataRequest(
                    tech_info_drop_ids
                )
            )

            dispatch(
                insuranceActions.set_has_sections_dropdowns_ids([
                    'technical_information',
                ])
            )
        }

        let car: ICar | undefined = carsData[carid]
        let carMake: string | null = car?.overview.data['make'].answer

        if (
            userLoggedIn &&
            !modelsDropdowndata &&
            carMake &&
            carMake.length > 1
        ) {
            dispatch(
                dropDownActions.setModelDropdownDataRequest({
                    makeName: carMake,
                    make_uid: car?.overview.data['make']?.current_uid,
                })
            )
        }
    }

    const setInitialCarMileageAndParkingDropdowns = (carid: string) => {
        if (userLoggedIn) {
            dispatch(
                dropDownActions.setInsuranceFieldByIDsDropdownDataRequest(
                    car_mileage_and_parking_drop_ids
                )
            )
        }
    }

    const setInitialCarOwnershipDropdowns = (carid: string) => {
        if (userLoggedIn && !countriesList) {
            dispatch(dropDownActions.setCountriesCodeDropdownDataRequest())
        }

        if (userLoggedIn) {
            dispatch(
                dropDownActions.setInsuranceFieldByIDsDropdownDataRequest(
                    tech_info_drop_ids
                )
            )
        }
    }

    const setInitialInsurance_CarTechInfo = (carid: string) => {
        // let car: ICar | undefined = carsData[carid]
        // console.log('car', car)
        // let tech_info_id = car?.technical_information
        // let tech_car_info: ITechInfoData | undefined =
        //     techInfoData[tech_info_id]
        // let carModel: string | null = car?.overview.data.model.answer
        // let carMake: string | null = car?.overview.data.make.answer
        // let carYear: string | number | null = car?.overview.data['year'].answer
        // let carEngineSize: string | number | null =
        //     car?.overview.data['engine_capacity'].answer
        // let transmission: string | number | null | undefined =
        //     tech_car_info && tech_car_info?.transmission.answer
        // let carBodyType: string | number | null =
        //     tech_car_info && tech_car_info?.body_type.answer
        // let fuelType: string | number | null =
        //     tech_car_info && tech_car_info?.fuel_type.answer
        // let driveTrain: string | number | null =
        //     tech_car_info && tech_car_info?.drive_train.answer
        // if (car) {
        // dispatch(
        //     insuranceActions.set_multiple_answers_insurance_car_techInfo({
        //         carid: carid,
        //         data: {
        //             make: carMake
        //                 ? { name: carMake, uid: carMake }
        //                 : undefined,
        //             model: carModel
        //                 ? { name: carModel, uid: carModel }
        //                 : undefined,
        //             year: carYear,
        //             body_type: carBodyType
        //                 ? { name: carBodyType, uid: carBodyType }
        //                 : undefined,
        //             engine_size: carEngineSize
        //                 ? {
        //                       name: carEngineSize,
        //                       uid: carEngineSize,
        //                   }
        //                 : undefined,
        //             fuel_type: fuelType
        //                 ? { name: fuelType, uid: fuelType }
        //                 : undefined,
        //             transmission: transmission
        //                 ? { name: transmission, uid: transmission }
        //                 : undefined,
        //             drive_train: driveTrain
        //                 ? { name: driveTrain, uid: driveTrain }
        //                 : undefined,
        //         },
        //     })
        // )
        // }
    }

    const getFirstIncompleteSubsection_Single_EverydayVehicle = (
        carID: string,
        from_subsection?: string
    ): IWizardSectionsID | null => {
        let res: IWizardSectionsID | null = null
        let evcar = applicationDataDraft.vehicles[carID]

        let sectionID: IInsuranceApplicationSectionId = 'everyday_vehicles'
        if (
            wizard_entities_to_skip.indexOf(`${evcar.id}`) !== -1 ||
            wizard_entities_to_skip.indexOf(`${evcar.api_uid}`) !== -1
        ) {
            res = null
        } else {
            if (evcar && evcar.completion !== 100) {
                if (
                    evcar.tech_info_stats !== 100 && // if not the same or coming from after
                    // if not the same or coming from after
                    from_subsection !== 'tech_info' &&
                    from_subsection !== 'mileage_parking' &&
                    from_subsection !== 'ownership'
                ) {
                    return {
                        sectionID,
                        sub_sectionID: 'tech_info',
                        car_id: evcar.id,
                    }
                }

                if (
                    evcar.mileage_parking_stats !== 100 &&
                    // if not the same or coming from after
                    from_subsection !== 'mileage_parking' &&
                    from_subsection !== 'ownership'
                ) {
                    return {
                        sectionID,
                        sub_sectionID: 'mileage_parking',
                        car_id: evcar.id,
                    }
                }

                if (
                    evcar.ownership_stats !== 100 && // if not the same or coming from after
                    from_subsection !== 'ownership'
                ) {
                    return {
                        sectionID,
                        sub_sectionID: 'ownership',
                        car_id: evcar.id,
                    }
                }
            }
        }
        return res
    }

    const getFirstIncompleteSubsection_Single_InsuredVehicle = (
        carID: string,
        from_subsection?: string
    ): IWizardSectionsID | null => {
        let res: IWizardSectionsID | null = null
        let car = applicationDataDraft.vehicles[carID]

        let sectionID: IInsuranceApplicationSectionId = 'vehicles_to_insure'
        if (wizard_entities_to_skip.indexOf(`${car.id}`) !== -1) {
            res = null
        } else {
            if (car && car.completion !== 100) {
                if (
                    (car.is_everyday === false ||
                        car.is_everyday === undefined) &&
                    // if not the same or coming from after
                    from_subsection !== 'is_everyday' &&
                    from_subsection !== 'tech_info' &&
                    from_subsection !== 'mileage_parking' &&
                    from_subsection !== 'ownership'
                ) {
                    return {
                        sectionID,
                        sub_sectionID: 'is_everyday',
                        car_id: car.id,
                    }
                }

                if (
                    car.tech_info_stats !== 100 &&
                    // if not the same or coming from after
                    from_subsection !== 'tech_info' &&
                    from_subsection !== 'mileage_parking' &&
                    from_subsection !== 'ownership'
                ) {
                    return {
                        sectionID,
                        sub_sectionID: 'tech_info',
                        car_id: car.id,
                    }
                }

                if (
                    car.mileage_parking_stats !== 100 &&
                    // if not the same or coming from after
                    from_subsection !== 'mileage_parking' &&
                    from_subsection !== 'ownership'
                ) {
                    return {
                        sectionID,
                        sub_sectionID: 'mileage_parking',
                        car_id: car.id,
                    }
                }

                if (
                    car.ownership_stats !== 100 &&
                    from_subsection !== 'ownership'
                ) {
                    return {
                        sectionID,
                        sub_sectionID: 'ownership',
                        car_id: car.id,
                    }
                }
            }
        }
        return res
    }

    const getFirstIncompleteSubsection_InsuredVehicle = (
        from_subsection?: string
    ): IWizardSectionsID | null => {
        // here checks stats to determine which should go
        let sectionID: 'vehicles_to_insure' = 'vehicles_to_insure'

        if (applicationDataDraft.vehicles_to_insure_ids_list.length === 0) {
            return {
                sectionID,
                sub_sectionID: null,
            }
        } else {
            let res: IWizardSectionsID | null = null
            for (
                let i = 0;
                i < applicationDataDraft.vehicles_to_insure_ids_list.length;
                i++
            ) {
                let carID = applicationDataDraft.vehicles_to_insure_ids_list[i]

                if (wizard_entities_to_skip.indexOf(carID) === -1) {
                    let ress =
                        getFirstIncompleteSubsection_Single_InsuredVehicle(
                            carID
                        )
                    if (ress) {
                        res = ress
                    }
                }
            }
            return res
        }
    }

    const getFirstIncompleteSubsection_EverydayVehicle = (
        from_subsection?: string
    ): IWizardSectionsID | null => {
        // here checks stats to determine which should go
        let sectionID: 'everyday_vehicles' = 'everyday_vehicles'

        if (
            !applicationDataDraft.everyday_car_ids ||
            applicationDataDraft.everyday_car_ids?.length === 0
        ) {
            return {
                sectionID,
                sub_sectionID: null,
            }
        } else {
            let res: null | IWizardSectionsID = null

            for (
                let i = 0;
                i < applicationDataDraft.everyday_car_ids.length;
                i++
            ) {
                let evcarID = applicationDataDraft.everyday_car_ids[i]

                let ress = getFirstIncompleteSubsection_Single_EverydayVehicle(
                    evcarID,
                    from_subsection
                )

                if (ress) {
                    res = ress
                }
            }

            return res
        }
    }

    const getFirstIncompleteSubsection_MainDriver = (
        from_subsection?: string
    ): IWizardSectionsID | null => {
        let res: IWizardSectionsID | null = null
        if (
            wizard_entities_to_skip.indexOf(
                `${applicationData.main_driver.id}`
            ) !== -1
        ) {
            res = null
        } else {
            let sectionID: 'main_policy_holder' = 'main_policy_holder'

            if (
                applicationData.main_driver.basic_details_stats !== 100 &&
                // if not the same or coming from after
                from_subsection !== 'basic_details' &&
                from_subsection !== 'licence_address' &&
                from_subsection !== 'additional_details' &&
                from_subsection !== 'track_record'
            ) {
                return {
                    sectionID: sectionID,
                    sub_sectionID: 'basic_details',
                }
            }

            if (
                applicationData.main_driver.licence_address_stats !== 100 &&
                // if not the same or coming from after
                from_subsection !== 'licence_address' &&
                from_subsection !== 'additional_details' &&
                from_subsection !== 'track_record'
            ) {
                return {
                    sectionID,
                    sub_sectionID: 'licence_address',
                }
            }

            if (
                applicationData.main_driver.additional_details_stats !== 100 &&
                // if not the same or coming from after
                from_subsection !== 'additional_details' &&
                from_subsection !== 'track_record'
            ) {
                return {
                    sectionID,
                    sub_sectionID: 'additional_details',
                }
            }

            if (
                applicationData.main_driver.track_record_stats !== 100 &&
                // if not the same or coming from after
                from_subsection !== 'track_record'
            ) {
                return {
                    sectionID,
                    sub_sectionID: 'track_record',
                }
            }
        }
        return res
    }

    const getFirstIncompleteSubsection_OtherDrivers = (
        from_subsection?: string
    ): IWizardSectionsID | null => {
        let sectionID: 'drivers' = 'drivers'

        if (
            !applicationData.selected_named_drivers ||
            applicationData.selected_named_drivers?.length === 0
        ) {
            return {
                sectionID,
                sub_sectionID: null,
            }
        }

        let res: IWizardSectionsID | null = null

        for (
            let i = 0;
            i < applicationData.selected_named_drivers.length;
            i++
        ) {
            let driverID = applicationData.selected_named_drivers[i]

            let idss = getFirstIncompleteSubsection_OtherDriver(
                driverID,
                from_subsection
            )
            if (idss) {
                res = idss
            }
        }

        return res
    }
    const getFirstIncompleteSubsection_OtherDriver = (
        driverID: string,
        from_subsection?: string
    ): IWizardSectionsID | null => {
        let nDriver = applicationData.other_drivers[driverID]
        let sectionID: 'drivers' = 'drivers'

        let res = null

        if (wizard_entities_to_skip.indexOf(driverID) !== -1) {
            res = null
        } else {
            if (
                nDriver.basic_details_stats !== 100 &&
                // if not the same or coming from after
                from_subsection !== 'basic_details' &&
                from_subsection !== 'licence_address' &&
                from_subsection !== 'additional_details' &&
                from_subsection !== 'track_record' &&
                from_subsection !== 'drive_list'
            ) {
                return {
                    sectionID: sectionID,
                    sub_sectionID: 'basic_details',
                    driver_id: nDriver.id,
                }
            }

            if (
                nDriver.licence_address_stats !== 100 &&
                // if not the same or coming from after
                from_subsection !== 'licence_address' &&
                from_subsection !== 'additional_details' &&
                from_subsection !== 'track_record' &&
                from_subsection !== 'drive_list'
            ) {
                return {
                    sectionID,
                    sub_sectionID: 'licence_address',
                    driver_id: nDriver.id,
                }
            }

            if (
                nDriver.additional_details_stats !== 100 &&
                // if not the same or coming from after
                from_subsection !== 'additional_details' &&
                from_subsection !== 'track_record' &&
                from_subsection !== 'drive_list'
            ) {
                return {
                    sectionID,
                    sub_sectionID: 'additional_details',
                    driver_id: nDriver.id,
                }
            }

            if (
                nDriver.track_record_stats !== 100 &&
                // if not the same or coming from after
                from_subsection !== 'track_record' &&
                from_subsection !== 'drive_list'
            ) {
                return {
                    sectionID,
                    sub_sectionID: 'track_record',
                    driver_id: nDriver.id,
                }
            }

            if (
                // nDriver.completion !== 100 &&
                from_subsection !== 'drive_list'
            ) {
                return {
                    sectionID,
                    sub_sectionID: 'drive_list',
                    driver_id: nDriver.id,
                }
            }
        }

        return res
        // if not 100 and none of those, drive list.
    }
    const getInitialSectionWizardIds = (
        from_section?: IInsuranceApplicationSectionId,
        from_subsection?: string
    ): IWizardSectionsID | null => {
        let sectionID:
            | 'insurance_details'
            | 'drivers'
            | 'vehicles_to_insure'
            | 'everyday_vehicle'
            | 'main_policy_holder'
            | null = null
        let sub_sectionID = null

        if (applicationData) {
            if (applicationData.overall_completion === 100) {
                return {
                    sectionID: 'submit',
                    sub_sectionID: null,
                }
            }
            // conditions check for if thi section already have been passed based of optional from_x
            if (
                applicationData.insurance_details_stats !== 100 &&
                from_section !== 'vehicles_to_insure' &&
                from_section !== 'drivers' &&
                from_section !== 'everyday_vehicles' &&
                from_section !== 'main_policy_holder' &&
                from_section !== 'insurance_details' &&
                wizard_entities_to_skip.indexOf('insurance_details') === -1
            ) {
                return {
                    sectionID: 'insurance_details',
                    sub_sectionID: null,
                }
            }
            if (
                applicationData.vehicles_overall_stats !== 100 &&
                from_section !== 'drivers' &&
                from_section !== 'everyday_vehicles' &&
                from_section !== 'main_policy_holder' &&
                wizard_entities_to_skip.indexOf('insured_vehicles') === -1
            ) {
                // to dashboard for making ti easier to follow
                if (from_section && from_section !== 'vehicles_to_insure') {
                    return {
                        sectionID: 'vehicles_to_insure',
                        sub_sectionID: null,
                    }
                } else {
                    let idsc =
                        getFirstIncompleteSubsection_InsuredVehicle(
                            from_subsection
                        )
                    if (idsc) return idsc
                }
            }

            if (
                applicationData.everyday_car_stats !== 100 &&
                // checking not coming from next sections
                from_section !== 'drivers' &&
                from_section !== 'main_policy_holder' &&
                wizard_entities_to_skip.indexOf('everyday_vehicles') === -1
            ) {
                // to dashboard for making ti easier to follow
                if (from_section && from_section !== 'everyday_vehicles') {
                    return {
                        sectionID: 'everyday_vehicles',
                        sub_sectionID: null,
                    }
                } else {
                    let idsx = getFirstIncompleteSubsection_EverydayVehicle()
                    if (idsx) return idsx
                }
            }

            if (
                applicationData.main_driver_overall_stats !== 100 &&
                from_section !== 'drivers' &&
                wizard_entities_to_skip.indexOf('main_policy_holder') === -1
            ) {
                let idss =
                    getFirstIncompleteSubsection_MainDriver(from_subsection)
                if (idss) return idss
            }

            if (
                applicationData.other_drivers_overall_stats !== 100 &&
                wizard_entities_to_skip.indexOf('drivers') === -1
            ) {
                // to dashboard for making ti easier to follow
                if (from_section && from_section !== 'drivers') {
                    return {
                        sectionID: 'drivers',
                        sub_sectionID: null,
                    }
                } else {
                    let idssd =
                        getFirstIncompleteSubsection_OtherDrivers(
                            from_subsection
                        )
                    if (idssd) return idssd
                }
            }

            if (
                wizard_entities_to_skip.indexOf('drivers') === -1 &&
                (!applicationData.selected_named_drivers ||
                    applicationData.selected_named_drivers?.length === 0)
            ) {
                return {
                    sectionID: 'drivers',
                    sub_sectionID: null,
                }
            }
            if (sectionID) {
                return {
                    sectionID: sectionID,
                    sub_sectionID: sub_sectionID,
                }
            } else {
                reset_set_wizard_entity_id_to_skip()
                dispatch(
                    insuranceActions.validate_insurance_application_request({
                        application: { ...applicationData },
                        activateValidationSheet: true,
                        isWizard: true,
                    })
                )

                return null
            }
        } else {
            // resirect to overview
            return null
        }
    }

    const wrapped: ContextType = {
        setInitialInsurance_CarTechInfo,
        setInitialCarOwnershipDropdowns,
        setInitialCarTechnicalDropdowns,
        setInitialCarMileageAndParkingDropdowns,
        setInitialInsurance_Drivers_AdditionalDetails_dropdowns,
        setInitialInsurance_Drivers_TrackRecord_dropdowns,
        setInitialInsurance_Drivers_License_dropdowns,
        getUserAndApplicationData,
        setInitialInsurance_Drivers_BasicDetails_dropdowns,
        getInitialSectionWizardIds,
        getFirstIncompleteSubsection_InsuredVehicle,
        getFirstIncompleteSubsection_EverydayVehicle,
        getFirstIncompleteSubsection_OtherDrivers,
        getFirstIncompleteSubsection_OtherDriver,
        set_wizard_entity_id_to_skip,
        reset_set_wizard_entity_id_to_skip,
        unset_wizard_entity_id_to_skip,
        getFirstIncompleteSubsection_Single_EverydayVehicle,
        getFirstIncompleteSubsection_Single_InsuredVehicle,
        isInsuranceWindowOpened,
        setIsInsuranceWindowOpened,
        crossFormData,
        setCrossFormData,
        isCrossFormOpen,
        setIsCrossFormOpen,
        trackCrossFormSubmission,
        setTrackCrossFormSubmission,
    }

    return (
        <InsuranceInitialCtxt.Provider value={wrapped}>
            {children}
        </InsuranceInitialCtxt.Provider>
    )
}
