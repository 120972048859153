import { FunctionComponent, useEffect, useRef, useState } from 'react'
import Cropper, { ReactCropperElement } from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import styled from 'styled-components'
import ButtonAtom from '../../atoms/Button/ButtonAtom'
import Ellipsis from '../../atoms/loader/ellipsis'
import { motion } from 'framer-motion'
import { device } from '../../templates/displays/devices'
import { message } from 'antd'
import heic2any from 'heic2any'
import RotateCircle from '../../atoms/icons/components/rotateCircle'

type Props = {
    currentImagesUploaded?: any
    close: any
    handleFile: (file: File | undefined) => void
    aspect?: number
}

const FormBtnsBottomRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: absolute;
    bottom: 8vh;
    opacity: 0.95;
`

type StyleProps = {
    hasLoaded?: boolean
}

const CropperWrapper = styled.div<StyleProps>`
    visibility: ${(props) => (props.hasLoaded ? 'visible' : 'hidden')};
    position: ${(props) => (props.hasLoaded ? 'static' : 'absolute')};
    transform: translateY(-10px);
`

const CropperCustom = styled(Cropper)`
    .cropper-modal {
        background-color: var(
            --background-overlay-light,
            rgba(255, 255, 255, 0.96)
        ) !important;
    }
`

const AbsoluteDivLoader = styled.div`
    display: flex;
    position: relative;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: var(
        --background-overlay-light,
        rgba(255, 255, 255, 0.96)
    );
    height: 100vh;
    width: 100vw;
    padding-bottom: 10vh;
`
const Rotate = styled.div`
    position: absolute;
    bottom: 21vh;
    right: 20px;

    @media ${device.mobile} {
        bottom: 22vh;
    }
`

const ImageCropperMobile2: FunctionComponent<Props> = (props: Props) => {
    const [hasSaveBeenClicked, setHasSaveBeenClicked] = useState(false)
    const [hasLoaded, setHasLoaded] = useState(false)

    setTimeout(function () {
        setHasLoaded(true)
    }, 3000)

    const cropperRef: React.Ref<ReactCropperElement> | undefined =
        useRef<ReactCropperElement>(null)

    type IBlobFetchRes = File | undefined

    const convertToBlob = async (
        url: string,
        fileTitle: string
    ): Promise<IBlobFetchRes> => {
        let res: IBlobFetchRes = await fetch(url)
            .then((res) => res.blob()) // Gets the response and returns it as a blob
            .then((blob) => {
                // Here's where you get access to the blob
                // And you can use it for whatever you want
                // Like calling ref().put(blob)

                let newfile: File = new File([blob], fileTitle, {
                    type: 'image/jpeg',
                })

                return newfile

                // Here, I use it to make an image appear on the page
            })
            .catch((e) => {
                return undefined
            })

        return res
    }
    const onCrop = async () => {}

    const onSubmit = async () => {
        const imageElement = cropperRef?.current
        const cropper = imageElement?.cropper

        let croppedCanvas = cropper && cropper.getCroppedCanvas()

        let d: string | undefined = croppedCanvas && croppedCanvas.toDataURL()
        try {
            let finalFile = d
                ? await convertToBlob(d, props.currentImagesUploaded.name)
                : undefined

            props.handleFile(finalFile)

            props.close()
        } catch (e) {
            // console.error(e)
        }

        // props.handleFile(finalFile)
    }

    const rotateImg = () => {
        const imageElement: any = cropperRef?.current
        const cropper: any = imageElement?.cropper

        cropper.rotate(90)
    }

    const [url, setUrl] = useState('')

    useEffect(() => {
        const getCustomFormatImgUrl = async () => {
            try {
                // Convert HEIC/HEIF to JPEG/PNG
                const conversionResult = await heic2any({
                    blob: props.currentImagesUploaded,
                    toType: 'image/jpeg',
                })

                if (conversionResult instanceof Blob) {
                    // Create a new filename with the .jpg extension
                    const newFileName =
                        props.currentImagesUploaded.name.replace(
                            /\.[^/.]+$/,
                            ''
                        ) + '.jpg'
                    // Create a new File object
                    const newFile = new File([conversionResult], newFileName, {
                        type: 'image/jpeg',
                    })

                    // Create a URL for the converted image
                    const url = URL.createObjectURL(newFile)
                    setUrl(url)
                } else {
                    message.error(
                        'An error occured, please try uploading your image again.'
                    )
                }
            } catch (e) {
                message.error(
                    'An error occured, please try uploading your image again.'
                )
            }
        }

        if (props.currentImagesUploaded) {
            if (
                props.currentImagesUploaded.type === 'image/heic' ||
                props.currentImagesUploaded.type === 'image/heif'
            ) {
                getCustomFormatImgUrl()
            } else {
                let url = URL.createObjectURL(props.currentImagesUploaded)
                url && setUrl(url)
            }
        }

        return () => {}
    }, [props.currentImagesUploaded])

    return (
        <AbsoluteDivLoader>
            {(hasSaveBeenClicked === true || hasLoaded !== true) && (
                <Ellipsis size={10} />
            )}

            <CropperWrapper
                hasLoaded={hasLoaded && !hasSaveBeenClicked ? true : false}
            >
                <CropperCustom
                    src={url}
                    style={{ height: 400, width: '100%' }}
                    // Cropper.js options
                    aspectRatio={props.aspect ? props.aspect : 3.2}
                    guides={false}
                    crop={onCrop}
                    ref={cropperRef}
                    viewMode={1}
                    autoCropArea={1}
                    checkOrientation={true}
                    cropBoxResizable={false}
                    dragMode={'none'}
                />
            </CropperWrapper>
            <ButtonAtom theme="naked" onClick={rotateImg}>
                <Rotate>
                    <RotateCircle />
                </Rotate>
            </ButtonAtom>
            <FormBtnsBottomRow>
                <div style={{ paddingLeft: 24 }} />
                <motion.div whileTap={{ scale: 1.05 }}>
                    <ButtonAtom
                        theme="uppercase-white-background"
                        onClick={(e: any) => {
                            props.close()
                        }}
                        width="46dvw"
                        height="48px"
                    >
                        <div style={{ textTransform: 'none' }}>Go back</div>
                    </ButtonAtom>
                </motion.div>

                <div style={{ paddingLeft: 16 }} />
                <motion.div whileTap={{ scale: 1.05 }}>
                    <ButtonAtom
                        theme="uppercase-blue-background"
                        onClick={(e: any) => {
                            setHasSaveBeenClicked(true)
                            onSubmit()
                        }}
                        disabled={
                            hasSaveBeenClicked === true || hasLoaded !== true
                                ? true
                                : false
                        }
                        width="46dvw"
                        height="48px"
                    >
                        {hasSaveBeenClicked === true || hasLoaded !== true ? (
                            <div
                                style={{
                                    textTransform: 'none',
                                    color: 'var(--text-muted, #b3b3b3)',
                                }}
                            >
                                Please Wait...
                            </div>
                        ) : (
                            <div
                                style={{
                                    textTransform: 'none',
                                    color: 'var(--bg-color, #fff)',
                                }}
                            >
                                Save image
                            </div>
                        )}
                    </ButtonAtom>
                </motion.div>
                <div style={{ paddingLeft: 24 }} />
            </FormBtnsBottomRow>
        </AbsoluteDivLoader>
    )
}

export default ImageCropperMobile2
