import { ISocialAuthData, ISocialAuthRes, IUserPayload } from 'IapiDataPayload'
import { call, fork, put, select, take } from 'redux-saga/effects'
import * as userActions from '../actions/index'
import { IDropdownItem, IReduxError } from 'entityModels'
import { push } from 'connected-react-router'
import posthog from 'posthog-js'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import { customNavDataActions } from '../../localdata/customNav/reducer'
import { registerError, signInUserError } from '../actions/errorActions'
import { time_zones_dropdowns_state } from './getUserData'
import { get_check_cookie_name } from '../../../helpers/cookies'
import { GetUserIPAndCallFbTracking } from '../../../helpers/ipAddress'
import {
    sortOutFbTracking,
    state_select_initial_landing_url,
} from './authSagas'
import { socialAuthenticationAPI } from '../../services/authentication/authentication'
import {
    getCurrentUser,
    updateUserProfileInformationApi,
} from '../../services/user_data/user-data-api-client'
import { ConvertUserApitoUserState } from '../../conversions/user/userConversions'
import { IUser, IUSerGeneralInfoUpdatePayload } from 'myModels'

export type IAuthSocialRequestPayload = {
    authorization_code?: string
    id_token?: string
    provider: 'apple' | 'google'
    platformConsent: boolean
    apexConsent: boolean
    fbclid: string | null
    invite_code?: string | undefined
    next_path?: string | null
    is_registration: boolean
    from_landing: boolean
    given_name?: string
    family_name?: string
}

function* registerSocialAuth(payload: IAuthSocialRequestPayload): any {
    try {
        // 1. TRIM INVITE CODE
        // if (p.invite_code !== undefined) {
        //     p.invite_code = trimInviteCode(p.invite_code)
        // }

        // 2 GET RID OF UNDEFINED (CLEAN DATA)

        let registerData: ISocialAuthData = {
            authorization_code: payload.authorization_code,
            id_token: payload.id_token,
            provider: payload.provider,
            consent: {
                platform: true,
                apex: true,
            },
        }

        if (payload.given_name) {
            registerData = {
                ...registerData,
                given_name: payload.given_name,
            }
        }

        if (payload.family_name) {
            registerData = {
                ...registerData,
                family_name: payload.family_name,
            }
        }
        // invite_code: p.invite_code,
        const fbclidCookie: string | undefined = get_check_cookie_name('fbclid')
        if (payload.fbclid !== null || fbclidCookie !== undefined) {
            registerData.tracking_codes = [
                {
                    id: 'fbclid',
                    value: payload.fbclid
                        ? payload.fbclid
                        : fbclidCookie
                        ? fbclidCookie
                        : undefined,
                },
            ]
        }

        const userData: ISocialAuthRes = yield call(
            socialAuthenticationAPI,
            registerData
        )

        // START

        const sortOutFB = (ip?: string | undefined) =>
            // @ts-ignore
            sortOutFbTracking(userData, payload, fbclidCookie, ip)
        GetUserIPAndCallFbTracking(sortOutFB)
        // END IS HERE

        if (userData.refresh_token) {
            window.localStorage.setItem('refresh_token', userData.refresh_token)
        }

        // Capture event
        posthog.capture('USER REGISTRATION')

        const userData2: IUserPayload = yield call(getCurrentUser)

        // Set email or any other data
        if (posthog.people !== undefined) {
            posthog.people.set_once({
                email: userData2?.email?.toLowerCase(),
                given_name:
                    userData2 && userData2?.given_name && userData2?.given_name,
                family_name:
                    userData2 &&
                    userData2?.family_name &&
                    userData2?.family_name,
            })
        }

        let time_zone_dropdown: IDropdownItem[] | null = yield select(
            time_zones_dropdowns_state
        )

        let data: IUser = ConvertUserApitoUserState(
            userData2,
            time_zone_dropdown
        )

        if (data.owns_garage?.uid) {
            window?.localStorage?.setItem('garage_id', data.owns_garage?.uid)
        }

        yield put(userActions.default.actions.getCurrentUserDataSuccess(data))

        if (userData2?.uid) {
            yield put(userActions.default.actions.registerSuccess())

            let initial_landing_url: string | null = yield select(
                state_select_initial_landing_url
            )

            if (initial_landing_url) {
                const url = initial_landing_url
                yield put(customNavDataActions.setInitialLandingUrl(null))
                yield put(push(url))
            } else {
                if (
                    !payload.next_path &&
                    userData2?.onboarding_viewed === true
                ) {
                    yield put(push('/garage'))
                } else
                    yield put(
                        push(
                            payload.next_path
                                ? payload.next_path
                                : '/onboarding-step-0'
                        )
                    )

                let payl: IUSerGeneralInfoUpdatePayload = {
                    id: userData2?.uid,
                    data: {
                        onboarding_viewed: true,
                    },
                }

                const userDataAfterUpdate: IUserPayload = yield call(
                    updateUserProfileInformationApi,
                    payl
                )

                let data: IUser = ConvertUserApitoUserState(
                    userDataAfterUpdate,
                    time_zone_dropdown
                )

                yield put(
                    userActions.default.actions.getCurrentUserDataSuccess(data)
                )
            }
        }
    } catch (error: any) {
        let typedError: IReduxError = ConvertToReduxError(error)

        yield put(registerError(typedError))

        if (payload.from_landing) {
            yield put(push('/register/landing/1'))
        } else yield put(push('/register'))
    }
}

function* loginSocialAuth(p: IAuthSocialRequestPayload): any {
    try {
        let loginData: ISocialAuthData = {
            authorization_code: p.authorization_code,
            id_token: p.id_token,
            provider: p.provider,
            consent: {
                platform: true,
                apex: true,
            },
        }

        if (p.given_name) {
            loginData = {
                ...loginData,
                given_name: p.given_name,
            }
        }

        if (p.family_name) {
            loginData = {
                ...loginData,
                family_name: p.family_name,
            }
        }

        const fbclidCookie: string | undefined = get_check_cookie_name('fbclid')
        if (p.fbclid !== null || fbclidCookie !== undefined) {
            loginData.tracking_codes = [
                {
                    id: 'fbclid',
                    value: p.fbclid
                        ? p.fbclid
                        : fbclidCookie
                        ? fbclidCookie
                        : undefined,
                },
            ]
        }

        const socialAuthRes: ISocialAuthRes = yield call(
            socialAuthenticationAPI,
            loginData
        )

        // set data in localStorage

        if (socialAuthRes?.refresh_token) {
            window.localStorage.setItem(
                'refresh_token',
                socialAuthRes?.refresh_token
            )
        }

        // Capture event
        posthog.capture('USER LOGIN')

        let initial_landing_url: string | null = yield select(
            state_select_initial_landing_url
        )

        const userData2: IUserPayload = yield call(getCurrentUser)

        // Set email or any other data
        if (posthog.people !== undefined) {
            posthog.people.set_once({
                email: userData2?.email?.toLowerCase(),
                given_name:
                    userData2 && userData2?.given_name && userData2?.given_name,
                family_name:
                    userData2 &&
                    userData2?.family_name &&
                    userData2?.family_name,
            })
        }

        let time_zone_dropdown: IDropdownItem[] | null = yield select(
            time_zones_dropdowns_state
        )

        let data: IUser = ConvertUserApitoUserState(
            userData2,
            time_zone_dropdown
        )

        if (data.owns_garage?.uid) {
            window?.localStorage?.setItem('garage_id', data.owns_garage?.uid)
        }

        yield put(userActions.default.actions.getCurrentUserDataSuccess(data))

        if (userData2?.uid) {
            yield put(userActions.default.actions.signInUserSuccess())

            if (initial_landing_url) {
                const url = initial_landing_url
                yield put(customNavDataActions.setInitialLandingUrl(null))
                yield put(push(url))
            } else {
                if (userData2.onboarding_viewed !== true) {
                    posthog.capture('USER REGISTRATION from login page')
                    yield put(push('/onboarding-step-0'))

                    let payl: IUSerGeneralInfoUpdatePayload = {
                        id: userData2?.uid,
                        data: {
                            onboarding_viewed: true,
                        },
                    }

                    const userDataAfterUpdate: IUserPayload = yield call(
                        updateUserProfileInformationApi,
                        payl
                    )

                    let data: IUser = ConvertUserApitoUserState(
                        userDataAfterUpdate,
                        time_zone_dropdown
                    )

                    yield put(
                        userActions.default.actions.getCurrentUserDataSuccess(
                            data
                        )
                    )
                } else yield put(push('/garage'))
            }
        }
    } catch (error: any) {
        let typedError: IReduxError = ConvertToReduxError(error)

        yield put(signInUserError(typedError))
        yield put(push('/login'))
    }
}

function* socialAuthSaga(payload: IAuthSocialRequestPayload): any {
    if (payload.is_registration) {
        yield call(registerSocialAuth, payload)
    } else {
        yield call(loginSocialAuth, payload)
    }
}

function* watcherAuthSocial() {
    while (true) {
        const { payload } = yield take(
            userActions.default.loadingActions.socialAuthRequest
        )

        yield call(socialAuthSaga, payload)
    }
}

const social_auth_sagas: any[] = [fork(watcherAuthSocial)]

export default social_auth_sagas
