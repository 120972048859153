import styled from 'styled-components'
import { withFormik, FormikProps } from 'formik'
// import * as Yup from 'yup'
import { StandardInputField } from '../../../atoms/Inputfield/inputField'
import { device } from '../../../templates/displays/devices'
import Faded from '../../../templates/animated/faded'
import Expander from '../../../atoms/expander/expander'
import { IDropdownItem } from 'entityModels'
// import { yupVar_NumberBasicSingleField } from '../yupVars'
import { Select } from '../../../atoms/select'
import PickerBtnInsurance from '../../../atoms/Button/pickerBtnInsurance'
import useThemes from '../../../../providers/theme/hooks'
import colours from '../../../../providers/theme/colours'

const Wrapper = styled.section`
    width: 100%;
`

const Error = styled.div`
    font-family: 'Lato-light';
    color: red;
    font-style: italic;
    font-size: 12px;
    @media ${device.ipad} {
        font-size: 10px;
    }
`

const Row = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* max-width: 500px; */
`

const Col = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
`

const AdjustPosition = styled.div`
    margin-right: 20px;
`
interface Values {
    input_value_number?: number
    input_value_string?: string
    dropdown_value?: string
}

interface OtherProps {
    input_id: string
    dropdown_id?: string
    input_value_number?: number
    input_value_string?: string
    input_placeholder: string
    dropdown_value: string | undefined
    dropdown_placeholder?: string
    onChange: (id: string, value: string | number) => void
    dataCyId?: string
    error?: string
    options: string | boolean | IDropdownItem[] | null | undefined
    isDisabled?: boolean
    dropdownFullScreen?: boolean
    onPickerBtnClick?: () => void
    customActiveColour?: string
    customOptionHighlightColour?: string
    rightAdornment?: React.ReactNode
    bg?: string
}
const InnerForm = (props: OtherProps & FormikProps<Values>) => {
    const {
        input_id,
        input_value_number,
        input_placeholder,
        dropdown_value,
        dropdown_placeholder,
        values,
        isDisabled,
        onChange,
        setFieldValue,
        dropdown_id,
        error,
        dropdownFullScreen,
        onPickerBtnClick,
        customActiveColour,
        customOptionHighlightColour,
        rightAdornment,
        bg,
    } = props

    const handleDropdownChange = (value: string) => {
        setFieldValue('dropdown_value', value)
        if (dropdown_id) {
            onChange(dropdown_id, value)
        }
    }

    const handleInputChange = (
        input: 'input_value_number' | 'input_value_string',
        value: string | number
    ) => {
        setFieldValue(input, value)
        if (input_id) {
            onChange(input_id, value)
        }
    }

    const { theme } = useThemes()

    return (
        <Wrapper>
            <Row style={dropdown_id && dropdownFullScreen ? { gap: 8 } : {}}>
                {dropdown_id && dropdownFullScreen ? (
                    <PickerBtnInsurance
                        fwArrow
                        height="50px"
                        bg={bg ? bg : colours[theme].background_neutral_subtle}
                        placeholder={dropdown_placeholder}
                        onClick={onPickerBtnClick}
                        value={dropdown_value ? `${dropdown_value}` : undefined}
                        width="70%"
                    />
                ) : (
                    dropdown_id && (
                        <AdjustPosition>
                            <Select
                                placeholder={dropdown_placeholder}
                                defaultValue={dropdown_value}
                                onChange={handleDropdownChange}
                                options={
                                    (props.options &&
                                        //@ts-ignore
                                        props.options.map((option) => {
                                            return {
                                                value: option?.name,
                                                label: option?.name,
                                            }
                                        })) ??
                                    []
                                }
                                backgroundColor={
                                    bg
                                        ? bg
                                        : colours[theme]
                                              .background_neutral_subtle
                                }
                                customActiveColour={customActiveColour}
                                customOptionHighlightColour={
                                    customOptionHighlightColour
                                }
                            />
                        </AdjustPosition>
                    )
                )}
                <Col>
                    {input_value_number !== undefined ? (
                        <StandardInputField
                            theme={theme}
                            id="input_value_number"
                            name="input_value_number"
                            placeholder={input_placeholder}
                            labelNotRaised
                            tabindex={1}
                            aria-live="polite"
                            formikprops={props}
                            value={
                                // @ts-ignore
                                values.input_value_number !== '-' &&
                                values.input_value_number !== undefined
                                    ? values.input_value_number
                                    : undefined
                            }
                            type="number"
                            font_family="Lato"
                            paddingLeft="0px"
                            width="100%"
                            fontSize="16px"
                            onChange={(e: any) => {
                                if (e.target.value === '') {
                                    handleInputChange('input_value_number', '')
                                } else
                                    handleInputChange(
                                        'input_value_number',
                                        +e.target.value
                                    )
                            }}
                            dataCyId={props.dataCyId}
                            step="0.01"
                            disabled={isDisabled}
                            backgroundColor={
                                bg
                                    ? bg
                                    : colours[theme].background_neutral_subtle
                            }
                            customActiveColour={customActiveColour}
                            rightAdornment={rightAdornment}
                        />
                    ) : (
                        <StandardInputField
                            theme={theme}
                            id="input_value_string"
                            name="input_value_string"
                            placeholder={input_placeholder}
                            labelNotRaised
                            tabindex={1}
                            aria-live="polite"
                            formikprops={props}
                            value={values.input_value_string}
                            type="text"
                            font_family="Lato"
                            paddingLeft="0px"
                            width="100%"
                            fontSize="16px"
                            onChange={(e: any) =>
                                handleInputChange(
                                    'input_value_string',
                                    e.target.value
                                )
                            }
                            dataCyId={props.dataCyId}
                            step="0.01"
                            disabled={isDisabled}
                            backgroundColor={
                                bg
                                    ? bg
                                    : colours[theme].background_neutral_subtle
                            }
                            customActiveColour={customActiveColour}
                            rightAdornment={rightAdornment}
                        />
                    )}
                    <Expander height={error ? 'auto' : 0}>
                        <Faded>
                            <div style={{ paddingTop: '5px' }} />
                            <Error className="animated fadeIn slower">
                                {error}
                            </Error>
                        </Faded>
                    </Expander>
                </Col>
            </Row>
        </Wrapper>
    )
}

const DropdownAndTextOrNumber = withFormik<OtherProps, Values>({
    mapPropsToValues: (props) => ({
        input_value_number: props.input_value_number ?? undefined,
        input_value_string: props.input_value_string ?? undefined,
        dropdown_value: props.dropdown_value ?? undefined,
    }),
    enableReinitialize: true,
    // validationSchema: {},
    // validationSchema: () => {
    //     return Yup.lazy((values: Values) => {
    //         return Yup.object().shape({
    //             single_item_edited_value_number_field:
    //                 yupVar_NumberBasicSingleField(values.input_value_number),
    //         })
    //     })
    // },

    handleSubmit: (values, { props, setSubmitting }) => {},
})(InnerForm)

export default DropdownAndTextOrNumber
