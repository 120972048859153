import StandardCtaBtn from '../../atoms/Button/standardCtaBtn'
import ActiveThemeIcon from '../../atoms/icons/themeIcons'
import { useState } from 'react'
import { useClickAway } from '../../../helpers/hooks/domHooks'
import ActionsMenuDots, {
    IThreeDotsActionsItems,
} from '../../atoms/menu/actionsMenu/actionsMenuDots'
import colours from '../../../providers/theme/colours'
import useThemes from '../../../providers/theme/hooks'
import LightThemeIcon from '../../atoms/icons/themeIcons/light'
import DarkThemeIcon from '../../atoms/icons/themeIcons/dark'
import SystemThemeIcon from '../../atoms/icons/themeIcons/system'
import { useAppDispatch, useAppSelector } from '../../../redux/store/hooks'
import { IUSerGeneralInfoUpdatePayload } from 'myModels'
import { updateUserGeneralInfoRequest } from '../../../redux/user/actions/loadingActions'
import { getPreferredTheme } from '../../../providers/theme/customThemeProvider'
import './style.css'

export default function ThemeSwitcherDesktop({
    variant,
}: {
    variant?: 'insurance'
}) {
    const [isOpen, setIsOpen] = useState(false)

    const openList = () => {
        setIsOpen(true)
    }

    const closeList = () => {
        setIsOpen(false)
    }

    const dropdownRef: any = useClickAway(closeList)

    const { theme } = useThemes()

    const dispatch = useAppDispatch()

    const userLoggedIn = useAppSelector((state) => state.user.userLoggedIn)

    const userPreferredTheme = userLoggedIn?.ui_appearance_mode

    const updateTheme = (p: 'SYSTEM' | 'DARK' | 'LIGHT') => {
        if (userLoggedIn?.id) {
            let dataToSend: IUSerGeneralInfoUpdatePayload = {
                id: userLoggedIn?.id,
                data: {
                    user_preferences: { ui_appearance_mode: p },
                },
            }

            dispatch(updateUserGeneralInfoRequest(dataToSend))
        }

        let systemPreferredTheme = getPreferredTheme()
        localStorage.setItem(
            'ui_appearance_mode',
            p === 'SYSTEM'
                ? systemPreferredTheme
                : p === 'DARK'
                ? 'dark'
                : 'light'
        )
    }

    const actionItems: IThreeDotsActionsItems[] = [
        {
            id: 'LIGHT',
            text: 'Light',
            actionOnClick: () => {
                updateTheme('LIGHT')
                closeList()
            },
            icon: <LightThemeIcon size="16" />,
        },
        {
            id: 'DARK',
            text: 'Dark',
            actionOnClick: () => {
                updateTheme('DARK')
                closeList()
            },
            icon: <DarkThemeIcon size="16" />,
        },
        {
            id: 'SYSTEM',
            text: 'System',
            actionOnClick: () => {
                updateTheme('SYSTEM')
                closeList()
            },
            icon: <SystemThemeIcon size="16" />,
        },
    ]

    let activeItemIndex = actionItems.findIndex(
        (item) => item.id === userPreferredTheme
    )

    let themeSwitcherSeen = localStorage?.getItem('theme-switcher-seen')

    return (
        <div ref={dropdownRef}>
            <ActionsMenuDots
                direction="bottom"
                customButton={
                    <StandardCtaBtn
                        customWidth={variant === 'insurance' ? '48px' : '32px'}
                        customHeight={variant === 'insurance' ? '48px' : '32px'}
                        removePaddings
                        isSecondary
                        bg={colours[theme].background_neutral_subtle}
                        customHoverColor={
                            colours[theme].base_overlays_neutral_alpha_200
                        }
                        borderOnHover={
                            variant === 'insurance'
                                ? undefined
                                : `2px solid ${colours[theme].border_muted}`
                        }
                        boder={
                            variant === 'insurance'
                                ? undefined
                                : `1px solid ${colours[theme].border_muted}`
                        }
                        onClick={() => {
                            if (variant !== 'insurance' && !themeSwitcherSeen) {
                                localStorage?.setItem(
                                    'theme-switcher-seen',
                                    'seen'
                                )
                            }
                            openList()
                        }}
                        removeShadowOnHover
                        className={
                            themeSwitcherSeen || variant === 'insurance'
                                ? undefined
                                : 'animatedBorder'
                        }
                    >
                        <ActiveThemeIcon
                            size={variant === 'insurance' ? '24' : '16'}
                            color={
                                themeSwitcherSeen || variant === 'insurance'
                                    ? undefined
                                    : colours[theme].primary
                            }
                        />
                    </StandardCtaBtn>
                }
                active_item={actionItems[activeItemIndex]}
                menuOpened={isOpen ? 'theme' : null}
                menuId={'theme'}
                actions_items={actionItems}
            />
        </div>
    )
}
