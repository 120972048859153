import { call, fork, join, put, select, take } from 'redux-saga/effects'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../../user/sagas/unauthHandler'
import { ICustomErrorData, IReduxError } from 'entityModels'
import { ConvertToReduxError } from '../../../conversions/errors/convertToReduxError'
import {
    IAnyObject,
    IInsuranceQuoteApplication,
    IInsuranceQuoteApplication_OtherDriver,
    IInsuranceQuoteApplication_Reducer,
    insuranceActions,
} from './../../reducer'

import {
    gen_draft_differing_self_replicating_entities_multiple,
    gen_draft_differing_values_to_patch,
} from '../../../conversions/insuranceApplication/convertDraftToAPIPatchObject'
import { api } from '../../../services'
import { IDeleteInsuranceApplication_req } from '../../../services/insuranceApplication/deleteEntity'
import {
    entityDeletionSaga,
    insurance_application_draft_state_select,
    insurance_application_existing_state_select,
} from '../maindriver/maindriver_additionaldetails_submit'
import { IOtherDriverID } from './otherdriver_trackrecord_submit'
import {
    GetInsuranceApplication,
    ValidateAndAddStatsToApplication,
    insurance_application_state_select,
} from '../application/get'
import posthog from 'posthog-js'
import { message } from 'antd'

export function* Submit_otherdriver_additionaldetails(p: IOtherDriverID): any {
    const { driver_id } = p

    posthog.capture(
        'Update insurance application named driver additional details'
    )

    try {
        // global func to convert and check any data thats not the same ?
        // yield call(api. ENDPOINT)

        let application_has_deletions = false
        let application_has_patch = false

        let all_draft: IInsuranceQuoteApplication = yield select(
            insurance_application_draft_state_select
        )

        let all_existing: IInsuranceQuoteApplication = yield select(
            insurance_application_existing_state_select
        )

        let draft: IInsuranceQuoteApplication_OtherDriver = {
            ...all_draft.other_drivers[driver_id],
        }
        let existing: IInsuranceQuoteApplication_OtherDriver = {
            ...all_existing.other_drivers[driver_id],
        }

        let dataToSendToPatch: IAnyObject | undefined = undefined

        if (draft.additional_details) {
            dataToSendToPatch = gen_draft_differing_values_to_patch({
                existing: existing.additional_details ?? {},
                draft: draft.additional_details ?? {},
            })
        }

        if (draft.entities) {
            let entitiies_existing = {
                disabilities: existing.entities.disabilities,
            }

            let entitiies_draft = {
                disabilities: draft.entities.disabilities,
            }
            let entities_info =
                gen_draft_differing_self_replicating_entities_multiple({
                    draft: entitiies_draft,
                    existing: entitiies_existing,
                })

            if (entities_info.data_to_patch_by_type) {
                dataToSendToPatch = dataToSendToPatch
                    ? {
                          ...dataToSendToPatch,
                          ...entities_info.data_to_patch_by_type,
                      }
                    : { ...entities_info.data_to_patch_by_type }
            }

            if (entities_info.ids_to_delete_by_type) {
                let arrayOfDeletionTasks: any = []
                let entity_ids = Object.keys(
                    entities_info.ids_to_delete_by_type
                )
                for (let i = 0; i < entity_ids.length; i++) {
                    let entity_type = entity_ids[i]

                    for (
                        let i = 0;
                        i <
                        entities_info.ids_to_delete_by_type[entity_type].length;
                        i++
                    ) {
                        let id_to_delete: string =
                            entities_info.ids_to_delete_by_type[entity_type][i]

                        // console.log(
                        //     'deleting entity type:',
                        //     entity_type,
                        //     'of ID:',
                        //     id_to_delete
                        // )

                        let req_del: IDeleteInsuranceApplication_req = {
                            appli_id: `${all_existing.id}`,
                            entity_id: id_to_delete,
                            // @ts-ignore
                            entity_type: entity_type,
                            parent_named_driver_id: driver_id,
                        }

                        const deletionTask = yield fork(entityDeletionSaga, {
                            ...req_del,
                        })
                        arrayOfDeletionTasks.push(deletionTask)

                        // call BE with /DELETE/entity_type id =entity_id
                    }
                }

                if (arrayOfDeletionTasks && arrayOfDeletionTasks.length > 0) {
                    yield join(arrayOfDeletionTasks)
                    application_has_deletions = true
                }
            }
        }

        if (dataToSendToPatch && Object.keys(dataToSendToPatch).length !== 0) {
            // call api to patch as thats all the data merged we want to patch

            dataToSendToPatch = {
                customer: {
                    named_drivers: [
                        {
                            uid: driver_id,
                            ...dataToSendToPatch,
                        },
                    ],
                },
            }
            // console.log('data sent to api  PATCH ', dataToSendToPatch)
            let res = yield call(
                api.insuranceApplication.patchInsuranceApplication,
                {
                    id: `${all_existing.id}`,
                    data: { ...dataToSendToPatch },
                }
            )

            if (p.isCrossForm) {
                p.message && message.success(p.message)
            }

            application_has_patch = true

            let applicationReducerDataWithStats = yield call(
                ValidateAndAddStatsToApplication,
                `${all_existing.id}`,
                res
            )

            yield put(
                insuranceActions.submit_otherDriver_additionalDetails_success(
                    applicationReducerDataWithStats
                )
            )
        } else {
            yield put(
                insuranceActions.submit_otherDriver_additionalDetails_success()
            )
        }

        if (
            application_has_deletions === true &&
            application_has_patch === false
        ) {
            let application: IInsuranceQuoteApplication_Reducer = yield select(
                insurance_application_state_select
            )

            if (
                application.showValidationSheet &&
                application.submitted_data?.id
            ) {
                const isWizard =
                    window.location.pathname.match(/wizard/g) !== null
                        ? true
                        : false

                yield put(
                    insuranceActions.validate_insurance_application_request({
                        application: { ...application.submitted_data },
                        activateValidationSheet: true,
                        isWizard: isWizard,
                    })
                )
            } else {
                yield put(
                    insuranceActions.getting_insurance_info_after_change()
                )
                yield call(GetInsuranceApplication, `${all_existing.id}`)
            }
        }

        return
    } catch (error: any) {
        if (error.status === 401) {
            let p: IUnauthHandlerPayload = {
                functionToRepeat: Submit_otherdriver_additionaldetails,
            }
            yield call(unauthHandlerSaga, p)
        } else {
            let all_existing: IInsuranceQuoteApplication = yield select(
                insurance_application_existing_state_select
            )
            let customErrorData: ICustomErrorData = {
                custom_message: `Something went wrong, please try again.`,
                custom_user_action_text: 'OK',
                custom_redirect_path: `/insurance/application/${`${all_existing.id}/other_driver/${p.driver_id}/additional_details`}`,
            }
            let customError: IReduxError = ConvertToReduxError(
                error,
                customErrorData,
                'insurance'
            )
            yield put(
                insuranceActions.submit_otherDriver_additionalDetails_error(
                    customError
                )
            )
        }
    }
}

function* Watcher_Submit_otherdriver_additionaldetails() {
    while (true) {
        let { payload } = yield take(
            insuranceActions.submit_otherDriver_additionalDetails_request
        )
        yield call(Submit_otherdriver_additionaldetails, payload)
    }
}

const otherdriver_additionaldetails_submit: any[] = [
    fork(Watcher_Submit_otherdriver_additionaldetails),
]

export default otherdriver_additionaldetails_submit
