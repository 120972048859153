import styled from 'styled-components'
import React from 'react'
import { connect } from 'react-redux'
import top_bar_icon_mobile from '../../../../public/assets/navigation/top_bar_icon_mobile.svg'
import top_bar_icon_mobile_dark from '../../../../public/assets/navigation/top_bar_icon_mobile_dark.svg'
// import { device } from "../../templates/displays/devices";
import Faded from '../../../templates/animated/faded'
import account_default from '../../../../public/assets/navigation/account_default_v2_mobile.svg'
import account_default_dark from '../../../../public/assets/navigation/account_default_v2_dark.svg'
// import faq_quick_access_icon from '../../../../public/assets/icons/faq_quick_access_icon.svg'
import { RouteComponentProps, withRouter, Link } from 'react-router-dom'
import IpadAndMobileDisplay from '../../../templates/displays/ipadAndMobileDisplay'

import { RootState } from 'typesafe-actions'
import * as ReactScroll from 'react-scroll'
import {
    ISubNavigationListItem,
    ISubNavigationState,
} from 'TopSubnavigationData'
import { subNavigationStateActions } from '../../../../redux/localdata/subPageNavigation/reducer'
import { menuActions } from '../../../../redux/menus/reducer'
// import RedSticker from '../../stickers/redSticker'
// import { userIdFromDecodedToken } from '../../../../redux/services/authcheck'
import { IUser } from 'myModels'
// import back_circle_navigation from '../../../../public/assets/navigation/back_circle_navigation.svg'
import { IExternalCarsObject, INormalisedExternalGarages } from 'entityModels'
import { checkIfPwaStandalone } from '../../../templates/pwaCustom/checkIpadPwa'
import TopSubnavigationBarMobile from '../mainmenu/topSubnavigationBarMobile'
import ReturnNav from '../../header/returnNav'
import ButtonAtom from '../../Button/ButtonAtom'
import colours, { ITheme } from '../../../../providers/theme/colours'
import { ThemesContext } from '../../../../providers/theme/customThemeProvider'
import ThemeSwitcherMobile from '../../../organisms/themeSwitcher/themeSwitcherMobile'
import StandardCtaBtn from '../../Button/standardCtaBtn'
import ActiveThemeIcon from '../../icons/themeIcons'

type StyledProps = {
    $theme: ITheme
    isMainHeaderVisible?: boolean
    hasSubheader?: boolean
    isSubElementActive?: boolean
    index?: number
}

const OverWrapper = styled.div<StyledProps>`
    background-color: ${(props) => colours[props.$theme].background_default};
    border-bottom: 1px solid var(--border-muted, #e5e5e5);
    -webkit-box-shadow: ${(props) =>
        props.isMainHeaderVisible && !props.hasSubheader
            ? `0px 2px 9px -2px ${colours[props.$theme].shadow_strongest_03}`
            : 'none'};
    -moz-box-shadow: ${(props) =>
        props.isMainHeaderVisible && !props.hasSubheader
            ? `0px 2px 9px -2px ${colours[props.$theme].shadow_strongest_03}`
            : 'none'};
    box-shadow: ${(props) =>
        props.isMainHeaderVisible && !props.hasSubheader
            ? `0px 2px 9px -2px ${colours[props.$theme].shadow_strongest_03}`
            : 'none'};
    position: fixed;
    top: ${(props) => (props.isMainHeaderVisible ? '0px' : '-52px')};
    transition: all 0.2s;
    left: 0px;
    right: 0px;
    width: 100vw;
    z-index: 3;
`

const HeaderWrapper = styled.div<StyledProps>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: ${(props) => colours[props.$theme].background_default};
    /* height: 50px; */
    height: 64px;
    padding-left: 20px;
`

const NavCaption = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`

const Title = styled.div<StyledProps>`
    /* font-family: Lato;
    justify-content: center;
    font-weight: 200;
    color: #0c0c0c;
    font-size: 14px;
    margin-top: 3px;
    letter-spacing: 1.05px; */
    text-transform: capitalize;
    color: ${(props) => colours[props.$theme].text_strong};
    font-family: Lato-Semibold;
    font-size: 18px;
    /* font-weight: 600; */
    line-height: 32px; /* 177.778% */
    letter-spacing: 0.09px;
`

const CircleLogo = styled.img`
    width: 24px;
    height: 32px;
    margin-right: 10px;
    color: #1e1e1e;
`

const AccountIcon = styled.div<StyledProps>`
    height: 32px;
    width: 32px;
    background-color: ${(props) => colours[props.$theme].background_default};
    top: ${(props) => (props.isMainHeaderVisible ? '12px' : '-52px')};
    transition: top 0.2s;
    border-radius: 4px;
    border: 1px solid ${(props) => colours[props.$theme].border_muted};
`

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
`

const Submenu = styled.div<StyledProps>`
    position: fixed;
    left: 0px;
    right: 0;
    top: 0;
    height: 110px;
    min-width: 100%;
    overflow-x: scroll;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    ::-webkit-scrollbar {
        display: none;
    }

    white-space: nowrap;
    background-color: ${(props) => colours[props.$theme].background_default};

    display: ${(props) => (props.hasSubheader ? 'flex' : 'none')};

    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    z-index: 2;
    top: ${(props) => (props.isMainHeaderVisible ? '0px' : '-52px')};
    padding-top: 50px;
    transition: top 0.2s;

    -webkit-box-shadow: 0px 2px 9px -2px ${(props) => colours[props.$theme].shadow_strongest_03};
    -moz-box-shadow: 0px 2px 9px -2px ${(props) => colours[props.$theme].shadow_strongest_03};
    box-shadow: 0px 2px 9px -2px ${(props) => colours[props.$theme].shadow_strongest_03};
`

const SubmenuText = styled.div<StyledProps>`
    padding-bottom: ${(props) => (props.isMainHeaderVisible ? '12px' : '15px')};
    color: ${(props) =>
        props.isSubElementActive
            ? colours[props.$theme].primary
            : colours[props.$theme].primary_50};
    text-align: center;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    font-family: lato-light;
    padding-left: 20px;
    padding-right: 20px;
    min-width: 50px;

    @media (max-width: 325px) {
        font-size: 10px;
        padding-left: 15px;
        padding-right: 15px;
    }

    white-space: nowrap;
    border-bottom: ${(props) =>
        props.isSubElementActive
            ? `4px solid ${colours[props.$theme].primary}`
            : `4px solid ${colours[props.$theme].background_default};`};
`
// transform: ${(props) =>
//     props.index ? `translateX(${props.index}vw)` : `none`};

const dispatchProps = {
    setCurrentVisitedSectionID: (id: string) =>
        subNavigationStateActions.setCurrentVisitedSectionID(id),
    toggleMobileAccountMenu: (isOpen: boolean) =>
        menuActions.toggleMobileAccountMenu(isOpen),
}

function mapStateToProps(state: RootState) {
    return {
        carsData: state.entities.carsData.cars,
        subnavigation_state: state.localdata.subnavigation_state,
        currentCarID:
            state.entities.carsData.currentCarID &&
            state.entities.carsData.currentCarID,
        externalCarsData: state.entities.carsData.directly_shared_ext_cars,
        extternalGaragesData:
            state.entities.garagesData.directly_shared_external_garages,
        currentExternalCarId:
            state.entities.carsData.currentCarID_ext_directShare,
        currentExternalGarageId:
            state.entities.garagesData
                .directly_shared_current_external_garage_id,
        isDefaultNavigationRemoved: state.menus.isDefaultNavigationRemoved,
    }
}

type State = {
    prevScrollpos: any
    scrollTransitionX: number
    isMainHeaderVisible: boolean
    isThemeSwitcherOpen: boolean
}
interface Props extends RouteComponentProps<any> {
    subnavigation_state: ISubNavigationState
    setCurrentVisitedSectionID: any
    toggleMobileAccountMenu: any
    user: IUser | null
    currentCarID: any
    externalCarsData: IExternalCarsObject | undefined
    currentExternalCarId: string | undefined
    extternalGaragesData: INormalisedExternalGarages | undefined
    currentExternalGarageId: string | undefined
    location: any
    isDefaultNavigationRemoved: boolean
}

class MobileTopHeaderNav extends React.PureComponent<Props, State> {
    state = {
        prevScrollpos: window.pageYOffset,
        scrollTransitionX: 0,
        isMainHeaderVisible: true,
        isThemeSwitcherOpen: false,
    }

    // Adds an event listener when the component is mount.
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll, { passive: true })
    }

    // Remove the event listener when the component is unmount.
    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll)
        this.setState({ ...this.state })
        this.props.setCurrentVisitedSectionID('mobile-apex-podcasts-hp')
    }

    checkIfHasSubheader = () => {
        if (this.props.history.location.pathname === '/apex') {
            return true
        } else return false
    }

    checkIfMainPage = (pathname: string): boolean => {
        if (pathname.match(/confirm/g) !== null) {
            return false
        }

        if (
            pathname.match(/garage/g) !== null ||
            pathname === '/' ||
            pathname === '/garage/projects' ||
            pathname === '/garage/previously-owned' ||
            pathname === '/news' ||
            pathname.match(/car/g) !== null ||
            pathname.match(/apex/g) !== null ||
            pathname === '/apply' ||
            pathname === '/tasks' ||
            pathname === '/history-files' ||
            pathname === '/invite' ||
            pathname.match(/account/g) !== null ||
            pathname === '/upcoming' ||
            pathname === '/sharing/with-others' ||
            pathname === '/sharing/with-you' ||
            pathname === '/showroom' ||
            pathname === '/showroom/watch-list' ||
            pathname === '/showroom/for-sale' ||
            pathname === '/showroom/sold' ||
            pathname === '/insurance/overview'
        ) {
            return true
        } else return false
    }

    checkIfVisible = (pathname: string): boolean => {
        const params = new URLSearchParams(this.props.location.search)

        let userid: string | null = this.props.user && this.props.user.id

        if (
            pathname.match(/archives/g) !== null &&
            pathname.match(/entry/g) !== null
        ) {
            return false
        }

        if (pathname.match(/archives/g) !== null) {
            return true
        }

        if (!userid && pathname.match(/showroom/g) === null) {
            return false
        }

        if (pathname.match(/previously-owned/g) !== null) {
            return true
        }

        let checkIfSingleTaskPage = params.get('taskid')
        let checkIfIdPage = params.get('id')
        if (checkIfIdPage !== null && pathname.match(/gallery/g) !== null) {
            return false
        }
        if (checkIfIdPage !== null || checkIfSingleTaskPage !== null) {
            return false
        }

        if (
            pathname.match(/with-others/g) !== null &&
            pathname.match(/cars/g) !== null
        )
            return false

        // if (
        //     pathname.match(/tasks/g) !== null &&
        //     pathname.match(/car/g) !== null
        // ) {
        //     return false
        // }

        if (
            pathname.match(/shared/g) === null &&
            pathname.match(/car/g) !== null &&
            pathname.match(/history-file/g) !== null &&
            pathname.match(/archives/g) === null
        ) {
            return false
        }

        if (
            pathname.match(/sharing/g) !== null &&
            pathname.match(/car/g) !== null &&
            pathname.match(/with-others/g) !== null
        ) {
            return false
        }

        if (
            pathname.match(/shared/g) !== null &&
            pathname.match(/car/g) !== null &&
            pathname.match(/history-file/g) !== null &&
            pathname.match(/entry/g) !== null
        ) {
            return false
        }

        if (
            pathname.match(/shared/g) !== null &&
            pathname.match(/car/g) !== null &&
            pathname.match(/history-file/g) !== null &&
            pathname.match(/gallery/g) !== null
        ) {
            return false
        }

        if (
            pathname.match(/archives/g) !== null &&
            pathname.match(/car/g) !== null &&
            pathname.match(/history-file/g) !== null &&
            pathname.match(/entry/g) !== null
        ) {
            return false
        }

        if (pathname.match(/edit-share/g) !== null) {
            return false
        }

        if (pathname.match(/archive/g) !== null) {
            return false
        }

        if (pathname.match(/confirm/g) !== null) {
            return false
        } else return true
    }

    determineIfHeaderMobile = (pathname: string): boolean => {
        let user: IUser | null = this.props.user
        let isGaragePage: boolean =
            pathname.match(/garage/g) !== null &&
            pathname.match(/country/g) === null

        let isGaragePageV2: boolean =
            pathname === '/' && user && user.id ? true : false

        let isTheRightcarPage: boolean =
            pathname.match(/car/g) !== null &&
            pathname.match(/search/g) === null &&
            pathname.match(/apex/g) === null &&
            pathname.match(/deletion/g) === null &&
            pathname.match(/journey/g) === null

        let isOtherMainPage: boolean =
            pathname === '/history-files' ||
            pathname === '/apply' ||
            pathname === '/upcoming' ||
            pathname === '/tasks' ||
            pathname === '/apex' ||
            pathname === '/apex/podcasts' ||
            pathname === '/apex/articles' ||
            pathname === '/apex/cars-of-the-week' ||
            // pathname === '/apex/news-highlights' ||
            pathname === '/apex/interviews' ||
            (pathname.match(/account/g) !== null &&
                pathname.match(/timezone/g) === null) ||
            pathname === '/sharing/with-others' ||
            pathname === '/sharing/with-you' ||
            pathname === '/showroom' ||
            pathname === '/showroom/for-sale' ||
            pathname === '/showroom/sold' ||
            pathname === '/showroom/watch-list' ||
            pathname === '/insurance/overview'

        if (
            isGaragePage ||
            isGaragePageV2 ||
            isTheRightcarPage ||
            isOtherMainPage
        ) {
            return true
        } else return false
    }

    determineHeaderTitle = (pathname: string): string => {
        const trimmedTitle = () => pathname.replace(/[^\w\s]/gi, '')

        switch (true) {
            case pathname === '/' && pathname.match(/shared/g) === null:
                return 'garage'

            case pathname === '/account':
                return 'your profile'
            case pathname === '/account/preferences':
                return 'preferences'
            case pathname === '/account/faq':
                return 'faq'
            case pathname === '/account/install-app':
                return 'install custodian'
            case pathname === '/account/contact':
                return 'contact'
            case pathname === '/account/tutorials':
                return 'tutorials'
            case pathname === '/account/help':
                return 'help'
            case pathname === '/tasks':
                return 'your tasks'
            case pathname === '/history-files':
                return 'history files'
            case pathname === '/upcoming':
                return 'upcoming'
            case pathname === '/sharing/with-others' || '/sharing/with-you':
                return 'sharing'
            case pathname.match(/account/g) !== null:
                return 'account'
            case pathname.match(/garage/g) !== null &&
                pathname.match(/shared/g) === null:
                return 'garage'
            case pathname.match(/apex/g) !== null:
                return 'the apex'
            case pathname.match(/insights/g) !== null:
                return 'car insights'
            case pathname.match(/car/g) !== null &&
                pathname.match(/shared/g) === null &&
                pathname.match(/archives/g) === null:
                return 'car overview'
            case pathname.match(/archives/g) !== null &&
                pathname.match(/car/g) !== null:
                return 'previously owned'
            case pathname === '/sharing/with-others':
                return 'sharing'
            case pathname === '/sharing/with-you':
                return 'sharing'
            case pathname.match(/shared/g) !== null &&
                pathname.match(/car/g) !== null:
                return 'shared with you'
            case pathname.match(/shared/g) !== null &&
                pathname.match(/garage/g) !== null:
                return 'shared with you'
            case pathname === '/showroom/for-sale' ||
                pathname === '/showroom/sold' ||
                pathname === '/showroom/watch-list':
                return 'Showroom'

            case pathname === '/insurance/overview':
                return 'Insurance'

            default:
                return trimmedTitle()
        }
    }

    // Hide or show the menu.
    handleScroll = () => {
        const { prevScrollpos } = this.state

        const currentScrollPos = window.pageYOffset

        const checkvisibility = () => {
            let hasSubheader = this.checkIfHasSubheader()

            if (currentScrollPos < 10) {
                if (hasSubheader === true) {
                    let newTrans = 0

                    this.setState({
                        ...this.state,
                        scrollTransitionX: newTrans,
                    })
                }
                return true
            }

            if (prevScrollpos > currentScrollPos) {
                if (hasSubheader === true) {
                    let newTrans = this.state.scrollTransitionX + 0.2

                    if (currentScrollPos < 200) {
                        newTrans = 0
                    }

                    this.setState({
                        ...this.state,
                        scrollTransitionX: newTrans,
                    })
                }
                return true
            } else {
                if (hasSubheader === true) {
                    let newTrans = this.state.scrollTransitionX - 0.2

                    this.setState({
                        ...this.state,
                        scrollTransitionX: newTrans,
                    })
                }
                return false
            }
        }

        return this.setState({
            prevScrollpos: currentScrollPos,
            isMainHeaderVisible: checkvisibility(),
        })
    }

    determine_back_path = (): string => {
        if (this.props.history.location.pathname.includes('/shared/garage')) {
            return '/sharing/with-you?entity_id=garage'
        } else if (
            this.props.history.location.pathname.includes('/shared/car') &&
            this.props.history.location.pathname !==
                `/shared/car/${this.props.currentExternalCarId}`
        ) {
            return `/shared/car/${this.props.currentExternalCarId}`
        } else if (
            this.props.location.state &&
            this.props.location.state.prevPath.includes('/shared/garage')
        ) {
            return this.props.location.state.search
                ? `${this.props.location.state.prevPath}${this.props.location.state.search}`
                : this.props.location.state.prevPath
        } else {
            return '/sharing/with-you'
        }
    }

    closeThemeSwitcher = () => {
        this.setState({ ...this.state, isThemeSwitcherOpen: false })
    }

    render() {
        let current_pathname = checkIfPwaStandalone()
            ? window.location.pathname
            : window.location.pathname

        let isExternalCar =
            this.props.history.location.pathname.match(/shared/g) !== null
                ? true
                : false

        let isExternalGarage =
            this.props.history.location.pathname.match(/shared/g) !== null &&
            this.props.history.location.pathname.match(/garage/g) !== null
                ? true
                : false

        let {
            subnavigation_state,
            setCurrentVisitedSectionID,
            toggleMobileAccountMenu,
        } = this.props

        let themeSwitcherSeen = localStorage?.getItem('theme-switcher-seen')

        return (
            <ThemesContext.Consumer>
                {({ theme }) => (
                    <IpadAndMobileDisplay>
                        {this.determineIfHeaderMobile(current_pathname) ===
                            true &&
                            this.checkIfVisible(current_pathname) === true &&
                            !this.props.isDefaultNavigationRemoved && (
                                <>
                                    <div
                                        style={{
                                            position: 'fixed',
                                            right: 72,
                                            top: 16,
                                            zIndex: 1000,
                                        }}
                                    >
                                        <ThemeSwitcherMobile
                                            isOpen={
                                                this.state.isThemeSwitcherOpen
                                            }
                                            closeList={this.closeThemeSwitcher}
                                        />
                                    </div>
                                    <OverWrapper
                                        $theme={theme}
                                        isMainHeaderVisible={
                                            this.state.isMainHeaderVisible
                                        }
                                        hasSubheader={this.checkIfHasSubheader()}
                                    >
                                        <HeaderWrapper
                                            $theme={theme}
                                            id="mobile-header-wrapper"
                                            style={{
                                                paddingRight: this.props.user
                                                    ? '25px'
                                                    : '16px',
                                            }}
                                        >
                                            {this.checkIfMainPage(
                                                current_pathname
                                            ) === true &&
                                                (!isExternalCar ? (
                                                    <React.Fragment>
                                                        <NavCaption>
                                                            <Link
                                                                to={{
                                                                    pathname: `/garage`,
                                                                    state: {
                                                                        prevPath:
                                                                            this
                                                                                .props
                                                                                .history
                                                                                .location
                                                                                .pathname,
                                                                    },
                                                                }}
                                                            >
                                                                <CircleLogo
                                                                    src={
                                                                        theme ===
                                                                        'light'
                                                                            ? top_bar_icon_mobile
                                                                            : top_bar_icon_mobile_dark
                                                                    }
                                                                    alt="pcc-logo"
                                                                />
                                                            </Link>
                                                            <Faded>
                                                                <Title
                                                                    $theme={
                                                                        theme
                                                                    }
                                                                >
                                                                    {this.determineHeaderTitle(
                                                                        current_pathname
                                                                    )}
                                                                </Title>
                                                            </Faded>
                                                        </NavCaption>

                                                        <Row>
                                                            {this.props.user ? (
                                                                <>
                                                                    {/* <div
                                                                style={{
                                                                    transform:
                                                                        'translateY(3px)',
                                                                }}
                                                            >
                                                                <RedSticker
                                                                    text={
                                                                        'beta'
                                                                    }
                                                                    beta={true}
                                                                    height={
                                                                        '22px'
                                                                    }
                                                                    width={
                                                                        '48px'
                                                                    }
                                                                />
                                                            </div> */}
                                                                    <div
                                                                        style={{
                                                                            paddingLeft:
                                                                                '12px',
                                                                        }}
                                                                    />

                                                                    <AccountIcon
                                                                        $theme={
                                                                            theme
                                                                        }
                                                                        isMainHeaderVisible={
                                                                            this
                                                                                .state
                                                                                .isMainHeaderVisible
                                                                        }
                                                                        onClick={() =>
                                                                            toggleMobileAccountMenu(
                                                                                true
                                                                            )
                                                                        }
                                                                        data-attr="account-icon-triggering-account-menu"
                                                                    >
                                                                        <img
                                                                            style={{
                                                                                width: '100%',
                                                                                height: '100%',
                                                                                objectFit:
                                                                                    'cover',
                                                                                borderRadius: 8,
                                                                            }}
                                                                            src={
                                                                                this
                                                                                    .props
                                                                                    .user &&
                                                                                this
                                                                                    .props
                                                                                    .user
                                                                                    .profile_image_url
                                                                                    ? this
                                                                                          .props
                                                                                          .user
                                                                                          .profile_image_url
                                                                                    : theme ===
                                                                                      'dark'
                                                                                    ? account_default_dark
                                                                                    : account_default
                                                                            }
                                                                            alt="account"
                                                                        />
                                                                    </AccountIcon>
                                                                </>
                                                            ) : (
                                                                <ButtonAtom
                                                                    width="82px"
                                                                    height="30px"
                                                                    theme="capitalize-white-background"
                                                                    onClick={() => {
                                                                        this.props.history.push(
                                                                            '/login'
                                                                        )
                                                                    }}
                                                                >
                                                                    Sign In
                                                                </ButtonAtom>
                                                            )}
                                                            <div
                                                                style={{
                                                                    paddingLeft: 16,
                                                                }}
                                                            />
                                                            <StandardCtaBtn
                                                                customWidth="32px"
                                                                customHeight="32px"
                                                                removePaddings
                                                                isSecondary
                                                                bg={
                                                                    'transparent'
                                                                }
                                                                customHoverColor={
                                                                    colours[
                                                                        theme
                                                                    ]
                                                                        .background_neutral_subtle
                                                                }
                                                                borderOnHover={`2px solid ${colours[theme].border_muted}`}
                                                                boder={`1px solid ${colours[theme].border_muted}`}
                                                                onClick={() => {
                                                                    this.setState(
                                                                        {
                                                                            ...this
                                                                                .state,
                                                                            isThemeSwitcherOpen:
                                                                                true,
                                                                        }
                                                                    )
                                                                    if (
                                                                        !themeSwitcherSeen
                                                                    ) {
                                                                        localStorage?.setItem(
                                                                            'theme-switcher-seen',
                                                                            'seen'
                                                                        )
                                                                    }
                                                                }}
                                                                removeShadowOnHover
                                                                className={
                                                                    themeSwitcherSeen
                                                                        ? undefined
                                                                        : 'animatedBorder'
                                                                }
                                                            >
                                                                <ActiveThemeIcon
                                                                    color={
                                                                        themeSwitcherSeen
                                                                            ? undefined
                                                                            : colours[
                                                                                  theme
                                                                              ]
                                                                                  .primary
                                                                    }
                                                                />
                                                            </StandardCtaBtn>
                                                        </Row>
                                                    </React.Fragment>
                                                ) : (
                                                    <Link
                                                        to={this.determine_back_path()}
                                                    >
                                                        <ReturnNav
                                                            isExternalEntity={
                                                                isExternalCar ||
                                                                isExternalGarage
                                                            }
                                                        />
                                                    </Link>
                                                ))}
                                        </HeaderWrapper>

                                        {isExternalGarage ? null : (
                                            <TopSubnavigationBarMobile
                                                isMainTopHeaderVisible={
                                                    this.state
                                                        .isMainHeaderVisible
                                                }
                                                isExternalCar={isExternalCar}
                                            />
                                        )}
                                    </OverWrapper>

                                    <Submenu
                                        $theme={theme}
                                        isMainHeaderVisible={
                                            this.state.isMainHeaderVisible
                                        }
                                        hasSubheader={this.checkIfHasSubheader()}
                                    >
                                        {current_pathname === '/apex' &&
                                            subnavigation_state.apex_homepage.map(
                                                (
                                                    item: ISubNavigationListItem,
                                                    index: number
                                                ) => {
                                                    return (
                                                        <ReactScroll.Link
                                                            key={`${index}-mobiletopheader-mobile`}
                                                            to={item.mobile_id}
                                                            spy={false}
                                                            smooth={true}
                                                            offset={-70}
                                                            duration={500}
                                                            onClick={() => {
                                                                setTimeout(
                                                                    function () {
                                                                        setCurrentVisitedSectionID(
                                                                            item.mobile_id
                                                                        )
                                                                    },
                                                                    500
                                                                )
                                                            }}
                                                        >
                                                            <SubmenuText
                                                                $theme={theme}
                                                                index={
                                                                    this.state
                                                                        .scrollTransitionX
                                                                }
                                                                isSubElementActive={
                                                                    item.mobile_id ===
                                                                    subnavigation_state.currentID
                                                                        ? true
                                                                        : false
                                                                }
                                                            >
                                                                {item.name}
                                                            </SubmenuText>
                                                        </ReactScroll.Link>
                                                    )
                                                }
                                            )}
                                    </Submenu>
                                </>
                            )}
                    </IpadAndMobileDisplay>
                )}
            </ThemesContext.Consumer>
        )
    }
}

export default withRouter(
    connect(mapStateToProps, dispatchProps)(MobileTopHeaderNav)
)
