import { call, fork, put, select, take } from 'redux-saga/effects'

import * as requestActions from '../actions/requestActions'
import * as successActions from '../actions/successActions'
import * as errorActions from '../actions/errorActions'
import { api } from '../../services'

import {
    ICustomErrorData,
    IReduxError,
    IReminder,
    ITask,
    ITaskUpdateFieldsPayload,
    ITaskUpdateListElement,
    IUpdateTaskReq,
} from 'entityModels'

import { ITaskPayloadAPI } from 'IapiDataPayload'
import { ConvertSingleApiTask } from '../../conversions/tasks/taskTypeConversions'
import posthog from 'posthog-js'
import { ConvertToReduxError } from '../../conversions/errors/convertToReduxError'
import {
    IUnauthHandlerPayload,
    unauthHandlerSaga,
} from '../../user/sagas/unauthHandler'
import { deleteTaskRemindersSaga } from './deleteTaskReminder'
import { getTasksInState } from './addReminderToTask'
import { convertDateToUTCFormatWithTimeSetToZero } from '../../conversions/time/dates'
import { IApiTasksUpdate_args } from 'ApiInterfaces'

// UPDATE TASK

export function* updateTaskSaga(reqPayload: IUpdateTaskReq): any {
    // later on : put API TRY CATH
    let dataList = reqPayload.dataList
    let dataToSend: ITaskUpdateFieldsPayload = {}

    let deleteReminders: boolean = false
    let tasksInState = yield select(getTasksInState)

    if (dataList !== undefined) {
        if (dataList.length === 1) {
            let data = dataList[0]

            if (data.id === 'title') {
                dataToSend.title = data.answer
            }

            if (data.id === 'due') {
                if (
                    tasksInState[reqPayload.taskid] &&
                    tasksInState[reqPayload.taskid].due
                ) {
                    // if new due date is not the same as old due date go ahead
                    if (
                        convertDateToUTCFormatWithTimeSetToZero(
                            tasksInState[reqPayload.taskid].due
                        ) !==
                        convertDateToUTCFormatWithTimeSetToZero(data.answer)
                    ) {
                        dataToSend.due =
                            convertDateToUTCFormatWithTimeSetToZero(data.answer)
                    }
                }
                // else if there was not sur date go ahead
                else if (!tasksInState[reqPayload.taskid].due) {
                    dataToSend.due = convertDateToUTCFormatWithTimeSetToZero(
                        data.answer
                    )
                }

                deleteReminders = true
            }

            if (data.id === 'body') {
                dataToSend.body = data.answer
            }

            if (data.id === 'status') {
                dataToSend.complete = data.answer
            }
        } else {
            dataList.forEach((data: ITaskUpdateListElement, index: number) => {
                //  element :   ITaskUpdateListElement;

                if (data.id === 'title') {
                    dataToSend.title = data.answer
                }

                if (data.id === 'due') {
                    if (
                        tasksInState[reqPayload.taskid] &&
                        tasksInState[reqPayload.taskid].due
                    ) {
                        // if new due date is not the same as old due date go ahead
                        if (
                            convertDateToUTCFormatWithTimeSetToZero(
                                tasksInState[reqPayload.taskid].due
                            ) !==
                            convertDateToUTCFormatWithTimeSetToZero(data.answer)
                        ) {
                            dataToSend.due =
                                convertDateToUTCFormatWithTimeSetToZero(
                                    data.answer
                                )
                        }
                    }
                    // else if there was not sur date go ahead
                    else if (!tasksInState[reqPayload.taskid].due) {
                        dataToSend.due =
                            convertDateToUTCFormatWithTimeSetToZero(data.answer)
                    }

                    deleteReminders = true
                }

                if (data.id === 'body') {
                    dataToSend.body = data.answer
                }

                if (data.id === 'status') {
                    dataToSend.complete = data.answer
                }
            })
        }

        // else {
        //     let customErrorData: ICustomErrorData = {
        //         custom_message: `Something went wrong, we couldn't get yout car tasks`,
        //         custom_user_action_text: 'Return to see all tasks',
        //         custom_redirect_path: `/tasks`,
        //     }
        //     let customError: IReduxError = ConvertToReduxError(
        //         Error,
        //         customErrorData
        //     )
        //     yield put(errorActions.updateTaskError(customError))
        //     return
        // }

        if (dataToSend && Object.keys(dataToSend).length > 0) {
            try {
                let api_payload: IApiTasksUpdate_args = {
                    taskid: reqPayload.taskid,
                    dataToUpdate: dataToSend,
                }
                const updatedTask: ITaskPayloadAPI = yield call(
                    api.tasks.updateData.updateTask,
                    api_payload
                )

                let updatedTaskConverted: ITask =
                    ConvertSingleApiTask(updatedTask)

                updatedTaskConverted.has_reminders =
                    dataToSend.due !== undefined && deleteReminders === true
                        ? false
                        : tasksInState[reqPayload.taskid].has_reminders

                yield put(
                    successActions.updateTaskSuccess(updatedTaskConverted)
                )

                // Capture event
                posthog.capture('UPDATE TASK', {
                    property:
                        'General Task Update outside of car / garage relations',
                })

                if (dataToSend.due !== undefined && deleteReminders === true) {
                    if (
                        tasksInState[reqPayload.taskid] &&
                        tasksInState[reqPayload.taskid].reminders
                    ) {
                        let p = {
                            taskUID: reqPayload.taskid,
                            reminderIDs: tasksInState[
                                reqPayload.taskid
                            ].reminders.map((r: IReminder) => r.id),
                        }

                        yield call(deleteTaskRemindersSaga, p)
                    }
                }
            } catch (error: any) {
                if (error.status === 401) {
                    let p: IUnauthHandlerPayload = {
                        functionToRepeat: updateTaskSaga,
                        payload: reqPayload,
                    }
                    yield call(unauthHandlerSaga, p)
                } else {
                    let customErrorData: ICustomErrorData = {
                        custom_message: `Something went wrong, we couldn't get yout car tasks`,
                        custom_user_action_text: 'Return to see all tasks',
                        custom_redirect_path: `/tasks`,
                    }
                    let customError: IReduxError = ConvertToReduxError(
                        error,
                        customErrorData
                    )
                    yield put(errorActions.updateTaskError(customError))
                }
            }
        }
    }
}

function* watcherUpdateTask() {
    while (true) {
        const { payload } = yield take(requestActions.updateTaskRequest)

        yield call(updateTaskSaga, payload)
    }
}

const update_tasks: any[] = [fork(watcherUpdateTask)]

export default update_tasks
