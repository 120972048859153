import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router-dom'

// add Link above once using it
import './App.css'
import Register from './components/pages/register'
import Signin from './components/pages/signin'
import PrivateRoute from './components/templates/displays/PrivateRoute'
import VerifyPage from './components/pages/verifyPage'
import ErrorPage from './components/pages/error'
import { History } from 'history'
// import { ConnectedRouter } from 'connected-react-router'
import PhoneDetail from './components/pages/phone-detail'
import EnterAddress from './components/pages/enter-address'
import MobileTopHeaderNav from './components/atoms/menu/topnavmobile/mobileTopHeaderNav'
import { RootState } from 'typesafe-actions'
import ScrollToTop from './components/templates/displays/scrollToTop'
import AccountPage from './components/pages/account/account'
import UserPreferencesPage from './components/pages/account/userPreferences'
import NotFoundPage from './components/pages/notFound'
import ResetPasswordForm from './components/pages/resetPasswordForm'
import ResetPasswordRequest from './components/pages/resetPasswordRequest'
import MainMenu from './components/templates/menus/mainMenu'
import GarageProjects from './components/pages/garage/garageProjects'
import CarOverview from './components/pages/car/carOverview'
import IpadAndMobileDisplay from './components/templates/displays/ipadAndMobileDisplay'
import TechnicalInformation from './components/pages/car/technicalInformation'
import Gallery from './components/pages/car/gallery'
import MyGarage from './components/pages/garage/myGarage'
import GetGaragePrivateRoute from './components/templates/getDataOnRoute'
// import PublicMenu from './components/atoms/menu/publicmenu/publicMenu'
import ConfirmCarData from './components/pages/car/confirmCarData'
import Tasks from './components/pages/tasks/tasks'
import SingleTask from './components/pages/tasks/singleTask'
import CarTasks from './components/pages/car/carTasks'
import CarSingleTask from './components/pages/car/carSingleTask'
import CarDeletionConfirmation from './components/pages/car/carDeletionConfirmation'
import ApexOverview from './components/pages/apex/overview'
import ApexCarsOfTheWeek from './components/pages/apex/carsOfTheWeek'
// import ApexNewsHighlights from './components/pages/apex/apexNewsHighlights'
import ApexInterviewsOverview from './components/pages/apex/interviewsOverview'
import ApexSingleInterview from './components/pages/apex/apexSingleInterview'
import ApexSingleArticle from './components/pages/apex/apexSingleArticle'
import ApexArticleOverviews from './components/pages/apex/apexArticleOverviews'
import ApexPodcasts from './components/pages/apex/apexPodcasts'
import Help from './components/pages/account/help'
import Faq from './components/pages/account/faq'
import ContactHelp from './components/pages/account/contactHelp'
import TutorialsHelp from './components/pages/account/tutorials'
import InstallApp from './components/pages/account/installApp'
import InstallAndroidPropmt from './components/templates/pwaCustom/installAndroidPrompt'
import * as Sentry from '@sentry/react'
import Upcoming from './components/pages/upcoming/upcoming'
import AccountMenuMobile from './components/atoms/menu/bottomSheetMenu/accountMenuMobile'
import CarTimeline from './components/pages/entries/carTimeline'
import CarTimelineSingleEntry from './components/pages/entries/carTimelineSingleEntry'
import CreateTimelineEntry from './components/pages/entries/createTimelineEntry'
import EditTimelineEntry from './components/pages/entries/editTimelineEntry'
import CreateCost from './components/pages/entries/createCost'
import EditCost from './components/pages/entries/editCost'
import { IUser } from 'myModels'
import GeneralTimeline from './components/pages/entries/generalTimeline'
import TaskReminders from './components/pages/tasks/taskReminders'
import SharedWithOthers from './components/pages/sharing/sharedWithOthers'
import CarShares from './components/pages/sharing/carShares'
import CarShareCreate from './components/pages/sharing/carShareCreate'
import CarShareEdit from './components/pages/sharing/carShareEdit'
import CarShareEditRecipients from './components/pages/sharing/carShareEditRecipients'
import GarageShareCreate from './components/pages/sharing/garageShareCreate'
import GarageShareEdit from './components/pages/sharing/garageShareEdit'
import GarageShareEditRecipients from './components/pages/sharing/garageShareEditRecipients'
import SharedWithYou from './components/pages/sharing/sharedWithYou'
import ExternalCarOverview from './components/pages/car/externalCar/externalCarOverview'
import ExternalCarTechnicalInformation from './components/pages/car/externalCar/externalCarTechnicalInformation'
import ExternalCarGallery from './components/pages/car/externalCar/externalCarGallery'
import ExternalCarHistoryFile from './components/pages/car/externalCar/externalCarHistoryFile'
import ExternalCarHistoryFileEntry from './components/pages/car/externalCar/externalCarHistoryFileEntry'
import ExternalCarSingleEntryGallery from './components/pages/car/externalCar/externalCarSingleEntryGallery'
import CarSharing from './components/pages/car/carSharing'
import ExternalGarage from './components/pages/garage/externalGarage/externalGarage'
// import { resetAllForms } from './redux/editForms/reducer'
import SingleEntryGallery from './components/pages/entries/singleEntryGallery'
import { checkIfPwaStandalone } from './components/templates/pwaCustom/checkIpadPwa'
// import IdeasButton from './components/templates/ideasButton/ideasButton'
import DirectShareDeletionConfirmation from './components/organisms/directshareDeletionConfirmation/directshareDeletionConfirmationMobile'
import CarSearch from './components/pages/car/search'
import Showroom from './components/pages/showroom/showroom'
import showroomCarProfile from './components/pages/showroom/car/showroomCarProfile'
import ShowroomCarGallery from './components/pages/showroom/car/showroomCarGallery'
import ShowroomCarDescription from './components/pages/showroom/car/showroomCarDescription'
import TimezonePicker from './components/pages/account/userPreferences/timezonePicker'
import CountryPicker from './components/pages/garage/countryPicker'
import InviteShareError from './components/pages/emailInvites/inviteShareError'
import CarEnquiry from './components/pages/showroom/car/carEnquiry'
import ShowroomFiltersMobile from './components/pages/showroom/search/showroomFiltersMobile'
import ShowroomSearchFiltersMobile from './components/pages/showroom/search/showroomSearchFiltersMobile'
import showroomCarProfileEdit from './components/pages/showroom/car/showroomCarProfileEdit'
import CarGalleryShowroomEdit from './components/pages/showroom/car/carGalleryShowroomEdit'
import PublishToShowroomMobileStep from './components/pages/showroom/car/publishToShowroomMobileStep'
import CarHandover from './components/pages/car/handover'
import AccountDeletionConfirmed from './components/pages/accountDeletionConfirmed'
import EditShowroomEntryBasicInfoMobile from './components/pages/showroom/car/editShowroomEntryBasicInfoMobile'
import DescriptionImageShowroomEdit from './components/pages/showroom/car/descriptionImageEdit'
import WatchlistPage from './components/pages/showroom/watchlist'
import ShowroomSoldListPage from './components/pages/showroom/sold'
import ArchiveCarFlowMobile from './components/pages/car/ArchiveCarFlowMobile'
import GarageArchive from './components/pages/archive/garageArchive'
import CarOverviewArchive from './components/pages/archive/car/overview'
import CarGalleryArchive from './components/pages/archive/car/gallery'
import CarTechnicalInformationArchive from './components/pages/archive/car/technicalInformation'
import CarHistoryFileArchive from './components/pages/archive/car/historyFile'
import CarHistoryFileEntryArchive from './components/pages/archive/car/historyFileEntry'
import CarHistoryFileEntryGalleryArchive from './components/pages/archive/car/historyFileEntryGallery'
import { IFeatureFlagsReducer } from './redux/featureFlags/types'
import { getCurrentUserDataRequest } from './redux/user/actions/loadingActions'
import InsuranceApplicationDashboard from './components/pages/insurance_quote/dashboards/insuranceApplicationDashboard'
import InsuranceDetailsApplicationFlow from './components/pages/insurance_quote/flows/application/insuranceDetailsApplicationFlow'
import InsuranceApplicationCarDashboard from './components/pages/insurance_quote/dashboards/insuranceApplicationCarDashboard'
import InsuranceApplicationMainDriverDashboard from './components/pages/insurance_quote/dashboards/insuranceApplicationMainDriverDashboard'
import InsuranceApplicationOtherDriverDashboard from './components/pages/insurance_quote/dashboards/insuranceApplicationOtherDriverDashboard'
import InsuranceCarTechInfoApplicationFlow from './components/pages/insurance_quote/flows/main_car/insuranceCarTechInfoApplicationFlow'

import InsuranceCarOwnershipApplicationFlow from './components/pages/insurance_quote/flows/main_car/insuranceCarOwnershipApplicationFlow'

import InsuranceOtherDriverBasicDetailsApplicationFlow from './components/pages/insurance_quote/flows/other_driver/insuranceOtherDriverBasicDetailsApplicationFlow'
import InsuranceOtherDriverLicenceAddressApplicationFlow from './components/pages/insurance_quote/flows/other_driver/insuranceOtherDriverLicenceAddressApplicationFlow'
import InsuranceOtherDriverAdditionalDetailsApplicationFlow from './components/pages/insurance_quote/flows/other_driver/insuranceOtherDriverAdditionalDetailsApplicationFlow'
import InsuranceOtherDriverTrackRecordApplicationFlow from './components/pages/insurance_quote/flows/other_driver/insuranceOtherDriverTrackRecordApplicationFlow'
import InsuranceApplicationAddressesDashboard from './components/pages/insurance_quote/dashboards/insuranceApplicationAddressesDashboard'

import InsuranceMainDriverBasicDetailsApplicationFlow from './components/pages/insurance_quote/flows/main_driver/insuranceMainDriverBasicDetailsApplicationFlow'
import InsuranceMainDriverLicenceAddressApplicationFlow from './components/pages/insurance_quote/flows/main_driver/insuranceMainDriverLicenceAddressApplicationFlow'
import InsuranceMainDriverAdditionalDetailsApplicationFlow from './components/pages/insurance_quote/flows/main_driver/insuranceMainDriverAdditionalDetailsApplicationFlow'
import InsuranceMainDriverTrackRecordApplicationFlow from './components/pages/insurance_quote/flows/main_driver/insuranceMainDriverTrackRecordApplicationFlow'
import InsuranceEverydayCarOwnershipApplicationFlow from './components/pages/insurance_quote/flows/everyday_car/insuranceEverydayCarOwnershipApplicationFlow'
import InsuranceEverydayCarTechInfoApplicationFlow from './components/pages/insurance_quote/flows/everyday_car/insuranceEverydayCarTechInfoApplicationFlow'
import InsuranceOtherDriverAddressManager from './components/pages/insurance_quote/flows/other_driver/insuranceOtherDriverAddressManager'
import InsuranceMainDriverAddressManager from './components/pages/insurance_quote/flows/main_driver/insuranceMainDriverAddressManager'
import InsuranceApplicationDriversDashboard from './components/pages/insurance_quote/dashboards/insuranceApplicationDriversDashboard'
import InsuranceCarMileageParkingApplicationFlow from './components/pages/insurance_quote/flows/main_car/insuranceCarMileageParkingApplicationFlow'
import InsuranceEverydayCarMileageParkingApplicationFlow from './components/pages/insurance_quote/flows/everyday_car/insuranceEverydayCarMileageParkingApplicationFlow'
import InsuranceApplicationVehiclesToInsure from './components/pages/insurance_quote/flows/application/insuranceApplicationVehiclesToInsure.tsx'

import InsuranceApplicationSubmissionAgreement from './components/pages/insurance_quote/insuranceApplicationSubmissionAgreement'

import InstallIosNativeAppBanner from './components/templates/topBanners/installIosNativeAppBanner'
import OnboardingStep_1 from './components/pages/onboarding/new/onboarding_step_1'
import OnboardingStep_2_1 from './components/pages/onboarding/new/onboarding_step_2_1'
import OnboardingStep_2_2 from './components/pages/onboarding/new/onboarding_step_2_2'
import OnboardingStep_3 from './components/pages/onboarding/new/onboarding_step_3'
import OnboardingStep_4 from './components/pages/onboarding/new/onboarding_step_4'
import InsuranceApplicationNotInsuredEverydayCarDashboard from './components/pages/insurance_quote/dashboards/insuranceApplicationNonInsuredEverydayCarDashboard'
import InsuranceWelcome from './components/pages/insurance_quote/insuranceWelcomeWithCars'
import { WelcomeApplicationTest } from './components/pages/insurance_quote/test'
import InsuranceOverview from './components/pages/insurance_quote/overview'
import LoaderWithPercentage from './components/atoms/loader/loaderWithPercentage'
import InsuranceEligibilityConfirmation from './components/pages/insurance_quote/insuranceEligibilityConfirmation'
import { customNavDataActions } from './redux/localdata/customNav/reducer'
import CreateTimelineEntryByCategory from './components/pages/entries/createTimelineEntryByCategory'
import PlatformErrorManager from './components/templates/backEndError'
import { IReduxError } from 'entityModels'
import WizardPage from './components/pages/insurance_quote/wizard'
import RegisterNewLandingPage from './components/pages/register-new-landing-page'
import LoaderCarOutline from './components/atoms/loader/loaderCarOutline'
import CarJourney from './components/pages/journey/carProfile'
import SellYourCarJourney from './components/pages/journey/sellYourCar'
import AppleRedirectHandler from './components/pages/social-login/apple'
import CarInsights from './components/pages/car/insights'
import HistoryFileJourney from './components/pages/journey/historyFile'
import ShareYourCarJourney from './components/pages/journey/shareYourCar'
import UserProfileJourney from './components/pages/journey/userProfile'
import InsuranceJourney from './components/pages/journey/insurance'
import PastCarsJourney from './components/pages/journey/pastCars'
import HandoverJourney from './components/pages/journey/handover'
import JourneyTopBar from './components/templates/bars/journey/topBar'
import { ThemesContext } from './providers/theme/customThemeProvider'
import colours from './providers/theme/colours'
import OnboardingStep_0 from './components/pages/onboarding/new/onboarding_step_0'

function mapStateToProps(state: RootState) {
    return {
        user: state.user.userLoggedIn,
        userLoading: state.user.loading,
        isAuthLoading: state.user.isAuthLoading,
        featureFlags: state.featureFlags,
        insuranceDataLoading: state.insuranceQuoteApplication?.isLoading,
        getInsuranceDataLoading: state.insuranceQuoteApplication.isGetLoading,
        allInsuranceDataLoading:
            state.insuranceQuoteApplication.isAllApplicationsInfoLoading,
        userError: state.user.error,
    }
}
const dispatchProps = {
    // resetAllForms: () => resetAllForms(),
    getCurrentUserDataRequest: () => getCurrentUserDataRequest(),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
}

type OtherProps = {
    user: IUser | null
    userLoading: boolean
    isAuthLoading: boolean
    featureFlags: IFeatureFlagsReducer
    getCurrentUserDataRequest: () => any
    insuranceDataLoading: boolean
    getInsuranceDataLoading: boolean
    allInsuranceDataLoading: boolean | undefined
    setInitialLandingUrl: (str: string) => void
    userError: IReduxError | null
}

interface AppProps {
    history: History
    store: any
    // resetAllForms: () => void
}

class App extends React.Component<AppProps & OtherProps, {}> {
    // unlisten: any

    componentDidMount() {
        document.getElementById('loading-init')!.style.display = 'none'
        // this.unlisten = this.props.history.listen(() => {
        //     this.props.resetAllForms()
        // })

        let pathname = checkIfPwaStandalone()
            ? window.location.pathname
            : window.location.pathname

        //  this.props.history.location.pathname
        let q_params = checkIfPwaStandalone()
            ? window.location.search
            : window.location.search

        let is_insurance_related =
            pathname.match(/insurance/g) !== null ? true : false

        if (is_insurance_related) {
            this.props.setInitialLandingUrl(`${pathname}${q_params}`)

            if (
                this.props.user === null &&
                this.props.userError?.status_code !== 503
            ) {
                this.props.getCurrentUserDataRequest()
            }
        }

        let refresh_token: string | null =
            typeof localStorage !== 'undefined' && localStorage !== null
                ? localStorage.getItem('refresh_token')
                : null

        // console.log('refresh token', refresh_token)

        if (
            !is_insurance_related &&
            this.props.user === null &&
            refresh_token !== null &&
            this.props.userError?.status_code !== 503
        ) {
            this.props.getCurrentUserDataRequest()
        }
    }

    componentWillUnmount() {
        // this.unlisten()
    }

    render() {
        const { store, user, featureFlags } = this.props
        // let userid: string | boolean | null = user && user.id

        let pathname = checkIfPwaStandalone()
            ? window.location.pathname
            : window.location.pathname

        //  this.props.history.location.pathname
        // user role addition to that later
        let is_showroom_visible = featureFlags.enable_showroom
        let enable_insurance = featureFlags['enable_insurance']

        let is_dealer: boolean =
            user?.role === 'Admin' || user?.role === 'Dealer' ? true : false

        let params = new URLSearchParams(location.search)
        let carid: string | null = params.get('carid')

        // let is_admin: boolean = user?.role === 'Admin' ? true : false

        return (
            <ThemesContext.Consumer>
                {({ theme }) => (
                    <Sentry.ErrorBoundary fallback={undefined}>
                        <div
                            className="App"
                            style={{
                                backgroundColor:
                                    colours[theme].background_default,
                            }}
                        >
                            {/* <ConnectedRouter history={history}> */}
                            <InstallAndroidPropmt />
                            <PlatformErrorManager
                                isOnline={window.navigator.onLine}
                            />

                            {/* MAIN PAGES NAV HEADER MOBILE */}

                            <IpadAndMobileDisplay>
                                <MobileTopHeaderNav user={user} />
                                <DirectShareDeletionConfirmation />

                                <InstallIosNativeAppBanner />
                            </IpadAndMobileDisplay>

                            <React.Fragment>
                                <MainMenu user={user ? user : null} />
                            </React.Fragment>

                            {pathname.match(/insurance/g) !== null &&
                                pathname.match(/overview/g) === null &&
                                pathname.match(/register/g) === null && (
                                    <LoaderWithPercentage
                                        isLoading={
                                            this.props.insuranceDataLoading
                                                ? true
                                                : false
                                        }
                                    />
                                )}

                            {pathname.match(/insurance/g) !== null &&
                                pathname.match(/register/g) === null && (
                                    <LoaderCarOutline
                                        isOpen={
                                            this.props
                                                .getInsuranceDataLoading ||
                                            this.props.allInsuranceDataLoading
                                                ? true
                                                : false
                                        }
                                    />
                                )}

                            <JourneyTopBar
                                closeUrl={carid ? `/car/${carid}` : `/garage`}
                            />

                            {this.props.isAuthLoading && (
                                <LoaderCarOutline
                                    isOpen={
                                        this.props.isAuthLoading ? true : false
                                    }
                                />
                            )}

                            <AccountMenuMobile activepage={pathname} />
                            <ScrollToTop>
                                <Switch>
                                    <Route
                                        path="/register/landing/1"
                                        exact
                                        component={RegisterNewLandingPage}
                                    />
                                    <Route
                                        path="/register"
                                        exact
                                        component={Register}
                                    />
                                    <Route
                                        path="/social-login/apple"
                                        exact
                                        component={AppleRedirectHandler}
                                    />
                                    {/* deprecated route
                                    <Route
                                    path="/register/:invite_code"
                                    component={Register}
                                /> */}
                                    <Route path="/signin" component={Signin} />
                                    <Route
                                        path="/login"
                                        exact
                                        component={Signin}
                                    />
                                    <Route
                                        path="/"
                                        exact
                                        component={MyGarage}
                                    />
                                    <Route
                                        path="/history-files"
                                        exact
                                        component={GeneralTimeline}
                                    />
                                    <Route
                                        path="/reset-password-request"
                                        component={ResetPasswordRequest}
                                    />
                                    <Route
                                        path="/reset-password/:activationcode"
                                        component={ResetPasswordForm}
                                    />
                                    <Route
                                        path="/verify/:activationcode"
                                        component={VerifyPage}
                                    />
                                    <Route
                                        path="/error"
                                        component={ErrorPage}
                                    />
                                    {enable_insurance === true && (
                                        <PrivateRoute
                                            store={store}
                                            path="/insurance/application/:id/insurance_details"
                                            component={
                                                InsuranceDetailsApplicationFlow
                                            }
                                            exact
                                        />
                                    )}
                                    {enable_insurance === true && (
                                        <PrivateRoute
                                            store={store}
                                            path="/insurance/application/:id/wizard"
                                            component={WizardPage}
                                            exact
                                        />
                                    )}
                                    {enable_insurance === true && (
                                        <PrivateRoute
                                            store={store}
                                            path="/insurance"
                                            component={InsuranceWelcome}
                                            exact
                                        />
                                    )}
                                    {enable_insurance === true && (
                                        <PrivateRoute
                                            store={store}
                                            path="/insurance/confirm-eligibility"
                                            component={
                                                InsuranceEligibilityConfirmation
                                            }
                                            exact
                                        />
                                    )}
                                    {enable_insurance === true && (
                                        <PrivateRoute
                                            store={store}
                                            path="/insurance/new"
                                            component={InsuranceWelcome}
                                            exact
                                        />
                                    )}
                                    {enable_insurance === true && (
                                        <PrivateRoute
                                            store={store}
                                            path="/insurance/application/test"
                                            component={WelcomeApplicationTest}
                                            exact
                                        />
                                    )}
                                    {enable_insurance === true && (
                                        <PrivateRoute
                                            store={store}
                                            path="/insurance/overview"
                                            component={InsuranceOverview}
                                            exact
                                        />
                                    )}
                                    {enable_insurance === true && (
                                        <PrivateRoute
                                            store={store}
                                            path="/insurance/application/:id"
                                            component={
                                                InsuranceApplicationDashboard
                                            }
                                            exact
                                        />
                                    )}
                                    {enable_insurance === true && (
                                        <PrivateRoute
                                            store={store}
                                            path="/insurance/application/:id/car/:carid"
                                            component={
                                                InsuranceApplicationCarDashboard
                                            }
                                            exact
                                        />
                                    )}
                                    {enable_insurance === true && (
                                        <PrivateRoute
                                            store={store}
                                            path="/insurance/application/:id/main_driver/:userid"
                                            component={
                                                InsuranceApplicationMainDriverDashboard
                                            }
                                            exact
                                        />
                                    )}
                                    {enable_insurance === true && (
                                        <PrivateRoute
                                            store={store}
                                            path="/insurance/application/:id/other_driver/:userid"
                                            component={
                                                InsuranceApplicationOtherDriverDashboard
                                            }
                                            exact
                                        />
                                    )}
                                    {enable_insurance === true && (
                                        <PrivateRoute
                                            store={store}
                                            path="/insurance/application/:id/drivers"
                                            component={
                                                InsuranceApplicationDriversDashboard
                                            }
                                            exact
                                        />
                                    )}
                                    {enable_insurance === true && (
                                        <PrivateRoute
                                            store={store}
                                            path="/insurance/application/:id/vehicles"
                                            component={
                                                InsuranceApplicationVehiclesToInsure
                                            }
                                            exact
                                        />
                                    )}
                                    {enable_insurance === true && (
                                        <PrivateRoute
                                            store={store}
                                            path="/insurance/application/:id/other_driver/:userid/licence_address"
                                            component={
                                                InsuranceOtherDriverLicenceAddressApplicationFlow
                                            }
                                            exact
                                        />
                                    )}
                                    {enable_insurance === true && (
                                        <PrivateRoute
                                            store={store}
                                            path="/insurance/application/:id/other_driver/:userid/licence_address/address"
                                            component={
                                                InsuranceOtherDriverAddressManager
                                            }
                                        />
                                    )}
                                    {enable_insurance === true && (
                                        <PrivateRoute
                                            store={store}
                                            path="/insurance/application/:id/other_driver/:userid/additional_details"
                                            component={
                                                InsuranceOtherDriverAdditionalDetailsApplicationFlow
                                            }
                                            exact
                                        />
                                    )}
                                    {enable_insurance === true && (
                                        <PrivateRoute
                                            store={store}
                                            path="/insurance/application/:id/other_driver/:userid/basic_details"
                                            component={
                                                InsuranceOtherDriverBasicDetailsApplicationFlow
                                            }
                                            exact
                                        />
                                    )}
                                    {enable_insurance === true && (
                                        <PrivateRoute
                                            store={store}
                                            path="/insurance/application/:id/other_driver/:userid/track_record"
                                            component={
                                                InsuranceOtherDriverTrackRecordApplicationFlow
                                            }
                                            exact
                                        />
                                    )}
                                    {enable_insurance === true && (
                                        <PrivateRoute
                                            store={store}
                                            path="/insurance/application/:id/main_driver/:userid/licence_address"
                                            component={
                                                InsuranceMainDriverLicenceAddressApplicationFlow
                                            }
                                            exact
                                        />
                                    )}
                                    {enable_insurance === true && (
                                        <PrivateRoute
                                            store={store}
                                            path="/insurance/application/:id/main_driver/:userid/licence_address/address"
                                            component={
                                                InsuranceMainDriverAddressManager
                                            }
                                        />
                                    )}
                                    {enable_insurance === true && (
                                        <PrivateRoute
                                            store={store}
                                            path="/insurance/application/:id/main_driver/:userid/additional_details"
                                            component={
                                                InsuranceMainDriverAdditionalDetailsApplicationFlow
                                            }
                                            exact
                                        />
                                    )}
                                    {enable_insurance === true && (
                                        <PrivateRoute
                                            store={store}
                                            path="/insurance/application/:id/main_driver/:userid/basic_details"
                                            component={
                                                InsuranceMainDriverBasicDetailsApplicationFlow
                                            }
                                            exact
                                        />
                                    )}
                                    {enable_insurance === true && (
                                        <PrivateRoute
                                            store={store}
                                            path="/insurance/application/:id/main_driver/:userid/track_record"
                                            component={
                                                InsuranceMainDriverTrackRecordApplicationFlow
                                            }
                                            exact
                                        />
                                    )}
                                    {enable_insurance === true && (
                                        <PrivateRoute
                                            store={store}
                                            path="/insurance/application/:id/car/:carid/tech_info"
                                            component={
                                                InsuranceCarTechInfoApplicationFlow
                                            }
                                            exact
                                        />
                                    )}
                                    {enable_insurance === true && (
                                        <PrivateRoute
                                            store={store}
                                            path="/insurance/application/:id/addresses"
                                            component={
                                                InsuranceApplicationAddressesDashboard
                                            }
                                            exact
                                        />
                                    )}
                                    {enable_insurance === true && (
                                        <PrivateRoute
                                            store={store}
                                            path="/insurance/application/:id/car/:carid/ownership"
                                            component={
                                                InsuranceCarOwnershipApplicationFlow
                                            }
                                            exact
                                        />
                                    )}
                                    {enable_insurance === true && (
                                        <PrivateRoute
                                            store={store}
                                            path="/insurance/application/:id/everyday_car/:carid"
                                            component={
                                                InsuranceApplicationNotInsuredEverydayCarDashboard
                                            }
                                            exact
                                        />
                                    )}
                                    {enable_insurance === true && (
                                        <PrivateRoute
                                            store={store}
                                            path="/insurance/application/:id/everyday_car/:carid/tech_info"
                                            component={
                                                InsuranceEverydayCarTechInfoApplicationFlow
                                            }
                                            exact
                                        />
                                    )}
                                    {enable_insurance === true && (
                                        <PrivateRoute
                                            store={store}
                                            path="/insurance/application/:id/everyday_car/:carid/ownership"
                                            component={
                                                InsuranceEverydayCarOwnershipApplicationFlow
                                            }
                                            exact
                                        />
                                    )}
                                    {enable_insurance === true && (
                                        <PrivateRoute
                                            store={store}
                                            path="/insurance/application/:id/everyday_car/:carid/mileage_parking"
                                            component={
                                                InsuranceEverydayCarMileageParkingApplicationFlow
                                            }
                                            exact
                                        />
                                    )}
                                    {enable_insurance === true && (
                                        <PrivateRoute
                                            store={store}
                                            path="/insurance/application/:id/car/:carid/mileage_parking"
                                            component={
                                                InsuranceCarMileageParkingApplicationFlow
                                            }
                                            exact
                                        />
                                    )}
                                    {enable_insurance === true && (
                                        <PrivateRoute
                                            store={store}
                                            path="/insurance/application/:id/submission-agreement"
                                            component={
                                                InsuranceApplicationSubmissionAgreement
                                            }
                                            exact
                                        />
                                    )}
                                    <PrivateRoute
                                        store={store}
                                        path="/onboarding-step-0"
                                        component={OnboardingStep_0}
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/onboarding-step-1"
                                        component={OnboardingStep_1}
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/onboarding-step-2-1"
                                        component={OnboardingStep_2_1}
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/onboarding-step-2-2"
                                        component={OnboardingStep_2_2}
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/onboarding-step-3"
                                        component={OnboardingStep_3}
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/onboarding-step-4"
                                        component={OnboardingStep_4}
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/journey/car-profile"
                                        component={CarJourney}
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/journey/history-file"
                                        component={HistoryFileJourney}
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/journey/your-profile"
                                        component={UserProfileJourney}
                                        exact
                                    />

                                    <PrivateRoute
                                        store={store}
                                        path="/journey/sell-your-car"
                                        component={SellYourCarJourney}
                                        exact
                                    />

                                    <PrivateRoute
                                        store={store}
                                        path="/journey/handover"
                                        component={HandoverJourney}
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/journey/share-your-car"
                                        component={ShareYourCarJourney}
                                        exact
                                    />

                                    <PrivateRoute
                                        store={store}
                                        path="/journey/previously-owned"
                                        component={PastCarsJourney}
                                        exact
                                    />

                                    <PrivateRoute
                                        store={store}
                                        path="/journey/insurance"
                                        component={InsuranceJourney}
                                        exact
                                    />

                                    <PrivateRoute
                                        store={store}
                                        path="/handover/:carid"
                                        component={CarHandover}
                                        exact
                                    />
                                    <Route
                                        path="/account-deletion-confirmed"
                                        component={AccountDeletionConfirmed}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/invite-user-error"
                                        exact
                                        component={InviteShareError}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/car/:carid/search"
                                        exact
                                        component={CarSearch}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/car/:carid/confirm"
                                        exact
                                        component={ConfirmCarData}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/car/:carid/archive"
                                        exact
                                        component={ArchiveCarFlowMobile}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/car/:carid/gallery"
                                        component={Gallery}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/car/:carid/deletion"
                                        component={CarDeletionConfirmation}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/car/:carid/technical-information"
                                        exact
                                        component={TechnicalInformation}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/car/:carid/tasks/task"
                                        component={CarSingleTask}
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/car/:carid/insights"
                                        component={CarInsights}
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/car/:carid/tasks/:taskid/reminders"
                                        component={TaskReminders}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/car/:carid/sharing"
                                        component={CarSharing}
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/sharing/with-others/car"
                                        component={CarShares}
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/sharing/with-others/car/create-share"
                                        component={CarShareCreate}
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/sharing/with-others/car/:carid/edit-share"
                                        component={CarShareEdit}
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/sharing/with-others/car/:carid/edit-share/recipients"
                                        component={CarShareEditRecipients}
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/sharing/with-others"
                                        component={SharedWithOthers}
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/sharing/with-others/garage/create-share"
                                        component={GarageShareCreate}
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/sharing/with-others/garage/edit-share"
                                        component={GarageShareEdit}
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/sharing/with-others/garage/edit-share/recipients"
                                        component={GarageShareEditRecipients}
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/sharing/with-you"
                                        component={SharedWithYou}
                                        exact
                                    />
                                    {/*
                                    showroom route use the same component(smart), which will display the proper data regarding the different sub routes, from the child(dumb) components
                                */}
                                    {(is_dealer === true ||
                                        is_showroom_visible === true) && (
                                        <Route
                                            path="/showroom"
                                            component={Showroom}
                                            exact
                                        />
                                    )}
                                    {(is_dealer === true ||
                                        is_showroom_visible === true) && (
                                        <Route
                                            path="/showroom/for-sale"
                                            component={Showroom}
                                            exact
                                        />
                                    )}
                                    {(is_dealer === true ||
                                        is_showroom_visible === true) && (
                                        <Route
                                            path="/showroom/sold"
                                            component={ShowroomSoldListPage}
                                            exact
                                        />
                                    )}
                                    {(is_dealer === true ||
                                        is_showroom_visible === true) && (
                                        <PrivateRoute
                                            store={store}
                                            path="/showroom/watch-list"
                                            component={WatchlistPage}
                                            exact
                                        />
                                    )}
                                    {(is_dealer === true ||
                                        is_showroom_visible === true) && (
                                        <Route
                                            exact
                                            path="/showroom/filters"
                                            component={ShowroomFiltersMobile}
                                        />
                                    )}
                                    {(is_dealer === true ||
                                        is_showroom_visible === true) && (
                                        <Route
                                            exact
                                            path="/showroom/search"
                                            component={
                                                ShowroomSearchFiltersMobile
                                            }
                                        />
                                    )}
                                    <PrivateRoute
                                        store={store}
                                        path="/shared/garage"
                                        component={ExternalGarage}
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/shared/car/:carid"
                                        component={ExternalCarOverview}
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/shared/car/:carid/gallery"
                                        component={ExternalCarGallery}
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/shared/car/:carid/history-file"
                                        component={ExternalCarHistoryFile}
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/shared/car/:carid/history-file/entry"
                                        component={ExternalCarHistoryFileEntry}
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/shared/car/:carid/history-file/:entryid/gallery"
                                        component={
                                            ExternalCarSingleEntryGallery
                                        }
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/shared/car/:carid/technical-information"
                                        component={
                                            ExternalCarTechnicalInformation
                                        }
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/car/:carid/history-file"
                                        component={CarTimeline}
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/car/:carid/history-file/create"
                                        component={CreateTimelineEntry}
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/car/:carid/history-file/create/:categoryid"
                                        component={
                                            CreateTimelineEntryByCategory
                                        }
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/car/:carid/history-file/entry"
                                        component={CarTimelineSingleEntry}
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/car/:carid/history-file/:entryid/gallery"
                                        component={SingleEntryGallery}
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/car/:carid/history-file/entry/edit"
                                        component={EditTimelineEntry}
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/car/:carid/history-file/:entryid/cost/create"
                                        component={CreateCost}
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/car/:carid/history-file/:entryid/cost/edit"
                                        component={EditCost}
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/car/:carid/tasks"
                                        component={CarTasks}
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/car/:carid"
                                        exact
                                        component={CarOverview}
                                    />
                                    {is_dealer === true && (
                                        <PrivateRoute
                                            store={store}
                                            path="/car/:carid/showroom/create"
                                            exact
                                            component={
                                                PublishToShowroomMobileStep
                                            }
                                        />
                                    )}
                                    <PrivateRoute
                                        store={store}
                                        path="/garage/projects"
                                        exact
                                        component={GarageProjects}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/tasks"
                                        exact
                                        component={Tasks}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/tasks/task"
                                        exact
                                        component={SingleTask}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/tasks/:taskid/reminders"
                                        component={TaskReminders}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/garage/followers"
                                        exact
                                        component={GarageProjects}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/garage/curated-cars"
                                        exact
                                        component={GarageProjects}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/garage/country"
                                        exact
                                        component={CountryPicker}
                                    />
                                    <GetGaragePrivateRoute
                                        store={store}
                                        exact
                                        path="/garage"
                                        component={MyGarage}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        exact
                                        path="/garage/previously-owned"
                                        component={GarageArchive}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        exact
                                        path="/archives/car/:carid"
                                        component={CarOverviewArchive}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        exact
                                        path="/archives/car/:carid/overview"
                                        component={CarOverviewArchive}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        exact
                                        path="/archives/car/:carid/gallery"
                                        component={CarGalleryArchive}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        exact
                                        path="/archives/car/:carid/technical-information"
                                        component={
                                            CarTechnicalInformationArchive
                                        }
                                    />
                                    <PrivateRoute
                                        store={store}
                                        exact
                                        path="/archives/car/:carid/history-file"
                                        component={CarHistoryFileArchive}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        exact
                                        path="/archives/car/:carid/history-file/entry"
                                        component={CarHistoryFileEntryArchive}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        exact
                                        path="/archives/car/:carid/history-file/:entryid/gallery"
                                        component={
                                            CarHistoryFileEntryGalleryArchive
                                        }
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/phone-detail"
                                        component={PhoneDetail}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/enter-address"
                                        component={EnterAddress}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/account"
                                        component={AccountPage}
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/account/preferences"
                                        component={UserPreferencesPage}
                                        exact
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/account/preferences/timezone"
                                        component={TimezonePicker}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/account/help"
                                        component={Help}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/account/faq"
                                        component={Faq}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/account/tutorials"
                                        component={TutorialsHelp}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/account/contact"
                                        component={ContactHelp}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/account/install-app"
                                        component={InstallApp}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        path="/upcoming"
                                        component={Upcoming}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        exact
                                        path="/apex"
                                        component={ApexOverview}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        exact
                                        path="/apex/cars-of-the-week"
                                        component={ApexCarsOfTheWeek}
                                    />
                                    {/* <PrivateRoute
                                    store={store}
                                    exact
                                    path="/apex/news-highlights"
                                    component={ApexNewsHighlights}
                                /> */}
                                    <PrivateRoute
                                        store={store}
                                        exact
                                        path="/apex/interviews"
                                        component={ApexInterviewsOverview}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        exact
                                        path="/apex/interviews/:uid"
                                        component={ApexSingleInterview}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        exact
                                        path="/apex/articles/:uid"
                                        component={ApexSingleArticle}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        exact
                                        path="/apex/articles"
                                        component={ApexArticleOverviews}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        exact
                                        path="/apex/podcasts"
                                        component={ApexPodcasts}
                                    />
                                    <PrivateRoute
                                        store={store}
                                        exact
                                        path="/showroom/:entryid/enquiry"
                                        component={CarEnquiry}
                                    />
                                    {is_dealer === true && (
                                        <PrivateRoute
                                            store={store}
                                            exact
                                            path="/showroom/:entryid/edit"
                                            component={showroomCarProfileEdit}
                                        />
                                    )}
                                    {is_dealer === true && (
                                        <PrivateRoute
                                            store={store}
                                            exact
                                            path="/showroom/:entryid/edit/general"
                                            component={
                                                EditShowroomEntryBasicInfoMobile
                                            }
                                        />
                                    )}
                                    {is_dealer === true && (
                                        <PrivateRoute
                                            store={store}
                                            exact
                                            path="/showroom/:entryid/edit/gallery"
                                            component={CarGalleryShowroomEdit}
                                        />
                                    )}
                                    {is_dealer === true && (
                                        <PrivateRoute
                                            store={store}
                                            exact
                                            path="/showroom/:entryid/edit/description-image"
                                            component={
                                                DescriptionImageShowroomEdit
                                            }
                                        />
                                    )}
                                    {is_showroom_visible === true ||
                                    is_dealer === true ? (
                                        <Route
                                            path="/showroom/:entryid"
                                            exact
                                            component={showroomCarProfile}
                                        />
                                    ) : null}
                                    {is_showroom_visible === true ||
                                    is_dealer === true ? (
                                        <Route
                                            path="/showroom/:entryid/gallery"
                                            exact
                                            component={ShowroomCarGallery}
                                        />
                                    ) : null}
                                    {is_showroom_visible === true ||
                                    is_dealer === true ? (
                                        <Route
                                            path="/showroom/:entryid/description"
                                            exact
                                            component={ShowroomCarDescription}
                                        />
                                    ) : null}
                                    <Route path="*" component={NotFoundPage} />
                                </Switch>
                            </ScrollToTop>
                            {/* </ConnectedRouter> */}
                        </div>
                    </Sentry.ErrorBoundary>
                )}
            </ThemesContext.Consumer>
        )
    }
}

export default connect(mapStateToProps, dispatchProps)(App)
