import { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import {
    IAnyErrorString,
    IAnyObject,
    IInsuranceQuoteApplication,
    insuranceActions,
} from '../../../../../redux/insuranceQuoteApplication/reducer'
import { insurance_fields_other_driver_licence_address } from '../../../../../redux/localdata/insuranceLocalData/fieldTypes'
import { menuActions } from '../../../../../redux/menus/reducer'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../redux/store/hooks'
import InsuranceQuoteFormGenerator from '../../../../organisms/insuranceQuote/formGenerators'
import InsuranceGoBackReusableBottomBar from '../../../../templates/bars/insurance/bottomBars/insureanceGoBackReusableBottomBar'
import InsuranceTopBarWithProgressionRate from '../../../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRate'
import IpadAndMobileDisplay from '../../../../templates/displays/ipadAndMobileDisplay'
import InsuranceTopBarWithProgressionRateMobile from '../../../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRateMobile'
import DesktopDisplayOnly from '../../../../templates/displays/desktopDisplayOnly'
import { useSetInitialInsurance } from '../../../../../providers/insurance/insuranceInitialProvider'
import { IRootState } from '../../../../../redux/store'
// import {
//     isInsuranceApplicationDateValid,
//     IsInsuranceApplicationDateValid_result,
// } from '../../../../../redux/localdata/insuranceLocalData/datesValidation'
import colours from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'
import { IDropdownItem } from 'entityModels'
import PortalInvisibleWrapper from '../../../../templates/displays/pageWrappers/portalInvisibleWrapper'
import RemoveMobileSearchAnchor from '../../../../templates/insurance/removeMobileSearchAnchor'
import InsuranceApplicationMobileSearch from '../search/mobileSearch'
import { dropDownActions } from '../../../../../redux/localdata/dropdownData/reducer'

export type UserParams = {
    userid: string
}

const InsuranceOtherDriverLicenceAddressApplicationFlow = () => {
    const history = useHistory()
    const dispatch = useAppDispatch()

    const { userid } = useParams<UserParams>()

    const data: IAnyObject = useAppSelector((state) => {
        return (
            state.insuranceQuoteApplication.draft.other_drivers[userid]
                ?.licence_address ?? {}
        )
    })

    const data_submitted: IAnyObject = useAppSelector((state) => {
        return (
            state.insuranceQuoteApplication.submitted_data.other_drivers[userid]
                ?.licence_address ?? {}
        )
    })

    const errors: IAnyErrorString | undefined = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft.other_drivers[userid]
            ?.licence_address_errors
    })

    const [isSavedDisabled, setIsSavedDisabled] = useState(false)
    const [hasFormChanged, setHasFormChanged] = useState(false)

    let onAnswerChange = (id: string, answer: any) => {
        dispatch(
            insuranceActions.set_answer_other_driver_licenseAddress({
                id: id,
                answer: answer,
                userid: userid,
            })
        )
        if (isSavedDisabled) {
            setIsSavedDisabled(false)
        }
        if (hasFormChanged === false) {
            setHasFormChanged(true)
        }
    }

    let setError = (id: string, error: string | undefined) => {
        dispatch(
            insuranceActions.set_error_insurance_other_driver_licenseAddress({
                id: id,
                error: error,
                userid: userid,
            })
        )
    }

    // const hasErrorFunc = () => {
    //     let hasErr: boolean = false

    //     if (errors) {
    //         for (const [_, value] of Object.entries(errors)) {
    //             if (value !== undefined) {
    //                 hasErr = true
    //             }
    //         }
    //     }

    //     return hasErr
    // }

    // let hasError = hasErrorFunc()

    let {
        getUserAndApplicationData,
        setInitialInsurance_Drivers_License_dropdowns,
    } = useSetInitialInsurance()

    const validateOnClick = (): boolean => {
        let hasErr2 = false

        for (
            let i = 0;
            i < insurance_fields_other_driver_licence_address.length;
            i++
        ) {
            let current_field_type =
                insurance_fields_other_driver_licence_address[i]

            if (current_field_type.is_required === true) {
                if (data[current_field_type.id] === undefined) {
                    setError(current_field_type.id, 'Required field.')

                    hasErr2 = true
                }
            }

            if (
                current_field_type.validation &&
                data[current_field_type.id] !== undefined
            ) {
                let is_valid_func = current_field_type.validation
                let value = data[current_field_type.id]
                let error_txt = current_field_type.error_txt

                if (is_valid_func && is_valid_func(value) !== true) {
                    setError(current_field_type.id, error_txt ?? 'Invalid')
                    hasErr2 = true
                }
            }
        }

        // if (data && data['residency_date']) {
        //     let res: IsInsuranceApplicationDateValid_result =
        //         isInsuranceApplicationDateValid({
        //             fieldID: 'residency_date',
        //             application_edited: applicationData,
        //             section: 'license',
        //             other_driver_id: userid,
        //             valueToCheck: data['residency_date'],
        //             value_of_dependable_field:
        //                 data['is_uk_resident_since_birth'],
        //             expected_value_of_dependable_field_for_validation: false,
        //         })
        //     if (res.isValid === false) {
        //         hasErr2 = true
        //         setError('residency_date', res.error_txt ?? 'Invalid')
        //     }
        // }

        if (data && data['license_date'] && errors && errors[`license_date`]) {
            hasErr2 = true
        }

        if (
            data &&
            data['residency_date'] &&
            errors &&
            errors[`residency_date`]
        ) {
            hasErr2 = true
        }

        return hasErr2
    }

    const { id } = useParams<{ id?: string }>()

    useEffect(() => {
        dispatch(menuActions.manageTopSubmenu({ isRemoved: true }))
        // below only needed after application process?
        // return () => {
        //     dispatch(menuActions.manageTopSubmenu({ isRemoved: false }))
        // }
    }, [])

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    useEffect(() => {
        id && getUserAndApplicationData(id)
        setInitialInsurance_Drivers_License_dropdowns()
    }, [userLoggedIn])

    let countriesList: IDropdownItem[] | null = useAppSelector((state) => {
        return state.localdata.dropdownData.countriesCode
    })

    useEffect(() => {
        if (userLoggedIn && !countriesList) {
            dispatch(dropDownActions.setCountriesCodeDropdownDataRequest())
        }
    }, [userLoggedIn])

    let applicationData: IInsuranceQuoteApplication = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.submitted_data
    )

    const saveForm = () => {
        let hasErr2 = validateOnClick()
        if (!hasErr2) {
            dispatch(
                insuranceActions.submit_otherDriver_licenceAddress_request({
                    driver_id: userid,
                })
            )
            history.push(`/insurance/application/${id}/other_driver/${userid}`)
        } else {
            setIsSavedDisabled(true)
        }
    }

    const { theme } = useThemes()
    const { search } = useLocation()
    let queryParams = new URLSearchParams(search)
    let field_id = queryParams.get('field_id')

    return userLoggedIn && applicationData?.id ? (
        <div
            style={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                backgroundColor: colours[theme].background_neutral_subtle,
            }}
        >
            <DesktopDisplayOnly>
                <InsuranceTopBarWithProgressionRate
                    breadCrumbs={[
                        {
                            pageName: 'Named driver',
                            pageUrl: () =>
                                history.push(
                                    `/insurance/application/${id}/other_driver/${userid}`
                                ),
                        },
                        { pageName: ' Drivers license' },
                    ]}
                    onSave={saveForm}
                    hasFormChanged={hasFormChanged}
                    sectionId="drivers"
                />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        paddingTop: 120,
                        paddingBottom: 120,
                    }}
                >
                    <div
                        style={{
                            minWidth: '448px',
                        }}
                    >
                        <InsuranceQuoteFormGenerator
                            sectionID="drivers"
                            list={insurance_fields_other_driver_licence_address}
                            onAnswerChange={onAnswerChange}
                            data={data}
                            errors={errors}
                            setError={setError}
                            mobileSearchPickerSectionURL={`/insurance/application/${id}/other_driver/${userid}/licence_address`}
                            driverid={userid}
                        />
                    </div>
                    <div style={{ paddingTop: '200px' }} />
                </div>

                <InsuranceGoBackReusableBottomBar
                    saveBtnTxt="Save & Continue"
                    isSaveDisabled={isSavedDisabled ? true : false}
                    onSave={saveForm}
                    hasFormChanged={hasFormChanged}
                    goBack={() =>
                        history.push(
                            `/insurance/application/${id}/other_driver/${userid}`
                        )
                    }
                    sectionId="drivers"
                />
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <RemoveMobileSearchAnchor />
                {field_id ? (
                    <PortalInvisibleWrapper>
                        <InsuranceApplicationMobileSearch
                            sectionId="drivers"
                            urlBack={`/insurance/application/${applicationData?.id}/other_driver/${userid}/licence_address`}
                            draft={data}
                            submitted={data_submitted}
                            onChange={(value: IDropdownItem) => {
                                if (field_id && errors && errors[field_id]) {
                                    setError(field_id, undefined)
                                }

                                userid &&
                                    dispatch(
                                        insuranceActions.set_answer_other_driver_licenseAddress(
                                            {
                                                id: `${field_id}`,
                                                answer: value,
                                                userid: userid,
                                            }
                                        )
                                    )
                            }}
                        />
                    </PortalInvisibleWrapper>
                ) : (
                    <>
                        <div style={{ width: '100vw' }}>
                            <InsuranceTopBarWithProgressionRateMobile
                                breadCrumbs={[
                                    {
                                        pageName: 'Named driver',
                                        pageUrl: () =>
                                            history.push(
                                                `/insurance/application/${id}/other_driver/${userid}`
                                            ),
                                    },
                                    { pageName: ' Drivers license' },
                                ]}
                                onSave={saveForm}
                                hasFormChanged={hasFormChanged}
                                sectionId="drivers"
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    width: '100vw',
                                    paddingTop: 120,
                                    paddingBottom: 120,
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                }}
                            >
                                <InsuranceQuoteFormGenerator
                                    sectionID="drivers"
                                    list={
                                        insurance_fields_other_driver_licence_address
                                    }
                                    onAnswerChange={onAnswerChange}
                                    data={data}
                                    errors={errors}
                                    setError={setError}
                                    mobileSearchPickerSectionURL={`/insurance/application/${id}/other_driver/${userid}/licence_address`}
                                    driverid={userid}
                                />

                                <div style={{ paddingTop: '200px' }} />
                            </div>
                            <InsuranceGoBackReusableBottomBar
                                isMobile
                                saveBtnTxt="Save & Continue"
                                isSaveDisabled={isSavedDisabled ? true : false}
                                onSave={saveForm}
                                hasFormChanged={hasFormChanged}
                                goBack={() =>
                                    history.push(
                                        `/insurance/application/${id}/other_driver/${userid}`
                                    )
                                }
                                sectionId="drivers"
                            />
                        </div>
                    </>
                )}
            </IpadAndMobileDisplay>
        </div>
    ) : (
        <div />
    )
}

export default InsuranceOtherDriverLicenceAddressApplicationFlow
