import { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import {
    IAnyErrorString,
    IAnyObject,
    IInsuranceQuoteApplication,
    insuranceActions,
} from '../../../../../redux/insuranceQuoteApplication/reducer'
import { insurance_fields_other_driver_basic_details } from '../../../../../redux/localdata/insuranceLocalData/fieldTypes'
import { menuActions } from '../../../../../redux/menus/reducer'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../redux/store/hooks'
import InsuranceQuoteFormGenerator from '../../../../organisms/insuranceQuote/formGenerators'
import InsuranceGoBackReusableBottomBar from '../../../../templates/bars/insurance/bottomBars/insureanceGoBackReusableBottomBar'
import InsuranceTopBarWithProgressionRate from '../../../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRate'
import IpadAndMobileDisplay from '../../../../templates/displays/ipadAndMobileDisplay'
import InsuranceTopBarWithProgressionRateMobile from '../../../../templates/bars/insurance/topBars/insuranceTopBarWithProgressionRateMobile'
import DesktopDisplayOnly from '../../../../templates/displays/desktopDisplayOnly'
import { useSetInitialInsurance } from '../../../../../providers/insurance/insuranceInitialProvider'
import { IRootState } from '../../../../../redux/store'
import PortalInvisibleWrapper from '../../../../templates/displays/pageWrappers/portalInvisibleWrapper'
import InsuranceApplicationMobileSearch from '../search/mobileSearch'
import { IDropdownItem } from 'entityModels'
import RemoveMobileSearchAnchor from '../../../../templates/insurance/removeMobileSearchAnchor'
import colours from '../../../../../providers/theme/colours'
import useThemes from '../../../../../providers/theme/hooks'

export type UserParams = {
    userid: string
}

const InsuranceOtherDriverBasicDetailsApplicationFlow = () => {
    const history = useHistory()
    const dispatch = useAppDispatch()

    const { userid } = useParams<UserParams>()

    const data: IAnyObject = useAppSelector((state) => {
        return (
            state.insuranceQuoteApplication.draft.other_drivers[userid]
                ?.basic_details ?? {}
        )
    })

    const errors: IAnyErrorString | undefined = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft.other_drivers[userid]
            ?.basic_details_errors
    })

    const [isSavedDisabled, setIsSavedDisabled] = useState(false)
    const [hasFormChanged, setHasFormChanged] = useState(false)

    let onAnswerChange = (id: string, answer: any) => {
        dispatch(
            insuranceActions.set_answer_other_driver_basicDetails({
                id: id,
                answer: answer,
                userid: userid,
            })
        )
        if (isSavedDisabled) {
            setIsSavedDisabled(false)
        }
        if (hasFormChanged === false) {
            setHasFormChanged(true)
        }
    }

    let setError = (id: string, error: string | undefined) => {
        dispatch(
            insuranceActions.set_error_insurance_other_driver_basic_details({
                id: id,
                error: error,
                userid: userid,
            })
        )
    }

    // const hasErrorFunc = () => {
    //     let hasErr: boolean = false

    //     if (errors) {
    //         for (const [_, value] of Object.entries(errors)) {
    //             if (value !== undefined) {
    //                 hasErr = true
    //             }
    //         }
    //     }

    //     return hasErr
    // }

    // let hasError = hasErrorFunc()

    const validateOnClick = (): boolean => {
        let hasErr2 = false
        for (
            let i = 0;
            i < insurance_fields_other_driver_basic_details.length;
            i++
        ) {
            if (
                insurance_fields_other_driver_basic_details[i].is_required ===
                true
            ) {
                if (
                    data[insurance_fields_other_driver_basic_details[i].id] ===
                    undefined
                ) {
                    setError(
                        insurance_fields_other_driver_basic_details[i].id,
                        'Required field.'
                    )

                    hasErr2 = true
                }
                if (
                    insurance_fields_other_driver_basic_details[i].validation &&
                    data[insurance_fields_other_driver_basic_details[i].id]
                ) {
                    let validation_func =
                        insurance_fields_other_driver_basic_details[i]
                            .validation
                    let value =
                        data[insurance_fields_other_driver_basic_details[i].id]
                    let error_txt =
                        insurance_fields_other_driver_basic_details[i].error_txt

                    if (
                        validation_func &&
                        value &&
                        validation_func(value) !== true
                    ) {
                        setError(
                            insurance_fields_other_driver_basic_details[i].id,
                            error_txt ?? 'Invalid'
                        )
                        hasErr2 = true
                    }
                }
            }

            if (
                insurance_fields_other_driver_basic_details[i].validation &&
                data[insurance_fields_other_driver_basic_details[i].id] !==
                    undefined
            ) {
                let is_valid_func =
                    insurance_fields_other_driver_basic_details[i].validation
                let value =
                    data[insurance_fields_other_driver_basic_details[i].id]
                let error_txt =
                    insurance_fields_other_driver_basic_details[i].error_txt

                if (is_valid_func && is_valid_func(value) !== true) {
                    setError(
                        insurance_fields_other_driver_basic_details[i].id,
                        error_txt ?? 'Invalid'
                    )
                    hasErr2 = true
                }
            }
        }

        return hasErr2
    }

    const { id } = useParams<{ id?: string }>()

    useEffect(() => {
        dispatch(menuActions.manageTopSubmenu({ isRemoved: true }))
        // below only needed after application process?
        // return () => {
        //     dispatch(menuActions.manageTopSubmenu({ isRemoved: false }))
        // }
    }, [])

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    let {
        getUserAndApplicationData,
        setInitialInsurance_Drivers_BasicDetails_dropdowns,
    } = useSetInitialInsurance()

    useEffect(() => {
        id && getUserAndApplicationData(id)
        setInitialInsurance_Drivers_BasicDetails_dropdowns()
    }, [userLoggedIn])

    let applicationData: IInsuranceQuoteApplication = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.submitted_data
    )

    const saveForm = () => {
        let hasErr2 = validateOnClick()
        if (!hasErr2) {
            dispatch(
                insuranceActions.submit_otherDriver_basicDetails_request({
                    driver_id: userid,
                })
            )
            history.push(`/insurance/application/${id}/other_driver/${userid}`)
        } else {
            setIsSavedDisabled(true)
        }
    }

    const { search } = useLocation()
    let queryParams = new URLSearchParams(search)
    let field_id = queryParams.get('field_id')

    const { theme } = useThemes()

    return userLoggedIn && applicationData?.id ? (
        <div
            style={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                backgroundColor: colours[theme].background_neutral_subtle,
            }}
        >
            <DesktopDisplayOnly>
                <InsuranceTopBarWithProgressionRate
                    breadCrumbs={[
                        {
                            pageName: 'Named driver',
                            pageUrl: () =>
                                history.push(
                                    `/insurance/application/${id}/other_driver/${userid}`
                                ),
                        },
                        { pageName: 'Basic details' },
                    ]}
                    onSave={saveForm}
                    hasFormChanged={hasFormChanged}
                    sectionId="drivers"
                />
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        paddingTop: 120,
                        paddingBottom: 120,
                    }}
                >
                    <div
                        style={{
                            minWidth: '448px',
                        }}
                    >
                        <InsuranceQuoteFormGenerator
                            driverid={userid}
                            sectionID="drivers"
                            list={insurance_fields_other_driver_basic_details}
                            onAnswerChange={onAnswerChange}
                            data={data}
                            errors={errors}
                            setError={setError}
                        />
                    </div>
                    <div style={{ paddingTop: '200px' }} />
                </div>

                <InsuranceGoBackReusableBottomBar
                    saveBtnTxt="Save & Continue"
                    isSaveDisabled={isSavedDisabled ? true : false}
                    onSave={saveForm}
                    hasFormChanged={hasFormChanged}
                    goBack={() =>
                        history.push(
                            `/insurance/application/${id}/other_driver/${userid}`
                        )
                    }
                    sectionId="drivers"
                />
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <RemoveMobileSearchAnchor />
                <div style={{ width: '100vw' }}>
                    {field_id ? (
                        <PortalInvisibleWrapper>
                            <InsuranceApplicationMobileSearch
                                sectionId="drivers"
                                urlBack={`/insurance/application/${id}/other_driver/${userid}/basic_details`}
                                draft={data}
                                submitted={applicationData}
                                onChange={(value: IDropdownItem) => {
                                    if (
                                        field_id &&
                                        errors &&
                                        errors[field_id]
                                    ) {
                                        setError(field_id, undefined)
                                    }

                                    dispatch(
                                        insuranceActions.set_answer_other_driver_basicDetails(
                                            {
                                                id:
                                                    field_id === 'titles'
                                                        ? `given_name-title`
                                                        : `${field_id}`,
                                                answer: value,
                                                userid: userid,
                                            }
                                        )
                                    )
                                }}
                            />
                        </PortalInvisibleWrapper>
                    ) : (
                        <>
                            <InsuranceTopBarWithProgressionRateMobile
                                breadCrumbs={[
                                    {
                                        pageName: 'Named driver',
                                        pageUrl: () =>
                                            history.push(
                                                `/insurance/application/${id}/other_driver/${userid}`
                                            ),
                                    },
                                    { pageName: 'Basic details' },
                                ]}
                                onSave={saveForm}
                                hasFormChanged={hasFormChanged}
                                sectionId="drivers"
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    width: '100%',
                                    paddingTop: 120,
                                    paddingBottom: 120,
                                    paddingLeft: 16,
                                    paddingRight: 16,
                                }}
                            >
                                <InsuranceQuoteFormGenerator
                                    driverid={userid}
                                    sectionID="drivers"
                                    list={
                                        insurance_fields_other_driver_basic_details
                                    }
                                    onAnswerChange={onAnswerChange}
                                    data={data}
                                    errors={errors}
                                    setError={setError}
                                    mobileSearchPickerSectionURL={`/insurance/application/${id}/other_driver/${userid}/basic_details`}
                                />

                                <div style={{ paddingTop: '200px' }} />
                            </div>
                            <InsuranceGoBackReusableBottomBar
                                isMobile
                                saveBtnTxt="Save & Continue"
                                isSaveDisabled={isSavedDisabled ? true : false}
                                onSave={saveForm}
                                hasFormChanged={hasFormChanged}
                                goBack={() =>
                                    history.push(
                                        `/insurance/application/${id}/other_driver/${userid}`
                                    )
                                }
                                sectionId="drivers"
                            />
                        </>
                    )}
                </div>
            </IpadAndMobileDisplay>
        </div>
    ) : (
        <div />
    )
}

export default InsuranceOtherDriverBasicDetailsApplicationFlow
