import { ICarsObject } from 'entityModels'
import VehicleItemDesktop from '../../../atoms/list/vehicleItemDesktop'
import EmptyVehicleItem from '../../../atoms/placeholders/insuranceEmptyVehicleItem'
// import InfoBox from '../../../atoms/info/infoBox'
import VehicleItemMobile from '../../../atoms/list/vehicleItemMobile'
import {
    IInsuranceQuoteApplication_OtherDriver,
    IInsuranceQuoteApplication_VehiclesObj,
    IOtherDriver_DriveList,
} from '../../../../redux/insuranceQuoteApplication/reducer'
import { InsuranceFormQuestion } from '../../../organisms/insuranceQuote/formGenerators/renderElementMobile'
import info_red from '../../../../public/assets/icons/info_red.svg'
import useThemes from '../../../../providers/theme/hooks'
import colours from '../../../../providers/theme/colours'

type Props = {
    carsData: ICarsObject
    vehicles: IInsuranceQuoteApplication_VehiclesObj
    vehicles_to_insure_list: string[]
    drive_list?: IOtherDriver_DriveList[]
    onCardClick: (id: string) => void
    isMobile?: boolean
    named_driver?: IInsuranceQuoteApplication_OtherDriver | undefined
    onSkeletonClick?: () => any
}
const InsuranceOtherDriverDriveListCardContent = (props: Props) => {
    let {
        carsData,
        vehicles,
        named_driver,
        vehicles_to_insure_list,
        drive_list,
        onCardClick,
        isMobile,
    } = props

    const { theme } = useThemes()

    return drive_list && drive_list.length > 0 ? (
        <div style={{ width: '100%', paddingTop: 16 }}>
            {named_driver &&
                named_driver.drive_list_errors &&
                named_driver.drive_list_errors.message && (
                    <div
                        style={{
                            backgroundColor:
                                'var(--error_08, rgba(223, 111, 111, 0.08)',
                            width: '100%',
                            padding: '14px',
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '20px',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: '20px',
                            marginTop: '10px',
                        }}
                    >
                        <img
                            src={info_red}
                            alt="info-red"
                            style={{ height: '12px' }}
                        />

                        <InsuranceFormQuestion
                            $theme={theme}
                            style={{
                                color: colours[theme].error,
                                fontSize: '14px',
                            }}
                        >
                            {named_driver.drive_list_errors.message}
                        </InsuranceFormQuestion>
                    </div>
                )}

            {drive_list.map((item: IOtherDriver_DriveList, i) => {
                let is_vehicle_insured = vehicles_to_insure_list.includes(
                    item.carid
                )
                    ? true
                    : false
                return (
                    <div
                        key={`${item.carid}_${i}_${
                            isMobile ? 'mobile' : 'desktop'
                        }`}
                        style={{
                            width: '100%',
                            paddingBottom: 8,
                            cursor: is_vehicle_insured ? 'pointer' : undefined,
                        }}
                        onClick={
                            is_vehicle_insured
                                ? () => onCardClick(item.carid)
                                : () => {}
                        }
                    >
                        {isMobile ? (
                            <VehicleItemMobile
                                car_id={item.carid}
                                insuranceVehicleItem={vehicles[item.carid]}
                                garageItem={carsData[item.carid]}
                                completion={
                                    vehicles[item.carid]?.completion ?? 0
                                }
                                isDisabled={is_vehicle_insured ? false : true}
                                sectionId="drivers"
                            />
                        ) : (
                            <VehicleItemDesktop
                                car_id={item.carid}
                                insuranceVehicleItem={vehicles[item.carid]}
                                garageItem={carsData[item.carid]}
                                completion={
                                    vehicles[item.carid]?.completion ?? 0
                                }
                                isDisabled={is_vehicle_insured ? false : true}
                                sectionId="drivers"
                            />
                        )}
                    </div>
                )
            })}
        </div>
    ) : (
        <div style={{ width: '100%', paddingTop: 10 }}>
            {named_driver &&
                named_driver.drive_list_errors &&
                named_driver.drive_list_errors.message && (
                    <div
                        style={{
                            backgroundColor:
                                'var(--error_08, rgba(223, 111, 111, 0.08)',
                            width: '100%',
                            padding: '14px',
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '20px',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            src={info_red}
                            alt="info-red"
                            style={{ height: '12px' }}
                        />

                        <InsuranceFormQuestion
                            $theme={theme}
                            style={{
                                color: colours[theme].error,
                                fontSize: '14px',
                            }}
                        >
                            {named_driver.drive_list_errors.message}
                        </InsuranceFormQuestion>
                    </div>
                )}

            <div style={{ width: '100%', paddingTop: 30 }}>
                {[1, 2, 3].map((i) => (
                    <div
                        key={`empty_box_${i}_${
                            isMobile ? 'mobile' : 'desktop'
                        }`}
                        onClick={() => {
                            if (props.onSkeletonClick) {
                                props.onSkeletonClick()
                            }
                        }}
                        style={{
                            width: '100%',
                            paddingBottom: 8,
                            opacity: 1 / i,
                            cursor: 'pointer',
                        }}
                    >
                        <EmptyVehicleItem />
                    </div>
                ))}
            </div>
        </div>
    )
}

export default InsuranceOtherDriverDriveListCardContent
