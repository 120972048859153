import './styles.css'
import styled from 'styled-components'
import { useState } from 'react'
import { IDropdownItem } from 'entityModels'
import MobileSelectBottomSheet from '../menu/bottomSheetMenu/mobileSelectBottomSheet'
import PickerBtnInsurance from '../Button/pickerBtnInsurance'
import useThemes from '../../../providers/theme/hooks'
import colours, { ITheme } from '../../../providers/theme/colours'

interface IInputFieldProps {
    name: string
    value?: string | undefined
    dropdownData: IDropdownItem[]
    placeholder?: string
    onSelect: (value: any) => any
    disabled?: boolean
    isnarrow?: boolean
    id?: string
    tabindex?: number | string
    isNarrow?: boolean
    fontSize?: string
    font_family?: string
    width?: string
    mobile_width?: string
    ipad_width?: string
    helperText?: string
    paddingLeft?: string
    linecolor?: string
    leftAdornment?: React.ReactNode
    customBsHeight?: string
    hasOptionalTag?: boolean
    placeholderStyle?: any
    valueStart?: string
    backgroundColor?: string
    sendDropdownItem?: boolean
    isValueLabel?: boolean
    capitalise?: boolean
    allowContentScroll?: boolean
    customActiveColour?: string
    customOptionHighlightColour?: string
    bg?: string
}

const Wrapper = styled.div``

const HelperText = styled.div<{ $theme: ITheme }>`
    color: ${(props) => colours[props.$theme].text_darker};
    font-size: 11px;
    padding-top: 8px;
    padding-left: 12px;
`

function InputFieldNoFormikAnimated({
    value,
    dropdownData,
    placeholder,
    width,
    helperText,
    onSelect,
    sendDropdownItem,
    isValueLabel,
    capitalise,
    disabled,
    allowContentScroll,
    customBsHeight,
    customActiveColour,
    bg,
}: IInputFieldProps) {
    const [isBottomSheetOpen, toggleBottomsheet] = useState<boolean>(false)
    const { theme } = useThemes()
    return (
        <Wrapper style={{ width: width ?? '100%' }}>
            <PickerBtnInsurance
                capitalise={capitalise}
                downArrow
                bg={bg ? bg : colours[theme].background_neutral_subtle}
                placeholder={placeholder}
                onClick={(e: any) => {
                    if (!disabled) {
                        toggleBottomsheet(true)
                    }
                }}
                value={value ? `${value}` : undefined}
                disabled={disabled}
                customActiveColour={customActiveColour}
            />

            {helperText && (
                <HelperText $theme={theme} style={{ width: 'max-content' }}>
                    {helperText}
                </HelperText>
            )}
            <MobileSelectBottomSheet
                disabled={disabled}
                sendDropdownItem={sendDropdownItem}
                currentOption={value}
                dropdownData={dropdownData}
                isOpen={isBottomSheetOpen}
                toggle={() => toggleBottomsheet(false)}
                onSelected={(value: string) => onSelect(value)}
                customHeight={customBsHeight ?? '50vh'}
                isValueLabel={isValueLabel}
                allowContentScroll={allowContentScroll}
                customActiveColour={customActiveColour}
            />
        </Wrapper>
    )
}

export default InputFieldNoFormikAnimated
