import { IDropdownItem } from 'entityModels'
import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useSetInitialInsurance } from '../../../../../../providers/insurance/insuranceInitialProvider'
import {
    IAnyErrorString,
    IAnyObject,
    IInsuranceQuoteApplication,
    insuranceActions,
} from '../../../../../../redux/insuranceQuoteApplication/reducer'
import { dropDownActions } from '../../../../../../redux/localdata/dropdownData/reducer'
import { fields_array_insurance_application_car_techinfo } from '../../../../../../redux/localdata/insuranceLocalData/fieldTypes'
import { IRootState } from '../../../../../../redux/store'
import {
    useAppDispatch,
    useAppSelector,
} from '../../../../../../redux/store/hooks'
import InsuranceApplicationMobileSearch from '../../../../../pages/insurance_quote/flows/search/mobileSearch'
import { ITopWizardCheckActions } from '../../../../../pages/insurance_quote/wizard'
import InsuranceGoBackReusableBottomBar from '../../../../../templates/bars/insurance/bottomBars/insureanceGoBackReusableBottomBar'
import DesktopDisplayOnly from '../../../../../templates/displays/desktopDisplayOnly'
import IpadAndMobileDisplay from '../../../../../templates/displays/ipadAndMobileDisplay'
import PortalInvisibleWrapper from '../../../../../templates/displays/pageWrappers/portalInvisibleWrapper'
import InsuranceQuoteFormGenerator from '../../../formGenerators'
import RemoveMobileSearchAnchor from '../../../../../templates/insurance/removeMobileSearchAnchor'
import colours from '../../../../../../providers/theme/colours'
import useThemes from '../../../../../../providers/theme/hooks'

type Props = {
    car_id: string | null
    setTopWizardCheckActions: (p: ITopWizardCheckActions | null) => any
}

const InsuredVehicleTechInfoWizardForm = (props: Props) => {
    let { car_id } = props

    const userLoggedIn = useAppSelector((state) => {
        return state.user.userLoggedIn
    })

    let {
        setInitialCarTechnicalDropdowns,
        setInitialInsurance_CarTechInfo,
        getInitialSectionWizardIds,
        getFirstIncompleteSubsection_Single_InsuredVehicle,
        setCrossFormData,
    } = useSetInitialInsurance()

    useEffect(() => {
        setInitialInsurance_CarTechInfo(`${car_id}`)
        setInitialCarTechnicalDropdowns(`${car_id}`)
    }, [userLoggedIn])

    let applicationData: IInsuranceQuoteApplication = useAppSelector(
        (state: IRootState) => state.insuranceQuoteApplication.submitted_data
    )

    const errors: IAnyErrorString | undefined = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft.vehicles[`${car_id}`]
            ?.tech_info_errors
    })

    const data_draft: IAnyObject = useAppSelector((state) => {
        return (
            (state.insuranceQuoteApplication.draft.vehicles[`${car_id}`] &&
                state.insuranceQuoteApplication.draft.vehicles[`${car_id}`]
                    .tech_info) ??
            {}
        )
    })

    const data_submitted: IAnyObject = useAppSelector((state) => {
        return (
            (state.insuranceQuoteApplication.submitted_data.vehicles[
                `${car_id}`
            ] &&
                state.insuranceQuoteApplication.submitted_data.vehicles[
                    `${car_id}`
                ].tech_info) ??
            {}
        )
    })

    const dispatch = useAppDispatch()

    const [isSavedDisabled, setIsSavedDisabled] = useState(false)
    const [hasFormChanged, setHasFormChanged] = useState(false)

    let setError = (id: string, error: string | undefined) => {
        dispatch(
            insuranceActions.set_error_insurance_car_techInfo({
                carid: `${car_id}`,
                id: id,
                error: error,
            })
        )
    }

    const validateOnClick = (): boolean => {
        let hasErr2 = false

        if (!data_draft) {
            hasErr2 = true
        }

        if (data_draft) {
            if (
                data_draft['has_vehicle_modifications'] === true ||
                data_draft['has_vehicle_modifications']?.name === 'Yes'
            ) {
                if (
                    !entitiesObjArr?.modifications ||
                    (entitiesObjArr?.modifications &&
                        entitiesObjArr?.modifications.length === 0)
                ) {
                    hasErr2 = true
                    setError(
                        'has_vehicle_modifications',
                        `Please fill in the modification(s) below.`
                    )
                }

                if (
                    entitiesObjArr?.modifications &&
                    entitiesObjArr?.modifications[0]
                ) {
                    const obj = entitiesObjArr?.modifications[0]
                    let entityKeys = Object.keys(obj)
                    if (obj[entityKeys[0]] === undefined) {
                        hasErr2 = true
                        setError(
                            'has_vehicle_modifications',
                            `Please fill in all the modification's fields.`
                        )
                    }
                }

                if (
                    // @ts-ignore
                    entitiesObjArr?.modifications
                ) {
                    // @ts-ignore
                    let arrayOfObjToCheck = [...entitiesObjArr?.modifications]

                    arrayOfObjToCheck.map((obj, i) => {
                        let objTocheck = { ...obj }
                        if (objTocheck[`sentence`] === undefined) {
                            delete objTocheck[`sentence`]
                        }
                        if (objTocheck[`driving_ban`] === undefined) {
                            delete objTocheck[`driving_ban`]
                        }

                        if (
                            Object.values(objTocheck).some(
                                (v) => v === undefined
                            )
                        ) {
                            hasErr2 = true
                            setError(
                                'has_vehicle_modifications',
                                `Please fill in all the modification's fields.`
                            )
                        }
                    })
                }
                if (data_draft['has_modification_adjusted_bhp'] === undefined) {
                    hasErr2 = true
                    setError('has_modification_adjusted_bhp', `Required.`)
                }

                if (
                    data_draft['has_modification_adjusted_bhp'] === true ||
                    data_draft['has_modification_adjusted_bhp']?.name === 'Yes'
                ) {
                    if (data_draft['modified_bhp'] === undefined) {
                        hasErr2 = true
                        setError('modified_bhp', `Required.`)
                    }
                }
            } else if (
                data_draft['has_vehicle_modifications'] === false ||
                data_draft['has_vehicle_modifications']?.name === 'No'
            ) {
                if (
                    data_draft['is_planned_on_getting_modified'] === undefined
                ) {
                    hasErr2 = true
                    setError('is_planned_on_getting_modified', `Required.`)
                }
            }

            for (
                let i = 0;
                i < fields_array_insurance_application_car_techinfo.length;
                i++
            ) {
                if (
                    fields_array_insurance_application_car_techinfo[i]
                        .is_required === true
                ) {
                    if (
                        data_draft[
                            fields_array_insurance_application_car_techinfo[i]
                                .id
                        ] === undefined ||
                        data_draft[
                            fields_array_insurance_application_car_techinfo[i]
                                .id
                        ] === '' ||
                        data_draft[
                            fields_array_insurance_application_car_techinfo[i]
                                .id
                        ] === null
                    ) {
                        setError(
                            fields_array_insurance_application_car_techinfo[i]
                                .id,
                            'Required field.'
                        )

                        hasErr2 = true
                    }
                }

                if (
                    fields_array_insurance_application_car_techinfo[i]
                        .validation &&
                    data_draft[
                        fields_array_insurance_application_car_techinfo[i].id
                    ] !== undefined
                ) {
                    let is_valid_func =
                        fields_array_insurance_application_car_techinfo[i]
                            .validation
                    let value =
                        data_draft[
                            fields_array_insurance_application_car_techinfo[i]
                                .id
                        ]
                    let error_txt =
                        fields_array_insurance_application_car_techinfo[i]
                            .error_txt

                    if (is_valid_func && is_valid_func(value) !== true) {
                        setError(
                            fields_array_insurance_application_car_techinfo[i]
                                .id,
                            error_txt ?? 'Invalid'
                        )
                        hasErr2 = true
                    }
                }
            }
        }

        return hasErr2
    }

    const entitiesObjArr = useAppSelector((state) => {
        return state.insuranceQuoteApplication.draft.vehicles[`${car_id}`] &&
            state.insuranceQuoteApplication.draft.vehicles[`${car_id}`].entities
            ? state.insuranceQuoteApplication.draft.vehicles[`${car_id}`]
                  .entities
            : undefined
    })

    const history = useHistory()

    const goPrevious = () => {
        history.push(
            `/insurance/application/${applicationData?.id}/wizard?section_id=vehicles_to_insure&subsection_id=is_everyday&car_id=${car_id}`
        )
    }

    const goNext = () => {
        let ids = getFirstIncompleteSubsection_Single_InsuredVehicle(
            `${car_id}`,
            'tech_info'
        )

        if (ids) {
            let currURL = new URL(window.location.href)
            let params2 = new URLSearchParams(currURL.search)
            params2.delete('section_id')
            params2.delete('car_id')
            params2.delete('driver_id')
            params2.delete('subsection_id')
            params2.append('section_id', `${ids.sectionID}`)
            params2.append('subsection_id', `${ids.sub_sectionID}`)
            if (ids.car_id) {
                params2.append('car_id', `${ids.car_id}`)
            }
            if (ids.driver_id) {
                params2.append('driver_id', `${ids.driver_id}`)
            }
            return history.replace({
                search: params2.toString(),
            })
        }

        let getToAny = () => {
            let ids2 = getInitialSectionWizardIds('vehicles_to_insure')

            if (ids2) {
                let currURL = new URL(window.location.href)
                let params2 = new URLSearchParams(currURL.search)
                params2.delete('section_id')
                params2.delete('car_id')
                params2.delete('driver_id')
                params2.delete('subsection_id')
                params2.append('section_id', `${ids2.sectionID}`)
                params2.append('subsection_id', `${ids2.sub_sectionID}`)
                if (ids2.car_id) {
                    params2.append('car_id', `${ids2.car_id}`)
                }
                if (ids2.driver_id) {
                    params2.append('driver_id', `${ids2.driver_id}`)
                }
                history.replace({
                    search: params2.toString(),
                })
            } else
                history.push(
                    `/insurance/application/${applicationData?.id}/wizard?section_id=vehicles_to_insure`
                )
        }

        return getToAny()
    }
    const saveForm = () => {
        let hasErr2 = validateOnClick()
        if (!hasErr2) {
            dispatch(
                insuranceActions.submit_carTechInfo_request({
                    carid: `${car_id}`,
                })
            )
            // next step
            setTimeout(() => {
                goNext()
            }, 100)

            // history.push(
            //     `/insurance/application/${
            //         applicationData?.id
            //     }/car/${`${car_id}`}`
            // )
        } else {
            setIsSavedDisabled(true)
        }
    }

    useEffect(() => {
        props.setTopWizardCheckActions({
            sectionID: 'vehicles_to_insure',
            onSave: () => saveForm(),
            has_changes: false,
        })
        return () => {
            setCrossFormData(undefined)
        }
    }, [])

    let isLoadingNewMakeOrModel = useAppSelector((state) => {
        return state.localdata.dropdownData.isLoadingNewMakeOrModel
    })

    const { search } = useLocation()
    let queryParams = new URLSearchParams(search)

    let field_id = queryParams.get('field_id')
    let entity = queryParams.get('entity')
    let entity_index = queryParams.get('entity_index')

    let onAnswerChange = (id: string, answer: any) => {
        dispatch(
            insuranceActions.set_answer_insurance_car_techInfo({
                carid: `${car_id}`,
                id: id,
                answer: answer,
            })
        )

        if (id === 'make') {
            dispatch(
                dropDownActions.setModelDropdownDataRequest({
                    makeName: answer?.name,
                    make_uid: answer?.uid,
                })
            )
            dispatch(dropDownActions.setTempMakeDisplay(answer?.name))
            dispatch(dropDownActions.setTempMakeIDSuccess(answer?.uid))
        }

        if (isSavedDisabled) {
            setIsSavedDisabled(false)
        }
        if (hasFormChanged === false) {
            setHasFormChanged(true)
            props.setTopWizardCheckActions({
                sectionID: 'vehicles_to_insure',
                onSave: () => saveForm(),
                has_changes: true,
            })
        }
    }
    const { theme } = useThemes()

    return userLoggedIn && applicationData?.id ? (
        <div
            style={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start',
                width: '100%',
                backgroundColor: colours[theme].background_neutral_subtle,
            }}
        >
            <DesktopDisplayOnly>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '566px',
                        paddingBottom: 120,
                    }}
                >
                    <InsuranceQuoteFormGenerator
                        sectionID="vehicles_to_insure"
                        carid={car_id ? car_id : undefined}
                        list={fields_array_insurance_application_car_techinfo}
                        onAnswerChange={onAnswerChange}
                        data={data_draft}
                        errors={errors}
                        setError={setError}
                        entitiesObjArr={entitiesObjArr}
                        setEntityArr={(
                            arr: IAnyObject[],
                            fieldID: string,
                            has_error?: {
                                entityTypeID: string
                                entityUID: string
                            }
                        ) => {
                            setHasFormChanged(true)

                            if (isSavedDisabled) {
                                setIsSavedDisabled(false)
                            }
                            setError('has_vehicle_modifications', undefined)

                            dispatch(
                                insuranceActions.set_entity_arr_car({
                                    carid: `${car_id}`,
                                    fieldID: fieldID,
                                    arr: arr,
                                    has_error,
                                })
                            )
                        }}
                    />
                    <div style={{ paddingTop: '200px' }} />
                </div>

                <InsuranceGoBackReusableBottomBar
                    saveBtnTxt="Save & Continue"
                    isSaveDisabled={isSavedDisabled ? true : false}
                    onSave={saveForm}
                    hasFormChanged={hasFormChanged}
                    goBack={() => {
                        goPrevious()
                        // previous step
                    }}
                    isSaveLoading={isLoadingNewMakeOrModel}
                    sectionId="vehicles_to_insure"
                />
            </DesktopDisplayOnly>
            <IpadAndMobileDisplay>
                <RemoveMobileSearchAnchor />
                {field_id ? (
                    <PortalInvisibleWrapper>
                        <InsuranceApplicationMobileSearch
                            sectionId="vehicles_to_insure"
                            urlBack={`/insurance/application/${applicationData?.id}/wizard?section_id=vehicles_to_insure&subsection_id=tech_info&car_id=${car_id}`}
                            carid={`${car_id}`}
                            draft={data_draft}
                            submitted={data_submitted}
                            onChange={(value: IDropdownItem) => {
                                if (field_id && errors && errors[field_id]) {
                                    setError(field_id, undefined)
                                }

                                if (entity !== null && entity_index !== null) {
                                    let roota: IAnyObject[] =
                                        entitiesObjArr &&
                                        // @ts-ignore
                                        entitiesObjArr[`${entity}`]
                                            ? [
                                                  // @ts-ignore
                                                  ...entitiesObjArr[
                                                      `${entity}`
                                                  ],
                                              ]
                                            : []

                                    roota[+entity_index] = {
                                        ...roota[+entity_index],
                                        [`${field_id}`]: value,
                                    }

                                    dispatch(
                                        insuranceActions.set_entity_arr_car({
                                            arr: [...roota],
                                            fieldID: entity,
                                            carid: `${car_id}`,
                                        })
                                    )
                                } else
                                    dispatch(
                                        insuranceActions.set_answer_insurance_car_techInfo(
                                            {
                                                id: `${field_id}`,
                                                answer: value,
                                                carid: `${car_id}`,
                                            }
                                        )
                                    )
                            }}
                        />
                    </PortalInvisibleWrapper>
                ) : (
                    <>
                        <div
                            style={{
                                minHeight: '100vh',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                width: '100%',
                                backgroundColor:
                                    colours[theme].background_neutral_subtle,
                                paddingTop: '20px',
                            }}
                        >
                            <InsuranceQuoteFormGenerator
                                sectionID="vehicles_to_insure"
                                carid={car_id ? car_id : undefined}
                                list={
                                    fields_array_insurance_application_car_techinfo
                                }
                                onAnswerChange={onAnswerChange}
                                data={data_draft}
                                errors={errors}
                                setError={setError}
                                entitiesObjArr={entitiesObjArr}
                                setEntityArr={(
                                    arr: IAnyObject[],
                                    fieldID: string,
                                    has_error?: {
                                        entityTypeID: string
                                        entityUID: string
                                    }
                                ) => {
                                    setHasFormChanged(true)
                                    setError(
                                        'has_vehicle_modifications',
                                        undefined
                                    )
                                    if (isSavedDisabled) {
                                        setIsSavedDisabled(false)
                                    }

                                    dispatch(
                                        insuranceActions.set_entity_arr_car({
                                            carid: `${car_id}`,
                                            fieldID: fieldID,
                                            arr: arr,
                                            has_error,
                                        })
                                    )
                                }}
                                mobileSearchPickerSectionURL={
                                    `/insurance/application/${applicationData?.id}/wizard?section_id=vehicles_to_insure&subsection_id=tech_info&car_id=${car_id}`
                                    // `/insurance/application/${applicationData?.id}/car/${car_id}/tech_info`
                                }
                            />
                            <div style={{ paddingTop: '200px' }} />
                        </div>
                        <InsuranceGoBackReusableBottomBar
                            isMobile
                            saveBtnTxt="Save & Continue"
                            isSaveDisabled={isSavedDisabled ? true : false}
                            onSave={saveForm}
                            hasFormChanged={hasFormChanged}
                            goBack={
                                () =>
                                    // replace with previous step
                                    goPrevious()
                                // history.goBack()
                            }
                            isSaveLoading={isLoadingNewMakeOrModel}
                            sectionId="vehicles_to_insure"
                        />
                    </>
                )}
            </IpadAndMobileDisplay>
        </div>
    ) : (
        <div />
    )
}

export default InsuredVehicleTechInfoWizardForm
