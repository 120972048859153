import React from 'react'
import { connect } from 'react-redux'
import { RootState } from 'typesafe-actions'
import { IUser, IUSerGeneralInfoUpdatePayload } from 'myModels'
// import Loader from "../atoms/loader/loader";
import Faded from '../../templates/animated/faded'

import SectionHeader from '../../atoms/header/section/sectionHeader'

import Loader from '../../atoms/loader/loader'
import CenteredPageWrapper from '../../templates/displays/pageWrappers/CenteredPageWrapper'
import DesktopDisplayOnly from '../../templates/displays/desktopDisplayOnly'

import { editFormsActions } from '../../../redux/editForms/reducer'

import IpadAndMobileDisplay from '../../templates/displays/ipadAndMobileDisplay'
import MobileSettingsSectionHeader from '../../atoms/header/section/mobileSettingsSection'

import ListItemMobile from '../../atoms/list/listItemMobile'

import styled from 'styled-components'
import PreferencesInlineFormDesktop from '../../organisms/editForms/preferences/preferencesInlineFormDesktop'
import PreferencesRadioFormMobile from '../../organisms/editForms/preferences/preferencesRadioFormMobile'

import ModalDisplay from '../../templates/displays/pageWrappers/modalDisplay'
import { IUserPreferencesDataLevel4Obj } from 'IUserPreferencesModels'
import InfoBarMobile from '../../atoms/info/infoBarMobile'
import { getGarageCarsDataRequest } from '../../../redux/entities/cars/actions/loadingActions'
import {
    getCurrentUserDataRequest,
    updateUserGeneralInfoRequest,
} from '../../../redux/user/actions/loadingActions'
import PreferencesInlineSearchSelectDesktop from '../../organisms/editForms/preferences/preferencesInlineSearchSelectDesktop'
import { IGarageObject, IKeyValue } from 'entityModels'
import PreferencesSearchSelectFormMobile from '../../organisms/editForms/preferences/preferencesSearchSelectMobile'
import { dropDownActions } from '../../../redux/localdata/dropdownData/reducer'
import { customNavDataActions } from '../../../redux/localdata/customNav/reducer'
import PreferencesSelectMobile from '../../organisms/editForms/preferences/preferencesSelectMobile'

const dispatchProps = {
    // GET DATA
    getUserDataRequest: () => getCurrentUserDataRequest(),
    getGarageCarsDataRequest: (id: string) => getGarageCarsDataRequest(id),
    // UPDATE
    updateUserGeneralInfoRequest: (data: IUSerGeneralInfoUpdatePayload) =>
        updateUserGeneralInfoRequest(data),

    toggleAccountExternalInfoEditForm: (item?: any) =>
        editFormsActions.toggleAccountExternalInfoEditForm(item),
    setTimeZoneDropdownData: () =>
        dropDownActions.setTimeZoneDropdownDataRequest(),
    setInitialLandingUrl: (str: string) =>
        customNavDataActions.setInitialLandingUrl(str),
    clearSelectedTimezone: () => dropDownActions.setSelectedTimezone(null),
}

function mapStateToProps(state: RootState) {
    return {
        userLoggedIn: state.user.userLoggedIn,
        userid: state.user.userLoggedIn && state.user.userLoggedIn.id,
        isAccountExternalInfoFormOpened:
            state.editForms.formsData.accountExternalInfoForm.isOpen,
        accountExternalInfoFormID:
            state.editForms.formsData.accountExternalInfoForm.item &&
            state.editForms.formsData.accountExternalInfoForm.item.id,
        garage_data: state.entities.garagesData.garages,
        garage_id:
            state.user.userLoggedIn &&
            state.user.userLoggedIn.owns_garage &&
            state.user.userLoggedIn.owns_garage?.uid,
        time_zone_dropdown_data: state.localdata.dropdownData.timeZonesData,
        time_zone_dropdown_list: state.localdata.dropdownData.timeZonesList,
        selectedTimezone: state.localdata.dropdownData.selectedTimezone,
    }
}

type State = {
    hasResetPasswordBeenClicked: boolean
}

interface Props {
    setInitialLandingUrl: (str: string) => void
    userLoggedIn: null | IUser
    isAccountExternalInfoFormOpened: boolean
    getUserDataRequest: () => void
    userid: string | null
    updateUserGeneralInfoRequest: (data: IUSerGeneralInfoUpdatePayload) => void
    toggleAccountExternalInfoEditForm: (item?: any) => void
    accountExternalInfoFormID: string | undefined
    garage_data: IGarageObject
    garage_id: string | null
    getGarageCarsDataRequest: (id: string) => void
    time_zone_dropdown_data: IKeyValue | null
    setTimeZoneDropdownData: () => void
    time_zone_dropdown_list: string[] | null
    selectedTimezone: string | null
    clearSelectedTimezone: () => void
}

const ColWrapperUnit = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
`

class UserPreferencesPage extends React.Component<Props, State> {
    state = {
        hasResetPasswordBeenClicked: false,
    }

    componentDidMount() {
        this.props.setInitialLandingUrl('/account/preferences')

        let userLoggedIn = this.props.userLoggedIn

        if (!userLoggedIn) {
            this.props.getUserDataRequest()
        }

        if (
            this.props.userLoggedIn &&
            this.props.userLoggedIn.owns_garage &&
            !this.props.garage_data[this.props.userLoggedIn.owns_garage.uid]
        ) {
            this.props.getGarageCarsDataRequest(
                this.props.userLoggedIn.owns_garage.uid
            )
        }

        if (
            !this.props.time_zone_dropdown_data ||
            this.props.time_zone_dropdown_data === null
        ) {
            this.props.setTimeZoneDropdownData()
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.userLoggedIn !== prevProps.userLoggedIn) {
            if (this.props.userLoggedIn) {
                if (
                    this.props.userLoggedIn.owns_garage &&
                    this.props.userLoggedIn.owns_garage.uid
                ) {
                    // garage stuff

                    this.props.getGarageCarsDataRequest(
                        this.props.userLoggedIn.owns_garage.uid
                    )
                }
            }
        }
    }

    render() {
        const {
            userLoggedIn,
            toggleAccountExternalInfoEditForm,
            isAccountExternalInfoFormOpened,
            accountExternalInfoFormID,
            updateUserGeneralInfoRequest,
            time_zone_dropdown_data,
            time_zone_dropdown_list,
            selectedTimezone,
            clearSelectedTimezone,
        } = this.props

        let nofCars =
            this.props.userLoggedIn &&
            this.props.userLoggedIn.owns_garage &&
            this.props.userLoggedIn.owns_garage.uid &&
            this.props.garage_data[this.props.userLoggedIn.owns_garage.uid] &&
            this.props.garage_data[this.props.userLoggedIn.owns_garage.uid].cars
                ? this.props.garage_data[
                      this.props.userLoggedIn.owns_garage.uid
                  ].cars.length
                : null

        let def_garage_pref: string | null = nofCars === null ? null : 'grid'

        // ids

        let fuel_unit_field_id: string | null =
            userLoggedIn &&
            userLoggedIn.preferences.data['units'] &&
            userLoggedIn.preferences.data['units'].data['fuel_unit'].field_id

        let distance_unit_field_id: string | null =
            userLoggedIn &&
            userLoggedIn.preferences.data['units'] &&
            userLoggedIn.preferences.data['units'].data['distance_unit']
                .field_id

        let currency_field_id: string | null =
            userLoggedIn &&
            userLoggedIn.preferences.data['units'] &&
            userLoggedIn.preferences.data['units'].data['default_currency']
                .field_id

        let garage_user_preferences_field_id: string | null =
            userLoggedIn &&
            userLoggedIn.preferences.data['garage'] &&
            userLoggedIn.preferences.data['garage'].data['default_garage_view']
                .field_id

        let time_zone_user_preferences_field_id: string | null =
            userLoggedIn &&
            userLoggedIn.preferences.data['notifications'] &&
            userLoggedIn.preferences.data['notifications'].data['time_zone']
                .field_id

        let notification_hour_user_preferences_field_id: string | null =
            userLoggedIn &&
            userLoggedIn.preferences.data['notifications'] &&
            userLoggedIn.preferences.data['notifications'].data[
                'preferred_notification_time'
            ] &&
            userLoggedIn.preferences.data['notifications'].data[
                'preferred_notification_time'
            ].field_id

        // lists

        let fuel_unit_user_preferences_list_desktop: string[] | null =
            userLoggedIn &&
            userLoggedIn.preferences.data['units'] &&
            userLoggedIn.preferences.data['units'].data['fuel_unit'].ids_list

        let distance_unit_user_preferences_list_desktop: string[] | null =
            userLoggedIn &&
            userLoggedIn.preferences.data['units'] &&
            userLoggedIn.preferences.data['units'].data['distance_unit']
                .ids_list

        let currency_user_preferences_list_desktop: string[] | null =
            userLoggedIn &&
            userLoggedIn.preferences.data['units'] &&
            userLoggedIn.preferences.data['units'].data['default_currency']
                .ids_list

        let garage_user_preferences_list_desktop: string[] | null =
            userLoggedIn &&
            userLoggedIn.preferences.data['garage'] &&
            userLoggedIn.preferences.data['garage'].data['default_garage_view']
                .ids_list

        let notification_hour_user_preference_list_desktop: string[] | null =
            userLoggedIn &&
            userLoggedIn.preferences.data['notifications'] &&
            userLoggedIn.preferences.data['notifications'].data[
                'preferred_notification_time'
            ] &&
            userLoggedIn.preferences.data['notifications'].data[
                'preferred_notification_time'
            ].ids_list

        // let time_zone_user_preference_list_desktop: string[] | null =
        //     userLoggedIn &&
        //     userLoggedIn.preferences.data['notifications'] &&
        //     userLoggedIn.preferences.data['notifications'].data['time_zone'] &&
        //     userLoggedIn.preferences.data['notifications'].data['time_zone']
        //         .ids_list

        // current choices

        let current_fuel_unit_id: string | null =
            userLoggedIn &&
            userLoggedIn.preferences.data['units'] &&
            userLoggedIn.preferences.data['units'].data['fuel_unit']
                .user_choice_id

        let current_distance_unit_id: string | null =
            userLoggedIn &&
            userLoggedIn.preferences.data['units'] &&
            userLoggedIn.preferences.data['units'].data['distance_unit']
                .user_choice_id

        let current_user_currency_id: string | null =
            userLoggedIn &&
            userLoggedIn.preferences.data['units'] &&
            userLoggedIn.preferences.data['units'].data['default_currency']
                .user_choice_id

        let current_garage_preferences_id: string | null =
            userLoggedIn &&
            userLoggedIn.preferences.data['garage'] &&
            (userLoggedIn.preferences.data['garage'].data['default_garage_view']
                .user_choice_id === 'grid' ||
                userLoggedIn.preferences.data['garage'].data[
                    'default_garage_view'
                ].user_choice_id === 'list')
                ? userLoggedIn.preferences.data['garage'].data[
                      'default_garage_view'
                  ].user_choice_id
                : def_garage_pref !== null
                ? def_garage_pref
                : 'grid'

        let current_user_time_zone_id: string | null =
            userLoggedIn?.preferences?.data?.notifications?.data?.time_zone
                ?.user_choice_id ?? ''

        let current_user_notification_hour_id: string | null =
            userLoggedIn &&
            userLoggedIn.preferences.data['notifications'] &&
            userLoggedIn.preferences.data['notifications'].data[
                'preferred_notification_time'
            ] &&
            userLoggedIn.preferences.data['notifications'].data[
                'preferred_notification_time'
            ].user_choice_id

        let fuel_unit_user_preferences_data_desktop: IUserPreferencesDataLevel4Obj | null =
            userLoggedIn &&
            userLoggedIn.preferences.data['units'] &&
            userLoggedIn.preferences.data['units'].data['fuel_unit'].data

        let distance_unit_user_preferences_data_desktop: IUserPreferencesDataLevel4Obj | null =
            userLoggedIn &&
            userLoggedIn.preferences.data['units'] &&
            userLoggedIn.preferences.data['units'].data['distance_unit'].data

        let garage_user_preferences_data_desktop: IUserPreferencesDataLevel4Obj | null =
            userLoggedIn &&
            userLoggedIn.preferences.data['garage'] &&
            userLoggedIn.preferences.data['garage'].data['default_garage_view']
                .data

        let currency_user_preferences_data_desktop: IUserPreferencesDataLevel4Obj | null =
            userLoggedIn &&
            userLoggedIn.preferences.data['units'] &&
            userLoggedIn.preferences.data['units'].data['default_currency'].data

        let notification_hour_data_desktop: IUserPreferencesDataLevel4Obj | null =
            userLoggedIn &&
            userLoggedIn.preferences.data['notifications'] &&
            userLoggedIn.preferences.data['notifications'].data[
                'preferred_notification_time'
            ] &&
            userLoggedIn.preferences.data['notifications'].data[
                'preferred_notification_time'
            ].data

        let handleChangeTimeZone = (
            dataToSend: IUSerGeneralInfoUpdatePayload
        ) => {
            if (dataToSend.data?.user_preferences && time_zone_dropdown_data) {
                let match: string =
                    time_zone_dropdown_data[
                        dataToSend.data.user_preferences.time_zone
                            ? dataToSend.data.user_preferences.time_zone
                            : ''
                    ]
                dataToSend.data.user_preferences.time_zone = match

                updateUserGeneralInfoRequest(dataToSend)
            }

            clearSelectedTimezone()
        }

        return (
            <CenteredPageWrapper hasMobileSubNav>
                {userLoggedIn &&
                    currency_user_preferences_list_desktop &&
                    current_fuel_unit_id &&
                    distance_unit_user_preferences_data_desktop &&
                    distance_unit_user_preferences_list_desktop &&
                    currency_user_preferences_data_desktop &&
                    current_distance_unit_id &&
                    current_user_currency_id &&
                    fuel_unit_user_preferences_list_desktop &&
                    garage_user_preferences_data_desktop &&
                    current_garage_preferences_id &&
                    garage_user_preferences_field_id &&
                    garage_user_preferences_list_desktop &&
                    fuel_unit_user_preferences_data_desktop &&
                    fuel_unit_field_id &&
                    currency_field_id &&
                    distance_unit_field_id &&
                    time_zone_dropdown_data &&
                    time_zone_dropdown_list && (
                        <Faded>
                            <Loader />
                            <DesktopDisplayOnly>
                                <div
                                    style={{
                                        paddingLeft: '50px',
                                        paddingRight: '50px',
                                    }}
                                >
                                    <SectionHeader
                                        title={'Notifications'}
                                        removeEdit={true}
                                    />

                                    <PreferencesInlineSearchSelectDesktop
                                        userid={userLoggedIn.id}
                                        title="Time-zone"
                                        field_id={
                                            time_zone_user_preferences_field_id
                                        }
                                        item={{
                                            id: current_user_time_zone_id
                                                ? current_user_time_zone_id
                                                : '',
                                            text: current_user_time_zone_id
                                                ? current_user_time_zone_id
                                                : '',
                                            answer: current_user_time_zone_id
                                                ? current_user_time_zone_id
                                                : undefined,
                                        }}
                                        helperText={
                                            'Please type your region (ex: Europe) to search'
                                        }
                                        submitFunc={handleChangeTimeZone}
                                        isKeyValue={true}
                                        optionList={time_zone_dropdown_list}
                                    />
                                    <div style={{ marginTop: '20px' }} />

                                    <PreferencesInlineSearchSelectDesktop
                                        userid={userLoggedIn.id}
                                        title="Preferred hour"
                                        field_id={
                                            notification_hour_user_preferences_field_id
                                        }
                                        item={
                                            notification_hour_data_desktop &&
                                            current_user_notification_hour_id
                                                ? notification_hour_data_desktop[
                                                      current_user_notification_hour_id
                                                  ]
                                                : null
                                        }
                                        ids_list={
                                            notification_hour_user_preference_list_desktop
                                        }
                                        data={notification_hour_data_desktop}
                                        submitFunc={
                                            updateUserGeneralInfoRequest
                                        }
                                    />
                                    <SectionHeader
                                        title={'Units'}
                                        removeEdit={true}
                                    />

                                    <ColWrapperUnit>
                                        <PreferencesInlineFormDesktop
                                            title="Distance units"
                                            item={
                                                distance_unit_user_preferences_data_desktop[
                                                    current_distance_unit_id
                                                ]
                                            }
                                            ids_list={
                                                distance_unit_user_preferences_list_desktop
                                            }
                                            data={
                                                distance_unit_user_preferences_data_desktop
                                            }
                                            userid={userLoggedIn.id}
                                            field_id={distance_unit_field_id}
                                            submitFunc={
                                                updateUserGeneralInfoRequest
                                            }
                                        />
                                        <div style={{ marginTop: '20px' }} />

                                        <PreferencesInlineFormDesktop
                                            userid={userLoggedIn.id}
                                            title="Capacity units"
                                            field_id={fuel_unit_field_id}
                                            item={
                                                fuel_unit_user_preferences_data_desktop[
                                                    current_fuel_unit_id
                                                ]
                                            }
                                            ids_list={
                                                fuel_unit_user_preferences_list_desktop
                                            }
                                            data={
                                                fuel_unit_user_preferences_data_desktop
                                            }
                                            submitFunc={
                                                updateUserGeneralInfoRequest
                                            }
                                        />
                                        <div style={{ marginTop: '20px' }} />

                                        <PreferencesInlineFormDesktop
                                            userid={userLoggedIn.id}
                                            title="Currency"
                                            field_id={currency_field_id}
                                            item={
                                                currency_user_preferences_data_desktop[
                                                    current_user_currency_id
                                                ]
                                            }
                                            ids_list={
                                                currency_user_preferences_list_desktop
                                            }
                                            data={
                                                currency_user_preferences_data_desktop
                                            }
                                            submitFunc={
                                                updateUserGeneralInfoRequest
                                            }
                                        />
                                    </ColWrapperUnit>
                                    <div style={{ marginTop: '10px' }} />
                                    <SectionHeader
                                        title={'Garage'}
                                        removeEdit={true}
                                    />

                                    <PreferencesInlineFormDesktop
                                        userid={userLoggedIn.id}
                                        title="Default view"
                                        field_id={
                                            garage_user_preferences_field_id
                                        }
                                        item={
                                            garage_user_preferences_data_desktop[
                                                current_garage_preferences_id
                                            ]
                                        }
                                        ids_list={
                                            garage_user_preferences_list_desktop
                                        }
                                        data={
                                            garage_user_preferences_data_desktop
                                        }
                                        submitFunc={
                                            updateUserGeneralInfoRequest
                                        }
                                    />

                                    <div style={{ marginTop: '10px' }} />
                                </div>
                            </DesktopDisplayOnly>

                            <IpadAndMobileDisplay>
                                <InfoBarMobile>
                                    Please tap the field you wish to edit
                                </InfoBarMobile>
                                <MobileSettingsSectionHeader title="Notifications" />

                                <div style={{ marginTop: '20px' }}>
                                    <ListItemMobile
                                        item={{
                                            id: time_zone_user_preferences_field_id,
                                            text: 'Time-zone*',
                                            answer: current_user_time_zone_id
                                                ? current_user_time_zone_id
                                                : undefined,
                                        }}
                                        toggle={() =>
                                            toggleAccountExternalInfoEditForm({
                                                id: time_zone_user_preferences_field_id,
                                                text: '',
                                                answer: '',
                                            })
                                        }
                                        height="80px"
                                        fontStyle="Lato"
                                    />
                                    <div style={{ height: '10px' }} />
                                    <ListItemMobile
                                        item={{
                                            id: notification_hour_user_preferences_field_id,
                                            text: 'Notification hour*',
                                            answer:
                                                notification_hour_data_desktop &&
                                                current_user_notification_hour_id &&
                                                notification_hour_data_desktop[
                                                    current_user_notification_hour_id
                                                ].text,
                                        }}
                                        toggle={() =>
                                            toggleAccountExternalInfoEditForm({
                                                id: notification_hour_user_preferences_field_id,
                                                text: '',
                                                answer: '',
                                            })
                                        }
                                        height="80px"
                                        fontStyle="Lato"
                                    />
                                </div>
                                <MobileSettingsSectionHeader title="units" />

                                <div style={{ marginTop: '20px' }}>
                                    <ListItemMobile
                                        item={{
                                            id: fuel_unit_field_id,
                                            text: 'Capacity units*',
                                            answer: fuel_unit_user_preferences_data_desktop[
                                                current_fuel_unit_id
                                            ].text,
                                        }}
                                        toggle={() =>
                                            toggleAccountExternalInfoEditForm({
                                                id: fuel_unit_field_id,
                                                text: '',
                                                answer: '',
                                            })
                                        }
                                        height="80px"
                                        fontStyle="Lato"
                                    />
                                </div>
                                <div style={{ height: '10px' }} />
                                <ListItemMobile
                                    item={{
                                        id: distance_unit_field_id,
                                        text: 'Distance units*',
                                        answer: distance_unit_user_preferences_data_desktop[
                                            current_distance_unit_id
                                        ].text,
                                    }}
                                    minHeight="80px"
                                    toggle={() =>
                                        toggleAccountExternalInfoEditForm({
                                            id: distance_unit_field_id,
                                            text: '',
                                            answer: '',
                                        })
                                    }
                                    fontStyle="Lato"
                                />

                                <div style={{ height: '10px' }} />
                                <ListItemMobile
                                    item={{
                                        id: currency_field_id,
                                        text: 'Currency*',
                                        answer: currency_user_preferences_data_desktop[
                                            current_user_currency_id
                                        ].text,
                                    }}
                                    minHeight="80px"
                                    toggle={() =>
                                        toggleAccountExternalInfoEditForm({
                                            id: currency_field_id,
                                            text: '',
                                            answer: '',
                                        })
                                    }
                                    fontStyle="Lato"
                                />

                                <MobileSettingsSectionHeader title="garage" />

                                <div style={{ marginTop: '20px' }}>
                                    <ListItemMobile
                                        item={{
                                            id: garage_user_preferences_field_id,
                                            text: 'Default view*',
                                            answer: garage_user_preferences_data_desktop[
                                                current_garage_preferences_id
                                            ].text,
                                        }}
                                        toggle={() =>
                                            toggleAccountExternalInfoEditForm({
                                                id: 'garage_default_view',
                                                text: 'Grid',
                                                answer: 'grid',
                                            })
                                        }
                                        height="80px"
                                        fontStyle="Lato"
                                    />
                                </div>

                                {/* Notifications data to be adapted*/}

                                <div style={{ height: '80px' }} />

                                {isAccountExternalInfoFormOpened &&
                                    accountExternalInfoFormID &&
                                    accountExternalInfoFormID ===
                                        'fuel_unit' && (
                                        <ModalDisplay
                                            toggle={
                                                toggleAccountExternalInfoEditForm
                                            }
                                            isOpen={true}
                                        >
                                            <PreferencesRadioFormMobile
                                                title="Capacity Units"
                                                item={
                                                    fuel_unit_user_preferences_data_desktop[
                                                        current_fuel_unit_id
                                                    ]
                                                }
                                                ids_list={
                                                    fuel_unit_user_preferences_list_desktop
                                                }
                                                data={
                                                    fuel_unit_user_preferences_data_desktop
                                                }
                                                toggle={() => {
                                                    toggleAccountExternalInfoEditForm()
                                                }}
                                                handleSubmit={
                                                    updateUserGeneralInfoRequest
                                                }
                                                userid={userLoggedIn.id}
                                                field_id={fuel_unit_field_id}
                                            />
                                        </ModalDisplay>
                                    )}

                                {isAccountExternalInfoFormOpened &&
                                    accountExternalInfoFormID &&
                                    accountExternalInfoFormID ===
                                        'garage_default_view' && (
                                        <ModalDisplay
                                            toggle={
                                                toggleAccountExternalInfoEditForm
                                            }
                                            isOpen={true}
                                        >
                                            <PreferencesRadioFormMobile
                                                toggle={() => {
                                                    toggleAccountExternalInfoEditForm()
                                                }}
                                                title="Garage view"
                                                item={
                                                    garage_user_preferences_data_desktop[
                                                        current_garage_preferences_id
                                                    ]
                                                }
                                                ids_list={
                                                    garage_user_preferences_list_desktop
                                                }
                                                data={
                                                    garage_user_preferences_data_desktop
                                                }
                                                userid={userLoggedIn.id}
                                                field_id={
                                                    garage_user_preferences_field_id
                                                }
                                                handleSubmit={
                                                    updateUserGeneralInfoRequest
                                                }
                                            />
                                        </ModalDisplay>
                                    )}

                                {isAccountExternalInfoFormOpened &&
                                    accountExternalInfoFormID &&
                                    accountExternalInfoFormID ===
                                        distance_unit_field_id && (
                                        <ModalDisplay
                                            toggle={
                                                toggleAccountExternalInfoEditForm
                                            }
                                            isOpen={true}
                                        >
                                            <PreferencesRadioFormMobile
                                                title="Distance units"
                                                item={
                                                    distance_unit_user_preferences_data_desktop[
                                                        current_distance_unit_id
                                                    ]
                                                }
                                                ids_list={
                                                    distance_unit_user_preferences_list_desktop
                                                }
                                                data={
                                                    distance_unit_user_preferences_data_desktop
                                                }
                                                toggle={() => {
                                                    toggleAccountExternalInfoEditForm()
                                                }}
                                                handleSubmit={
                                                    updateUserGeneralInfoRequest
                                                }
                                                userid={userLoggedIn.id}
                                                field_id={
                                                    distance_unit_field_id
                                                }
                                            />
                                        </ModalDisplay>
                                    )}

                                {isAccountExternalInfoFormOpened &&
                                    accountExternalInfoFormID &&
                                    accountExternalInfoFormID ===
                                        currency_field_id && (
                                        <ModalDisplay
                                            toggle={
                                                toggleAccountExternalInfoEditForm
                                            }
                                            isOpen={true}
                                        >
                                            <PreferencesRadioFormMobile
                                                title="Currency"
                                                item={
                                                    currency_user_preferences_data_desktop[
                                                        current_user_currency_id
                                                    ]
                                                }
                                                ids_list={
                                                    currency_user_preferences_list_desktop
                                                }
                                                data={
                                                    currency_user_preferences_data_desktop
                                                }
                                                toggle={() => {
                                                    toggleAccountExternalInfoEditForm()
                                                }}
                                                handleSubmit={
                                                    updateUserGeneralInfoRequest
                                                }
                                                userid={userLoggedIn.id}
                                                field_id={currency_field_id}
                                            />
                                        </ModalDisplay>
                                    )}

                                {/* time-zone form data to be adapted bellow */}

                                {isAccountExternalInfoFormOpened &&
                                    accountExternalInfoFormID &&
                                    accountExternalInfoFormID ===
                                        time_zone_user_preferences_field_id && (
                                        <ModalDisplay
                                            toggle={
                                                toggleAccountExternalInfoEditForm
                                            }
                                            isOpen={true}
                                        >
                                            <PreferencesSearchSelectFormMobile
                                                userid={userLoggedIn.id}
                                                title="Timezone"
                                                field_id={
                                                    time_zone_user_preferences_field_id
                                                }
                                                item={{
                                                    id: current_user_time_zone_id
                                                        ? current_user_time_zone_id
                                                        : '',
                                                    text: current_user_time_zone_id
                                                        ? current_user_time_zone_id
                                                        : '',
                                                    answer: current_user_time_zone_id
                                                        ? current_user_time_zone_id
                                                        : undefined,
                                                }}
                                                selectedValue={selectedTimezone}
                                                handleSubmit={
                                                    handleChangeTimeZone
                                                }
                                                onCancel={() =>
                                                    clearSelectedTimezone()
                                                }
                                                isKeyValue={true}
                                                optionsList={
                                                    time_zone_dropdown_list
                                                }
                                                toggle={() => {
                                                    toggleAccountExternalInfoEditForm()
                                                }}
                                                onlyShowMatch={false}
                                                standalonePicker={true}
                                                pickerPath={
                                                    '/account/preferences/timezone'
                                                }

                                                // onlyShowMatch
                                            />
                                        </ModalDisplay>
                                    )}
                                {isAccountExternalInfoFormOpened &&
                                    accountExternalInfoFormID &&
                                    accountExternalInfoFormID ===
                                        notification_hour_user_preferences_field_id && (
                                        <ModalDisplay
                                            toggle={
                                                toggleAccountExternalInfoEditForm
                                            }
                                            isOpen={true}
                                        >
                                            <PreferencesSelectMobile
                                                title="Notification Hour"
                                                item={
                                                    notification_hour_data_desktop &&
                                                    current_user_notification_hour_id
                                                        ? notification_hour_data_desktop[
                                                              current_user_notification_hour_id
                                                          ]
                                                        : null
                                                }
                                                ids_list={
                                                    notification_hour_user_preference_list_desktop
                                                }
                                                data={
                                                    notification_hour_data_desktop
                                                }
                                                toggle={() => {
                                                    toggleAccountExternalInfoEditForm()
                                                }}
                                                handleSubmit={
                                                    updateUserGeneralInfoRequest
                                                }
                                                userid={userLoggedIn.id}
                                                field_id={
                                                    notification_hour_user_preferences_field_id
                                                }
                                            />
                                        </ModalDisplay>
                                    )}

                                <div style={{ paddingBottom: '100px' }} />
                            </IpadAndMobileDisplay>
                        </Faded>
                    )}
            </CenteredPageWrapper>
        )
    }
}

export default connect(mapStateToProps, dispatchProps)(UserPreferencesPage)
