
var Rive = (() => {
  var _scriptDir = typeof document !== 'undefined' && document.currentScript ? document.currentScript.src : undefined;
  
  return (
function(moduleArg = {}) {

var h = moduleArg, aa, ba;
h.ready = new Promise((b, a) => {
  aa = b;
  ba = a;
});
function ca() {
  function b(m) {
    const k = d;
    c = a = 0;
    d = new Map();
    k.forEach(n => {
      try {
        n(m);
      } catch (l) {
        console.error(l);
      }
    });
    this.xa();
    e && e.Ta();
  }
  let a = 0, c = 0, d = new Map(), e = null, f = null;
  this.requestAnimationFrame = function(m) {
    a || (a = requestAnimationFrame(b.bind(this)));
    const k = ++c;
    d.set(k, m);
    return k;
  };
  this.cancelAnimationFrame = function(m) {
    d.delete(m);
    a && 0 == d.size && (cancelAnimationFrame(a), a = 0);
  };
  this.Ra = function(m) {
    f && (document.body.remove(f), f = null);
    m || (f = document.createElement("div"), f.style.backgroundColor = "black", f.style.position = "fixed", f.style.right = 0, f.style.top = 0, f.style.color = "white", f.style.padding = "4px", f.innerHTML = "RIVE FPS", m = function(k) {
      f.innerHTML = "RIVE FPS " + k.toFixed(1);
    }, document.body.appendChild(f));
    e = new function() {
      let k = 0, n = 0;
      this.Ta = function() {
        var l = performance.now();
        n ? (++k, l -= n, 1000 < l && (m(1000 * k / l), k = n = 0)) : (n = l, k = 0);
      };
    }();
  };
  this.Oa = function() {
    f && (document.body.remove(f), f = null);
    e = null;
  };
  this.xa = function() {
  };
}
function da(b) {
  console.assert(!0);
  const a = new Map();
  let c = -Infinity;
  this.push = function(d) {
    d = d + ((1 << b) - 1) >> b;
    a.has(d) && clearTimeout(a.get(d));
    a.set(d, setTimeout(function() {
      a.delete(d);
      0 == a.length ? c = -Infinity : d == c && (c = Math.max(...a.keys()), console.assert(c < d));
    }, 1000));
    c = Math.max(d, c);
    return c << b;
  };
}
const ea = h.onRuntimeInitialized;
h.onRuntimeInitialized = function() {
  ea && ea();
  let b = h.decodeAudio;
  h.decodeAudio = function(e, f) {
    e = b(e);
    f(e);
  };
  let a = h.decodeFont;
  h.decodeFont = function(e, f) {
    e = a(e);
    f(e);
  };
  const c = h.FileAssetLoader;
  h.ptrToAsset = e => {
    let f = h.ptrToFileAsset(e);
    return f.isImage ? h.ptrToImageAsset(e) : f.isFont ? h.ptrToFontAsset(e) : f.isAudio ? h.ptrToAudioAsset(e) : f;
  };
  h.CustomFileAssetLoader = c.extend("CustomFileAssetLoader", {__construct:function({loadContents:e}) {
    this.__parent.__construct.call(this);
    this.Ha = e;
  }, loadContents:function(e, f) {
    e = h.ptrToAsset(e);
    return this.Ha(e, f);
  },});
  h.CDNFileAssetLoader = c.extend("CDNFileAssetLoader", {__construct:function() {
    this.__parent.__construct.call(this);
  }, loadContents:function(e) {
    let f = h.ptrToAsset(e);
    e = f.cdnUuid;
    if ("" === e) {
      return !1;
    }
    (function(m, k) {
      var n = new XMLHttpRequest();
      n.responseType = "arraybuffer";
      n.onreadystatechange = function() {
        4 == n.readyState && 200 == n.status && k(n);
      };
      n.open("GET", m, !0);
      n.send(null);
    })(f.cdnBaseUrl + "/" + e, m => {
      f.decode(new Uint8Array(m.response));
    });
    return !0;
  },});
  h.FallbackFileAssetLoader = c.extend("FallbackFileAssetLoader", {__construct:function() {
    this.__parent.__construct.call(this);
    this.wa = [];
  }, addLoader:function(e) {
    this.wa.push(e);
  }, loadContents:function(e, f) {
    for (let m of this.wa) {
      if (m.loadContents(e, f)) {
        return !0;
      }
    }
    return !1;
  },});
  let d = h.computeAlignment;
  h.computeAlignment = function(e, f, m, k, n = 1.0) {
    return d.call(this, e, f, m, k, n);
  };
};
const fa = "createConicGradient createImageData createLinearGradient createPattern createRadialGradient getContextAttributes getImageData getLineDash getTransform isContextLost isPointInPath isPointInStroke measureText".split(" "), ha = new function() {
  function b() {
    if (!a) {
      var g = document.createElement("canvas"), r = {alpha:1, depth:0, stencil:0, antialias:0, premultipliedAlpha:1, preserveDrawingBuffer:0, powerPreference:"high-performance", failIfMajorPerformanceCaveat:0, enableExtensionsByDefault:1, explicitSwapControl:1, renderViaOffscreenBackBuffer:1,};
      let q;
      if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
        if (q = g.getContext("webgl", r), c = 1, !q) {
          return console.log("No WebGL support. Image mesh will not be drawn."), !1;
        }
      } else {
        if (q = g.getContext("webgl2", r)) {
          c = 2;
        } else {
          if (q = g.getContext("webgl", r)) {
            c = 1;
          } else {
            return console.log("No WebGL support. Image mesh will not be drawn."), !1;
          }
        }
      }
      q = new Proxy(q, {get(D, v) {
        if (D.isContextLost()) {
          if (n || (console.error("Cannot render the mesh because the GL Context was lost. Tried to invoke ", v), n = !0), "function" === typeof D[v]) {
            return function() {
            };
          }
        } else {
          return "function" === typeof D[v] ? function(...I) {
            return D[v].apply(D, I);
          } : D[v];
        }
      }, set(D, v, I) {
        if (D.isContextLost()) {
          n || (console.error("Cannot render the mesh because the GL Context was lost. Tried to set property " + v), n = !0);
        } else {
          return D[v] = I, !0;
        }
      },});
      d = Math.min(q.getParameter(q.MAX_RENDERBUFFER_SIZE), q.getParameter(q.MAX_TEXTURE_SIZE));
      function G(D, v, I) {
        v = q.createShader(v);
        q.shaderSource(v, I);
        q.compileShader(v);
        I = q.getShaderInfoLog(v);
        if (0 < (I || "").length) {
          throw I;
        }
        q.attachShader(D, v);
      }
      g = q.createProgram();
      G(g, q.VERTEX_SHADER, "attribute vec2 vertex;\n                attribute vec2 uv;\n                uniform vec4 mat;\n                uniform vec2 translate;\n                varying vec2 st;\n                void main() {\n                    st = uv;\n                    gl_Position = vec4(mat2(mat) * vertex + translate, 0, 1);\n                }");
      G(g, q.FRAGMENT_SHADER, "precision highp float;\n                uniform sampler2D image;\n                varying vec2 st;\n                void main() {\n                    gl_FragColor = texture2D(image, st);\n                }");
      q.bindAttribLocation(g, 0, "vertex");
      q.bindAttribLocation(g, 1, "uv");
      q.linkProgram(g);
      r = q.getProgramInfoLog(g);
      if (0 < (r || "").trim().length) {
        throw r;
      }
      e = q.getUniformLocation(g, "mat");
      f = q.getUniformLocation(g, "translate");
      q.useProgram(g);
      q.bindBuffer(q.ARRAY_BUFFER, q.createBuffer());
      q.enableVertexAttribArray(0);
      q.enableVertexAttribArray(1);
      q.bindBuffer(q.ELEMENT_ARRAY_BUFFER, q.createBuffer());
      q.uniform1i(q.getUniformLocation(g, "image"), 0);
      q.pixelStorei(q.UNPACK_PREMULTIPLY_ALPHA_WEBGL, !0);
      a = q;
    }
    return !0;
  }
  let a = null, c = 0, d = 0, e = null, f = null, m = 0, k = 0, n = !1;
  b();
  this.eb = function() {
    b();
    return d;
  };
  this.Ma = function(g) {
    a.deleteTexture && a.deleteTexture(g);
  };
  this.La = function(g) {
    if (!b()) {
      return null;
    }
    const r = a.createTexture();
    if (!r) {
      return null;
    }
    a.bindTexture(a.TEXTURE_2D, r);
    a.texImage2D(a.TEXTURE_2D, 0, a.RGBA, a.RGBA, a.UNSIGNED_BYTE, g);
    a.texParameteri(a.TEXTURE_2D, a.TEXTURE_WRAP_S, a.CLAMP_TO_EDGE);
    a.texParameteri(a.TEXTURE_2D, a.TEXTURE_WRAP_T, a.CLAMP_TO_EDGE);
    a.texParameteri(a.TEXTURE_2D, a.TEXTURE_MAG_FILTER, a.LINEAR);
    2 == c ? (a.texParameteri(a.TEXTURE_2D, a.TEXTURE_MIN_FILTER, a.LINEAR_MIPMAP_LINEAR), a.generateMipmap(a.TEXTURE_2D)) : a.texParameteri(a.TEXTURE_2D, a.TEXTURE_MIN_FILTER, a.LINEAR);
    return r;
  };
  const l = new da(8), t = new da(8), w = new da(10), x = new da(10);
  this.Qa = function(g, r, q, G, D) {
    if (b()) {
      var v = l.push(g), I = t.push(r);
      if (a.canvas) {
        if (a.canvas.width != v || a.canvas.height != I) {
          a.canvas.width = v, a.canvas.height = I;
        }
        a.viewport(0, I - r, g, r);
        a.disable(a.SCISSOR_TEST);
        a.clearColor(0, 0, 0, 0);
        a.clear(a.COLOR_BUFFER_BIT);
        a.enable(a.SCISSOR_TEST);
        q.sort((C, W) => W.Ba - C.Ba);
        v = w.push(G);
        m != v && (a.bufferData(a.ARRAY_BUFFER, 8 * v, a.DYNAMIC_DRAW), m = v);
        v = 0;
        for (var O of q) {
          a.bufferSubData(a.ARRAY_BUFFER, v, O.ia), v += 4 * O.ia.length;
        }
        console.assert(v == 4 * G);
        for (var X of q) {
          a.bufferSubData(a.ARRAY_BUFFER, v, X.Ea), v += 4 * X.Ea.length;
        }
        console.assert(v == 8 * G);
        v = x.push(D);
        k != v && (a.bufferData(a.ELEMENT_ARRAY_BUFFER, 2 * v, a.DYNAMIC_DRAW), k = v);
        O = 0;
        for (var ia of q) {
          a.bufferSubData(a.ELEMENT_ARRAY_BUFFER, O, ia.indices), O += 2 * ia.indices.length;
        }
        console.assert(O == 2 * D);
        ia = 0;
        X = !0;
        v = O = 0;
        for (const C of q) {
          C.image.da != ia && (a.bindTexture(a.TEXTURE_2D, C.image.ca || null), ia = C.image.da);
          C.hb ? (a.scissor(C.na, I - C.oa - C.va, C.sb, C.va), X = !0) : X && (a.scissor(0, I - r, g, r), X = !1);
          q = 2 / g;
          const W = -2 / r;
          a.uniform4f(e, C.N[0] * q * C.X, C.N[1] * W * C.Y, C.N[2] * q * C.X, C.N[3] * W * C.Y);
          a.uniform2f(f, C.N[4] * q * C.X + q * (C.na - C.fb * C.X) - 1, C.N[5] * W * C.Y + W * (C.oa - C.gb * C.Y) + 1);
          a.vertexAttribPointer(0, 2, a.FLOAT, !1, 0, v);
          a.vertexAttribPointer(1, 2, a.FLOAT, !1, 0, v + 4 * G);
          a.drawElements(a.TRIANGLES, C.indices.length, a.UNSIGNED_SHORT, O);
          v += 4 * C.ia.length;
          O += 2 * C.indices.length;
        }
        console.assert(v == 4 * G);
        console.assert(O == 2 * D);
      }
    }
  };
  this.canvas = function() {
    return b() && a.canvas;
  };
}(), la = h.onRuntimeInitialized;
h.onRuntimeInitialized = function() {
  function b(p) {
    switch(p) {
      case l.srcOver:
        return "source-over";
      case l.screen:
        return "screen";
      case l.overlay:
        return "overlay";
      case l.darken:
        return "darken";
      case l.lighten:
        return "lighten";
      case l.colorDodge:
        return "color-dodge";
      case l.colorBurn:
        return "color-burn";
      case l.hardLight:
        return "hard-light";
      case l.softLight:
        return "soft-light";
      case l.difference:
        return "difference";
      case l.exclusion:
        return "exclusion";
      case l.multiply:
        return "multiply";
      case l.hue:
        return "hue";
      case l.saturation:
        return "saturation";
      case l.color:
        return "color";
      case l.luminosity:
        return "luminosity";
    }
  }
  function a(p) {
    return "rgba(" + ((16711680 & p) >>> 16) + "," + ((65280 & p) >>> 8) + "," + ((255 & p) >>> 0) + "," + ((4278190080 & p) >>> 24) / 255 + ")";
  }
  function c() {
    0 < I.length && (ha.Qa(v.drawWidth(), v.drawHeight(), I, O, X), I = [], X = O = 0, v.reset(512, 512));
    for (const p of D) {
      for (const u of p.u) {
        u();
      }
      p.u = [];
    }
    D.clear();
  }
  la && la();
  var d = h.RenderPaintStyle;
  const e = h.RenderPath, f = h.RenderPaint, m = h.Renderer, k = h.StrokeCap, n = h.StrokeJoin, l = h.BlendMode, t = d.fill, w = d.stroke, x = h.FillRule.evenOdd;
  let g = 1;
  var r = h.RenderImage.extend("CanvasRenderImage", {__construct:function({R:p, W:u} = {}) {
    this.__parent.__construct.call(this);
    this.da = g;
    g = g + 1 & 2147483647 || 1;
    this.R = p;
    this.W = u;
  }, __destruct:function() {
    this.ca && (ha.Ma(this.ca), URL.revokeObjectURL(this.la));
    this.__parent.__destruct.call(this);
  }, decode:function(p) {
    var u = this;
    u.W && u.W(u);
    var A = new Image();
    u.la = URL.createObjectURL(new Blob([p], {type:"image/png",}));
    A.onload = function() {
      u.Ga = A;
      u.ca = ha.La(A);
      u.size(A.width, A.height);
      u.R && u.R(u);
    };
    A.src = u.la;
  },}), q = e.extend("CanvasRenderPath", {__construct:function() {
    this.__parent.__construct.call(this);
    this.F = new Path2D();
  }, rewind:function() {
    this.F = new Path2D();
  }, addPath:function(p, u, A, B, y, E, F) {
    var H = this.F, T = H.addPath;
    p = p.F;
    const K = new DOMMatrix();
    K.a = u;
    K.b = A;
    K.c = B;
    K.d = y;
    K.e = E;
    K.f = F;
    T.call(H, p, K);
  }, fillRule:function(p) {
    this.ka = p;
  }, moveTo:function(p, u) {
    this.F.moveTo(p, u);
  }, lineTo:function(p, u) {
    this.F.lineTo(p, u);
  }, cubicTo:function(p, u, A, B, y, E) {
    this.F.bezierCurveTo(p, u, A, B, y, E);
  }, close:function() {
    this.F.closePath();
  },}), G = f.extend("CanvasRenderPaint", {color:function(p) {
    this.ma = a(p);
  }, thickness:function(p) {
    this.Ja = p;
  }, join:function(p) {
    switch(p) {
      case n.miter:
        this.ba = "miter";
        break;
      case n.round:
        this.ba = "round";
        break;
      case n.bevel:
        this.ba = "bevel";
    }
  }, cap:function(p) {
    switch(p) {
      case k.butt:
        this.aa = "butt";
        break;
      case k.round:
        this.aa = "round";
        break;
      case k.square:
        this.aa = "square";
    }
  }, style:function(p) {
    this.Ia = p;
  }, blendMode:function(p) {
    this.Fa = b(p);
  }, clearGradient:function() {
    this.P = null;
  }, linearGradient:function(p, u, A, B) {
    this.P = {Ca:p, Da:u, qa:A, ra:B, ga:[],};
  }, radialGradient:function(p, u, A, B) {
    this.P = {Ca:p, Da:u, qa:A, ra:B, ga:[], bb:!0,};
  }, addStop:function(p, u) {
    this.P.ga.push({color:p, stop:u,});
  }, completeGradient:function() {
  }, draw:function(p, u, A) {
    let B = this.Ia;
    var y = this.ma, E = this.P;
    p.globalCompositeOperation = this.Fa;
    if (null != E) {
      y = E.Ca;
      var F = E.Da;
      const T = E.qa;
      var H = E.ra;
      const K = E.ga;
      E.bb ? (E = T - y, H -= F, y = p.createRadialGradient(y, F, 0, y, F, Math.sqrt(E * E + H * H))) : y = p.createLinearGradient(y, F, T, H);
      for (let Y = 0, N = K.length; Y < N; Y++) {
        F = K[Y], y.addColorStop(F.stop, a(F.color));
      }
      this.ma = y;
      this.P = null;
    }
    switch(B) {
      case w:
        p.strokeStyle = y;
        p.lineWidth = this.Ja;
        p.lineCap = this.aa;
        p.lineJoin = this.ba;
        p.stroke(u);
        break;
      case t:
        p.fillStyle = y, p.fill(u, A);
    }
  },});
  const D = new Set();
  let v = null, I = [], O = 0, X = 0;
  var ia = h.CanvasRenderer = m.extend("Renderer", {__construct:function(p) {
    this.__parent.__construct.call(this);
    this.D = [1, 0, 0, 1, 0, 0];
    this.o = p.getContext("2d");
    this.ja = p;
    this.u = [];
  }, save:function() {
    this.D.push(...this.D.slice(this.D.length - 6));
    this.u.push(this.o.save.bind(this.o));
  }, restore:function() {
    const p = this.D.length - 6;
    if (6 > p) {
      throw "restore() called without matching save().";
    }
    this.D.splice(p);
    this.u.push(this.o.restore.bind(this.o));
  }, transform:function(p, u, A, B, y, E) {
    const F = this.D, H = F.length - 6;
    F.splice(H, 6, F[H] * p + F[H + 2] * u, F[H + 1] * p + F[H + 3] * u, F[H] * A + F[H + 2] * B, F[H + 1] * A + F[H + 3] * B, F[H] * y + F[H + 2] * E + F[H + 4], F[H + 1] * y + F[H + 3] * E + F[H + 5]);
    this.u.push(this.o.transform.bind(this.o, p, u, A, B, y, E));
  }, rotate:function(p) {
    const u = Math.sin(p);
    p = Math.cos(p);
    this.transform(p, u, -u, p, 0, 0);
  }, _drawPath:function(p, u) {
    this.u.push(u.draw.bind(u, this.o, p.F, p.ka === x ? "evenodd" : "nonzero"));
  }, _drawRiveImage:function(p, u, A) {
    var B = p.Ga;
    if (B) {
      var y = this.o, E = b(u);
      this.u.push(function() {
        y.globalCompositeOperation = E;
        y.globalAlpha = A;
        y.drawImage(B, 0, 0);
        y.globalAlpha = 1;
      });
    }
  }, _getMatrix:function(p) {
    const u = this.D, A = u.length - 6;
    for (let B = 0; 6 > B; ++B) {
      p[B] = u[A + B];
    }
  }, _drawImageMesh:function(p, u, A, B, y, E, F, H, T, K) {
    var Y = this.o.canvas.width, N = this.o.canvas.height;
    const tb = T - F, ub = K - H;
    F = Math.max(F, 0);
    H = Math.max(H, 0);
    T = Math.min(T, Y);
    K = Math.min(K, N);
    const wa = T - F, xa = K - H;
    console.assert(wa <= Math.min(tb, Y));
    console.assert(xa <= Math.min(ub, N));
    if (!(0 >= wa || 0 >= xa)) {
      T = wa < tb || xa < ub;
      Y = K = 1;
      var ja = Math.ceil(wa * K), ka = Math.ceil(xa * Y);
      N = ha.eb();
      ja > N && (K *= N / ja, ja = N);
      ka > N && (Y *= N / ka, ka = N);
      v || (v = new h.DynamicRectanizer(N), v.reset(512, 512));
      N = v.addRect(ja, ka);
      0 > N && (c(), D.add(this), N = v.addRect(ja, ka), console.assert(0 <= N));
      var vb = N & 65535, wb = N >> 16;
      I.push({N:this.D.slice(this.D.length - 6), image:p, na:vb, oa:wb, fb:F, gb:H, sb:ja, va:ka, X:K, Y, ia:new Float32Array(B), Ea:new Float32Array(y), indices:new Uint16Array(E), hb:T, Ba:p.da << 1 | (T ? 1 : 0),});
      O += B.length;
      X += E.length;
      var pa = this.o, oc = b(u);
      this.u.push(function() {
        pa.save();
        pa.resetTransform();
        pa.globalCompositeOperation = oc;
        pa.globalAlpha = A;
        const xb = ha.canvas();
        xb && pa.drawImage(xb, vb, wb, ja, ka, F, H, wa, xa);
        pa.restore();
      });
    }
  }, _clipPath:function(p) {
    this.u.push(this.o.clip.bind(this.o, p.F, p.ka === x ? "evenodd" : "nonzero"));
  }, clear:function() {
    D.add(this);
    this.u.push(this.o.clearRect.bind(this.o, 0, 0, this.ja.width, this.ja.height));
  }, flush:function() {
  }, translate:function(p, u) {
    this.transform(1, 0, 0, 1, p, u);
  },});
  h.makeRenderer = function(p) {
    const u = new ia(p), A = u.o;
    return new Proxy(u, {get(B, y) {
      if ("function" === typeof B[y]) {
        return function(...E) {
          return B[y].apply(B, E);
        };
      }
      if ("function" === typeof A[y]) {
        if (-1 < fa.indexOf(y)) {
          throw Error("RiveException: Method call to '" + y + "()' is not allowed, as the renderer cannot immediately pass through the return                 values of any canvas 2d context methods.");
        }
        return function(...E) {
          u.u.push(A[y].bind(A, ...E));
        };
      }
      return B[y];
    }, set(B, y, E) {
      if (y in A) {
        return u.u.push(() => {
          A[y] = E;
        }), !0;
      }
    },});
  };
  h.decodeImage = function(p, u) {
    (new r({R:u})).decode(p);
  };
  h.renderFactory = {makeRenderPaint:function() {
    return new G();
  }, makeRenderPath:function() {
    return new q();
  }, makeRenderImage:function() {
    let p = W;
    return new r({W:() => {
      p.total++;
    }, R:() => {
      p.loaded++;
      if (p.loaded === p.total) {
        const u = p.ready;
        u && (u(), p.ready = null);
      }
    },});
  },};
  let C = h.load, W = null;
  h.load = function(p, u, A = !0) {
    const B = new h.FallbackFileAssetLoader();
    void 0 !== u && B.addLoader(u);
    A && (u = new h.CDNFileAssetLoader(), B.addLoader(u));
    return new Promise(function(y) {
      let E = null;
      W = {total:0, loaded:0, ready:function() {
        y(E);
      },};
      E = C(p, B);
      0 == W.total && y(E);
    });
  };
  let pc = h.RendererWrapper.prototype.align;
  h.RendererWrapper.prototype.align = function(p, u, A, B, y = 1.0) {
    pc.call(this, p, u, A, B, y);
  };
  d = new ca();
  h.requestAnimationFrame = d.requestAnimationFrame.bind(d);
  h.cancelAnimationFrame = d.cancelAnimationFrame.bind(d);
  h.enableFPSCounter = d.Ra.bind(d);
  h.disableFPSCounter = d.Oa;
  d.xa = c;
  h.resolveAnimationFrame = c;
  h.cleanup = function() {
    v && v.delete();
  };
};
var ma = Object.assign({}, h), na = "./this.program", oa = "object" == typeof window, qa = "function" == typeof importScripts, z = "", ra, sa;
if (oa || qa) {
  qa ? z = self.location.href : "undefined" != typeof document && document.currentScript && (z = document.currentScript.src), _scriptDir && (z = _scriptDir), 0 !== z.indexOf("blob:") ? z = z.substr(0, z.replace(/[?#].*/, "").lastIndexOf("/") + 1) : z = "", qa && (sa = b => {
    var a = new XMLHttpRequest();
    a.open("GET", b, !1);
    a.responseType = "arraybuffer";
    a.send(null);
    return new Uint8Array(a.response);
  }), ra = (b, a, c) => {
    var d = new XMLHttpRequest();
    d.open("GET", b, !0);
    d.responseType = "arraybuffer";
    d.onload = () => {
      200 == d.status || 0 == d.status && d.response ? a(d.response) : c();
    };
    d.onerror = c;
    d.send(null);
  };
}
var ta = h.print || console.log.bind(console), ua = h.printErr || console.error.bind(console);
Object.assign(h, ma);
ma = null;
h.thisProgram && (na = h.thisProgram);
var va;
h.wasmBinary && (va = h.wasmBinary);
var noExitRuntime = h.noExitRuntime || !0;
"object" != typeof WebAssembly && ya("no native wasm support detected");
var za, J, Aa = !1, Ba, L, Ca, Da, M, P, Ea, Fa;
function Ga() {
  var b = za.buffer;
  h.HEAP8 = Ba = new Int8Array(b);
  h.HEAP16 = Ca = new Int16Array(b);
  h.HEAP32 = M = new Int32Array(b);
  h.HEAPU8 = L = new Uint8Array(b);
  h.HEAPU16 = Da = new Uint16Array(b);
  h.HEAPU32 = P = new Uint32Array(b);
  h.HEAPF32 = Ea = new Float32Array(b);
  h.HEAPF64 = Fa = new Float64Array(b);
}
var Ha, Ia = [], Ja = [], Ka = [];
function La() {
  var b = h.preRun.shift();
  Ia.unshift(b);
}
var Ma = 0, Na = null, Oa = null;
function ya(b) {
  if (h.onAbort) {
    h.onAbort(b);
  }
  b = "Aborted(" + b + ")";
  ua(b);
  Aa = !0;
  b = new WebAssembly.RuntimeError(b + ". Build with -sASSERTIONS for more info.");
  ba(b);
  throw b;
}
function Pa(b) {
  return b.startsWith("data:application/octet-stream;base64,");
}
var Qa;
Qa = "canvas_advanced.wasm";
if (!Pa(Qa)) {
  var Ra = Qa;
  Qa = h.locateFile ? h.locateFile(Ra, z) : z + Ra;
}
function Sa(b) {
  if (b == Qa && va) {
    return new Uint8Array(va);
  }
  if (sa) {
    return sa(b);
  }
  throw "both async and sync fetching of the wasm failed";
}
function Ta(b) {
  if (!va && (oa || qa)) {
    if ("function" == typeof fetch && !b.startsWith("file://")) {
      return fetch(b, {credentials:"same-origin"}).then(a => {
        if (!a.ok) {
          throw "failed to load wasm binary file at '" + b + "'";
        }
        return a.arrayBuffer();
      }).catch(() => Sa(b));
    }
    if (ra) {
      return new Promise((a, c) => {
        ra(b, d => a(new Uint8Array(d)), c);
      });
    }
  }
  return Promise.resolve().then(() => Sa(b));
}
function Ua(b, a, c) {
  return Ta(b).then(d => WebAssembly.instantiate(d, a)).then(d => d).then(c, d => {
    ua("failed to asynchronously prepare wasm: " + d);
    ya(d);
  });
}
function Va(b, a) {
  var c = Qa;
  return va || "function" != typeof WebAssembly.instantiateStreaming || Pa(c) || c.startsWith("file://") || "function" != typeof fetch ? Ua(c, b, a) : fetch(c, {credentials:"same-origin"}).then(d => WebAssembly.instantiateStreaming(d, b).then(a, function(e) {
    ua("wasm streaming compile failed: " + e);
    ua("falling back to ArrayBuffer instantiation");
    return Ua(c, b, a);
  }));
}
var Wa = b => {
  for (; 0 < b.length;) {
    b.shift()(h);
  }
};
function Xa(b) {
  if (void 0 === b) {
    return "_unknown";
  }
  b = b.replace(/[^a-zA-Z0-9_]/g, "$");
  var a = b.charCodeAt(0);
  return 48 <= a && 57 >= a ? `_${b}` : b;
}
function Ya(b, a) {
  b = Xa(b);
  return {[b]:function() {
    return a.apply(this, arguments);
  }}[b];
}
function Za() {
  this.G = [void 0];
  this.ta = [];
}
var Q = new Za(), $a = void 0;
function R(b) {
  throw new $a(b);
}
var S = b => {
  b || R("Cannot use deleted val. handle = " + b);
  return Q.get(b).value;
}, ab = b => {
  switch(b) {
    case void 0:
      return 1;
    case null:
      return 2;
    case !0:
      return 3;
    case !1:
      return 4;
    default:
      return Q.Za({Aa:1, value:b});
  }
};
function bb(b) {
  var a = Error, c = Ya(b, function(d) {
    this.name = b;
    this.message = d;
    d = Error(d).stack;
    void 0 !== d && (this.stack = this.toString() + "\n" + d.replace(/^Error(:[^\n]*)?\n/, ""));
  });
  c.prototype = Object.create(a.prototype);
  c.prototype.constructor = c;
  c.prototype.toString = function() {
    return void 0 === this.message ? this.name : `${this.name}: ${this.message}`;
  };
  return c;
}
var cb = void 0, db = void 0;
function U(b) {
  for (var a = ""; L[b];) {
    a += db[L[b++]];
  }
  return a;
}
var eb = [];
function fb() {
  for (; eb.length;) {
    var b = eb.pop();
    b.g.M = !1;
    b["delete"]();
  }
}
var gb = void 0, hb = {};
function ib(b, a) {
  for (void 0 === a && R("ptr should not be undefined"); b.l;) {
    a = b.S(a), b = b.l;
  }
  return a;
}
var jb = {};
function kb(b) {
  b = lb(b);
  var a = U(b);
  mb(b);
  return a;
}
function nb(b, a) {
  var c = jb[b];
  void 0 === c && R(a + " has unknown type " + kb(b));
  return c;
}
function ob() {
}
var pb = !1;
function qb(b) {
  --b.count.value;
  0 === b.count.value && (b.s ? b.A.H(b.s) : b.j.h.H(b.i));
}
function rb(b, a, c) {
  if (a === c) {
    return b;
  }
  if (void 0 === c.l) {
    return null;
  }
  b = rb(b, a, c.l);
  return null === b ? null : c.Pa(b);
}
var sb = {};
function yb(b, a) {
  a = ib(b, a);
  return hb[a];
}
var zb = void 0;
function Ab(b) {
  throw new zb(b);
}
function Bb(b, a) {
  a.j && a.i || Ab("makeClassHandle requires ptr and ptrType");
  !!a.A !== !!a.s && Ab("Both smartPtrType and smartPtr must be specified");
  a.count = {value:1};
  return Cb(Object.create(b, {g:{value:a,},}));
}
function Cb(b) {
  if ("undefined" === typeof FinalizationRegistry) {
    return Cb = a => a, b;
  }
  pb = new FinalizationRegistry(a => {
    qb(a.g);
  });
  Cb = a => {
    var c = a.g;
    c.s && pb.register(a, {g:c}, a);
    return a;
  };
  ob = a => {
    pb.unregister(a);
  };
  return Cb(b);
}
var Db = {};
function Eb(b) {
  for (; b.length;) {
    var a = b.pop();
    b.pop()(a);
  }
}
function Fb(b) {
  return this.fromWireType(M[b >> 2]);
}
var Gb = {}, Hb = {};
function V(b, a, c) {
  function d(k) {
    k = c(k);
    k.length !== b.length && Ab("Mismatched type converter count");
    for (var n = 0; n < b.length; ++n) {
      Ib(b[n], k[n]);
    }
  }
  b.forEach(function(k) {
    Hb[k] = a;
  });
  var e = Array(a.length), f = [], m = 0;
  a.forEach((k, n) => {
    jb.hasOwnProperty(k) ? e[n] = jb[k] : (f.push(k), Gb.hasOwnProperty(k) || (Gb[k] = []), Gb[k].push(() => {
      e[n] = jb[k];
      ++m;
      m === f.length && d(e);
    }));
  });
  0 === f.length && d(e);
}
function Jb(b) {
  switch(b) {
    case 1:
      return 0;
    case 2:
      return 1;
    case 4:
      return 2;
    case 8:
      return 3;
    default:
      throw new TypeError(`Unknown type size: ${b}`);
  }
}
function Kb(b, a, c = {}) {
  var d = a.name;
  b || R(`type "${d}" must have a positive integer typeid pointer`);
  if (jb.hasOwnProperty(b)) {
    if (c.ab) {
      return;
    }
    R(`Cannot register type '${d}' twice`);
  }
  jb[b] = a;
  delete Hb[b];
  Gb.hasOwnProperty(b) && (a = Gb[b], delete Gb[b], a.forEach(e => e()));
}
function Ib(b, a, c = {}) {
  if (!("argPackAdvance" in a)) {
    throw new TypeError("registerType registeredInstance requires argPackAdvance");
  }
  Kb(b, a, c);
}
function Lb(b) {
  R(b.g.j.h.name + " instance already deleted");
}
function Mb() {
}
function Nb(b, a, c) {
  if (void 0 === b[a].m) {
    var d = b[a];
    b[a] = function() {
      b[a].m.hasOwnProperty(arguments.length) || R(`Function '${c}' called with an invalid number of arguments (${arguments.length}) - expects one of (${b[a].m})!`);
      return b[a].m[arguments.length].apply(this, arguments);
    };
    b[a].m = [];
    b[a].m[d.L] = d;
  }
}
function Ob(b, a, c) {
  h.hasOwnProperty(b) ? ((void 0 === c || void 0 !== h[b].m && void 0 !== h[b].m[c]) && R(`Cannot register public name '${b}' twice`), Nb(h, b, b), h.hasOwnProperty(c) && R(`Cannot register multiple overloads of a function with the same number of arguments (${c})!`), h[b].m[c] = a) : (h[b] = a, void 0 !== c && (h[b].tb = c));
}
function Pb(b, a, c, d, e, f, m, k) {
  this.name = b;
  this.constructor = a;
  this.B = c;
  this.H = d;
  this.l = e;
  this.Ua = f;
  this.S = m;
  this.Pa = k;
  this.ya = [];
}
function Qb(b, a, c) {
  for (; a !== c;) {
    a.S || R(`Expected null or instance of ${c.name}, got an instance of ${a.name}`), b = a.S(b), a = a.l;
  }
  return b;
}
function Rb(b, a) {
  if (null === a) {
    return this.ea && R(`null is not a valid ${this.name}`), 0;
  }
  a.g || R(`Cannot pass "${Sb(a)}" as a ${this.name}`);
  a.g.i || R(`Cannot pass deleted object as a pointer of type ${this.name}`);
  return Qb(a.g.i, a.g.j.h, this.h);
}
function Tb(b, a) {
  if (null === a) {
    this.ea && R(`null is not a valid ${this.name}`);
    if (this.V) {
      var c = this.fa();
      null !== b && b.push(this.H, c);
      return c;
    }
    return 0;
  }
  a.g || R(`Cannot pass "${Sb(a)}" as a ${this.name}`);
  a.g.i || R(`Cannot pass deleted object as a pointer of type ${this.name}`);
  !this.U && a.g.j.U && R(`Cannot convert argument of type ${a.g.A ? a.g.A.name : a.g.j.name} to parameter type ${this.name}`);
  c = Qb(a.g.i, a.g.j.h, this.h);
  if (this.V) {
    switch(void 0 === a.g.s && R("Passing raw pointer to smart pointer is illegal"), this.nb) {
      case 0:
        a.g.A === this ? c = a.g.s : R(`Cannot convert argument of type ${a.g.A ? a.g.A.name : a.g.j.name} to parameter type ${this.name}`);
        break;
      case 1:
        c = a.g.s;
        break;
      case 2:
        if (a.g.A === this) {
          c = a.g.s;
        } else {
          var d = a.clone();
          c = this.jb(c, ab(function() {
            d["delete"]();
          }));
          null !== b && b.push(this.H, c);
        }
        break;
      default:
        R("Unsupporting sharing policy");
    }
  }
  return c;
}
function Ub(b, a) {
  if (null === a) {
    return this.ea && R(`null is not a valid ${this.name}`), 0;
  }
  a.g || R(`Cannot pass "${Sb(a)}" as a ${this.name}`);
  a.g.i || R(`Cannot pass deleted object as a pointer of type ${this.name}`);
  a.g.j.U && R(`Cannot convert argument of type ${a.g.j.name} to parameter type ${this.name}`);
  return Qb(a.g.i, a.g.j.h, this.h);
}
function Vb(b, a, c, d) {
  this.name = b;
  this.h = a;
  this.ea = c;
  this.U = d;
  this.V = !1;
  this.H = this.jb = this.fa = this.za = this.nb = this.ib = void 0;
  void 0 !== a.l ? this.toWireType = Tb : (this.toWireType = d ? Rb : Ub, this.v = null);
}
function Wb(b, a, c) {
  h.hasOwnProperty(b) || Ab("Replacing nonexistant public symbol");
  void 0 !== h[b].m && void 0 !== c ? h[b].m[c] = a : (h[b] = a, h[b].L = c);
}
var Xb = [], Yb = b => {
  var a = Xb[b];
  a || (b >= Xb.length && (Xb.length = b + 1), Xb[b] = a = Ha.get(b));
  return a;
}, Zb = (b, a) => {
  var c = [];
  return function() {
    c.length = 0;
    Object.assign(c, arguments);
    if (b.includes("j")) {
      var d = h["dynCall_" + b];
      d = c && c.length ? d.apply(null, [a].concat(c)) : d.call(null, a);
    } else {
      d = Yb(a).apply(null, c);
    }
    return d;
  };
};
function Z(b, a) {
  b = U(b);
  var c = b.includes("j") ? Zb(b, a) : Yb(a);
  "function" != typeof c && R(`unknown function pointer with signature ${b}: ${a}`);
  return c;
}
var $b = void 0;
function ac(b, a) {
  function c(f) {
    e[f] || jb[f] || (Hb[f] ? Hb[f].forEach(c) : (d.push(f), e[f] = !0));
  }
  var d = [], e = {};
  a.forEach(c);
  throw new $b(`${b}: ` + d.map(kb).join([", "]));
}
function bc(b, a, c, d, e) {
  var f = a.length;
  2 > f && R("argTypes array size mismatch! Must at least get return value and 'this' types!");
  var m = null !== a[1] && null !== c, k = !1;
  for (c = 1; c < a.length; ++c) {
    if (null !== a[c] && void 0 === a[c].v) {
      k = !0;
      break;
    }
  }
  var n = "void" !== a[0].name, l = f - 2, t = Array(l), w = [], x = [];
  return function() {
    arguments.length !== l && R(`function ${b} called with ${arguments.length} arguments, expected ${l} args!`);
    x.length = 0;
    w.length = m ? 2 : 1;
    w[0] = e;
    if (m) {
      var g = a[1].toWireType(x, this);
      w[1] = g;
    }
    for (var r = 0; r < l; ++r) {
      t[r] = a[r + 2].toWireType(x, arguments[r]), w.push(t[r]);
    }
    r = d.apply(null, w);
    if (k) {
      Eb(x);
    } else {
      for (var q = m ? 1 : 2; q < a.length; q++) {
        var G = 1 === q ? g : t[q - 2];
        null !== a[q].v && a[q].v(G);
      }
    }
    g = n ? a[0].fromWireType(r) : void 0;
    return g;
  };
}
function cc(b, a) {
  for (var c = [], d = 0; d < b; d++) {
    c.push(P[a + 4 * d >> 2]);
  }
  return c;
}
function dc(b, a, c) {
  b instanceof Object || R(`${c} with invalid "this": ${b}`);
  b instanceof a.h.constructor || R(`${c} incompatible with "this" of type ${b.constructor.name}`);
  b.g.i || R(`cannot call emscripten binding method ${c} on deleted object`);
  return Qb(b.g.i, b.g.j.h, a.h);
}
function ec(b) {
  b >= Q.ua && 0 === --Q.get(b).Aa && Q.$a(b);
}
function fc(b, a, c) {
  switch(a) {
    case 0:
      return function(d) {
        return this.fromWireType((c ? Ba : L)[d]);
      };
    case 1:
      return function(d) {
        return this.fromWireType((c ? Ca : Da)[d >> 1]);
      };
    case 2:
      return function(d) {
        return this.fromWireType((c ? M : P)[d >> 2]);
      };
    default:
      throw new TypeError("Unknown integer type: " + b);
  }
}
function Sb(b) {
  if (null === b) {
    return "null";
  }
  var a = typeof b;
  return "object" === a || "array" === a || "function" === a ? b.toString() : "" + b;
}
function gc(b, a) {
  switch(a) {
    case 2:
      return function(c) {
        return this.fromWireType(Ea[c >> 2]);
      };
    case 3:
      return function(c) {
        return this.fromWireType(Fa[c >> 3]);
      };
    default:
      throw new TypeError("Unknown float type: " + b);
  }
}
function hc(b, a, c) {
  switch(a) {
    case 0:
      return c ? function(d) {
        return Ba[d];
      } : function(d) {
        return L[d];
      };
    case 1:
      return c ? function(d) {
        return Ca[d >> 1];
      } : function(d) {
        return Da[d >> 1];
      };
    case 2:
      return c ? function(d) {
        return M[d >> 2];
      } : function(d) {
        return P[d >> 2];
      };
    default:
      throw new TypeError("Unknown integer type: " + b);
  }
}
var ic = (b, a, c, d) => {
  if (0 < d) {
    d = c + d - 1;
    for (var e = 0; e < b.length; ++e) {
      var f = b.charCodeAt(e);
      if (55296 <= f && 57343 >= f) {
        var m = b.charCodeAt(++e);
        f = 65536 + ((f & 1023) << 10) | m & 1023;
      }
      if (127 >= f) {
        if (c >= d) {
          break;
        }
        a[c++] = f;
      } else {
        if (2047 >= f) {
          if (c + 1 >= d) {
            break;
          }
          a[c++] = 192 | f >> 6;
        } else {
          if (65535 >= f) {
            if (c + 2 >= d) {
              break;
            }
            a[c++] = 224 | f >> 12;
          } else {
            if (c + 3 >= d) {
              break;
            }
            a[c++] = 240 | f >> 18;
            a[c++] = 128 | f >> 12 & 63;
          }
          a[c++] = 128 | f >> 6 & 63;
        }
        a[c++] = 128 | f & 63;
      }
    }
    a[c] = 0;
  }
}, jc = b => {
  for (var a = 0, c = 0; c < b.length; ++c) {
    var d = b.charCodeAt(c);
    127 >= d ? a++ : 2047 >= d ? a += 2 : 55296 <= d && 57343 >= d ? (a += 4, ++c) : a += 3;
  }
  return a;
}, kc = "undefined" != typeof TextDecoder ? new TextDecoder("utf8") : void 0, lc = (b, a, c) => {
  var d = a + c;
  for (c = a; b[c] && !(c >= d);) {
    ++c;
  }
  if (16 < c - a && b.buffer && kc) {
    return kc.decode(b.subarray(a, c));
  }
  for (d = ""; a < c;) {
    var e = b[a++];
    if (e & 128) {
      var f = b[a++] & 63;
      if (192 == (e & 224)) {
        d += String.fromCharCode((e & 31) << 6 | f);
      } else {
        var m = b[a++] & 63;
        e = 224 == (e & 240) ? (e & 15) << 12 | f << 6 | m : (e & 7) << 18 | f << 12 | m << 6 | b[a++] & 63;
        65536 > e ? d += String.fromCharCode(e) : (e -= 65536, d += String.fromCharCode(55296 | e >> 10, 56320 | e & 1023));
      }
    } else {
      d += String.fromCharCode(e);
    }
  }
  return d;
}, mc = "undefined" != typeof TextDecoder ? new TextDecoder("utf-16le") : void 0, nc = (b, a) => {
  var c = b >> 1;
  for (var d = c + a / 2; !(c >= d) && Da[c];) {
    ++c;
  }
  c <<= 1;
  if (32 < c - b && mc) {
    return mc.decode(L.subarray(b, c));
  }
  c = "";
  for (d = 0; !(d >= a / 2); ++d) {
    var e = Ca[b + 2 * d >> 1];
    if (0 == e) {
      break;
    }
    c += String.fromCharCode(e);
  }
  return c;
}, qc = (b, a, c) => {
  void 0 === c && (c = 2147483647);
  if (2 > c) {
    return 0;
  }
  c -= 2;
  var d = a;
  c = c < 2 * b.length ? c / 2 : b.length;
  for (var e = 0; e < c; ++e) {
    Ca[a >> 1] = b.charCodeAt(e), a += 2;
  }
  Ca[a >> 1] = 0;
  return a - d;
}, rc = b => 2 * b.length, sc = (b, a) => {
  for (var c = 0, d = ""; !(c >= a / 4);) {
    var e = M[b + 4 * c >> 2];
    if (0 == e) {
      break;
    }
    ++c;
    65536 <= e ? (e -= 65536, d += String.fromCharCode(55296 | e >> 10, 56320 | e & 1023)) : d += String.fromCharCode(e);
  }
  return d;
}, tc = (b, a, c) => {
  void 0 === c && (c = 2147483647);
  if (4 > c) {
    return 0;
  }
  var d = a;
  c = d + c - 4;
  for (var e = 0; e < b.length; ++e) {
    var f = b.charCodeAt(e);
    if (55296 <= f && 57343 >= f) {
      var m = b.charCodeAt(++e);
      f = 65536 + ((f & 1023) << 10) | m & 1023;
    }
    M[a >> 2] = f;
    a += 4;
    if (a + 4 > c) {
      break;
    }
  }
  M[a >> 2] = 0;
  return a - d;
}, uc = b => {
  for (var a = 0, c = 0; c < b.length; ++c) {
    var d = b.charCodeAt(c);
    55296 <= d && 57343 >= d && ++c;
    a += 4;
  }
  return a;
}, vc = {};
function wc(b) {
  var a = vc[b];
  return void 0 === a ? U(b) : a;
}
var xc = [];
function yc(b) {
  var a = xc.length;
  xc.push(b);
  return a;
}
function zc(b, a) {
  for (var c = Array(b), d = 0; d < b; ++d) {
    c[d] = nb(P[a + 4 * d >> 2], "parameter " + d);
  }
  return c;
}
var Ac = [], Bc = {}, Dc = () => {
  if (!Cc) {
    var b = {USER:"web_user", LOGNAME:"web_user", PATH:"/", PWD:"/", HOME:"/home/web_user", LANG:("object" == typeof navigator && navigator.languages && navigator.languages[0] || "C").replace("-", "_") + ".UTF-8", _:na || "./this.program"}, a;
    for (a in Bc) {
      void 0 === Bc[a] ? delete b[a] : b[a] = Bc[a];
    }
    var c = [];
    for (a in b) {
      c.push(`${a}=${b[a]}`);
    }
    Cc = c;
  }
  return Cc;
}, Cc, Ec = [null, [], []], Fc = b => 0 === b % 4 && (0 !== b % 100 || 0 === b % 400), Gc = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31], Hc = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
function Ic(b) {
  var a = Array(jc(b) + 1);
  ic(b, a, 0, a.length);
  return a;
}
var Jc = (b, a, c, d) => {
  function e(g, r, q) {
    for (g = "number" == typeof g ? g.toString() : g || ""; g.length < r;) {
      g = q[0] + g;
    }
    return g;
  }
  function f(g, r) {
    return e(g, r, "0");
  }
  function m(g, r) {
    function q(D) {
      return 0 > D ? -1 : 0 < D ? 1 : 0;
    }
    var G;
    0 === (G = q(g.getFullYear() - r.getFullYear())) && 0 === (G = q(g.getMonth() - r.getMonth())) && (G = q(g.getDate() - r.getDate()));
    return G;
  }
  function k(g) {
    switch(g.getDay()) {
      case 0:
        return new Date(g.getFullYear() - 1, 11, 29);
      case 1:
        return g;
      case 2:
        return new Date(g.getFullYear(), 0, 3);
      case 3:
        return new Date(g.getFullYear(), 0, 2);
      case 4:
        return new Date(g.getFullYear(), 0, 1);
      case 5:
        return new Date(g.getFullYear() - 1, 11, 31);
      case 6:
        return new Date(g.getFullYear() - 1, 11, 30);
    }
  }
  function n(g) {
    var r = g.J;
    for (g = new Date((new Date(g.K + 1900, 0, 1)).getTime()); 0 < r;) {
      var q = g.getMonth(), G = (Fc(g.getFullYear()) ? Gc : Hc)[q];
      if (r > G - g.getDate()) {
        r -= G - g.getDate() + 1, g.setDate(1), 11 > q ? g.setMonth(q + 1) : (g.setMonth(0), g.setFullYear(g.getFullYear() + 1));
      } else {
        g.setDate(g.getDate() + r);
        break;
      }
    }
    q = new Date(g.getFullYear() + 1, 0, 4);
    r = k(new Date(g.getFullYear(), 0, 4));
    q = k(q);
    return 0 >= m(r, g) ? 0 >= m(q, g) ? g.getFullYear() + 1 : g.getFullYear() : g.getFullYear() - 1;
  }
  var l = M[d + 40 >> 2];
  d = {qb:M[d >> 2], pb:M[d + 4 >> 2], Z:M[d + 8 >> 2], ha:M[d + 12 >> 2], $:M[d + 16 >> 2], K:M[d + 20 >> 2], C:M[d + 24 >> 2], J:M[d + 28 >> 2], ub:M[d + 32 >> 2], ob:M[d + 36 >> 2], rb:l ? l ? lc(L, l) : "" : ""};
  c = c ? lc(L, c) : "";
  l = {"%c":"%a %b %d %H:%M:%S %Y", "%D":"%m/%d/%y", "%F":"%Y-%m-%d", "%h":"%b", "%r":"%I:%M:%S %p", "%R":"%H:%M", "%T":"%H:%M:%S", "%x":"%m/%d/%y", "%X":"%H:%M:%S", "%Ec":"%c", "%EC":"%C", "%Ex":"%m/%d/%y", "%EX":"%H:%M:%S", "%Ey":"%y", "%EY":"%Y", "%Od":"%d", "%Oe":"%e", "%OH":"%H", "%OI":"%I", "%Om":"%m", "%OM":"%M", "%OS":"%S", "%Ou":"%u", "%OU":"%U", "%OV":"%V", "%Ow":"%w", "%OW":"%W", "%Oy":"%y",};
  for (var t in l) {
    c = c.replace(new RegExp(t, "g"), l[t]);
  }
  var w = "Sunday Monday Tuesday Wednesday Thursday Friday Saturday".split(" "), x = "January February March April May June July August September October November December".split(" ");
  l = {"%a":g => w[g.C].substring(0, 3), "%A":g => w[g.C], "%b":g => x[g.$].substring(0, 3), "%B":g => x[g.$], "%C":g => f((g.K + 1900) / 100 | 0, 2), "%d":g => f(g.ha, 2), "%e":g => e(g.ha, 2, " "), "%g":g => n(g).toString().substring(2), "%G":g => n(g), "%H":g => f(g.Z, 2), "%I":g => {
    g = g.Z;
    0 == g ? g = 12 : 12 < g && (g -= 12);
    return f(g, 2);
  }, "%j":g => {
    for (var r = 0, q = 0; q <= g.$ - 1; r += (Fc(g.K + 1900) ? Gc : Hc)[q++]) {
    }
    return f(g.ha + r, 3);
  }, "%m":g => f(g.$ + 1, 2), "%M":g => f(g.pb, 2), "%n":() => "\n", "%p":g => 0 <= g.Z && 12 > g.Z ? "AM" : "PM", "%S":g => f(g.qb, 2), "%t":() => "\t", "%u":g => g.C || 7, "%U":g => f(Math.floor((g.J + 7 - g.C) / 7), 2), "%V":g => {
    var r = Math.floor((g.J + 7 - (g.C + 6) % 7) / 7);
    2 >= (g.C + 371 - g.J - 2) % 7 && r++;
    if (r) {
      53 == r && (q = (g.C + 371 - g.J) % 7, 4 == q || 3 == q && Fc(g.K) || (r = 1));
    } else {
      r = 52;
      var q = (g.C + 7 - g.J - 1) % 7;
      (4 == q || 5 == q && Fc(g.K % 400 - 1)) && r++;
    }
    return f(r, 2);
  }, "%w":g => g.C, "%W":g => f(Math.floor((g.J + 7 - (g.C + 6) % 7) / 7), 2), "%y":g => (g.K + 1900).toString().substring(2), "%Y":g => g.K + 1900, "%z":g => {
    g = g.ob;
    var r = 0 <= g;
    g = Math.abs(g) / 60;
    return (r ? "+" : "-") + String("0000" + (g / 60 * 100 + g % 60)).slice(-4);
  }, "%Z":g => g.rb, "%%":() => "%"};
  c = c.replace(/%%/g, "\x00\x00");
  for (t in l) {
    c.includes(t) && (c = c.replace(new RegExp(t, "g"), l[t](d)));
  }
  c = c.replace(/\0\0/g, "%");
  t = Ic(c);
  if (t.length > a) {
    return 0;
  }
  Ba.set(t, b);
  return t.length - 1;
};
Object.assign(Za.prototype, {get(b) {
  return this.G[b];
}, has(b) {
  return void 0 !== this.G[b];
}, Za(b) {
  var a = this.ta.pop() || this.G.length;
  this.G[a] = b;
  return a;
}, $a(b) {
  this.G[b] = void 0;
  this.ta.push(b);
}});
$a = h.BindingError = class extends Error {
  constructor(b) {
    super(b);
    this.name = "BindingError";
  }
};
Q.G.push({value:void 0}, {value:null}, {value:!0}, {value:!1},);
Q.ua = Q.G.length;
h.count_emval_handles = function() {
  for (var b = 0, a = Q.ua; a < Q.G.length; ++a) {
    void 0 !== Q.G[a] && ++b;
  }
  return b;
};
cb = h.PureVirtualError = bb("PureVirtualError");
for (var Kc = Array(256), Lc = 0; 256 > Lc; ++Lc) {
  Kc[Lc] = String.fromCharCode(Lc);
}
db = Kc;
h.getInheritedInstanceCount = function() {
  return Object.keys(hb).length;
};
h.getLiveInheritedInstances = function() {
  var b = [], a;
  for (a in hb) {
    hb.hasOwnProperty(a) && b.push(hb[a]);
  }
  return b;
};
h.flushPendingDeletes = fb;
h.setDelayFunction = function(b) {
  gb = b;
  eb.length && gb && gb(fb);
};
zb = h.InternalError = class extends Error {
  constructor(b) {
    super(b);
    this.name = "InternalError";
  }
};
Mb.prototype.isAliasOf = function(b) {
  if (!(this instanceof Mb && b instanceof Mb)) {
    return !1;
  }
  var a = this.g.j.h, c = this.g.i, d = b.g.j.h;
  for (b = b.g.i; a.l;) {
    c = a.S(c), a = a.l;
  }
  for (; d.l;) {
    b = d.S(b), d = d.l;
  }
  return a === d && c === b;
};
Mb.prototype.clone = function() {
  this.g.i || Lb(this);
  if (this.g.O) {
    return this.g.count.value += 1, this;
  }
  var b = Cb, a = Object, c = a.create, d = Object.getPrototypeOf(this), e = this.g;
  b = b(c.call(a, d, {g:{value:{count:e.count, M:e.M, O:e.O, i:e.i, j:e.j, s:e.s, A:e.A,},}}));
  b.g.count.value += 1;
  b.g.M = !1;
  return b;
};
Mb.prototype["delete"] = function() {
  this.g.i || Lb(this);
  this.g.M && !this.g.O && R("Object already scheduled for deletion");
  ob(this);
  qb(this.g);
  this.g.O || (this.g.s = void 0, this.g.i = void 0);
};
Mb.prototype.isDeleted = function() {
  return !this.g.i;
};
Mb.prototype.deleteLater = function() {
  this.g.i || Lb(this);
  this.g.M && !this.g.O && R("Object already scheduled for deletion");
  eb.push(this);
  1 === eb.length && gb && gb(fb);
  this.g.M = !0;
  return this;
};
Vb.prototype.Va = function(b) {
  this.za && (b = this.za(b));
  return b;
};
Vb.prototype.pa = function(b) {
  this.H && this.H(b);
};
Vb.prototype.argPackAdvance = 8;
Vb.prototype.readValueFromPointer = Fb;
Vb.prototype.deleteObject = function(b) {
  if (null !== b) {
    b["delete"]();
  }
};
Vb.prototype.fromWireType = function(b) {
  function a() {
    return this.V ? Bb(this.h.B, {j:this.ib, i:c, A:this, s:b,}) : Bb(this.h.B, {j:this, i:b,});
  }
  var c = this.Va(b);
  if (!c) {
    return this.pa(b), null;
  }
  var d = yb(this.h, c);
  if (void 0 !== d) {
    if (0 === d.g.count.value) {
      return d.g.i = c, d.g.s = b, d.clone();
    }
    d = d.clone();
    this.pa(b);
    return d;
  }
  d = this.h.Ua(c);
  d = sb[d];
  if (!d) {
    return a.call(this);
  }
  d = this.U ? d.Ka : d.pointerType;
  var e = rb(c, this.h, d.h);
  return null === e ? a.call(this) : this.V ? Bb(d.h.B, {j:d, i:e, A:this, s:b,}) : Bb(d.h.B, {j:d, i:e,});
};
$b = h.UnboundTypeError = bb("UnboundTypeError");
var Nc = {_embind_create_inheriting_constructor:function(b, a, c) {
  b = U(b);
  a = nb(a, "wrapper");
  c = S(c);
  var d = [].slice, e = a.h, f = e.B, m = e.l.B, k = e.l.constructor;
  b = Ya(b, function() {
    e.l.ya.forEach(function(l) {
      if (this[l] === m[l]) {
        throw new cb(`Pure virtual function ${l} must be implemented in JavaScript`);
      }
    }.bind(this));
    Object.defineProperty(this, "__parent", {value:f});
    this.__construct.apply(this, d.call(arguments));
  });
  f.__construct = function() {
    this === f && R("Pass correct 'this' to __construct");
    var l = k.implement.apply(void 0, [this].concat(d.call(arguments)));
    ob(l);
    var t = l.g;
    l.notifyOnDestruction();
    t.O = !0;
    Object.defineProperties(this, {g:{value:t}});
    Cb(this);
    l = t.i;
    l = ib(e, l);
    hb.hasOwnProperty(l) ? R(`Tried to register registered instance: ${l}`) : hb[l] = this;
  };
  f.__destruct = function() {
    this === f && R("Pass correct 'this' to __destruct");
    ob(this);
    var l = this.g.i;
    l = ib(e, l);
    hb.hasOwnProperty(l) ? delete hb[l] : R(`Tried to unregister unregistered instance: ${l}`);
  };
  b.prototype = Object.create(f);
  for (var n in c) {
    b.prototype[n] = c[n];
  }
  return ab(b);
}, _embind_finalize_value_object:function(b) {
  var a = Db[b];
  delete Db[b];
  var c = a.fa, d = a.H, e = a.sa, f = e.map(m => m.Ya).concat(e.map(m => m.lb));
  V([b], f, m => {
    var k = {};
    e.forEach((n, l) => {
      var t = m[l], w = n.Wa, x = n.Xa, g = m[l + e.length], r = n.kb, q = n.mb;
      k[n.Sa] = {read:G => t.fromWireType(w(x, G)), write:(G, D) => {
        var v = [];
        r(q, G, g.toWireType(v, D));
        Eb(v);
      }};
    });
    return [{name:a.name, fromWireType:function(n) {
      var l = {}, t;
      for (t in k) {
        l[t] = k[t].read(n);
      }
      d(n);
      return l;
    }, toWireType:function(n, l) {
      for (var t in k) {
        if (!(t in l)) {
          throw new TypeError(`Missing field: "${t}"`);
        }
      }
      var w = c();
      for (t in k) {
        k[t].write(w, l[t]);
      }
      null !== n && n.push(d, w);
      return w;
    }, argPackAdvance:8, readValueFromPointer:Fb, v:d,}];
  });
}, _embind_register_bigint:function() {
}, _embind_register_bool:function(b, a, c, d, e) {
  var f = Jb(c);
  a = U(a);
  Ib(b, {name:a, fromWireType:function(m) {
    return !!m;
  }, toWireType:function(m, k) {
    return k ? d : e;
  }, argPackAdvance:8, readValueFromPointer:function(m) {
    if (1 === c) {
      var k = Ba;
    } else if (2 === c) {
      k = Ca;
    } else if (4 === c) {
      k = M;
    } else {
      throw new TypeError("Unknown boolean type size: " + a);
    }
    return this.fromWireType(k[m >> f]);
  }, v:null,});
}, _embind_register_class:function(b, a, c, d, e, f, m, k, n, l, t, w, x) {
  t = U(t);
  f = Z(e, f);
  k && (k = Z(m, k));
  l && (l = Z(n, l));
  x = Z(w, x);
  var g = Xa(t);
  Ob(g, function() {
    ac(`Cannot construct ${t} due to unbound types`, [d]);
  });
  V([b, a, c], d ? [d] : [], function(r) {
    r = r[0];
    if (d) {
      var q = r.h;
      var G = q.B;
    } else {
      G = Mb.prototype;
    }
    r = Ya(g, function() {
      if (Object.getPrototypeOf(this) !== D) {
        throw new $a("Use 'new' to construct " + t);
      }
      if (void 0 === v.I) {
        throw new $a(t + " has no accessible constructor");
      }
      var O = v.I[arguments.length];
      if (void 0 === O) {
        throw new $a(`Tried to invoke ctor of ${t} with invalid number of parameters (${arguments.length}) - expected (${Object.keys(v.I).toString()}) parameters instead!`);
      }
      return O.apply(this, arguments);
    });
    var D = Object.create(G, {constructor:{value:r},});
    r.prototype = D;
    var v = new Pb(t, r, D, x, q, f, k, l);
    v.l && (void 0 === v.l.T && (v.l.T = []), v.l.T.push(v));
    q = new Vb(t, v, !0, !1);
    G = new Vb(t + "*", v, !1, !1);
    var I = new Vb(t + " const*", v, !1, !0);
    sb[b] = {pointerType:G, Ka:I};
    Wb(g, r);
    return [q, G, I];
  });
}, _embind_register_class_class_function:function(b, a, c, d, e, f, m) {
  var k = cc(c, d);
  a = U(a);
  f = Z(e, f);
  V([], [b], function(n) {
    function l() {
      ac(`Cannot call ${t} due to unbound types`, k);
    }
    n = n[0];
    var t = `${n.name}.${a}`;
    a.startsWith("@@") && (a = Symbol[a.substring(2)]);
    var w = n.h.constructor;
    void 0 === w[a] ? (l.L = c - 1, w[a] = l) : (Nb(w, a, t), w[a].m[c - 1] = l);
    V([], k, function(x) {
      x = bc(t, [x[0], null].concat(x.slice(1)), null, f, m);
      void 0 === w[a].m ? (x.L = c - 1, w[a] = x) : w[a].m[c - 1] = x;
      if (n.h.T) {
        for (const g of n.h.T) {
          g.constructor.hasOwnProperty(a) || (g.constructor[a] = x);
        }
      }
      return [];
    });
    return [];
  });
}, _embind_register_class_class_property:function(b, a, c, d, e, f, m, k) {
  a = U(a);
  f = Z(e, f);
  V([], [b], function(n) {
    n = n[0];
    var l = `${n.name}.${a}`, t = {get() {
      ac(`Cannot access ${l} due to unbound types`, [c]);
    }, enumerable:!0, configurable:!0};
    t.set = k ? () => {
      ac(`Cannot access ${l} due to unbound types`, [c]);
    } : () => {
      R(`${l} is a read-only property`);
    };
    Object.defineProperty(n.h.constructor, a, t);
    V([], [c], function(w) {
      w = w[0];
      var x = {get() {
        return w.fromWireType(f(d));
      }, enumerable:!0};
      k && (k = Z(m, k), x.set = g => {
        var r = [];
        k(d, w.toWireType(r, g));
        Eb(r);
      });
      Object.defineProperty(n.h.constructor, a, x);
      return [];
    });
    return [];
  });
}, _embind_register_class_constructor:function(b, a, c, d, e, f) {
  var m = cc(a, c);
  e = Z(d, e);
  V([], [b], function(k) {
    k = k[0];
    var n = `constructor ${k.name}`;
    void 0 === k.h.I && (k.h.I = []);
    if (void 0 !== k.h.I[a - 1]) {
      throw new $a(`Cannot register multiple constructors with identical number of parameters (${a - 1}) for class '${k.name}'! Overload resolution is currently only performed using the parameter count, not actual type info!`);
    }
    k.h.I[a - 1] = () => {
      ac(`Cannot construct ${k.name} due to unbound types`, m);
    };
    V([], m, function(l) {
      l.splice(1, 0, null);
      k.h.I[a - 1] = bc(n, l, null, e, f);
      return [];
    });
    return [];
  });
}, _embind_register_class_function:function(b, a, c, d, e, f, m, k) {
  var n = cc(c, d);
  a = U(a);
  f = Z(e, f);
  V([], [b], function(l) {
    function t() {
      ac(`Cannot call ${w} due to unbound types`, n);
    }
    l = l[0];
    var w = `${l.name}.${a}`;
    a.startsWith("@@") && (a = Symbol[a.substring(2)]);
    k && l.h.ya.push(a);
    var x = l.h.B, g = x[a];
    void 0 === g || void 0 === g.m && g.className !== l.name && g.L === c - 2 ? (t.L = c - 2, t.className = l.name, x[a] = t) : (Nb(x, a, w), x[a].m[c - 2] = t);
    V([], n, function(r) {
      r = bc(w, r, l, f, m);
      void 0 === x[a].m ? (r.L = c - 2, x[a] = r) : x[a].m[c - 2] = r;
      return [];
    });
    return [];
  });
}, _embind_register_class_property:function(b, a, c, d, e, f, m, k, n, l) {
  a = U(a);
  e = Z(d, e);
  V([], [b], function(t) {
    t = t[0];
    var w = `${t.name}.${a}`, x = {get() {
      ac(`Cannot access ${w} due to unbound types`, [c, m]);
    }, enumerable:!0, configurable:!0};
    x.set = n ? () => {
      ac(`Cannot access ${w} due to unbound types`, [c, m]);
    } : () => {
      R(w + " is a read-only property");
    };
    Object.defineProperty(t.h.B, a, x);
    V([], n ? [c, m] : [c], function(g) {
      var r = g[0], q = {get() {
        var D = dc(this, t, w + " getter");
        return r.fromWireType(e(f, D));
      }, enumerable:!0};
      if (n) {
        n = Z(k, n);
        var G = g[1];
        q.set = function(D) {
          var v = dc(this, t, w + " setter"), I = [];
          n(l, v, G.toWireType(I, D));
          Eb(I);
        };
      }
      Object.defineProperty(t.h.B, a, q);
      return [];
    });
    return [];
  });
}, _embind_register_emval:function(b, a) {
  a = U(a);
  Ib(b, {name:a, fromWireType:function(c) {
    var d = S(c);
    ec(c);
    return d;
  }, toWireType:function(c, d) {
    return ab(d);
  }, argPackAdvance:8, readValueFromPointer:Fb, v:null,});
}, _embind_register_enum:function(b, a, c, d) {
  function e() {
  }
  c = Jb(c);
  a = U(a);
  e.values = {};
  Ib(b, {name:a, constructor:e, fromWireType:function(f) {
    return this.constructor.values[f];
  }, toWireType:function(f, m) {
    return m.value;
  }, argPackAdvance:8, readValueFromPointer:fc(a, c, d), v:null,});
  Ob(a, e);
}, _embind_register_enum_value:function(b, a, c) {
  var d = nb(b, "enum");
  a = U(a);
  b = d.constructor;
  d = Object.create(d.constructor.prototype, {value:{value:c}, constructor:{value:Ya(`${d.name}_${a}`, function() {
  })},});
  b.values[c] = d;
  b[a] = d;
}, _embind_register_float:function(b, a, c) {
  c = Jb(c);
  a = U(a);
  Ib(b, {name:a, fromWireType:function(d) {
    return d;
  }, toWireType:function(d, e) {
    return e;
  }, argPackAdvance:8, readValueFromPointer:gc(a, c), v:null,});
}, _embind_register_function:function(b, a, c, d, e, f) {
  var m = cc(a, c);
  b = U(b);
  e = Z(d, e);
  Ob(b, function() {
    ac(`Cannot call ${b} due to unbound types`, m);
  }, a - 1);
  V([], m, function(k) {
    Wb(b, bc(b, [k[0], null].concat(k.slice(1)), null, e, f), a - 1);
    return [];
  });
}, _embind_register_integer:function(b, a, c, d, e) {
  a = U(a);
  -1 === e && (e = 4294967295);
  e = Jb(c);
  var f = k => k;
  if (0 === d) {
    var m = 32 - 8 * c;
    f = k => k << m >>> m;
  }
  c = a.includes("unsigned") ? function(k, n) {
    return n >>> 0;
  } : function(k, n) {
    return n;
  };
  Ib(b, {name:a, fromWireType:f, toWireType:c, argPackAdvance:8, readValueFromPointer:hc(a, e, 0 !== d), v:null,});
}, _embind_register_memory_view:function(b, a, c) {
  function d(f) {
    f >>= 2;
    var m = P;
    return new e(m.buffer, m[f + 1], m[f]);
  }
  var e = [Int8Array, Uint8Array, Int16Array, Uint16Array, Int32Array, Uint32Array, Float32Array, Float64Array,][a];
  c = U(c);
  Ib(b, {name:c, fromWireType:d, argPackAdvance:8, readValueFromPointer:d,}, {ab:!0,});
}, _embind_register_std_string:function(b, a) {
  a = U(a);
  var c = "std::string" === a;
  Ib(b, {name:a, fromWireType:function(d) {
    var e = P[d >> 2], f = d + 4;
    if (c) {
      for (var m = f, k = 0; k <= e; ++k) {
        var n = f + k;
        if (k == e || 0 == L[n]) {
          m = m ? lc(L, m, n - m) : "";
          if (void 0 === l) {
            var l = m;
          } else {
            l += String.fromCharCode(0), l += m;
          }
          m = n + 1;
        }
      }
    } else {
      l = Array(e);
      for (k = 0; k < e; ++k) {
        l[k] = String.fromCharCode(L[f + k]);
      }
      l = l.join("");
    }
    mb(d);
    return l;
  }, toWireType:function(d, e) {
    e instanceof ArrayBuffer && (e = new Uint8Array(e));
    var f = "string" == typeof e;
    f || e instanceof Uint8Array || e instanceof Uint8ClampedArray || e instanceof Int8Array || R("Cannot pass non-string to std::string");
    var m = c && f ? jc(e) : e.length;
    var k = Mc(4 + m + 1), n = k + 4;
    P[k >> 2] = m;
    if (c && f) {
      ic(e, L, n, m + 1);
    } else {
      if (f) {
        for (f = 0; f < m; ++f) {
          var l = e.charCodeAt(f);
          255 < l && (mb(n), R("String has UTF-16 code units that do not fit in 8 bits"));
          L[n + f] = l;
        }
      } else {
        for (f = 0; f < m; ++f) {
          L[n + f] = e[f];
        }
      }
    }
    null !== d && d.push(mb, k);
    return k;
  }, argPackAdvance:8, readValueFromPointer:Fb, v:function(d) {
    mb(d);
  },});
}, _embind_register_std_wstring:function(b, a, c) {
  c = U(c);
  if (2 === a) {
    var d = nc;
    var e = qc;
    var f = rc;
    var m = () => Da;
    var k = 1;
  } else {
    4 === a && (d = sc, e = tc, f = uc, m = () => P, k = 2);
  }
  Ib(b, {name:c, fromWireType:function(n) {
    for (var l = P[n >> 2], t = m(), w, x = n + 4, g = 0; g <= l; ++g) {
      var r = n + 4 + g * a;
      if (g == l || 0 == t[r >> k]) {
        x = d(x, r - x), void 0 === w ? w = x : (w += String.fromCharCode(0), w += x), x = r + a;
      }
    }
    mb(n);
    return w;
  }, toWireType:function(n, l) {
    "string" != typeof l && R(`Cannot pass non-string to C++ string type ${c}`);
    var t = f(l), w = Mc(4 + t + a);
    P[w >> 2] = t >> k;
    e(l, w + 4, t + a);
    null !== n && n.push(mb, w);
    return w;
  }, argPackAdvance:8, readValueFromPointer:Fb, v:function(n) {
    mb(n);
  },});
}, _embind_register_value_object:function(b, a, c, d, e, f) {
  Db[b] = {name:U(a), fa:Z(c, d), H:Z(e, f), sa:[],};
}, _embind_register_value_object_field:function(b, a, c, d, e, f, m, k, n, l) {
  Db[b].sa.push({Sa:U(a), Ya:c, Wa:Z(d, e), Xa:f, lb:m, kb:Z(k, n), mb:l,});
}, _embind_register_void:function(b, a) {
  a = U(a);
  Ib(b, {cb:!0, name:a, argPackAdvance:0, fromWireType:function() {
  }, toWireType:function() {
  },});
}, _emscripten_get_now_is_monotonic:() => !0, _emval_as:function(b, a, c) {
  b = S(b);
  a = nb(a, "emval::as");
  var d = [], e = ab(d);
  P[c >> 2] = e;
  return a.toWireType(d, b);
}, _emval_call_method:function(b, a, c, d, e) {
  b = xc[b];
  a = S(a);
  c = wc(c);
  var f = [];
  P[d >> 2] = ab(f);
  return b(a, c, f, e);
}, _emval_call_void_method:function(b, a, c, d) {
  b = xc[b];
  a = S(a);
  c = wc(c);
  b(a, c, null, d);
}, _emval_decref:ec, _emval_get_method_caller:function(b, a) {
  var c = zc(b, a), d = c[0];
  a = d.name + "_$" + c.slice(1).map(function(m) {
    return m.name;
  }).join("_") + "$";
  var e = Ac[a];
  if (void 0 !== e) {
    return e;
  }
  var f = Array(b - 1);
  e = yc((m, k, n, l) => {
    for (var t = 0, w = 0; w < b - 1; ++w) {
      f[w] = c[w + 1].readValueFromPointer(l + t), t += c[w + 1].argPackAdvance;
    }
    m = m[k].apply(m, f);
    for (w = 0; w < b - 1; ++w) {
      c[w + 1].Na && c[w + 1].Na(f[w]);
    }
    if (!d.cb) {
      return d.toWireType(n, m);
    }
  });
  return Ac[a] = e;
}, _emval_get_module_property:function(b) {
  b = wc(b);
  return ab(h[b]);
}, _emval_get_property:function(b, a) {
  b = S(b);
  a = S(a);
  return ab(b[a]);
}, _emval_incref:function(b) {
  4 < b && (Q.get(b).Aa += 1);
}, _emval_new_cstring:function(b) {
  return ab(wc(b));
}, _emval_new_object:function() {
  return ab({});
}, _emval_run_destructors:function(b) {
  var a = S(b);
  Eb(a);
  ec(b);
}, _emval_set_property:function(b, a, c) {
  b = S(b);
  a = S(a);
  c = S(c);
  b[a] = c;
}, _emval_take_value:function(b, a) {
  b = nb(b, "_emval_take_value");
  b = b.readValueFromPointer(a);
  return ab(b);
}, abort:() => {
  ya("");
}, emscripten_date_now:function() {
  return Date.now();
}, emscripten_get_now:() => performance.now(), emscripten_memcpy_big:(b, a, c) => L.copyWithin(b, a, a + c), emscripten_resize_heap:b => {
  var a = L.length;
  b >>>= 0;
  if (2147483648 < b) {
    return !1;
  }
  for (var c = 1; 4 >= c; c *= 2) {
    var d = a * (1 + 0.2 / c);
    d = Math.min(d, b + 100663296);
    var e = Math;
    d = Math.max(b, d);
    a: {
      e = e.min.call(e, 2147483648, d + (65536 - d % 65536) % 65536) - za.buffer.byteLength + 65535 >>> 16;
      try {
        za.grow(e);
        Ga();
        var f = 1;
        break a;
      } catch (m) {
      }
      f = void 0;
    }
    if (f) {
      return !0;
    }
  }
  return !1;
}, environ_get:(b, a) => {
  var c = 0;
  Dc().forEach(function(d, e) {
    var f = a + c;
    e = P[b + 4 * e >> 2] = f;
    for (f = 0; f < d.length; ++f) {
      Ba[e++ >> 0] = d.charCodeAt(f);
    }
    Ba[e >> 0] = 0;
    c += d.length + 1;
  });
  return 0;
}, environ_sizes_get:(b, a) => {
  var c = Dc();
  P[b >> 2] = c.length;
  var d = 0;
  c.forEach(function(e) {
    d += e.length + 1;
  });
  P[a >> 2] = d;
  return 0;
}, fd_close:() => 52, fd_seek:function() {
  return 70;
}, fd_write:(b, a, c, d) => {
  for (var e = 0, f = 0; f < c; f++) {
    var m = P[a >> 2], k = P[a + 4 >> 2];
    a += 8;
    for (var n = 0; n < k; n++) {
      var l = L[m + n], t = Ec[b];
      0 === l || 10 === l ? ((1 === b ? ta : ua)(lc(t, 0)), t.length = 0) : t.push(l);
    }
    e += k;
  }
  P[d >> 2] = e;
  return 0;
}, strftime_l:(b, a, c, d) => Jc(b, a, c, d)};
(function() {
  function b(c) {
    J = c = c.exports;
    za = J.memory;
    Ga();
    Ha = J.__indirect_function_table;
    Ja.unshift(J.__wasm_call_ctors);
    Ma--;
    h.monitorRunDependencies && h.monitorRunDependencies(Ma);
    if (0 == Ma && (null !== Na && (clearInterval(Na), Na = null), Oa)) {
      var d = Oa;
      Oa = null;
      d();
    }
    return c;
  }
  var a = {env:Nc, wasi_snapshot_preview1:Nc,};
  Ma++;
  h.monitorRunDependencies && h.monitorRunDependencies(Ma);
  if (h.instantiateWasm) {
    try {
      return h.instantiateWasm(a, b);
    } catch (c) {
      ua("Module.instantiateWasm callback failed with error: " + c), ba(c);
    }
  }
  Va(a, function(c) {
    b(c.instance);
  }).catch(ba);
  return {};
})();
var mb = b => (mb = J.free)(b), Mc = b => (Mc = J.malloc)(b), lb = b => (lb = J.__getTypeName)(b);
h.__embind_initialize_bindings = () => (h.__embind_initialize_bindings = J._embind_initialize_bindings)();
h.dynCall_jiji = (b, a, c, d, e) => (h.dynCall_jiji = J.dynCall_jiji)(b, a, c, d, e);
h.dynCall_viijii = (b, a, c, d, e, f, m) => (h.dynCall_viijii = J.dynCall_viijii)(b, a, c, d, e, f, m);
h.dynCall_iiiiij = (b, a, c, d, e, f, m) => (h.dynCall_iiiiij = J.dynCall_iiiiij)(b, a, c, d, e, f, m);
h.dynCall_iiiiijj = (b, a, c, d, e, f, m, k, n) => (h.dynCall_iiiiijj = J.dynCall_iiiiijj)(b, a, c, d, e, f, m, k, n);
h.dynCall_iiiiiijj = (b, a, c, d, e, f, m, k, n, l) => (h.dynCall_iiiiiijj = J.dynCall_iiiiiijj)(b, a, c, d, e, f, m, k, n, l);
var Oc;
Oa = function Pc() {
  Oc || Qc();
  Oc || (Oa = Pc);
};
function Qc() {
  function b() {
    if (!Oc && (Oc = !0, h.calledRun = !0, !Aa)) {
      Wa(Ja);
      aa(h);
      if (h.onRuntimeInitialized) {
        h.onRuntimeInitialized();
      }
      if (h.postRun) {
        for ("function" == typeof h.postRun && (h.postRun = [h.postRun]); h.postRun.length;) {
          var a = h.postRun.shift();
          Ka.unshift(a);
        }
      }
      Wa(Ka);
    }
  }
  if (!(0 < Ma)) {
    if (h.preRun) {
      for ("function" == typeof h.preRun && (h.preRun = [h.preRun]); h.preRun.length;) {
        La();
      }
    }
    Wa(Ia);
    0 < Ma || (h.setStatus ? (h.setStatus("Running..."), setTimeout(function() {
      setTimeout(function() {
        h.setStatus("");
      }, 1);
      b();
    }, 1)) : b());
  }
}
if (h.preInit) {
  for ("function" == typeof h.preInit && (h.preInit = [h.preInit]); 0 < h.preInit.length;) {
    h.preInit.pop()();
  }
}
Qc();



  return moduleArg.ready
}

);
})();
export default Rive;